import { Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import {
  postTruckTypesAPI,
} from "../../../../../api/modules";

import { BiTrash } from "react-icons/bi";

// react-bootstrap components
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { errorToast, successToast } from "../../../../../services/toastService";
import LogInput from "../../../../../components/atoms/LogInput";
import LogButton from "../../../../../components/atoms/LogButton";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ContentHeader from "../../../components/molecules/ContentHeader";
import ModalQuestion from "../../../../../components/organisms/modal-question/ModalQuestion";

const BtnInvisible = styled(Button)`
  background: none;
  border: none;
  &:hover {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
  &:active {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
  &:focus {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
`;
const RowDeleteStyled = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  right: 15px;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
`;

const CardLogBootstrapStyled = styled(Card)`
  padding: 10px;
  border-radius: 15px;
`;

function TruckType() {
  const [modalExit, setModalExit] = useState(false);
  const history = useHistory();
  const [entity] = useState({
    id_truckType: {},
  });


  let ProfileSchema = Yup.object().shape({
    truck_type_name: Yup.string().required(`Nombre del Tipo de camión es obligatorio`),
  });


  const handleDeleteItem = async (id) => {
    // const resp = await delTUnitByIdAPI(id);
    // const { msg } = resp;
    // successToast(msg);
    // history.push("/app/my-units");
  };

  const handleCloseExitModal = async (response) => {
    if (response) {
      await handleDeleteItem(entity._id);
      setModalExit(false);
    } else {
      setModalExit(false);
    }
  };

  const RowDelete = () => {
    return entity._id ? (
      <RowDeleteStyled>
        <OverlayTrigger
          key="delete"
          overlay={<Tooltip id={`delete`}>Eliminar</Tooltip>}
        >
          <BtnInvisible onClick={() => setModalExit(true)}>
            <BiTrash />{" "}
          </BtnInvisible>
        </OverlayTrigger>
      </RowDeleteStyled>
    ) : (
      ""
    );
  };

  const handleSubmitAPI = async (values) => {
    let body = null;
    body = {
      ...values,
    };
    const response = await postTruckTypesAPI(body);
    const { msg, truckType } = response;
    if (truckType) {
      successToast(msg);
      history.push("/app/truck-types");
    } else {
      errorToast(msg);
    }
  };

  return (
    <>
      <Container fluid>
        <Formik
          enableReinitialize
          initialValues={{
            truck_type_name: entity.truck_type_name || "",
          }}
          validationSchema={ProfileSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmitAPI(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Row>
              <ContentHeader
                className={`ml-3`}
                title={
                  entity._id
                    ? `Editá un nuevo Tipo de camión`
                    : `Registrá un nuevo Tipo de camión`
                }
              />
              <Col md="12">
                <CardLogBootstrapStyled className="shadow-4dp pt-3">
                  <CardLogBootstrapStyled.Body>
                    <RowDelete />
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <label className="text-primary font-weight-bold">
                            Ingresá el nombre del tipo de camión
                          </label>
                          <LogInput
                            placeholder="Ingresá tipo de camión"
                            type="text"
                            name="truck_type_name"
                            onChange={(e) => {
                              const { value } = e.target;
                              setFieldValue("truck_type_name", value);
                            }}
                            onBlur={handleBlur}
                            errors={errors}
                            value={values.truck_type_name}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </CardLogBootstrapStyled.Body>
                </CardLogBootstrapStyled>
              </Col>
              <LogButton
                className="mt-4 btn-list-bottom"
                size="lg"
                title={`${entity._id ? "Editar" : "Agregar"
                  } tipo de camión`}
                border="curve"
                block
                onClick={handleSubmit}
              />
              <div className="clearfix"></div>
              <ModalQuestion
                title="¿Estás seguro de esta accion?"
                subTitle="¿Estás seguro?"
                isShow={modalExit}
                handleClose={handleCloseExitModal}
              />
            </Row>
          )}
        </Formik>
      </Container>
    </>
  );
}

export default TruckType;
