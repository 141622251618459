/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Container,
  Modal,
} from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import styled from "styled-components";

import { CardLogBootstrapStyled } from "../../../../../../styledComponents/CardBootstrapLog";
import { toNumber } from "lodash";
import { COLOR_GRAY_TEXT } from "../../../../../../constants/Colors";
import { priceCalculation, turnString } from "../../../../../../utils/helpers";
import LogButton from "../../../../../../components/atoms/LogButton";
import ModalRating from "../../../Modal-Rating/ModalRating";
import ModalMapViewByProps from "../../../MyTravelsToWork/TravelDetail/ModalMapView/ModalMapViewByProps";
const PAYMENT_TYPE_NAME = process.env.REACT_APP_TOMADOR_PAYMENT_NAME;

const DivInfoUser = styled.div`
  display: flex;
  align-items: center;
`;

const DivBtnShowInfo = styled.div`
  min-width: 100px;
  height: 50px;
  color: rgb(163, 199, 59);
  background-color: #effbcd;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 10px;
`;

const convertItemIntoCommatsDot = (array) => {
  return array?.length > 0
    ? array?.length > 1
      ? [array?.slice(0, -1).join(", "), array?.slice(-1)[0]].join(" y ")
      : array[0]
    : "";
};

const ModalLogStyled = styled(Modal)`
  opacity: 1;
  width: 100%;

  .modal-content {
    overflow-y: hidden;
    position: relative;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #d62929;
    }
    border-radius: 15px !important;
    /* padding: 20px; */
    max-height: 90vh;
    &:hover {
      overflow-y: overlay;
    }
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;

const TravelDetailPostulation = ({
  isShow,
  handleClose,
  travelData,
  handlePostulation,
}) => {
  const [entity, setEntity] = useState(null);
  const [isModalMapView, setIsModalMapView] = useState(false);
  const [isRating, setIsRating] = useState(false);

  useEffect(() => {
    getFetch();
  }, []);

  const getFetch = async () => {
    if (travelData) {
      let namesTruckFeatures = travelData.truck_features?.map(
        (tf) => tf.truck_characteristic_name
      );
      let namesTruckAccessories = travelData.truck_accessories?.map(
        (tf) => tf.accesory_name
      );
      let namesTruckTypes = travelData.truck_type?.map(
        (tf) => tf.truck_type_name
      );

      setEntity({
        ...travelData,
        truck_features: convertItemIntoCommatsDot(namesTruckFeatures),
        truck_accessories: convertItemIntoCommatsDot(namesTruckAccessories),
        truck_type: convertItemIntoCommatsDot(namesTruckTypes),
      });
    }
  };
  return entity ? (
    <ModalLogStyled
      className="modal-exit"
      centered
      size={"xl"}
      show={isShow}
      onHide={handleClose}
      onClose={handleClose}
    >
      <Modal.Body>
        <Container fluid>
          <Row className="">
            <Col md="6">
              <DivInfoUser>
                <h2 className=" text-primary font-weight-bold mb-0">
                  ID: {entity.id_trip}
                </h2>
                <div
                  className="ml-2 cursor-pointer"
                  onClick={() => setIsRating(true)}
                >
                  <p className="font-weight-bold mb-0 text-capitalize h4">
                    {/* {entity.user_id_dc.name} {entity.user_id_dc.last_name} */}
                  </p>
                  {entity._id && (
                    <OverlayTrigger
                      placement={"top"}
                      overlay={
                        <Tooltip id={`tooltip-left`}>
                          {entity.score
                            ? "Puntaje " + entity.score
                            : "Sin puntaje"}
                        </Tooltip>
                      }
                    >
                      <Row className="ml-0">
                        <ReactStars
                          value={toNumber(entity.score) || 0}
                          count={5}
                          size={36}
                          activeColor="#ffd700"
                          color="#CDE0EB"
                          isHalf={true}
                          edit={false}
                        />
                      </Row>
                    </OverlayTrigger>
                  )}
                </div>
              </DivInfoUser>
            </Col>
            <Col
              md="6"
              className="d-flex"
              style={{ justifyContent: "space-evenly", alignItems: "center" }}
            >
              <DivBtnShowInfo>
                {entity.product?.product_type_name}
              </DivBtnShowInfo>

              <DivBtnShowInfo>
                {`Distancia: ${entity.kilometers} km`}
              </DivBtnShowInfo>

              <LogButton
                title="Ver mapa"
                border="curve"
                onClick={() => {
                  setIsModalMapView(true);
                }}
                color="transparent"
                style={{ color: "black" }}
              ></LogButton>
            </Col>
          </Row>
          <Row>
            <Col md="6" className="mt-4">
              <CardLogBootstrapStyled className="shadow-6dp p-4">
                <Row>
                  <Col className="d-flex align-items-center">
                    <div className="circle-icon">
                      <span className="icon-ping text-primary"></span>
                    </div>
                    <p className="mb-0 text-primary h4">
                      <strong>Origen</strong>{" "}
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <p className="mb-0 text-capitalize">
                      {" "}
                      <strong>Lugar:</strong>{" "}
                      {entity?.origin_site?.locality?.name?.toLowerCase()}
                      {", "}
                      {entity?.origin_site.locality?.id_province?.name?.toLowerCase()}
                    </p>
                    <p className="mb-0 text-capitalize">
                      {" "}
                      <strong>Tipo de origen:</strong>{" "}
                      {entity.origin?.toLowerCase()}
                    </p>
                    <p className="mb-0 text-capitalize">
                      {" "}
                      <strong>Fecha:</strong>{" "}
                      {moment(entity.withdraw_date).format("DD/MM/YYYY")}, turno{" "}
                      {turnString(entity.withdraw_turn)?.toLowerCase()}
                    </p>
                  </Col>
                </Row>
              </CardLogBootstrapStyled>
            </Col>
            <Col md="6" className="mt-4">
              <CardLogBootstrapStyled className="shadow-6dp p-4">
                <Row>
                  <Col className="d-flex align-items-center">
                    <div className="circle-icon">
                      <span className="icon-ping text-primary"></span>
                    </div>
                    <p className="mb-0 text-primary h4">
                      <strong>Destino</strong>{" "}
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <p className="mb-0 text-capitalize">
                      {" "}
                      <strong>Lugar:</strong>{" "}
                      {entity.destiny_site.locality?.name?.toLowerCase()}
                      {", "}
                      {entity.destiny_site.locality?.id_province?.name?.toLowerCase()}
                    </p>
                    <p className="mb-0 text-capitalize">
                      {" "}
                      <strong>Tipo de destino:</strong>{" "}
                      {entity.destiny?.toLowerCase()}
                    </p>
                    <p className="mb-0 text-capitalize">
                      {" "}
                      <strong>Fecha:</strong>{" "}
                      {moment(entity.delivery_date).format("DD/MM/YYYY")}, turno{" "}
                      {turnString(entity.delivery_turn)?.toLowerCase()}
                    </p>
                  </Col>
                </Row>
              </CardLogBootstrapStyled>
            </Col>
          </Row>
          <Row>
            <Col md="6" className="mt-4 ">
              <CardLogBootstrapStyled
                className="shadow-6dp p-4"
                style={{ height: "100%" }}
              >
                <Row>
                  <Col className="d-flex align-items-center">
                    <div className="circle-icon">
                      <span className="icon-truck text-primary"></span>
                    </div>
                    <p className="mb-0 text-primary h4">
                      <strong>Camiones</strong>{" "}
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <p className="mb-0">
                      <strong>Camiones ofrecidos:</strong>{" "}
                      {entity.postulation?.number_trucks}
                    </p>
                    <p className="mb-0 text-capitalize">
                      <strong>Carga por camión:</strong> {entity.truck_charge}{" "}
                      {entity.unit_truck_charge}
                    </p>
                    <p className="mb-0">
                      <strong>Tipo:</strong> {entity.truck_type}
                    </p>
                    <p className="mb-0">
                      <strong>Características y accesorios:</strong>{" "}
                      {entity.truck_features && entity.truck_accessories
                        ? `${entity.truck_features} - ${entity.truck_accessories}`
                        : entity.truck_features
                        ? entity.truck_features
                        : entity.truck_accessories
                        ? entity.truck_accessories
                        : "" || "Sin especificar"}
                    </p>
                  </Col>
                </Row>
              </CardLogBootstrapStyled>
            </Col>
            <Col md="6" className="mt-4">
              <CardLogBootstrapStyled
                className="shadow-6dp p-4"
                style={{ height: "100%" }}
              >
                <Row>
                  <Col className="d-flex align-items-center">
                    <div className="circle-icon">
                      <span className="icon-cash text-primary"></span>
                    </div>
                    <p className="mb-0 text-primary h4">
                      <strong>Tarifa</strong>{" "}
                    </p>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <p className="mb-0 text-capitalize">
                      <strong>
                        {priceCalculation(
                          entity.postulation.price_ton_postulation ||
                            entity.price_per_ton,
                          entity.postulation.discount_trip ||
                            entity.postulation.discount_tomador +
                              entity.postulation.discount_dador,
                          entity.price_type == 1
                        )}
                        {"/" + entity.unit_truck_charge?.slice(0, 3)} (precio{" "}
                        {entity.price_type === "1" ? "abierto" : "cerrado"})
                      </strong>
                    </p>
                    <p className="mb-0">
                      <strong>Forma de pago:</strong>{" "}
                      {PAYMENT_TYPE_NAME || "E-cheq"}
                    </p>
                    <p className="mb-0">
                      <strong>Plazo de pagos en días:</strong>{" "}
                      {entity.postulation.payment_terms_days_postulation ||
                        entity.payment_terms_days}
                    </p>
                  </Col>
                </Row>
              </CardLogBootstrapStyled>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 className="text-primary font-weight-bold h3 mt-3">
                Requisitos y condiciones:{" "}
                <span
                  className="font-weight-normal h4"
                  style={{ color: "black" }}
                >
                  {entity.postulation.requirements_conditions ||
                    entity.requirements_conditions ||
                    "No disponible"}
                </span>
              </h1>
            </Col>
          </Row>
          <Row>
            {/* <Col md="3">
              <LogButton
                className="mt-3 w-100"
                title="Ofertar"
                border="curve"
                block="true"
                size="lg"
                onClick={handlePostulation}
              />
            </Col> */}
            <Col md={{ span: 3, offset: 9 }}>
              <LogButton
                color="secondary"
                className="mt-3 w-100"
                title="Cerrar"
                border="curve"
                block="true"
                size="lg"
                onClick={handleClose}
              />
            </Col>
          </Row>

          {entity._id && isModalMapView && (
            <ModalMapViewByProps
              isModalMapView={isModalMapView}
              entity={entity}
              onClosePostulation={(payload) => {
                setIsModalMapView(false);
              }}
            ></ModalMapViewByProps>
          )}

          {isRating ? (
            <ModalRating
              tripId={entity._id}
              showId={entity.id_trip}
              score={entity.score}
              isRating={isRating}
              onCloseRating={() => {
                setIsRating(false);
              }}
            />
          ) : null}
        </Container>
      </Modal.Body>
    </ModalLogStyled>
  ) : null;
};

export default TravelDetailPostulation;
