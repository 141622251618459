import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  getTruckTypeAPI,
  postTruckCharacteristicAPI,
} from "../../../../../api/modules";

import { BiTrash } from "react-icons/bi";

// react-bootstrap components
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { errorToast, successToast } from "../../../../../services/toastService";
import LogInput from "../../../../../components/atoms/LogInput";
import LogButton from "../../../../../components/atoms/LogButton";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import ContentHeader from "../../../components/molecules/ContentHeader";
import ModalQuestion from "../../../../../components/organisms/modal-question/ModalQuestion";
import LogSelectMulti from "../../../../../components/atoms/LogSelectMultiCustom";

const BtnInvisible = styled(Button)`
  background: none;
  border: none;
  &:hover {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
  &:active {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
  &:focus {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
`;
const RowDeleteStyled = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  right: 15px;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
`;

const CardLogBootstrapStyled = styled(Card)`
  padding: 10px;
  border-radius: 15px;
`;

function TruckCharac() {
  const [modalExit, setModalExit] = useState(false);

  const history = useHistory();
  const [truckTypes, setTruckTypes] = useState([]);
  const [entity] = useState({
    id_truckType: {},
  });


  let ProfileSchema = Yup.object().shape({
    truck_characteristic_name: Yup.string().required(`Nombre del Tipo de camión es obligatorio`),
    _truckType: Yup.array(),
  });


  useEffect(() => {
    const getFetchData = async () => {
      // ------------ truckTypes
      const respTruckTypeAPI = await getTruckTypeAPI();
      const { truckTypes } = respTruckTypeAPI;
      try {
        const TruckTypeAPIFormatted = truckTypes.map((l) => ({
          _id: l._id,
          label: l.truck_type_name,
          value: l._id,
        }));
        setTruckTypes(TruckTypeAPIFormatted);
      } catch (error) {
        setTruckTypes([]);
      }
    };

    getFetchData();
  }, []);


  const handleDeleteItem = async (id) => {
    // const resp = await delTUnitByIdAPI(id);
    // const { msg } = resp;
    // successToast(msg);
    // history.push("/app/my-units");
  };

  const handleCloseExitModal = async (response) => {
    if (response) {
      await handleDeleteItem(entity._id);
      setModalExit(false);
    } else {
      setModalExit(false);
    }
  };

  const RowDelete = () => {
    return entity._id ? (
      <RowDeleteStyled>
        <OverlayTrigger
          key="delete"
          overlay={<Tooltip id={`delete`}>Eliminar</Tooltip>}
        >
          <BtnInvisible onClick={() => setModalExit(true)}>
            <BiTrash />{" "}
          </BtnInvisible>
        </OverlayTrigger>
      </RowDeleteStyled>
    ) : (
      ""
    );
  };

  const handleSubmitAPI = async (values) => {
    let body = null;
    body = {
      ...values,
    };
    const response = await postTruckCharacteristicAPI(body);
    const { msg, truckChar } = response;
    if (truckChar) {
      successToast(msg);
      history.push("/app/truck-charac");
    } else {
      errorToast(msg);
    }
  };

  return (
    <>
      <Container fluid>
        <Formik
          enableReinitialize
          initialValues={{
            truck_characteristic_name: entity.truck_characteristic_name || "",
            _truckType: entity._truckType || [],
          }}
          validationSchema={ProfileSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmitAPI(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Row>
              <ContentHeader
                className={`ml-3`}
                title={
                  entity._id
                    ? `Editá un nuevo característica`
                    : `Registrá un nueva de característica`
                }
              />
              <Col md="12">
                <CardLogBootstrapStyled className="shadow-4dp pt-3">
                  <CardLogBootstrapStyled.Body>
                    <RowDelete />
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <label className="text-primary font-weight-bold">
                            Ingresá el nombre la característica de camión
                          </label>
                          <LogInput
                            placeholder="Característica de camión"
                            type="text"
                            name="truck_characteristic_name"
                            onChange={(e) => {
                              const { value } = e.target;
                              setFieldValue("truck_characteristic_name", value);
                            }}
                            onBlur={handleBlur}
                            errors={errors}
                            value={values.truck_characteristic_name}
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group>
                          <label className="text-primary font-weight-bold">
                            Ingresá los tipos de camión asociadas
                          </label>
                          <LogSelectMulti
                            placeholder="Tipos de camión"
                            className="mt-1"
                            name="_truckType"
                            items={truckTypes}
                            onChange={(v) => {
                              setFieldValue("_truckType", v);
                            }}
                            onBlur={handleBlur}
                            disabled={truckTypes.length === 0}
                            errors={errors}
                            value={values._truckType}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </CardLogBootstrapStyled.Body>
                </CardLogBootstrapStyled>
              </Col>
              <LogButton
                className="mt-4 btn-list-bottom"
                size="lg"
                title={`${entity._id ? "Editar" : "Agregar"
                  } característica`}
                border="curve"
                block
                onClick={handleSubmit}
              />
              <div className="clearfix"></div>
              <ModalQuestion
                title="¿Estás seguro de esta accion?"
                subTitle="¿Estás seguro?"
                isShow={modalExit}
                handleClose={handleCloseExitModal}
              />
            </Row>
          )}
        </Formik>
      </Container>
    </>
  );
}

export default TruckCharac;
