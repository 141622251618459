import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { Checkbox, Table } from "rsuite";
const { Column, HeaderCell, Cell, ColumnGroup } = Table;

function LiquidTable({ data  }) {
  const [liquidData, setLiquidData] = useState([]);

  useEffect(() => {
    let runningBalance = data?.lp_id?.amount; 

    const formattedliquidData = [
      {
        lp_date: data?.lp_id?.date,
        lp_number: data?.lp_id?.number,
        lp_amount: data?.lp_id?.amount,
        saldo: data?.lp_id?.amount, 
      },
      ...data?.lp_id.paymentOrder_id?.map((order) => {
        runningBalance -= order.amount;
        return {
          ...order,
          saldo: runningBalance,
        };
      }),
    ];

    setLiquidData(formattedliquidData);
  }, [data]);

  return (
    <Row className="mt-4">
      <Col md="12">
        <Table
          height={550}
          bordered
          cellBordered
          wordWrap={true}
          data={liquidData}
        >
          <Column flexGrow={1} align="center">
            <HeaderCell>Fecha</HeaderCell>
            <Cell dataKey="fecha">
              {(rowData) => (
                <p>
                  {moment
                    .utc(rowData.date || rowData.receipt_date)
                    .format("DD/MM/YYYY")}
                </p>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Comprobante</HeaderCell>
            <Cell dataKey="comprobante">
              {(rowData) => (
                <p>{rowData.lp_date ? "LP" : "Orden pago"}</p>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="right">
            <HeaderCell>Nro de Comprobante</HeaderCell>
            <Cell dataKey="nroComprobante">
              {(rowData) => (
                <p>{rowData.lp_number || rowData.number}</p>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="right">
            <HeaderCell>Importe</HeaderCell>
            <Cell dataKey="importe">
              {(rowData) => (
                <p>
                  {" "}
                  <CurrencyFormat
                    value={rowData.lp_amount || -rowData.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={"$"}
                  />
                </p>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="right">
            <HeaderCell align="middle">Saldo</HeaderCell>
            <Cell dataKey="saldo">
              {(rowData) => (
                <p>
                  {" "}
                  <CurrencyFormat
                    value={rowData.saldo}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={"$"}
                  />
                </p>
              )}
            </Cell>
          </Column>
        </Table>
      </Col>
    </Row>
  );
}

export default LiquidTable;
