import React from "react";
import { Col, Image, Row, Stack } from "react-bootstrap";
import { GoFilePdf } from "react-icons/go";
import DefaultAvatar from "../../../assets/img/default-avatar.png";
import moment from "moment";

function ChatInbox({ name, date, document, comment, avatar }) {
  return (
    <Col xs={11} md={9} className="p-2 mb-2 rounded shadow-sm">
      <Row className="m-0 d-flex justify-content-between ">
        <p className="small mb-1 ">{moment(date).format("DD/MM/YY HH:mm")}</p>
        <p className="small mb-1 mt-0 text-capitalize">{name}</p>
      </Row>
      <Row className="m-0 justify-content-start">
        <Col xs={0} md={2}  className="p-0">
          <Image
            roundedCircle
            src={avatar || DefaultAvatar}
            alt="avatar 2"
            className="w-100 mh-100 d-none d-md-block"
            //style={{ width: "45px", maxHeight: "100%" }}
          />
        </Col>

        <Col xs={12} md={10} >
          {document ? (
            document.endsWith(".pdf") ? (
              <Stack gap={3}>
                <div>
                  <GoFilePdf size={100} />
                </div>
                {/*  mb-1 */}
              </Stack>
            ) : (
              <a href={document} target="_blank">
                {" "}
                <img
                  className="mh-100 w-100 mb-1"
                  src={document}
                  alt="fallo carga"
                />
              </a>
            )
          ) : null}

          {comment ? (
            <p
              className="small p-2 ms-3 mb-3 rounded-3"
              style={{ backgroundColor: "#f5f6f7" }}
            >
              {comment}
            </p>
          ) : null}
        </Col>
      </Row>
    </Col>
  );
}

export default ChatInbox;
