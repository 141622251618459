import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { BiEdit } from "react-icons/bi";
import { Table } from "rsuite";
import { getBillingsDador } from "../../../../../api/modules";
import ModalReceipt from "./Components/Modalreceipt";
import { CardLogBootstrapStyled } from "../../../../../styledComponents/CardBootstrapLog";
const { Column, HeaderCell, Cell, ColumnGroup } = Table;
const TAXES_LABEL = process.env.REACT_APP_TAXES_LABEL;

function BillingDador() {
  const [receiptModal, setReceiptModal] = useState({ isShow: false, data: {} });
  const INITIAL_STATE = {
    total: 0,
    data: [],
    id_dador: null,
    id_tomador: null,
  };
  const [dadorBalance, setDadorBalance] = useState("");
  const [dadorInvoice, setDadorInvoice] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [billing, setBilling] = useState(INITIAL_STATE);

  const closeReceiptModal = () => setReceiptModal({ isShow: false, data: {} });
  const openReceiptModal = (rowData) =>
    setReceiptModal({ isShow: true, data: rowData });

  const calculateDadorBalance = () => {
    const uniqueInvoiceIds = new Set();
    let sum = 0;

    billing.data?.forEach((item) => {
      const invoiceId = item.invoice_id?._id;

      if (typeof invoiceId === "string" && !uniqueInvoiceIds.has(invoiceId)) {
        uniqueInvoiceIds.add(invoiceId);

        const amount = item.invoice_id?.amount || 0;
        const receiptAmounts =
          item.invoice_id?.receipts?.map((receipt) => receipt.receipt_amount) ||
          [];

        const totalReceiptAmount = receiptAmounts.reduce(
          (accumulator, currentItem) => accumulator + currentItem,
          0
        );

        sum += amount - totalReceiptAmount;
      }
    });

    setDadorBalance(sum);
  };

  const calculateInvoiceDador = () => {
    const uniqueInvoiceIds = new Set();
    let sum = 0;

    billing.data?.forEach((item) => {
      const invoiceId = item.invoice_id?._id;

      if (typeof invoiceId === "string" && !uniqueInvoiceIds.has(invoiceId)) {
        uniqueInvoiceIds.add(invoiceId);

        const amount = item.invoice_id?.amount || 0;

        sum += amount;
      }
    });

    setDadorInvoice(sum);
  };

  const getData = async () => {
    try {
      const { total, accounts, id_dador, id_tomador } =
        await getBillingsDador();
      setBilling({ total, data: accounts, id_dador, id_tomador });
      if (receiptModal.data) {
        let updatedData = accounts?.find(
          (ele) => ele._id === receiptModal.data._id
        );
        setReceiptModal((prev) => {
          return { ...prev, data: updatedData };
        });
      }
    } catch (error) {
      console.error("Billing Admin", error);
    }
  };
  const onRefetch = () => setRefetch((prev) => !prev);

  useEffect(() => {
    getData();
  }, [refetch]);

  useEffect(() => {
    calculateDadorBalance();
    calculateInvoiceDador();
  }, [billing]);

  return (
    <Container fluid>
      <Row /* className="mt-5" */>
        <Col>
          <h1 className="text-primary h3 font-weight-bold">Mis cuentas</h1>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="12">
          <h1 className=" h6 font-weight-bold font-italic">
            {TAXES_LABEL || "Montos netos sin impuestos"}
          </h1>
        </Col>
        <Col md="12">
          <Table
            rowHeight={30}
            hover={false}
            height={550}
            bordered
            cellBordered
            headerHeight={70}
            wordWrap={true}
            data={billing.data}
            rowClassName="p-0"
          >
            <ColumnGroup header="Viajes Finalizados" align="center">
              <Column flexGrow={2}>
                <HeaderCell>ID viaje</HeaderCell>
                <Cell dataKey="id_viaje">
                  {(rowData) => {
                    return (
                      <>
                        <p className="mb-0">
                          {rowData.parent_id + rowData.order || "No posee"}{" "}
                        </p>
                      </>
                    );
                  }}
                </Cell>
              </Column>
              <Column flexGrow={3}>
                <HeaderCell>Importe</HeaderCell>
                <Cell dataKey="billing_amount">
                  {(rowData) => {
                    return typeof rowData.auditedWeight === "number" ? (
                      <CurrencyFormat
                        value={
                          rowData.discount_trip
                            ? rowData.price_per_ton * rowData.auditedWeight
                            : rowData.price_per_ton * rowData.auditedWeight
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix={"$"}
                        renderText={(formattedValue) => (
                          <div>
                            <span>{formattedValue}</span>
                          </div>
                        )}
                      />
                    ) : (
                      <div>
                        <span>En Auditoria</span>
                      </div>
                    );
                  }}
                </Cell>
              </Column>
            </ColumnGroup>
            <ColumnGroup header="Dador" align="center">
              <Column
                flexGrow={3}
                verticalAlign="top"
                rowSpan={(rowData) => rowData.row_span}
              >
                <HeaderCell>Nro. Facturas</HeaderCell>
                <Cell dataKey="billing_number">
                  {(rowData) => {
                    return rowData.invoice_id?.invoice_number ? (
                      <>
                        <span>{rowData.invoice_id?.invoice_number + " "}</span>
                        <BiEdit
                          className="cursor-pointer ml-1 mb-1"
                          onClick={() => openReceiptModal(rowData)}
                        />
                      </>
                    ) : (
                      <p className="mb-0">N/A</p>
                    );
                  }}
                </Cell>
              </Column>
              <Column
                flexGrow={3}
                align="center"
                rowSpan={(rowData) => rowData.row_span}
              >
                <HeaderCell>Saldo Factura</HeaderCell>
                <Cell dataKey="invoice_balance">
                  {(rowData) => {
                    return rowData.invoice_id?.invoice_number ? (
                      <CurrencyFormat
                        value={
                          rowData.invoice_id?.amount -
                          rowData.invoice_id?.receipts?.reduce(
                            (accumulator, currentItem) => {
                              return accumulator + currentItem.receipt_amount;
                            },
                            0
                          )
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix={"$"}
                      />
                    ) : (
                      <p className="mb-0">N/A</p>
                    );
                  }}
                </Cell>
              </Column>
            </ColumnGroup>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <CardLogBootstrapStyled className="shadow-6dp p-4 ml-0 mr-0 mt-3 mb-4">
            <Row>
              <Col xs={6}>
                {" "}
                <label className="black-primary font-weight-bold mr-2">
                  Importe total viajes:
                </label>
              </Col>
              <Col xs={6}>
                <CurrencyFormat
                  value={billing.data
                    ?.filter((item) => typeof item.auditedWeight === "number")
                    ?.reduce((sum, item) => {
                      return sum + item.price_per_ton * item.auditedWeight;
                    }, 0)}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                  renderText={(formattedValue) => (
                    <div>
                      <span>{formattedValue}</span>
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                {" "}
                <label className="black-primary font-weight-bold mr-2">
                  Importe total facturado:
                </label>
              </Col>
              <Col xs={6}>
                <CurrencyFormat
                  value={dadorInvoice}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                  renderText={(formattedValue) => (
                    <div>
                      <span>{formattedValue}</span>
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                {" "}
                <label className="black-primary font-weight-bold mr-2">
                  Saldo de facturas:
                </label>
              </Col>
              <Col xs={6}>
                <CurrencyFormat
                  value={dadorBalance}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                  renderText={(formattedValue) => (
                    <div>
                      <span>{formattedValue}</span>
                    </div>
                  )}
                />
              </Col>
            </Row>
          </CardLogBootstrapStyled>
        </Col>
      </Row>

      {receiptModal?.isShow ? (
        <ModalReceipt
          isShow={receiptModal.isShow}
          data={receiptModal.data}
          handleClose={closeReceiptModal}
          refetch={onRefetch}
          admin={false}
        />
      ) : null}
    </Container>
  );
}

export default BillingDador;
