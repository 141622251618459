import { apiHttp } from "../axiosApi.js";
import {
  allTrips,
  pushNotifications,
  pushToken,
  usersToken,
} from "../config/apiRoute.js";

export const getAllTrips = ({
  limit,
  from = 0,
  state,
  user_id,
  status = true,
  withdraw_date,
  origin,
  destiny,
  charger_type,
}) => {
  return apiHttp(
    "GET",
    `${allTrips}?limit=${limit}&from=${from}${state ? `&state=${state}` : ""}${
      user_id ? `&user_id=${user_id}` : ""
    }&status=${status}${
      withdraw_date ? `&withdraw_date=${withdraw_date}` : ""
    }${origin ? `&origin=${origin}` : ""}${
      destiny ? `&destiny=${destiny}` : ""
    }${
      charger_type?.length
        ? `&charger_type=${JSON.stringify(charger_type)}`
        : ""
    }`
  );
};

export const getTokensUsers = () => {
  return apiHttp("GET", `${pushToken}`);
};

export const sendPushNotification = (body) => {
  return apiHttp("POST", `${pushNotifications}`, body);
};
export const getPushNotification = ({ limit, from }) => {
  return apiHttp("GET", `${pushNotifications}?limit=${limit}&from=${from}`);
};
