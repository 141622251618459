import React from "react";
import styled from "styled-components";
import { DatePicker } from "rsuite";

const LogDatePickerStyled = styled(DatePicker)`
  background-color: white;
  border: none;
  width: 100%;
  padding: 2px 2px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px #cccccc;
`;

const LogDateInput = ({
  placeholder,
  className,
  errors,
  messageError,
  name,
  cleanable,
  ...rest
}) => {
  // let mb0 = errors[name] ? "true" : "false";
  return (
    <>
      <LogDatePickerStyled
        {...{ ...rest, name }}
        placeholder={placeholder}
        className={`${className}`}
        format="dd/MM/yyyy"
        appearance="subtle"
        onSelect={() => {
          const btn = document.querySelector(
            ".rs-btn.rs-btn-primary.rs-btn-sm"
          );

          setTimeout(() => {
            btn?.click();
          }, 200);
        }}
        cleanable={cleanable}
        locale={{
          sunday: "Do",
          monday: "Lu",
          tuesday: "Ma",
          wednesday: "Mi",
          thursday: "Jue",
          friday: "Vi",
          saturday: "Sa",
          ok: "Seleccionar",
          today: "Hoy",
          yesterday: "Ayer",
          hours: "Horas",
          minutes: "Minutos",
          seconds: "Segundos",
        }}
      />
      {errors[name] && (
        <div className="mb-2 mt-0">
          <small className="text-danger">{errors[name]}.</small>
        </div>
      )}
    </>
  );
};

export default LogDateInput;
