import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import { COLOR_GRAY_TEXT } from "../../../../../constants/Colors";
import * as Yup from "yup";
import LogInputCurrency from "../../../../../components/atoms/LogInputCurrency";
import LogDatePicker from "../../../../../components/atoms/LogDatePicker";
import LogButton from "../../../../../components/atoms/LogButton";
import LogTimerPicker from "../../../../../components/atoms/LogTimerPicker";
import { putTrip } from "../../../../../api/modules";
import { errorToast } from "../../../../../services/toastService";
import LogInput from "../../../../../components/atoms/LogInput";

const ModalLogStyled = styled(Modal)`
  opacity: 1;
  .modal-content {
    border-radius: 15px !important;
    padding: 20px;
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;

const ContentModal = styled.div`
  text-align: left;
`;

const ContentFooterCenter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const turns = [
  { label: "Mañana", value: "M" },
  { label: "Tarde", value: "A" },
  { label: "Noche", value: "N" },
];

const EditTripSchema = Yup.object().shape({
  price_per_ton: Yup.number()
    .required("Precio por tonelada obligatorio")
    .moreThan(0, "Precio por tonelada debe ser mayor a cero"),
  withdraw_date: Yup.date().required("Fecha de retiro es obligatorio"),
  withdraw_turn: Yup.string().required("Turno de retiro es obligatorio"),
  delivery_date: Yup.date()
    .required("Fecha de entrega es obligatorio")
    .when(
      "withdraw_date",
      (withdraw_date, yup) =>
        withdraw_date &&
        yup.min(
          withdraw_date,
          "La fecha de entrega debe ser menor que la Fecha de retiro"
        )
    ),
  delivery_turn: Yup.string().required("Turno de entrega es obligatorio"),
  discount_trip: Yup.number()
    .min(0, "Numero igual o mayor a cero")
    .max(99, "Valor Maximo 99")
    .nullable(),
});

const ModalEditTrip = ({ isShow, entity, onCloseModal, isAdmin }) => {
  const [showModal, setShowModal] = useState(false);
  const [showMessage, setShowMessage] = useState({
    isShow: false,
    priceType: 0,
  });

  useEffect(() => {
    setShowModal(isShow);

    return () => {
      setShowMessage({ isShow: false, priceType: 0 });
    };
  }, [isShow]);

  const handleSubmitAPI = async (bodyfromForm) => {
    const newBody = {
      ...entity,
      ...bodyfromForm,
    };
    const response = await putTrip(newBody._id, newBody);
    // const { trip, msg } = response;
    // TODO AVISARLE A CARLOS Q EL RESPONSE ESTA RARO
    if (response._id) {
      onCloseModal(true);
    } else {
      errorToast("Algo malo ocurrio");
    }
  };

  const handleClose = (payload) => {
    setShowModal(false);
    onCloseModal(false);
    setShowMessage({ isShow: false, priceType: 0 });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        price_per_ton: entity.price_per_ton || "",
        withdraw_date: entity.withdraw_date || "",
        withdraw_turn: entity.withdraw_turn || "",
        delivery_date: entity.delivery_date || "",
        delivery_turn: entity.delivery_turn || "",
        discount_trip:
          entity.discount_trip !== undefined || entity.discount_trip !== null
            ? entity.discount_trip
            : null,
      }}
      validationSchema={EditTripSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmitAPI(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        resetForm,
      }) => (
        <ModalLogStyled
          show={showModal}
          onHide={handleClose}
          className="modal-exit"
        >
          <Modal.Body>
            <ContentModal>
              <h4 className="font-weight-bold text-primary h5">
                Editar precios y condiciones
              </h4>
              <hr />
            </ContentModal>
            <Row>
              <Col md="12">
                <Form.Group>
                  <label className="black-primary font-weight-bold">
                    Precio por tonelada
                  </label>

                  <LogInputCurrency
                    className="number-font"
                    placeholder="AR$"
                    type="text"
                    name="price_per_ton"
                    thousandSeparator={true}
                    prefix={"$ "}
                    onKeyDown={(text) => {
                      if (text.key === "0") {
                        setShowMessage({ isShow: true, priceType: 0 });
                      } else {
                        setShowMessage({ isShow: true, priceType: 1 });
                      }
                      console.log("debug press:", text);
                    }}
                    onValueChange={(values) => {
                      const { value } = values;
                      const re = /^[0-9\b]+$/;
                      if (value.value === "" || re.test(value)) {
                        const number = parseFloat(value || 0);
                        setFieldValue("price_per_ton", number);
                      } else {
                        setFieldValue("price_per_ton", 0);
                      }
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.price_per_ton}
                  />
                </Form.Group>
                {/* {showMessage.isShow && (
                  <small class="text-muted">
                    {" "}
                    {showMessage.priceType === 0
                      ? "Si el precio es cero, declararás que el viaje a modificar será ahora precio ABIERTO."
                      : " Si el precio es distinto de cero será modificado como precio CERRADO."}
                  </small>
                )} */}
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form.Group>
                  <label className="font-weight-bold text-black mb-1">
                    Re-Programá el retiro de tu cultivo
                  </label>
                  <Row>
                    <Col md="6">
                      <LogDatePicker
                        name="withdraw_date"
                        className="number-font"
                        placeholder="Fecha"
                        block
                        onBlur={handleBlur}
                        errors={errors}
                        value={new Date(values.withdraw_date)}
                        onChange={(value) => {
                          setFieldValue("withdraw_date", value);
                        }}
                      />
                    </Col>
                    <Col md="6">
                      <LogTimerPicker
                        placeholder="Turno"
                        name="withdraw_turn"
                        block
                        items={turns}
                        onChange={(value) => {
                          setFieldValue("withdraw_turn", value);
                        }}
                        onBlur={handleBlur}
                        value={values.withdraw_turn}
                        errors={errors}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="12">
                <label className="font-weight-bold text-black mb-1">
                  Re-Programá la entrega de tu cultivo
                </label>
                <Row>
                  <Col md="6">
                    <LogDatePicker
                      name="delivery_date"
                      placeholder="Fecha"
                      block
                      className="number-font"
                      value={new Date(values.delivery_date)}
                      onChange={(value) => {
                        setFieldValue("delivery_date", value);
                      }}
                      errors={errors}
                    />
                  </Col>
                  <Col md="6">
                    <LogTimerPicker
                      placeholder="Turno"
                      name="delivery_turn"
                      block
                      items={turns}
                      onChange={(value) => {
                        setFieldValue("delivery_turn", value);
                      }}
                      onBlur={handleBlur}
                      value={values.delivery_turn}
                      errors={errors}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {isAdmin ? (
              <Row>
                <Col md="12">
                  <Form.Group>
                    <label className="black-primary font-weight-bold">
                      Factor de Descuento
                    </label>

                    <LogInput
                      className="number-font"
                      placeholder="Factor sin especificar"
                      type="number"
                      name="discount_trip"
                      onChange={(e) => {
                        const re = /^[0-9]*(\.[0-9]{0,2})?$/;
                        if (re.test(e.target.value)) {
                          console.log("discount_factor", e.target.value);
                          handleChange(e);
                        }
                      }}
                      onBlur={handleBlur}
                      errors={errors}
                      value={values.discount_trip}
                      step="0.01"
                      max="99"
                    />

                    {/*  <LogInputCurrency
                      className="number-font"
                      placeholder="Factor sin especificar"
                      type="number"
                      name="discount_trip"
                      onValueChange={(values) => {
                        const { value } = values;
                        const re = /^[0-9]*(\.[0-9]{0,2})?$/;
                        if (re.test(value)) {
                          console.log("discount_trip", value);
                          setFieldValue("discount_trip", value);
                        }
                      }}
                      onBlur={handleBlur}
                      errors={errors}
                      value={values.discount_trip}
                      step="0.01"
                      max="99"
                    /> */}
                  </Form.Group>
                </Col>
              </Row>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <ContentFooterCenter>
              <LogButton
                color="primary"
                onClick={handleSubmit}
                title={"Editar viaje"}
                border="curve"
                block
                className="mr-4"
              ></LogButton>
              <LogButton
                color="secondary"
                onClick={() => {
                  resetForm();
                  handleClose();
                }}
                title={"Cancelar"}
                border="curve"
                block
              ></LogButton>
            </ContentFooterCenter>
          </Modal.Footer>
        </ModalLogStyled>
      )}
    </Formik>
  );
};

export default ModalEditTrip;
