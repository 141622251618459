import React from "react";
import "../../styles/components/atoms/LogSelect.scss";
import PropTypes from "prop-types";
import { CheckPicker } from "rsuite";
import styled from "styled-components";
import { getTypeTruck } from "../../utils/helpers";

const SelectPickerMultiStyled = styled(CheckPicker)`
  background-color: white;
  border: none;
  padding: 1px 1px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px #cccccc;
  height: 40px;
  display: flex;
  align-items: center;
`;

const DivItem = styled.div`
  display: flex;
  align-items: center;
`;

const LogSelectMultiCustom = ({
  items,
  className,
  errors,
  name,
  iconLogName,
  searchable = false,
  cleanable,
  ...rest
}) => {
  return (
    <>
      <SelectPickerMultiStyled
        className={className + " mt-1"}
        cleanable={cleanable}
        name={name}
        {...rest}
        data={items}
        searchable={searchable}
        block
        renderMenuItem={(label, item) => {
          return (
            <DivItem>
              {iconLogName && (
                <div className="circle-icon-list">
                  <span className={iconLogName + " text-primary"}></span>
                </div>
              )}
              <strong>{label}</strong>&nbsp;
              {item?.truck_type && getTypeTruck(item.truck_type)}
            </DivItem>
          );
        }}
      />
      {errors[name] && (
        <div className="mb-2 mt-0">
          <small className="text-danger">{errors[name]}.</small>
        </div>
      )}
    </>
  );
};

LogSelectMultiCustom.propTypes = {
  items: PropTypes.array.isRequired,
};

export default LogSelectMultiCustom;
