import io from "socket.io-client";
import {
  LOGIN_AUTH,
  LOGOUT_AUTH,
  SEND_ADMIN_SUPPORT,
  UPDATE_LIST_ADMIN,
  PUSH_LIST_ADMIN,
  PUSH_READMSG,
  MARK_AS_READ,
  LOGOUT_CHAT,
  PUSH_READMSG_CLIENT,
  PUSH_MSG_USER,
  UPDATE_ONLINE,
  SEND_ROOM_MESSAGE,
  GET_ROOM_CHAT,
  NEW_MSG_ROOM,
  MOUNTED_ROOM,
  PUSH_NOTIFICATION,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION,
  SEND_CHAT_APP,
  GET_ROOM_APP_CHAT,
  NEW_APP_ROOM,
  MOUNTED_APP_CHAT,
  MARK_AS_READ_CHAT_APP,
} from "../actionTypes";
import { ADMIN } from "../../constants/UserType";
import { MARK_AS_READ_ROOM } from "../actionTypes";
import { refreshData } from "../actions/auth";
import { errorToast } from "../../services/toastService";
import history from "../../utils/history";

let firstCall = true;

export const socketMiddleware = (baseUrl) => {
  return (storeAPI) => {
    let socket = io(baseUrl);

    return (next) => (action) => {
      if (firstCall) {
        firstCall = false;
        socket.on("connect", (response) => {
          const localStorageToken = localStorage.getItem("token");
          if (localStorageToken) {
            setupSocketListenerAdmin(socket, storeAPI);
            updateConnection(socket, storeAPI, localStorageToken);
          }
        });
      }

      if (action.type === LOGOUT_AUTH) {
        socket.removeAllListeners();
        if (action.payload) {
          socket.emit("client-sign-out", action.payload, (response) => {
            console.log("client-sign-out LOGIN_AUTH", response);
          });
          storeAPI.dispatch({ type: LOGOUT_CHAT });
        }
      }

      if (action.type === SEND_ROOM_MESSAGE) {
        socket.emit(
          "send-message",
          action.payload,
          ({ ok, msg, error, user }) => {
            if (ok) {
              return next({ ...action, payload: { msg, user } });
            }
          }
        );
        return;
      }

      if (action.type === SEND_CHAT_APP) {
        socket.emit(
          "send-app-chat",
          action.payload,
          ({ ok, msg, error, user }) => {
            if (ok) {
              return next({ ...action, payload: { msg, user } });
            } else {
              errorToast(error);
              console.error("SEND_CHAT_APP", error);
            }
          }
        );
        return;
      }

      if (action.type === GET_ROOM_CHAT) {
        socket.emit(
          "getRoomChat",
          action.payload,
          ({ ok, roomMsg, user, error }) => {
            if (
              storeAPI.getState().chatReducer.idMoundtedRoom ===
                roomMsg[0]?.room &&
              ok
            ) {
              socket.emit("markReadRoom", action.payload, ({ ok }) => {
                if (ok) {
                  storeAPI.dispatch({
                    type: MARK_AS_READ_ROOM,
                    payload: action.payload,
                  });
                }
              });
              return next({ ...action, payload: { roomMsg, user } });
            }
          }
        );
        storeAPI.dispatch({
          type: MOUNTED_ROOM,
          payload: action.payload,
        });
        return;
      }

      if (action.type === GET_ROOM_APP_CHAT) {
        socket.emit(
          "getRoomAppChat",
          action.payload,
          ({ ok, roomMsg, user, error }) => {
            if (
              storeAPI.getState().chatReducer.idMoundtedAppChat ===
                roomMsg[0]?.room &&
              ok
            ) {
              socket.emit("markReadChatApp", action.payload, ({ ok }) => {
                if (ok) {
                  storeAPI.dispatch({
                    type: MARK_AS_READ_CHAT_APP,
                    payload: action.payload,
                  });
                }
              });
              return next({ ...action, payload: { roomMsg, user } });
            }
          }
        );
        storeAPI.dispatch({
          type: MOUNTED_APP_CHAT,
          payload: action.payload,
        });
        return;
      }

      if (action.type === SEND_ADMIN_SUPPORT) {
        if (action.payload) {
          socket.emit(
            "support-msg",
            action.payload,
            ({ ok, msg, error, user }) => {
              if (ok) {
                if (user === ADMIN) {
                  storeAPI.dispatch({
                    type: PUSH_READMSG,
                    payload: msg,
                  });
                } else {
                  storeAPI.dispatch({
                    type: PUSH_READMSG_CLIENT,
                    payload: msg,
                  });
                }
              } else {
                console.error("support-msg", error);
              }
            }
          );
        }
      }

      if (action.type === MARK_AS_READ) {
        if (action.payload) {
          socket.emit(
            "mark-as-read",
            action.payload,
            ({ ok, attended_user, user, error }) => {
              if (ok) {
                return next({
                  type: MARK_AS_READ,
                  payload: { attended_user, user },
                });
              } else {
                console.error("mark-as-read", error);
              }
            }
          );
        }
        return;
      }
      if (action.type === DELETE_NOTIFICATION) {
        const localStorageToken = localStorage.getItem("token");
        if (action.payload && localStorageToken) {
          socket.emit(
            "deleteNotification",
            { query: action.payload, token: localStorageToken },
            ({ ok }) => {
              if (ok) {
                return next({
                  type: DELETE_NOTIFICATION,
                  payload: action.payload,
                });
              }
            }
          );
        }
        return;
      }

      if (action.type === LOGIN_AUTH) {
        setupSocketListenerAdmin(socket, storeAPI);
        updateConnection(socket, storeAPI, action.payload);
      }

      return next(action);
    };
  };
};

function setupSocketListenerAdmin(socket, storeAPI) {
  socket.on("new-message", ({ ok, msg, user, error }) => {
    if (ok) {
      if (storeAPI.getState().chatReducer.idMoundtedRoom === msg?.room?._id) {
        socket.emit(
          "markReadRoom",
          { token: storeAPI.getState().authApp.token, room: msg?.room?._id },
          ({ ok }) => {
            if (ok) {
              storeAPI.dispatch({
                type: MARK_AS_READ_ROOM,
                payload: msg?.room?._id,
              });
            }
          }
        );
      }
      storeAPI.dispatch({
        type: NEW_MSG_ROOM,
        payload: {
          msg,
          user,
          role: storeAPI.getState().authApp?.user?.role_id?.name,
        },
      });
    }
  });

  socket.on("new-app-chat", ({ ok, msg, user, error }) => {
    if (ok) {
      if (
        storeAPI.getState().chatReducer.idMoundtedAppChat === msg?.room?._id
      ) {
        socket.emit(
          "markReadChatApp",
          { token: storeAPI.getState().authApp.token, room: msg?.room?._id },
          ({ ok }) => {
            if (ok) {
              storeAPI.dispatch({
                type: MARK_AS_READ_CHAT_APP,
                payload: msg?.room?._id,
              });
            }
          }
        );
      }
      storeAPI.dispatch({
        type: NEW_APP_ROOM,
        payload: {
          msg,
          user,
          role: storeAPI.getState().authApp?.user?.role_id?.name,
        },
      });
    }
  });

  socket.on("support-msg-adm", ({ ok, msg, error, user }) => {
    if (ok) {
      if (user === ADMIN) {
        storeAPI.dispatch({
          type: PUSH_MSG_USER,
          payload: msg,
        });
      } else {
        storeAPI.dispatch({
          type: PUSH_LIST_ADMIN,
          payload: msg,
        });
      }
    } else {
      console.error("support-msg-adm", error);
    }
  });

  socket.on("support-own-msg", ({ ok, msg, error, user }) => {
    if (ok) {
      storeAPI.dispatch({
        type: PUSH_MSG_USER,
        payload: msg,
      });
    } else {
      console.error("support-own-msg", error);
    }
  });

  socket.on("support-own-msg-adm", ({ ok, msg, error, user }) => {
    if (ok) {
      storeAPI.dispatch({
        type: PUSH_LIST_ADMIN,
        payload: msg,
      });
    } else {
      console.error("support-own-msg-adm", error);
    }
  });

  socket.on("update-online", (clients) => {
    storeAPI.dispatch({
      type: UPDATE_ONLINE,
      payload: clients,
    });
  });
  socket.on("disconnect", () => {
    socket.removeAllListeners();
    socket.on("connect", (response) => {
      const localStorageToken = localStorage.getItem("token");
      if (localStorageToken) {
        setupSocketListenerAdmin(socket, storeAPI);
        updateConnection(socket, storeAPI, localStorageToken);
      }
    });
    storeAPI.dispatch({
      type: UPDATE_ONLINE,
      payload: [],
    });
  });
  socket.on("newNotification", (message) => {
    storeAPI.dispatch({
      type: PUSH_NOTIFICATION,
      payload: message,
    });
  });
  socket.on("dispatchAction", (action) => {
    switch (action) {
      case "REFRESH_DATA":
        storeAPI.dispatch(refreshData());
        break;

      case "CLOSE_ALL":
        history.go(0);
        break;

      default:
        break;
    }
  });
  socket.on("deleteNotification", (message) => {
    storeAPI.dispatch({
      type: DELETE_NOTIFICATION,
      payload: message,
    });
  });
}

function updateConnection(socket, storeAPI, token) {
  socket.emit(
    "client-sign-in",
    token,
    ({
      ok,
      listUsers = [],
      privateMsg = {},
      error,
      clients = [],
      unReadMsgRoom = [],
      unReadChatApp = [],
    }) => {
      if (ok) {
        storeAPI.dispatch({
          type: UPDATE_LIST_ADMIN,
          payload: { listUsers, privateMsg, unReadMsgRoom, unReadChatApp },
        });
        storeAPI.dispatch({
          type: UPDATE_ONLINE,
          payload: clients,
        });
      } else {
        console.error("client-sign-in", error);
      }
    }
  );
  socket.emit("getUserNotification", { token }, ({ ok, notification }) => {
    if (ok) {
      storeAPI.dispatch({
        type: UPDATE_NOTIFICATION,
        payload: notification,
      });
    }
  });
}
