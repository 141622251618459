import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  delTripChild,
  getDetailTrip,
  getTripAPI,
  getTripChilds,
} from "../../../../../api/modules";
import CardItemStartsTravelPending from "../../../components/molecules/CardItemStartsTravelPending";
import CardItemOriginDestiny from "../../../components/molecules/CardItemOriginDestiny";
import Badge from "react-bootstrap/Badge";
import LogTextNumber from "../../../../../components/atoms/LogTextNumber";
import ModalContact from "../../../../../components/organisms/modal-contact/ModalContact";
import { errorToast, successToast } from "../../../../../services/toastService";
import ModalQuestion from "../../../../../components/organisms/modal-question/ModalQuestion";

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const ContainerList = styled.div`
  overflow-y: overlay;
`;

const PendingsDetails = ({ trips, id }) => {
  const [showModal, setShowModal] = useState(false);
  const [showModalUnits, setShowModalUnits] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [tripDetail, setTripDetail] = useState(null);
  const [tripChilds, setTripChilds] = useState(null);
  const [tripDetails, setTripDetails] = useState(null);
  const [showContact, setShowContact] = useState(false);
  const [contactInfo, setContactInfo] = useState(null);
  const [isPostulationModal, setPostulationModal] = useState({
    view: false,
    id: {},
  });

  const handleContactClose = () => {
    setShowContact(false);
    setContactInfo(null);
  };
  const handleContactShow = () => setShowContact(true);

  useEffect(() => {
    fetchData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const onRefetch = () => setRefetch((prev) => !prev);

  const openCloseModal = async (id) => {
    const response = await getDetailTrip(id);
    setTripDetails(response);
    setShowModal((prev) => !prev);
  };

  const openCloseModalUnits = async (id) => {
    const response = await getDetailTrip(id);
    setTripDetails(response);
    setShowModalUnits((prev) => !prev);
  };

  const fetchData = async (id) => {
    try {
      const response = await getTripAPI(id);

      if (response) setTripDetail(response.data);
      const response2 = await getTripChilds(id, "[2,3]");
      if (response2) setTripChilds(response2);
    } catch (error) {
      console.log("pending error", error);
    }
  };

  return (
    <div>
      <Container fluid>
        <Row className="align-content-center mt-1">
          <Col className="d-flex flex-wrap align-items-center justify-content-end">
            <h6 className="">
              <Badge
                pill
                bg="primaryLight"
                text="primary"
                className="py-2 px-3"
              >
                Camiones:{" "}
                {tripDetail?.trip.trucks_number ? (
                  <LogTextNumber
                    text={`${tripDetail?.trip.trucks_number}`}
                    style={{
                      color: "#92c31a",
                      fontSize: "0.75rem",
                      fontWeight: "900",
                    }}
                  />
                ) : (
                  ""
                )}
              </Badge>
            </h6>
            <h6 className="ml-2">
              <Badge
                pill
                bg="primaryLight"
                text="primary"
                className="py-2 px-3"
              >
                {/* Tipo-Carga:{' '} */}
                {tripDetail?.trip.truck_type.length
                  ? tripDetail?.trip.truck_type?.map((item, index) => (
                      <div key={index}>{`${item?.truck_type_name}`} </div>
                    ))
                  : ""}
              </Badge>
            </h6>
            <h6 className="ml-2">
              <Badge
                pill
                bg="primaryLight"
                text="primary"
                className="py-2 px-3"
              >
                Distancia:{" "}
                {tripDetail?.trip.kilometers ? (
                  <LogTextNumber
                    text={`${tripDetail?.trip.kilometers} km`}
                    style={{
                      color: "#92c31a",
                      fontSize: "0.75rem",
                      fontWeight: "900",
                    }}
                  />
                ) : (
                  ""
                )}
              </Badge>
            </h6>
            <h6 className="ml-2">
              <Badge
                pill
                bg=""
                text="infoLight"
                className="py-2 px-3"
                onClick={() => {
                  console.log("Ver Mapa");
                }}
                style={{ cursor: "pointer" }}
              >
                Ver Mapa
              </Badge>
            </h6>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <CardItemOriginDestiny
              originName={tripDetail?.trip.origin_site.site_name}
              destinyName={tripDetail?.trip.destiny_site.site_name}
              deliveryDate={tripDetail?.trip.delivery_date}
              withdrawDate={tripDetail?.trip.withdraw_date}
              onClickDetail={handleContactShow}
              destiny={tripDetail?.trip.destiny}
              origin={tripDetail?.trip.origin}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <ContainerList>
              {tripChilds?.total > 0 ? (
                tripChilds.tripsDetails?.map((item, index) => (
                  <CardItemStartsTravelPending
                    state={item.state}
                    key={index}
                    item={item}
                    isDc={true}
                    price={item.price_per_ton || tripDetail?.trip.price_per_ton}
                    onClickDetail={() => {
                      console.log("onClickDetail");
                      const driver = item.is_tomador
                        ? item.id_tomador
                        : item.id_driver;

                      setContactInfo({
                        ...driver,
                        truckType:
                          item.id_trailer?.id_truckType?.truck_type_name,
                        chassis: item.id_truck?.domain,
                        coupled: item.id_trailer?.domain,
                      });
                      setShowContact(true);
                    }}
                    onClickDelete={(id) =>
                      setPostulationModal((prev) => {
                        return {
                          ...prev,
                          view: true,
                          id: id,
                        };
                      })
                    }
                  />
                ))
              ) : (
                <h2 className="font-weight-bold h3 text-center">
                  Aún no tenés ningún viaje pendiente
                </h2>
              )}
              {/* {tripsAPI.items.length === 0 && (
                <h2 className="font-weight-bold h3 text-center">Aún no tenés ningún viaje pendiente</h2>
              )} */}
            </ContainerList>
          </Col>
        </Row>
      </Container>
      <ModalQuestion
        title="¿Estás seguro de esta accion?"
        subTitle={"Eliminar la postulacion del tomador"}
        isShow={isPostulationModal.view}
        handleClose={async (payload) => {
          if (payload) {
            const response = await delTripChild(isPostulationModal.id);
            const { msg } = response;
            if (!msg) {
              successToast("Viaje Hijo eliminado correctamente");
            } else {
              errorToast(msg);
            }
            onRefetch();
          }

          setPostulationModal((prev) => {
            return {
              ...prev,
              view: false,
              id: {},
            };
          });
        }}
      />

      <RowStyled>
        <Col md="12"></Col>
      </RowStyled>

      <ModalContact
        title="¿Estás seguro de esta accion?"
        subTitle="¿Estás seguro?"
        isShow={showContact}
        name={contactInfo?.name}
        lastName={contactInfo?.last_name}
        srcAvatar="https://randomuser.me/api/portraits/men/43.jpg"
        phone={contactInfo?.phone}
        truckType={contactInfo?.truckType}
        chassis={contactInfo?.chassis}
        coupled={contactInfo?.coupled}
        handleClose={handleContactClose}
      />
    </div>
  );
};

export default PendingsDetails;
