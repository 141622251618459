/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
import { useLocation, Route, Switch } from "react-router-dom";

import AdminNavbar from "./components/Navbars/AdminNavbar";
import Sidebar from "./components/Sidebar/Sidebar";

import getRoutesInit from "./routes.js";

import sidebarImage from "../../assets/img/sidebar-3.jpg";
import { useSelector } from "react-redux";

function Admin() {
  const [image] = React.useState(sidebarImage);
  const [color] = React.useState("white");
  const [listRoutes, setListRoutes] = React.useState([]);
  const user = useSelector((state) => state.authApp.user);
  const [hasImage] = React.useState(true);
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const { collapse } = useSelector((state) => state.appReducer);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/app") {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
            exact
          />
        );
      } else {
        return null;
      }
    });
  };

  React.useEffect(() => {
    const routes = getRoutesInit(user?.role_id);
    setListRoutes(routes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRoutesInit, user]);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);

  return (
    <>
      <div className="wrapper">
        <Sidebar
          color={color}
          image={hasImage ? image : ""}
          routes={listRoutes}          
        />
        <div
          className={`${collapse ? "main-panel" : "main-panel main-panel-xs"}`}
          ref={mainPanel}
        >
          <AdminNavbar />
          <div className="content">
            <Switch>{getRoutes(listRoutes)}</Switch>
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin;
