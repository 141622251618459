import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";
import styled from "styled-components";

const CardStyled = styled(Card)`
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const CustomItemList = ({ children, onClick, className, ...rest }) => {
  return (
    <CardStyled
      className={`${className} shadow-4dp m-2`}
      onClick={onClick}
      {...rest}
    >
      <Card.Body>
        <Row className="align-content-center align-align-items-center">
          <Col className="text-left" md="12">
            {children}
          </Col>
        </Row>
      </Card.Body>
    </CardStyled>
  );
};

CustomItemList.propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func,
};

export default CustomItemList;
