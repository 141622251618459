import React, { useEffect, useState } from "react";

// react-bootstrap components
import { Container, Row, Col, Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { getUsers } from "../../../../api/modules";
import PaginationList from "../../../../components/organisms/pagination-list/PaginationList";
import { COLOR_PRIMARY } from "../../../../constants/Colors";
import CustomItemList from "../../components/molecules/CustomItemList ";
import { GoTriangleRight } from "react-icons/go";
import SearchComponent from "./SearchComponent";
import { TOMADOR_DE_CARGA } from "../../../../constants/UserType";
import { toNumber } from "lodash";
import ReactStars from "react-stars";

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;
const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const ContainerList = styled.div`
  overflow-y: overlay;
  padding-right: 10px;
`;

const BadgeLog = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  display: inline-block;
  padding: 0px 20px;
`;

function Users() {
  const spinner = useSelector((state) => state.appReducer.isLoading);
  const history = useHistory();
  const INITIAL_ENTITY = {
    items: [],
    total: 0,
  };
  const [users, setUsers] = useState(INITIAL_ENTITY);
  const [page, setPage] = useState(0);
  const numberList = 6;
  const [query, setQuery] = useState({
    limit: numberList,
    from: page,
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const fetchData = async () => {
    const response = await getUsers(query);
    const { users, total } = response;
    if (users) {
      setUsers({
        items: users,
        total: total,
      });
    } else {
      setUsers(INITIAL_ENTITY);
    }
  };

  const handlePreviewPage = () => {
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * page - numberList,
      };
    });
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * (page + 1),
      };
    });
  };

  const getStatusString = (status) => {
    let statusResponse;
    switch (status) {
      case "1":
        statusResponse = "Habilitado";
        break;

      case "2":
        statusResponse = "Eliminado";
        break;

      case "3":
        statusResponse = "Deshabilitado";
        break;
      default:
        statusResponse = "Habilitado";
        break;
    }
    return statusResponse;
  };

  return (
    <>
      <Container fluid>
        <SearchComponent
          onHandleSearchSubmit={({
            name,
            cuit,
            status,
            role_id,
            tripState,
          }) => {
            setPage(0);
            setQuery((prev) => {
              return {
                ...prev,
                limit: numberList,
                from: 0,
                search: name,
                cuit,
                status,
                role_id,
                tripState,
              };
            });
          }}
        />
        <Row>
          <Col md="12">
            <ContainerList>
              {users.items.length > 0 ? (
                users.items.map(
                  (
                    {
                      email,
                      name,
                      phone,
                      last_name,
                      cuit,
                      _id,
                      status,
                      role_id,
                      score_tomador,
                      score_dador,
                    },
                    index
                  ) => (
                    <CustomItemList
                      noIcon
                      onClick={() =>
                        history.push(`/app/users/user-profile/${_id}`)
                      }
                      key={index}
                      className={"p-30"}
                    >
                      {" "}
                      <Row>
                        <Col md="2" className="d-flex justify-content-center">
                          <ReactStars
                            value={
                              role_id.name === TOMADOR_DE_CARGA
                                ? toNumber(score_tomador)
                                : toNumber(score_dador)
                            }
                            isHalf={true}
                            count={5}
                            size={22}
                            color2="#ffd700"
                            color1="#CDE0EB"
                            edit={false}
                            /* char={<BsStarFill />} */
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="2" className="text-center">
                          <small>
                            <p className="text-primary text-center mb-1">
                              Cuit
                            </p>
                          </small>
                          <p className="h5 font-weight-bold mt-2 number-font">
                            {cuit || "CUIT"}
                          </p>
                        </Col>
                        <Col md="3" className="text-center">
                          <small>
                            <p className="text-primary text-center mb-1">
                              Email
                            </p>
                          </small>
                          <p className="h5 font-weight-bold mt-2">
                            {email || "Email"}
                          </p>
                        </Col>
                        <Col md="2" className="text-center">
                          <small>
                            <p className="text-primary text-center mb-1">
                              Nombre
                            </p>
                          </small>
                          <p className="h5 font-weight-bold mt-2">
                            {`${name} ${last_name}` || "Nombre completo"}
                          </p>
                        </Col>
                        <Col md="2" className="text-center">
                          <small>
                            <p className="text-primary text-center mb-1">
                              Teléfono
                            </p>
                          </small>
                          <p className="h5 font-weight-bold mt-2 number-font">
                            {`${phone}` || "Teléfono"}
                          </p>
                        </Col>
                        <Col md="2" className="text-center">
                          <small>
                            <p className="text-primary text-center mb-1">
                              Estado
                            </p>
                          </small>
                          <BadgeLog as="h5">{getStatusString(status)}</BadgeLog>
                        </Col>
                        <Col
                          md="1"
                          className="d-flex align-items-center text-right justify-content-end pr-4"
                        >
                          <GoTriangleRight />
                        </Col>
                      </Row>
                    </CustomItemList>
                  )
                )
              ) : (
                <DivEmpty>
                  {!spinner ? (
                    <h2 className="font-weight-bold h3 text-center">
                      No hay usuarios con el criterio de busqueda
                    </h2>
                  ) : null}
                </DivEmpty>
              )}
            </ContainerList>
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={users.items}
            total={users.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
      </RowStyled>
    </>
  );
}

export default Users;
