import { apiHttp } from "../axiosApi.js";
import {
  truck,
} from "../config/apiRoute.js";

export const getTTruckTypesAPI = (limit = 5, from = 0) => {
  return apiHttp("GET", `${truck}?limit=${limit}&from=${from}`)
};

export const postTruckTypesAPI = (body) => apiHttp("POST", `${truck}`, body);

export const getTruckTypeAPI = () =>
  apiHttp("GET", `${truck}`);