/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";
import { COLOR_GRAY_TEXT } from "../../../constants/Colors";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "#eeeeee";
};

const Container = styled.div`
  min-width: 260px;
  height: 200px;
  padding: 10px;
  border-width: 2px;
  border-radius: 20px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #ffffff;
  color: ${COLOR_GRAY_TEXT};
  outline: none;
  transition: border 0.24s ease-in-out;
  box-shadow: 0px 0px 5px #cccccc;
  align-content: center;
  p {
    text-align: left;
  }
`;

const DivImage = styled.div`
  width: 100%;
  cursor: pointer;
  border-radius: 20px;
  text-align: center;
  height: 200px;
`;

const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  padding: 5px;
  object-fit: contain;
  object-position: center;
`;

const DropText = styled.div`
  margin: auto;
  text-align: center;
`;

const DropZone = ({
  uploadFile,
  removeFile,
  errors,
  placeholder = "Subir una image",
  image,
  readOnly = false,
  reset = false,
}) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: "image/jpeg, image/png",
  });
  const [file, setFile] = useState(null);
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      if (reset) {
        setFile(null);
        acceptedFiles.splice(file, 1);
      }
    }
  }, [reset]);

  useEffect(() => {
    if (acceptedFiles.length) {
      setFile(window.URL.createObjectURL(acceptedFiles[0]));
      uploadFile(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  const remove = (file) => {
    setFile(null);
    acceptedFiles.splice(file, 1);
    removeFile();
  };

  return (
    <section>
      {acceptedFiles.length || image ? (
        readOnly ? (
          <DivImage
            className="shadow-4dp"
            style={{ cursor: readOnly ? "default" : "pointer" }}
          >
            <ImagePreview src={file || image} alt="" srcset="" />
          </DivImage>
        ) : (
          <OverlayTrigger
            key="delete"
            overlay={<Tooltip id={`delete`}>Eliminar</Tooltip>}
          >
            <DivImage
              onClick={() => remove()}
              style={{ cursor: readOnly ? "default" : "pointer" }}
              className="shadow-4dp"
            >
              <ImagePreview src={file || image} alt="" srcset="" />
            </DivImage>
          </OverlayTrigger>
        )
      ) : (
        <div>
          <Container
            {...getRootProps({
              style: { cursor: readOnly ? "default" : "pointer" },
              className: `dropzone shadow-2dp ${readOnly ? "readOnly" : ""}`,
            })}
          >
            {!readOnly && <input {...getInputProps()} />}
            <DropText>
              <FiUpload size={24} className="mr-2 text-primary" />
              <div>{placeholder}</div>
            </DropText>
          </Container>
          {errors && (
            <div className="mb-2 mt-0">
              <small className="text-danger">
                {`${placeholder} es obligatorio.`}.
              </small>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default DropZone;
