/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import moment from "moment";
import { Card, Form, Container, Row, Col } from "react-bootstrap";
import LogButton from "../../../../components/atoms/LogButton";
import { delTripAPI, getTripAPI, postTripAPI } from "../../../../api/modules";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { CardLogBootstrapStyled } from "../../../../styledComponents/CardBootstrapLog";
import { useEffect } from "react";
import queryString from "query-string";
import { errorToast, successToast } from "../../../../services/toastService";
import ModalEditTrip from "./Modal-Edit-Trip/ModalEditTrip";
import ModalQuestion from "../../../../components/organisms/modal-question/ModalQuestion";
import { getDriver } from "../../../../api/modules/driver";
import { ADMIN } from "../../../../constants/UserType";
import { useSelector } from "react-redux";
import { priceCalculation } from "../../../../utils/helpers";
const TAXES_LABEL = process.env.REACT_APP_TAXES_LABEL;

function TravellDetail({ detailTrip, back = false }) {
  const { user } = useSelector((state) => state.authApp);
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const [entity, setEntity] = useState({
    truck_features: [],
    truck_accessories: [],
    truck_type: [],
    origin_site: {},
    destiny_site: {},
    product: {},
    charger_type: {},
    drivers: {},
  });

  // state modalExit
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  // delete
  const [modalExit, setModalExit] = useState(false);

  // entity body for request
  const [previewEntity, setPreviewEntity] = useState(null);
  // entity of labels to show
  const [previewLabels, setPreviewLabels] = useState(null);
  const [isPreview, setIsPreviewMode] = useState(false);

  useEffect(() => {
    const { preview, fetchPreview } = queryString.parse(location.search);
    try {
      const { body, previewData } = location.state;
      if (preview) {
        previewDataFromCreate(body, previewData);
        setIsPreviewMode(preview);
        setPreviewLabels(previewData);
      }
    } catch (error) {
      const { id } = params;

      if (detailTrip) {
        fetchApi(detailTrip);
      } else if (id) {
        fetchApi(id);
      }
      if (fetchPreview) setIsPreviewMode(true);
    }
  }, []);

  const previewDataFromCreate = (body, previewData) => {
    setPreviewEntity({ ...body });
    const {
      charger_type,
      destiny_site,
      origin_site,
      product,
      truck_features,
      truck_type,
      truck_accessories,
      payment_type,
      drivers,
    } = previewData;
    setEntity({
      ...body,
      truck_features: convertItemIntoCommatsDot(truck_features),
      truck_accessories: convertItemIntoCommatsDot(truck_accessories),
      truck_type: convertItemIntoCommatsDot(truck_type),
      origin_site: { address: origin_site },
      destiny_site: { address: destiny_site },
      product: { product_type_name: product },
      payment_type: payment_type,
      charger_type: { cargo_type_name: charger_type },
      drivers: drivers,
    });
  };

  const fetchApi = async (id) => {
    const resp = await getTripAPI(id);
    const { trip, msg } = resp?.data;
    if (trip) {
      let namesTruckFeatures = trip.truck_features.map(
        (tf) => tf.truck_characteristic_name
      );
      let namesTruckAccessories = trip.truck_accessories.map(
        (tf) => tf.accesory_name
      );
      let namesTruckTypes = trip.truck_type.map((tf) => tf.truck_type_name);
      let arrayDrivers = [];
      let drivers = [...trip.drivers];
      if (drivers.length > 0) {
        for await (const driver of drivers) {
          const response = await getDriver(driver);
          arrayDrivers.push(response);
        }
      }
      const newArrayDrivers = arrayDrivers.map((item) => {
        return {
          name: item?.driver?.name,
          last_name: item?.driver?.last_name,
        };
      });

      setEntity({
        ...trip,
        truck_features: convertItemIntoCommatsDot(namesTruckFeatures),
        truck_accessories: convertItemIntoCommatsDot(namesTruckAccessories),
        truck_type: convertItemIntoCommatsDot(namesTruckTypes),
        payment_type: trip.payment_type?.payment_type_name,
        drivers: newArrayDrivers,
      });
    } else {
      errorToast(msg);
    }
  };

  const convertItemIntoCommatsDot = (array) => {
    return [array.slice(0, -1).join(", "), array.slice(-1)[0]].join(
      array.length < 2 ? "" : " y "
    );
  };

  const handleCloseExitModal = async (response) => {
    if (response) {
      const response = await delTripAPI(entity._id);
      const { trip, msg } = response;
      if (trip) {
        successToast(msg);
        history.push("/app/list-travels?state=0");
      } else {
        errorToast(msg);
      }
      setModalExit(false);
    } else {
      setModalExit(false);
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h1 className="h5 text-primary font-weight-bold mb-5">
              Resumen de tu viaje
            </h1>
          </Col>
          <Col md="12">
            <h1 className="h5 font-weight-bold mb-3">Punto de partida</h1>
            <CardLogBootstrapStyled className="shadow-6dp">
              <Card.Body>
                <Form>
                  <Row>
                    <Col md="1">
                      <div className="circle-icon">
                        <span className="icon-ping text-primary"></span>
                      </div>
                    </Col>
                    <Col md="11">
                      <p>
                        <strong>Dirección de retiro:</strong>{" "}
                        {entity?.origin_site?.address || "No posee"}
                      </p>
                      <p>
                        <strong>Indicaciones adicionales:</strong>{" "}
                        {entity?.additional_origin_indications || "No posee"}
                      </p>
                      <p className="text-capitalize">
                        <strong>Tipo de origen:</strong>{" "}
                        {entity?.origin?.toLowerCase() || "No posee"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="1">
                      <div className="circle-icon">
                        <span className="icon-calendar text-primary"></span>
                      </div>
                    </Col>
                    <Col md="11" className="mt-1">
                      <strong>Fecha de retiro:</strong>{" "}
                      {moment(entity.withdraw_date).format("DD/MM/YYYY")}
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </CardLogBootstrapStyled>
          </Col>

          <Col md="12" className="mt-5">
            <h1 className="h5 font-weight-bold mb-3">Destino</h1>
            <CardLogBootstrapStyled className="shadow-6dp">
              <Card.Body>
                <Form>
                  <Row>
                    <Col md="1">
                      <div className="circle-icon">
                        <span className="icon-ping text-primary"></span>
                      </div>
                    </Col>
                    <Col md="11">
                      <p>
                        <strong>Dirección de entrega:</strong>{" "}
                        {entity?.destiny_site?.address || "No posee"}
                      </p>
                      <p>
                        <strong>Indicaciones adicionales:</strong>{" "}
                        {entity?.additionl_destiny_indications || "No posee"}
                      </p>

                      <p className="text-capitalize">
                        <strong>Tipo de destino:</strong>{" "}
                        {entity?.destiny?.toLowerCase() || "No posee"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="1">
                      <div className="circle-icon">
                        <span className="icon-calendar text-primary"></span>
                      </div>
                    </Col>
                    <Col md="11" className="mt-1">
                      <strong>Fecha de entrega:</strong>{" "}
                      {moment(entity.delivery_date).format("DD/MM/YYYY")}
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </CardLogBootstrapStyled>
          </Col>

          <Col md="12" className="mt-5">
            <CardLogBootstrapStyled className="shadow-6dp">
              <Card.Body>
                <Form>
                  <Row>
                    <Col md="1">
                      <div className="circle-icon">
                        <span className="icon-plant text-primary"></span>
                      </div>
                    </Col>
                    <Col md="11">
                      <p>
                        <strong>Producto a trasladar:</strong>{" "}
                        {entity.product.product_type_name || "No posee"}
                      </p>
                      <p>
                        <strong>Tipo de carga:</strong>{" "}
                        {entity.charger_type.cargo_type_name || "No posee"}
                      </p>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </CardLogBootstrapStyled>
          </Col>

          <Col md="12">
            <h1 className="h5 font-weight-bold mt-5 mb-3">Camiones</h1>
            <CardLogBootstrapStyled className="shadow-6dp">
              <Card.Body>
                <Form>
                  <Row>
                    <Col md="1">
                      <div className="circle-icon">
                        <span className="icon-truck text-primary"></span>
                      </div>
                    </Col>
                    <Col md="11">
                      <p>
                        <strong>Cantidad de camiones solicitados:</strong>&nbsp;
                        {entity.trucks_number || "No posee"}
                      </p>
                      <p>
                        <strong>Cantidad de carga por camión:</strong>&nbsp;
                        {entity.truck_charge
                          ? entity.unit_truck_charge
                            ? entity.truck_charge +
                              " " +
                              entity.unit_truck_charge?.toLowerCase()
                            : entity.truck_charge
                          : "No posee"}
                      </p>
                      <p>
                        <strong>Tipo:</strong>&nbsp;
                        {entity.truck_type || "No posee"}
                      </p>
                      <p>
                        <strong>Características:</strong>&nbsp;
                        {entity.truck_features || "No posee"}
                      </p>
                      <p>
                        <strong>Accesorios:</strong>&nbsp;
                        {entity.truck_accessories || "No posee"}
                      </p>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </CardLogBootstrapStyled>
          </Col>
          {entity?.drivers?.length > 0 && (
            <Col md="12">
              <h1 className="h5 font-weight-bold mt-5 mb-3">
                Choferes asignados
              </h1>
              <CardLogBootstrapStyled className="shadow-6dp">
                <Card.Body>
                  <Form>
                    <Row>
                      <Col md="1">
                        <div className="circle-icon">
                          <span className="icon-truck text-primary"></span>
                        </div>
                      </Col>
                      <Col md="11">
                        <p>
                          <strong>Cantidad de choferes necesarios:</strong>
                          &nbsp;
                          {entity?.trucks_number}
                        </p>
                        <p>
                          <strong>Choferes asignados:</strong>&nbsp;
                        </p>
                        <ul>
                          {entity?.drivers?.map((item, index) => (
                            <li key={index}>
                              {item.name + " " + item.last_name}
                            </li>
                          ))}
                        </ul>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </CardLogBootstrapStyled>
            </Col>
          )}
          <Col md="12">
            <h1 className="h5 font-weight-bold mb-3 mt-5">
              Precios y condiciones{" "}
              <small className="text-font-info">
                ({TAXES_LABEL || "no incluyen IVA"})
              </small>
            </h1>
            <CardLogBootstrapStyled className="shadow-6dp">
              <Card.Body>
                <Form>
                  <Row>
                    <Col md="1">
                      <div className="circle-icon">
                        <span className="icon-cash text-primary"></span>
                      </div>
                    </Col>
                    <Col md="11">
                      <p>
                        <strong>
                          Precio por{" "}
                          {entity?.unit_truck_charge?.toLowerCase() ||
                            "tonelada"}
                          :
                        </strong>{" "}
                        {priceCalculation(entity.price_per_ton)}
                      </p>
                      <p>
                        <strong>
                          Precio{" "}
                          {entity.price_type === "1" ? "abierto" : "cerrado"}{" "}
                          por viaje:
                        </strong>{" "}
                        {priceCalculation(
                          entity.price_per_ton * entity.truck_charge
                        )}
                      </p>
                      <p>
                        <strong>Forma de pago:</strong> {entity.payment_type}
                      </p>
                      <p>
                        <strong>Teléfono de Contacto logístico:</strong>{" "}
                        {entity.logis_number_dc}
                      </p>
                      <p>
                        <strong>Plazos de pago en días:</strong>{" "}
                        {entity.payment_terms_days}
                      </p>
                      <p>
                        <strong>Requisitos y condiciones:</strong>{" "}
                        {entity.requirements_conditions || "No posee"}
                      </p>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </CardLogBootstrapStyled>
          </Col>

          <Col md="12">
            {isPreview ? (
              <Row>
                <Col md="4" className="text-center text-md-left">
                  <LogButton
                    className="mt-3 w-100"
                    style={{ maxWidth: "200px" }}
                    size="lg"
                    title="Volver"
                    border="curve"
                    block
                    color="secondary"
                    onClick={() => {
                      history.push("/app/travels?preview=true", {
                        previewEntity,
                        previewLabels,
                      });
                    }}
                  />
                </Col>
                <Col md="4" className="text-center">
                  <LogButton
                    className="mt-3 w-100"
                    style={{ maxWidth: "200px" }}
                    size="lg"
                    title="Listo"
                    border="curve"
                    block
                    onClick={async () => {
                      if (isPreview) {
                        const resp = await postTripAPI(previewEntity);
                        const { msg, status } = resp;
                        if (!status) {
                          successToast(msg);
                          history.push("/app");
                        } else {
                          errorToast(msg);
                        }
                      } else {
                        history.push("/app");
                      }
                    }}
                  />
                </Col>
              </Row>
            ) : back ? (
              <Row>
                <Col md="4" className="text-center text-md-left">
                  <LogButton
                    className="mt-3 w-100"
                    size="lg"
                    title="Volver"
                    border="curve"
                    block
                    style={{ maxWidth: "200px" }}
                    color="secondary"
                    onClick={() => {
                      history.goBack();
                    }}
                  />
                </Col>
              </Row>
            ) : (
              <Row>
                <Col md="4" className="text-center text-md-left">
                  <LogButton
                    className="mt-3 w-100"
                    size="lg"
                    title="Editar"
                    border="curve"
                    block
                    color="secondary"
                    onClick={() => {
                      setIsShowEditModal(true);
                    }}
                    style={{ maxWidth: "200px" }}
                  />
                </Col>
                <Col md="4" className="text-center">
                  <LogButton
                    className="mt-3 w-100"
                    size="lg"
                    title="Eliminar"
                    border="curve"
                    block
                    color="danger"
                    onClick={() => {
                      setModalExit(true);
                    }}
                    style={{ maxWidth: "200px" }}
                  />
                </Col>
                <Col md="4" className="text-center text-md-right">
                  <LogButton
                    className="mt-3  w-100"
                    size="lg"
                    title="Listo"
                    border="curve"
                    block
                    onClick={async () => {
                      if (isPreview) {
                        const resp = await postTripAPI(previewEntity);
                        const { msg, status } = resp;
                        if (!status) {
                          successToast(msg);
                          history.push("/app");
                        } else {
                          errorToast(msg);
                        }
                      } else {
                        history.goBack();
                      }
                    }}
                    style={{ maxWidth: "200px" }}
                  />
                </Col>
              </Row>
            )}

            <div className="clearfix"></div>
          </Col>
        </Row>
      </Container>
      <ModalQuestion
        title="¿Estás seguro de esta accion?"
        subTitle="¿Estás seguro?"
        isShow={modalExit}
        handleClose={handleCloseExitModal}
      />
      {entity._id && (
        <ModalEditTrip
          isAdmin={user.role_id.name === ADMIN}
          isShow={isShowEditModal}
          entity={entity}
          onCloseModal={(payload) => {
            setIsShowEditModal(false);
            if (payload) {
              successToast("Viaje modificado exitosamente");
              fetchApi(entity._id);
            }
          }}
        />
      )}
    </>
  );
}

export default TravellDetail;
