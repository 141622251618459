import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { FiX } from "react-icons/fi";

import { COLOR_GRAY_TEXT } from "../../../../../../constants/Colors";

import { Tab, Tabs } from "@material-ui/core";
import TabMaterialLog from "../../../../../../components/organisms/tabs-material/TabMaterialLog";
import BillingTable from "./Sub-Componets/BillingTable";
import SubmitReceipt from "./Sub-Componets/ReceiptSubmit";

const ModalLogStyled = styled(Modal)`
  opacity: 1;
  .modal-content {
    border-radius: 15px !important;
    padding: 20px;
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;
const DivParentTabs = styled.div`
  border-bottom: 1px solid #d1d0d0;
`;
const TabsStyled = styled(Tabs)`
  width: 80%;
  margin: 0px 30px;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0px;
    margin-right: 10px;
  }
`;

const TabStyled = styled(Tab)`
  span {
    font-size: 18px;
    font-weight: bolder !important;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #000;
  &:hover {
    color: #ff0000;
  }
`;

const ModalReceipt = ({ isShow, handleClose, data, refetch }) => {
  const [value, setValue] = useState(0);
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <ModalLogStyled
      size="xl"
      show={isShow}
      onHide={() => {
        handleClose();
      }}
      centered
      className="modal-exit"
    >
      <Modal.Header>
        <CloseButton
          onClick={() => {
            handleClose();
          }}
        >
          <FiX />
        </CloseButton>
      </Modal.Header>
      <Modal.Body className="p-0">
        <>
          <DivParentTabs>
            <TabsStyled
              value={value}
              onChange={handleTabs}
              indicatorColor="primary"
              textColor="primary"
              aria-label="simple tabs example"
            >
              <TabStyled label="Estado de cuenta" {...a11yProps(0)} />
              <TabStyled label="Cargar recibo" {...a11yProps(1)} />
            </TabsStyled>
          </DivParentTabs>
          <>
            <TabMaterialLog value={value} index={0}>
              <BillingTable data={data} />
            </TabMaterialLog>
            <TabMaterialLog value={value} index={1}>
              <SubmitReceipt data={data} refetch={refetch} />
            </TabMaterialLog>
          </>
        </>
      </Modal.Body>
      <Modal.Footer className="footer-modal">
        {/* <Col className="d-flex justify-content-center">
          <LogButton
            className="w-50"
            color="primary"
            onClick={handleSubmit}
            title={"Editar"}
            border="curve"
          />
        </Col> */}
      </Modal.Footer>
    </ModalLogStyled>
  );
};

export default ModalReceipt;
