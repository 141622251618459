import React from "react";
import {
  Col,
  Row,
  Card,
  Image,
  Container,
  InputGroup,
  Form,
  Button,
  Stack,
} from "react-bootstrap";
import styled from "styled-components";
import LogButton from "../../../../../../../../components/atoms/LogButton";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  getClaims,
  postClaim,
  putCloseClaim,
} from "../../../../../../../../api/modules";
import { errorToast } from "../../../../../../../../services/toastService";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import DefaultAvatar from "../../../../../../../../assets/img/default-avatar.png";
import { toNumber } from "lodash";
import { useRef } from "react";
import { FiPaperclip } from "react-icons/fi";
import useAutosizeTextArea from "../../../../../../../../components/organisms/auto-Resize/AutoResize";
import { GoFilePdf } from "react-icons/go";
import { getClaimsAdmin } from "../../../../../../../../api/modules";
import Chat from "../../../../../../../../components/organisms/chatWindow/Chat";
import ChatInbox from "../../../../../../../../components/organisms/chatWindow/ChatInbox";
import {
  ADMIN,
  DADOR_DE_CARGA,
} from "../../../../../../../../constants/UserType";
import ChatWindow from "../../../../../../../../components/organisms/chatWindow/ChatWindow";
import { claim } from "../../../../../../../../api/config/apiRoute";

const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  min-height: 70vh;
`;

const ClaimAdminDador = ({ id_trip, id_tripDetail }) => {
  const [claims, setClaims] = useState({});
  const spinner = useSelector((state) => state.appReducer.isLoading);
  const inToView = useRef(null);
  const scrollToView = () => {
    inToView.current?.scrollIntoView({ behavior: "smooth" });
  };

  const uploadData = async (image) => {
    try {
      let data = new FormData();
      data.append("file", image);
      data.append("upload_preset", "ceqdn88c");
      const res = await fetch(
        "https://api.cloudinary.com/v1_1/dcbqcvers/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const file = await res.json();
      return file.secure_url;
    } catch (error) {
      console.log("Error Cloudinary", error);
      errorToast("Por favor intente nuevamente");
    }
  };

  const handleSubmitAPI = async ({
    values,
    resetForm,
    setImage,
    image,
    id_receptor,
  }) => {
    try {
      let document = "";
      if (values?.document) {
        document = await uploadData(image);
      }
      const { msg } = await postClaim({
        ...values,
        document,
        id_trip,
        id_tripDetail,
        id_receptor,
      });

      resetForm();
      setImage({});
      getAllClaims();

      if (msg) {
        errorToast(msg);
      }
    } catch (error) {
      console.log("error", error);
      resetForm();
      setImage({});
      getAllClaims();
      /* errorToast(error); */
    }
  };
  const getAllClaims = async () => {
    const { msg, claim, claimCreator, claimed } = await getClaimsAdmin(
      id_tripDetail
    );
    if (!msg) {
      setClaims({
        claim,
        claimCreator,
        claimed,
      });
    } else {
      errorToast(msg);
    }
  };

  const handleCloseClaim = async () => {
    const { msg } = await putCloseClaim({ claim_id: claims.claim._id });
    getAllClaims();
    if (msg) {
      errorToast(msg);
    }
  };

  useEffect(() => {
    getAllClaims();
  }, []);

  useEffect(() => {
    scrollToView();
  }, [claims]);

  return (
    <Container className="py-4">
      {claims.claim ? (
        <>
          {" "}
          <Row className="justify-content-center">
            <Col md={6} className="mb-2">
              <ChatWindow
                title={"Reclamante"}
                subTitle={
                  <Row className="d-flex justify-content-between align-text-bottom m-0">
                    <small>
                      <p className="text-primary align-text-bottom text-capitalize mb-1 font-weight-bold">
                        {claims.claim.id_creator.name +
                          " " +
                          claims.claim.id_creator.last_name}
                      </p>
                    </small>
                    <small>
                      <p className="text-primary text-center mb-1 font-weight-bold">
                        {claims.claim.id_creator.name === DADOR_DE_CARGA
                          ? "Dador"
                          : "Tomador"}
                      </p>
                    </small>
                  </Row>
                }
                handleSubmitAPI={handleSubmitAPI}
                data={claims.claimCreator}
                roleSent={ADMIN}
                active={claims.claim}
                id_receptor={claims.claim.id_creator}
              />
            </Col>
            <Col md={6}>
              <ChatWindow
                title={"Reclamado"}
                subTitle={
                  <Row className="d-flex justify-content-between align-text-bottom m-0">
                    <small>
                      <p className="text-primary align-text-bottom text-capitalize mb-1 font-weight-bold">
                        {claims.claim.id_claimed.name +
                          " " +
                          claims.claim.id_claimed.last_name}
                      </p>
                    </small>
                    <small>
                      <p className="text-primary text-center mb-1 font-weight-bold">
                        {claims.claim.id_creator.name === DADOR_DE_CARGA
                          ? "Tomador"
                          : "Dador"}
                      </p>
                    </small>
                  </Row>
                }
                handleSubmitAPI={handleSubmitAPI}
                data={claims.claimed}
                roleSent={ADMIN}
                active={claims.claim}
                id_receptor={claims.claim.id_claimed}
              />
            </Col>
          </Row>
          <Row className="justify-content-center mt-1" ref={inToView}>
            {claims.claim?.status ? (
              <LogButton
                size={20}
                title={"Cerrar Reclamo"}
                onClick={handleCloseClaim}
              ></LogButton>
            ) : (
              <LogButton size={20} title={"Reclamo Cerrado"}></LogButton>
            )}
          </Row>{" "}
        </>
      ) : !spinner ? (
        <DivEmpty>
          <h2 className="font-weight-bold h3 mt-3">No hay reclamo inciado</h2>
        </DivEmpty>
      ) : (
        <DivEmpty></DivEmpty>
      )}
    </Container>
  );
};

export default ClaimAdminDador;
