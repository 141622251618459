/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import {
  postLocationAPI,
  getLocationByIdAPI,
  putLocationByIdAPI,
  delLocationByIdAPI,
} from "../../../../../api/modules";

import { BiTrash } from "react-icons/bi";

// react-bootstrap components
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { errorToast, successToast } from "../../../../../services/toastService";
import LogInput from "../../../../../components/atoms/LogInput";
import LogButton from "../../../../../components/atoms/LogButton";
import LogTextArea from "../../../../../components/atoms/LogTextArea";
import LogRadioGroup from "../../../../../components/atoms/LogRadioGroup";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import LogRadioGroupWrapper from "../../../../../components/atoms/LogRadioGroupWrapper";
import { getAddressByCoors } from "../../../../../services/geocodeGoogleAPI";
import ContentHeader from "../../../components/molecules/ContentHeader";
import ModalQuestion from "../../../../../components/organisms/modal-question/ModalQuestion";
import ModalInfo from "../../../../../components/organisms/modal-info/ModalInfo";
import useGoogleMapsApi from "../../../../../services/useGoogleMapsApi";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import {
  setLoadingSpinerOff,
  setLoadingSpinerOn,
} from "../../../../../redux/actions/app";

const ProfileSchema = Yup.object().shape({
  site_name: Yup.string().required("Nombre del lugar es obligatorio"),
  address: Yup.string().required("Dirección es obligatorio"),
  additional_indications: Yup.string().matches(
    /^([^0-9]*)$/,
    "No está permitido ingresar numeros"
  ),
  origin: Yup.string().required("Tipo de origen es obligatorio"),
});

const BtnInvisible = styled(Button)`
  background: none;
  border: none;
  &:hover {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
  &:active {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
  &:focus {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
`;
const RowDeleteStyled = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  right: 15px;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
`;

const CardLogBootstrapStyled = styled(Card)`
  padding: 10px;
  border-radius: 15px;
`;

function Location() {
  const mapRef = useRef(null);
  const addressRef = useRef(null);
  const autoCompleteRef = useRef(null);
  const locationUrl = useLocation();
  const history = useHistory();
  const params = useParams();
  const googleMapsApi = useGoogleMapsApi();
  const [zoomMap, setZoomMap] = useState(8);
  const [entity, setEntity] = useState({});
  const [modalExit, setModalExit] = useState(false);
  const [marker, setMarker] = useState();
  const [map, setMap] = useState();
  const [successModal, setSuccessModal] = useState(false);
  const [locationAPI, setLocation] = useState({
    lat: -38.4192641,
    lng: -63.5989206,
  });
  const dispatch = useDispatch();

  const [province, setProvince] = useState("");
  const [locality, setLocality] = useState("");
  const {
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    dirty,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      site_name: entity.site_name || "",
      address: entity.address || "",
      additional_indications: entity.additional_indications || "",
      origin: entity.origin || "SILO",
    },
    validationSchema: ProfileSchema,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmitAPI(values);
      setSubmitting(false);
    },
  });

  const fetchApi = async ({ id, userLocation }) => {
    let resp = null;
    if (id) resp = await getLocationByIdAPI(id);
    const location = resp?.location || userLocation;
    if (location) {
      setLocation({
        lat: location.latitude,
        lng: location.longitude,
      });
      setEntity({
        ...location,
      });
      handleLoadGoogleMap({
        lat: location.latitude,
        lng: location.longitude,
      });
      setAddressInInput({
        lat: location.latitude,
        lng: location.longitude,
        first: true,
      });
    }
  };

  const handleLoadGoogleMap = () => {
    const mapOptions = {
      zoom: zoomMap,
      center: locationAPI,
      scrollwheel: true,
      zoomControl: true,
      mapTypeId: googleMapsApi?.MapTypeId?.ROADMAP,
      clickableIcons: false,
      disableDefaultUI: true,
      fullscreenControl: true,
      mapTypeControl: true,
    };
    setMap(new googleMapsApi.Map(mapRef.current, mapOptions));
  };

  useEffect(() => {
    if (marker) {
      marker.addListener("dragend", async (event) => {
        setLocation({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        });
        setAddressInInput({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        });
      });
    }
  }, [marker]);

  useEffect(() => {
    if (map) {
      map.addListener("zoom_changed", () => {
        const zoomLevel = map.getZoom();
        setZoomMap(zoomLevel);
      });
      map.setCenter(locationAPI);
      setMarker(
        new googleMapsApi.Marker({
          position: locationAPI,
          map: map,
          draggable: true,
          title: "Arrastrar a la ubicacion",
          animation: googleMapsApi.Animation.DROP,
        })
      );
    }
  }, [map]);

  useEffect(() => {
    marker?.setPosition(locationAPI);
    map?.setCenter(locationAPI);
  }, [locationAPI]);

  const setAddressInInput = async ({ lat, lng, first, coords = false }) => {
    try {
      const { ok, data, province, locality } = await getAddressByCoors(
        lat,
        lng
      );
      if (ok) {
        if (!first) setFieldValue("address", data);
        setProvince(province);
        setLocality(locality);
        if (coords)
          setLocation({
            lat,
            lng,
          });
      } else {
        setProvince("");
        setLocality("");
        setFieldValue("address", "");
        errorToast("Seleccione otra localización en el mapa");
      }
    } catch (error) {
      console.error("setAddressInInput", error);
      setFieldValue("address", "");
    }
  };

  useEffect(() => {
    if (googleMapsApi) {
      const { id } = params;
      if (id) {
        fetchApi({ id });
      } else {
        getLocation();
      }
      const options = {
        /* types: ["geocode"], */
        componentRestrictions: { country: "ar" },
      };
      autoCompleteRef.current = new googleMapsApi.places.Autocomplete(
        addressRef.current,
        options
      );
      autoCompleteRef.current.addListener("place_changed", (e) => {
        const place = autoCompleteRef.current.getPlace();
        if (place?.geometry?.location) {
          setAddressInInput(place.geometry.location.toJSON());
          setLocation(place.geometry.location.toJSON());
        }

        /*  if (!place?.geometry || !place?.geometry?.location) {
          setFieldValue("address", "");
          errorToast("Ingrese una direccion valida");
          return;
        }
        setAddressInInput(place.geometry.location.toJSON());
        setLocation(place.geometry.location.toJSON()); */
      });
    }
  }, [googleMapsApi]);

  const getLocation = () => {
    if (navigator.geolocation) {
      dispatch(setLoadingSpinerOn());
      navigator.geolocation.getCurrentPosition(
        (position) => {
          fetchApi({
            userLocation: {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
          });
          dispatch(setLoadingSpinerOff());
        },
        (error) => {
          handleLoadGoogleMap();
          dispatch(setLoadingSpinerOff());
        },
        {
          enableHighAccuracy: true,
          timeout: 50000,
          maximumAge: 0,
        }
      );
    } else {
      handleLoadGoogleMap();
    }
  };

  const handleSubmitAPI = async (values) => {
    const body = {
      ...values,
      latitude: locationAPI.lat,
      longitude: locationAPI.lng,
      province,
      locality,
    };

    if (!province || !locality) {
      errorToast("Por favor ingrese una direccion valida");
      return;
    }

    let response = {};
    if (entity._id) {
      response = await putLocationByIdAPI(entity._id, body);
    } else {
      response = await postLocationAPI(body);
    }
    const { msg, status, location } = response;
    const { retrieve_origin, retrieve_destine, destiny_site, origin_site } =
      queryString.parse(locationUrl.search);
    if (retrieve_origin) {
      history.push(
        `/app/travels?retrieve_origin=${location?._id}${
          destiny_site ? `&retrieve_destine=${destiny_site}` : ""
        }`
      );
    }

    if (retrieve_destine) {
      history.push(
        `/app/travels?retrieve_destine=${location?._id}${
          origin_site ? `&retrieve_origin=${origin_site}` : ""
        }`
      );
    }

    if (status) {
      errorToast(msg);
    } else {
      setSuccessModal(true);
    }
  };

  const handleDeleteItem = async (id) => {
    const resp = await delLocationByIdAPI(id);
    const { msg } = resp;

    successToast(msg);
    history.push("/app/locations");
  };

  const handleSuccessModal = async (response) => {
    if (response) history.push("/app/locations");
  };
  const handleCloseExitModal = async (response) => {
    if (response) {
      await handleDeleteItem(entity._id);
      setModalExit(false);
    } else {
      setModalExit(false);
    }
  };

  const RowDelete = () => {
    return entity._id ? (
      <RowDeleteStyled>
        <OverlayTrigger
          key="delete"
          overlay={<Tooltip id={`delete`}>Eliminar</Tooltip>}
        >
          <BtnInvisible onClick={() => setModalExit(true)}>
            <BiTrash />{" "}
          </BtnInvisible>
        </OverlayTrigger>
      </RowDeleteStyled>
    ) : (
      ""
    );
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const input = e?.target?.value;
      const regexCoords = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/; // Expresión regular para validar coordenadas

      if (regexCoords.test(input)) {
        const [lat, lng] = input.split(",").map(parseFloat);
        setAddressInInput({ lat, lng, coords: true });
      }
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <ContentHeader
            className={`ml-3`}
            title={entity._id ? "Editar Localización" : "Nueva Localización"}
          />
          <Col md="12">
            <CardLogBootstrapStyled className="shadow-3dp pt-3">
              <CardLogBootstrapStyled.Body>
                <Form>
                  <RowDelete />
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label className="text-primary">Nombre del lugar</label>
                        <LogInput
                          placeholder="Lugar"
                          type="text"
                          name="site_name"
                          onChange={handleChange}
                          onBlur={handleBlur.site_name}
                          errors={errors}
                          value={values.site_name}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <Form.Group>
                        <label className="text-primary">Dirección</label>

                        <LogInput
                          refEl={addressRef}
                          placeholder="Dirección"
                          autoComplete="off"
                          type="text"
                          name="address"
                          onBlur={() => {
                            setAddressInInput(locationAPI);
                          }}
                          onKeyDown={handleKeyDown}
                          onChange={async (e) => {
                            setFieldValue("address", e.target.value);
                          }}
                          errors={errors}
                          value={values.address}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="p-0 mb-3 mt-2">
                    <Col md="12" className="p-0">
                      <div className="map-container">
                        <div id="map" ref={mapRef}></div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Form.Group>
                        <label className="font-weight-bold text-black">
                          Indicaciones adicionales{" "}
                          <span className="text-infoLight">(opcional)</span>
                        </label>
                        <div>
                          <LogTextArea
                            /* style={{ width: "60%" }} */
                            placeholder="Indicaciones adicionales"
                            className="mb-2 number-font w-100"
                            name="additional_indications"
                            onChange={handleChange}
                            onBlur={handleBlur.additional_indications}
                            errors={errors}
                            value={values.additional_indications}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <LogRadioGroupWrapper
                        name="origin"
                        onBlur={handleBlur.origin}
                        errors={errors}
                        label="Selecciona un tipo de localización"
                      >
                        <Row key={1} className="pl-3">
                          <Col md="6" key={"SILO"}>
                            <LogRadioGroup
                              className="log-radio"
                              name="origin"
                              value="SILO"
                              label="Silo"
                              checked={values.origin === "SILO"}
                              onChange={(value) => {
                                setFieldValue(
                                  "origin",
                                  value.target.value.toUpperCase()
                                );
                              }}
                            />
                          </Col>
                          <Col md="6" key={"PLANTA"}>
                            <LogRadioGroup
                              className="log-radio"
                              name="origin"
                              value="PLANTA"
                              label="Planta"
                              checked={values.origin === "PLANTA"}
                              onChange={(value) => {
                                setFieldValue(
                                  "origin",
                                  value.target.value.toUpperCase()
                                );
                              }}
                            />
                          </Col>
                        </Row>
                        <Row key={2} className="pl-3">
                          <Col md="6" key={"CAMPO"}>
                            <LogRadioGroup
                              className="log-radio"
                              name="origin"
                              value="CAMPO"
                              label="Campo"
                              checked={values.origin === "CAMPO"}
                              onChange={(value) => {
                                setFieldValue(
                                  "origin",
                                  value.target.value.toUpperCase()
                                );
                              }}
                            />
                          </Col>
                          <Col md="6" key={"PUERTO"}>
                            <LogRadioGroup
                              className="log-radio"
                              name="origin"
                              value="PUERTO"
                              label="Puerto"
                              checked={values.origin === "PUERTO"}
                              onChange={(value) => {
                                setFieldValue(
                                  "origin",
                                  value.target.value.toUpperCase()
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      </LogRadioGroupWrapper>
                    </Col>
                  </Row>
                  <Row className="justify-content-center justify-content-md-between">
                    <LogButton
                      color="primary"
                      className="mt-3 w-100"
                      style={{ maxWidth: "300px" }}
                      title={`${entity._id ? "Editar" : "Crear"} localización`}
                      block
                      border="curve"
                      size="lg"
                      onClick={() => {
                        if (dirty) handleSubmit();
                      }}
                      disabled={!dirty}
                    />
                    {entity._id ? (
                      <LogButton
                        color="secondary"
                        className="mt-3 w-100"
                        style={{ maxWidth: "300px" }}
                        title={`Cancelar`}
                        block
                        border="curve"
                        size="lg"
                        onClick={() => {
                          history.goBack();
                        }}
                      />
                    ) : null}
                  </Row>

                  <div className="clearfix"></div>
                </Form>
              </CardLogBootstrapStyled.Body>
            </CardLogBootstrapStyled>
          </Col>
          <ModalQuestion
            title="Estás por eliminar esta localización"
            subTitle="¿Estás seguro?"
            isShow={modalExit}
            handleClose={handleCloseExitModal}
          />
          <ModalInfo
            title="Tu localización se guardó exitosamente"
            subTitle="Podés usar esta localización tanto como origen o destino
                a la hora de pedir un nuevo viaje"
            isShow={successModal}
            handleClose={handleSuccessModal}
          />
        </Row>
      </Container>
    </>
  );
}

export default Location;
