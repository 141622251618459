/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import queryString from "query-string";
import TripDetails from "../InActions/TripDetails";
import FinishedTravelsTC from "./FinishedTravelsTC";
import FinishedTravelsTrucksTC from "./FinishedTravelsTrucksTC";

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const ContainerList = styled.div`
  overflow-y: overlay;
`;

const FinishedTC = ({ id, searchQuery }) => {
  const history = useHistory();
  const location = useLocation();
  const [tripId, setTripId] = useState(null);
  const [detailTrip, setDetailTrip] = useState(false);



  useEffect(() => {
    const { tripId, detailTrip } = queryString.parse(location.search);
    setTripId(tripId);
    setDetailTrip(detailTrip);
  }, [location]); 


  const getScreen =
    tripId && detailTrip ? (
      <TripDetails detailTrip={detailTrip} tripId={tripId} tripState={5}/>
    ) : tripId ? (
      <FinishedTravelsTrucksTC tripId={tripId} idTc={id}/>
    ) : (
      <FinishedTravelsTC id={id} searchQuery={searchQuery}/>
    );

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <ContainerList>{getScreen}</ContainerList>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default FinishedTC;
