/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// react-bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import { getUserReputation } from "../../../../../api/modules";
import { errorToast } from "../../../../../services/toastService";

import styled from "styled-components";

import ReputationAdmin from "./ReputationAdmin";
import { TOMADOR_DE_CARGA } from "../../../../../constants/UserType";
import { useSelector } from "react-redux";

const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  /* align-items: center; */
  min-height: 70vh;
`;

function UserReputation({ id, role }) {
  const [reputation, setReputation] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const spinner = useSelector((state) => state.appReducer.isLoading);

  const onRefetch = () => setRefetch((prev) => !prev);

  useEffect(() => {
    const UserReputation = async () => {
      const response = await getUserReputation(id);
      if (response.msg) {
        errorToast(response.msg);
      } else {
        setReputation(response);
      }
    };

    UserReputation();
  }, [refetch]);

  return (
    <>
      <Container fluid>
        {reputation?.ratingReceived?.length > 0 ||
        reputation?.ratingSent?.length > 0 ? (
          <Row>
            <Col md={6}>
              <h3 className="text-primary mt-2 ml-3 font-weight-bold ">
                Enviadas
              </h3>
              {reputation?.ratingSent?.length > 0 ? (
                reputation?.ratingSent.map((ele) => (
                  <ReputationAdmin
                    key={ele._id}
                    tomador={role === TOMADOR_DE_CARGA ? true : false}
                    score={ele.score}
                    comment={ele.comment}
                    refetch={onRefetch}
                    id_rating={ele._id}
                  />
                ))
              ) : (
                <DivEmpty>
                  <h2 className="font-weight-bold h3 text-center">
                    Sin calificaciones enviadas
                  </h2>
                </DivEmpty>
              )}
            </Col>
            <Col md={6}>
              <h3 className="text-primary mt-2 ml-3 font-weight-bold ">
                Recibidas
              </h3>
              {reputation?.ratingReceived?.length > 0 ? (
                reputation?.ratingReceived.map((ele) => (
                  <ReputationAdmin
                    key={ele._id}
                    tomador={role !== TOMADOR_DE_CARGA ? true : false}
                    score={ele.score}
                    comment={ele.comment}
                    refetch={onRefetch}
                    id_rating={ele._id}
                  />
                ))
              ) : (
                <DivEmpty>
                  <h2 className="font-weight-bold h3 text-center">
                    Sin calificaciones recibidas
                  </h2>
                </DivEmpty>
              )}
            </Col>
          </Row>
        ) : (
          <DivEmpty>
            {!spinner ? (
              <h2 className="font-weight-bold h3 text-center">
                Usuario sin calificaciones
              </h2>
            ) : null}
          </DivEmpty>
        )}
      </Container>
    </>
  );
}

export default UserReputation;
