import { Badge, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getTripAPI, getTripChilds } from "../../../../../api/modules";
import CardItemOriginDestiny from "../../../components/molecules/CardItemOriginDestiny";
import LogTextNumber from "../../../../../components/atoms/LogTextNumber";
import PaginationList from "../../../../../components/organisms/pagination-list/PaginationList";
import CardFinishedTrucksStars from "../../../components/molecules/CardFinishedTrucksStars";

const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;
const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const FinishedTravelsTrucks = ({ tripId }) => {
  const [refetch, setRefetch] = useState(false);
  const INITIAL_STATE = {
    total: 0,
    items: [],
  };
  const [page, setPage] = useState(0);
  const numberList = 5;
  const [tripsAPI, setTripsAPI] = useState(INITIAL_STATE);
  const [tripDetail, setTripDetail] = useState(null);
  const history = useHistory();
  const fetchData = async (limit = numberList, from = 0) => {
    const tripsResponse = await getTripChilds(tripId, "[5]", limit, from);
    const { total, tripsDetails } = tripsResponse;
    if (tripsDetails)
      setTripsAPI({
        ...INITIAL_STATE,
        total,
        items: tripsDetails,
      });
  };

  const fetchDetails = async (id) => {
    try {
      const response = await getTripAPI(id);

      if (response) setTripDetail(response.data);
    } catch (error) {
      console.log("pending error", error);
    }
  };

  const handlePreviewPage = () => {
    fetchData(numberList, numberList * page - numberList);
    setPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    fetchData(numberList, numberList * (page + 1));
  };
  const onRefetch = () => setRefetch((prev) => !prev);

  useEffect(() => {
    fetchData();
  }, [refetch]);

  useEffect(() => {
    fetchDetails(tripId);
  }, []);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <CardItemOriginDestiny
              originName={tripDetail?.trip.origin_site.site_name}
              destinyName={tripDetail?.trip.destiny_site.site_name}
              deliveryDate={tripDetail?.trip.delivery_date}
              withdrawDate={tripDetail?.trip.withdraw_date}
              /* onClickDetail={handleContactShow} */
              destiny={tripDetail?.trip.destiny}
              origin={tripDetail?.trip.origin}
            />
            <Row className="align-content-center m-0">
              <Col className="d-flex flex-wrap align-items-center justify-content-end">
                <h6 className="">
                  <Badge
                    pill
                    bg="primaryLight"
                    text="primary"
                    className="py-2 px-3"
                  >
                    Pago:{" "}
                    {tripDetail?.trip.payment_type ? (
                      <LogTextNumber
                        text={`${tripDetail?.trip.payment_type?.payment_type_name}`}
                        style={{
                          color: "#92c31a",
                          fontSize: "0.75rem",
                          fontWeight: "900",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Badge>
                </h6>
                <h6 className="ml-2">
                  <Badge
                    pill
                    bg="primaryLight"
                    text="primary"
                    className="py-2 px-3"
                  >
                    {/* Tipo-Carga:{' '} */}
                    {tripDetail?.trip.product?.product_type_name ? (
                      <div>
                        {`${tripDetail?.trip.product?.product_type_name}`}{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </Badge>
                </h6>
                <h6 className="ml-2">
                  <Badge
                    pill
                    bg="primaryLight"
                    text="primary"
                    className="py-2 px-3"
                  >
                    Distancia:{" "}
                    {tripDetail?.trip.kilometers ? (
                      <LogTextNumber
                        text={`${tripDetail?.trip.kilometers} km`}
                        style={{
                          color: "#92c31a",
                          fontSize: "0.75rem",
                          fontWeight: "900",
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Badge>
                </h6>
              </Col>
            </Row>

            {tripsAPI?.items.map((location, index) => (
              <CardFinishedTrucksStars
                realTransportedWeight={location?.realTransportedWeight}
                auditedWeight={location?.auditedWeight}
                paymentDays={
                  location.id_postulation?.payment_terms_days_postulation ||
                  tripDetail?.trip.payment_terms_days
                }
                paymentTerms={tripDetail?.trip.payment_type?.payment_type_name}
                chargeTruck={
                  tripDetail?.trip.truck_charge +
                  " " +
                  tripDetail?.trip.unit_truck_charge
                }
                productName={tripDetail?.trip.product?.product_type_name}
                priceUnit={"/" + location.unit_truck_charge?.slice(0, 3)}
                price_per_ton={
                  location.price_per_ton || tripDetail?.trip.price_per_ton
                }
                name={
                  location.id_driver
                    ? location.id_driver?.name
                    : location.id_tomador?.name
                }
                lastName={
                  location.id_driver
                    ? location.id_driver?.last_name
                    : location.id_tomador?.last_name
                }
                state={location.state}
                id={location._id}
                id_trip={tripId}
                truckDomain={location.id_truck?.domain}
                trailerDomain={location.id_trailer?.domain}
                key={index}
                onClick={() =>
                  history.push(
                    `/app/list-travels?state=3&tripId=${tripId}&detailTrip=${location._id}`
                  )
                }
                start={
                  location.registerstates.find((ele) => ele.state === 4)
                    .createdAt
                }
                end={
                  location.registerstates.find((ele) => ele.state === 5)
                    .createdAt
                }
                score={location?.rating_dador?.score}
                comment={location?.rating_dador?.comment}
                refetch={onRefetch}
              />
            ))}
            {tripsAPI?.length === 0 && (
              <DivEmpty>
                <p>No hay localizaciones disponibles</p>
              </DivEmpty>
            )}
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={tripsAPI.items}
            total={tripsAPI.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
      </RowStyled>
    </>
  );
};

export default FinishedTravelsTrucks;
