import React from 'react'
import { Modal, Col, Row } from 'react-bootstrap'
import AvatarLog from '../../../styledComponents/AvatarLog'
import ReactStars from 'react-rating-stars-component'
import { BsStarFill } from 'react-icons/bs'
import LogButton from '../../atoms/LogButton'
import styled from 'styled-components'
import { FiAlertCircle } from 'react-icons/fi'
import { COLOR_GRAY_TEXT } from '../../../constants/Colors'
import CardContactLog from '../../../pages/Dashboard/components/molecules/CardContactLog'

const ModalLogStyled = styled(Modal)`
  opacity: 1;
  .modal-content {
    border-radius: 22px !important;
    padding: 20px;
    width:360px;
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`

const ContentModal = styled.div`
  text-align: center;
`

const ModalContact = ({
  isShow,
  handleClose,
  name,
  lastName,
  srcAvatar,
  phone,
  truckType,
  chassis,
  coupled
}) => {
  return (
    <ModalLogStyled show={isShow} onHide={handleClose} className='modal-exit'>
      <Modal.Body>
        <ContentModal>
          <Row className='justify-content-center align-items-center'>
            <Col className='p-0 justify-content-center text-center'>
              <AvatarLog
                style={{
                  position: 'initial',
                  marginTop: '10px'
                }}
                width='50px'
                height='50px'
                src={srcAvatar}
                roundedCircle
              />
            </Col>
          </Row>
          <Row className='justify-content-center align-items-center'>
            <Col style={{ left: '86px' }}>
              <ReactStars
                className='rating-contact-log'
                value={4}
                count={5}
                size={18}
                activeColor='#ffd700'
                color='#CDE0EB'
                char={<BsStarFill />}
              />
            </Col>
          </Row>
          <Row className='align-items-center justify-content-center'>
            <Col className='text-center mt-3'>
              <small>
                <p
                  className='font-weight-bold mt-3 h6'
                  style={{ color: 'green' }}
                >
                  {' '}
                  Tu Chofer!{' '}
                </p>
              </small>
              <small>
                <p
                  className='font-weight-bold mb-0 h4'
                  style={{ marginBlock: '9px' }}
                >
                  {name} {lastName}
                </p>
              </small>
            </Col>
          </Row>

          <small>
            <Row className='align-items-center justify-content-center mt-3'>
              <Col md={10} className='p-0'>
                <p className='mb-0 mt-0 h6 font-weight-bold'>
                  Teléfono: <small>{phone}</small>
                </p>
                <p></p>
                <p className='mb-0 mt-0 h6 font-weight-bold'>
                  Tipo de camion: <small>{truckType}</small>
                </p>
                <p></p>
                <p className='mb-0 mt-0 h6 font-weight-bold'>
                  Chasis: <small>{chassis}</small>
                </p>
                <p></p>
                <p className='mb-0 mt-0 h6 font-weight-bold'>
                  Acoplado: <small>{coupled}</small>
                </p>
              </Col>
            </Row>
          </small>
        </ContentModal>
      </Modal.Body>
    </ModalLogStyled>
  )
}

export default ModalContact
