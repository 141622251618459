import React, { useEffect } from "react";
// react-bootstrap components
import { Container, Row, Col } from "react-bootstrap";

import { useSelector } from "react-redux";
import styled from "styled-components";
import TabMaterialLog from "../../../../../../../components/organisms/tabs-material/TabMaterialLog";
import { Tab, Tabs } from "@material-ui/core";
import Driver from "./Driver";
import GPS from "./GPS";
import TravellDetail from "../../../../Travel/TravellDetail";
import { getDetailTrip } from "../../../../../../../api/modules";
import Documentation from "../../../../MyTravels/InActions/TripDetails/Documentation";
import ClaimAdminDador from "../../../Dador/InActions/TripDetails/Claim/Claim";
import queryString from "query-string";
import ChatByTrip from "../../../../MyTravels/InActions/TripDetails/ChatByTrip/ChatByTrip";
import TravellDetailByProps from "../../../../Travel/TravellDetailByProps";
import ChatDriver from "../../../../MyTravels/InActions/TripDetails/ChatDriver/ChatDriver";

const TabsStyled = styled(Tabs)`
  width: 100%;
  height: 30px;
  .MuiTabs-flexContainer {
    justify-content: space-evenly;
  }
  .MuiTabs-indicator {
    background-color: #bcff0033;
    height: 100% !important;
    border-radius: 50px;
  }
`;

function TripDetails({ detailTrip, tripId, tripState }) {
  const id = useSelector((state) => state.authApp.user._id);
  const [value, setValue] = React.useState(0);
  const [details, setDetails] = React.useState({});

  useEffect(() => {
    const getFetchData = async () => {
      const response = await getDetailTrip(detailTrip);
      response.ok && setDetails(() => response.data);
    };

    getFetchData();
  }, [id]);

  useEffect(() => {
    const { claim } = queryString.parse(window.location.search);
    claim ? setValue(2) : setValue(0);
  }, []);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <TabsStyled
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="simple tabs example"
            >
              <Tab label="Seguimiento" {...a11yProps(0)} />
              <Tab label="Detalles de viaje" {...a11yProps(1)} />
              <Tab label="Reclamos" {...a11yProps(2)} />
              <Tab label="Chat" {...a11yProps(3)} />
              <Tab label="Chofer" {...a11yProps(4)} />
              {tripState === 5 ? (
                <Tab label="Documentación" {...a11yProps(5)} />
              ) : null}
            </TabsStyled>
            <TabMaterialLog value={value} index={0}>
              <GPS
                showId={
                  details?.parent_id && details.order
                    ? details?.parent_id + details.order
                    : null
                }
                detailTrip={detailTrip}
                state={details.state}
                origin={details.id_trip?.origin_site?.site_name}
                destiny={details.id_trip?.destiny_site?.site_name}
                registerStates={details.registerstates}
              />
            </TabMaterialLog>
            <TabMaterialLog value={value} index={1}>
              {details._id ? (
                details.discount_tomador ? (
                  <TravellDetailByProps
                    unit={details?.unit_truck_charge}
                    product={details.id_trip?.product}
                    charger_type={details.id_trip?.charger_type}
                    auditedWeight={details?.auditedWeight}
                    payment_type={
                      details.id_postulation?.payment_type_postulation
                        ?.payment_type_name ||
                      details.id_trip?.payment_type?.payment_type_name
                    }
                    phone_logic={details.id_trip?.logis_number_dc}
                    destiny_site={details.id_trip?.destiny_site}
                    origin_site={details.id_trip?.origin_site}
                    requirements_conditions={
                      details.id_postulation?.requirements_conditions ||
                      details.id_trip?.requirements_conditions
                    }
                    payment_terms_days={
                      details.id_postulation?.payment_terms_days_postulation ||
                      details.id_trip?.payment_terms_days
                    }
                    truck_charge={
                      details.auditedWeight ||
                      details.realTransportedWeight ||
                      details.id_trip?.truck_charge
                    }
                    origin={details.id_trip?.origin}
                    withdraw_date={details.id_trip?.withdraw_date}
                    destiny={details.id_trip?.destiny}
                    delivery_date={details.id_trip?.delivery_date}
                    price_per_ton={
                      typeof details.discount_trip === "number"
                        ? details.price_per_ton -
                          details.price_per_ton * (details.discount_trip / 100)
                        : details.price_per_ton -
                          details.price_per_ton *
                            ((details.discount_dador +
                              details.discount_tomador) /
                              100)
                    }
                    price_type={details.id_trip?.price_type}
                    factorDC={details.discount_dador}
                    factorTC={details.discount_tomador}
                    factorTrip={details.discount_trip}
                    additionl_destiny_indications={
                      details.id_trip?.additionl_destiny_indications
                    }
                    additional_origin_indications={
                      details.id_trip?.additional_origin_indications
                    }
                    tomador
                  />
                ) : (
                  <TravellDetail detailTrip={tripId} back={true} />
                )
              ) : null}
            </TabMaterialLog>
            <TabMaterialLog value={value} index={2}>
              <ClaimAdminDador
                id_tripDetail={detailTrip}
                id_trip={tripId}
                back={true}
              />
            </TabMaterialLog>
            <TabMaterialLog value={value} index={3}>
              <ChatByTrip
                id_tripDetail={detailTrip}
                id_trip={tripId}
                back={true}
              />
            </TabMaterialLog>
            <TabMaterialLog value={value} index={4}>
              <ChatDriver id_tripDetail={detailTrip} id_trip={tripId} />
            </TabMaterialLog>
            <TabMaterialLog value={value} index={5}>
              <Documentation data={details} />
            </TabMaterialLog>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default TripDetails;
