import { apiHttp } from "../axiosApi.js";
import { allDrivers, dadorDriver, driver } from "../config/apiRoute.js";

export const getMyDrivers = (limit = 5, from = 0, id) => {
  return apiHttp(
    "GET",
    `${driver}/my-drivers/${id}?limit=${limit}&from=${from}`
  );
};

export const getAllDrivers = ({
  limit = 5,
  from = 0,
  status,
  cuit,
  name,
  cuitTom,
}) => {
  return apiHttp(
    "GET",
    `${allDrivers}?limit=${limit}&from=${from}${
      status ? `&status=${status}` : ""
    }${name ? `&name=${name}` : ""}${cuit ? `&cuit=${cuit}` : ""}${
      cuitTom ? `&cuitTom=${cuitTom}` : ""
    }`
  );
};

export const getDriver = (id) => {
  return apiHttp("GET", `${driver}/${id}`);
};

export const addDriver = (body) => {
  return apiHttp("POST", `${driver}/`, body);
};

export const editDriver = (id, body) => {
  return apiHttp("PUT", `${driver}/${id}`, body);
};

export const deleteDriver = (id) => {
  return apiHttp("DELETE", `${driver}/delete/${id}`);
};

export const deleteLogicDriver = (id, body) => {
  return apiHttp("DELETE", `${driver}/${id}`, body);
};

export const getDriverByLocation = ({ originLocation, destinyLocation }) => {
  const queryString = originLocation || destinyLocation ? "?" : "";
  const params = [];

  if (originLocation) {
    params.push(`originLocation=${originLocation}`);
  }
  if (destinyLocation) {
    params.push(`destinyLocation=${destinyLocation}`);
  }

  return apiHttp(
    "GET",
    `${dadorDriver}${queryString}${params.join("&")}`
  );
};