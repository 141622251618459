import { apiHttp } from "../axiosApi";
import {
  toPostulate,
  sendPostulation,
  tripPostulated,
  tripPostulated1,
  tripPostulated2,
} from "../config/apiRoute";

export const getToPostulateTripAPI = (limit = 5, from = 0) =>
  apiHttp("GET", `${toPostulate}?limit=${limit}&from=${from}`);

export const getToPostulateTripAPIAll = () => apiHttp("GET", `${toPostulate}`);

export const getToPostulated = (limit = 5, from = 0, state) => {
  return apiHttp("GET", `${tripPostulated}?${state ? `state=${state}` : ""}`);
};

export const getToPostulated1 = (limit = 5, from = 0, state) => {
  return apiHttp(
    "GET",
    `${tripPostulated1}?${
      state ? `state=${state}` : ""
    }&limit=${limit}&from=${from}`
  );
};

export const getToPostulated2 = (limit = 5, from = 0, state) => {
  return apiHttp(
    "GET",
    `${tripPostulated2}?${
      state ? `state=${state}` : ""
    }&limit=${limit}&from=${from}`
  );
};

export const postSendPostulation = (body) =>
  apiHttp("POST", `${sendPostulation}`, body);

export const getToPostulateTripFiltered = (query) =>
  apiHttp("GET", `${toPostulate}${query}`);
