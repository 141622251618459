const LOGIN_AUTH = "LOGIN_AUTH";
const LOGOUT_AUTH = "LOGOUT_AUTH";
const SET_LOADING_SPINNER_ON = "SET_LOADING_SPINNER_ON";
const SET_LOADING_SPINNER_OFF = "SET_LOADING_SPINNER_OFF";
const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
const PUSH_NOTIFICATION = "PUSH_NOTIFICATION";
const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
const SET_COLLAPSE_OFF = "SET_COLLAPSE_OFF";
const SET_COLLAPSE_ON = "SET_COLLAPSE_ON";
const REFRESH_DATA = "REFRESH_DATA";
const UPLOAD_USER_DATA = "UPLOAD_USER_DATA";
const SEND_ADMIN_SUPPORT = "SEND_ADMIN_SUPPORT";
const UPDATE_LIST_ADMIN = "UPDATE_LIST_ADMIN";
const PUSH_LIST_ADMIN = "PUSH_LIST_ADMIN";
const MARK_AS_READ = "MARK_AS_READ";
const PUSH_READMSG = "PUSH_READMSG";
const LOGOUT_CHAT = "LOGOUT_CHAT";
const PUSH_READMSG_CLIENT = "PUSH_READMSG_CLIENT";
const PUSH_MSG_USER = "PUSH_MSG_USER";
const UPDATE_ONLINE = "UPDATE_ONLINE";
const SEND_ROOM_MESSAGE = "SEND_ROOM_MESSAGE";
const GET_ROOM_CHAT = "GET_ROOM_CHAT";
const NEW_MSG_ROOM = "NEW_MSG_ROOM";
const NEW_APP_ROOM = "NEW_APP_ROOM";
const ERASE_ROOM = "ERASE_ROOM";
const ERASE_ROOM_CHAT = "ERASE_ROOM_CHAT";
const MARK_AS_READ_ROOM = "MARK_AS_READ_ROOM";
const MARK_AS_READ_CHAT_APP = "MARK_AS_READ_CHAT_APP";
const MOUNTED_ROOM = "MOUNTED_ROOM";
const MOUNTED_APP_CHAT = "MOUNTED_APP_CHAT";
const SEND_CHAT_APP = "SEND_CHAT_APP";
const GET_ROOM_APP_CHAT = "GET_ROOM_APP_CHAT";

export {
  MARK_AS_READ,
  LOGIN_AUTH,
  LOGOUT_AUTH,
  SET_LOADING_SPINNER_ON,
  SET_LOADING_SPINNER_OFF,
  UPDATE_NOTIFICATION,
  PUSH_NOTIFICATION,
  DELETE_NOTIFICATION,
  REFRESH_DATA,
  UPLOAD_USER_DATA,
  SEND_ADMIN_SUPPORT,
  UPDATE_LIST_ADMIN,
  PUSH_LIST_ADMIN,
  PUSH_READMSG,
  LOGOUT_CHAT,
  PUSH_READMSG_CLIENT,
  PUSH_MSG_USER,
  UPDATE_ONLINE,
  SEND_ROOM_MESSAGE,
  GET_ROOM_CHAT,
  NEW_MSG_ROOM,
  ERASE_ROOM,
  MARK_AS_READ_ROOM,
  MOUNTED_ROOM,
  SET_COLLAPSE_ON,
  SET_COLLAPSE_OFF,
  SEND_CHAT_APP,
  GET_ROOM_APP_CHAT,
  ERASE_ROOM_CHAT,
  MOUNTED_APP_CHAT,
  NEW_APP_ROOM,
  MARK_AS_READ_CHAT_APP,
};
