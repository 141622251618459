import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import { GoTriangleRight, GoUnverified, GoVerified } from "react-icons/go";
import SliderLog from "../../../../components/atoms/SliderLog";
import moment from "moment";
import { priceCalculation } from "../../../../utils/helpers";
import {
  COLOR_GRAY_TEXT,
  COLOR_PRIMARY_DARK,
} from "../../../../constants/Colors";

const CardStyled = styled(Card)`
  border-radius: 15px;
  cursor: pointer;
  padding: 10px 20px;
  &:hover {
    opacity: 0.8;
  }
`;

const DivSegments = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  text-align: left;
  height: 100%;
`;

const Price = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  background-color: #9dc537;
  padding: 2px 10px;
  border-radius: 8px;
  width: 100%;
  color: white;
`;

const CardFinishedTrucks = memo(
  ({
    onClick,
    className,
    state,
    id,
    btnIcon,
    name,
    lastName,
    truckDomain,
    trailerDomain,
    start,
    end,
    paymentDays,
    paymentTerms,
    price_per_ton,
    productName,
    chargeTruck,
    priceUnit,
    realTransportedWeight,
    tomador,
    auditedWeight,
    ...rest
  }) => {
    const startDate = useMemo(
      () => moment(start).format("DD/MM/YYYY HH:mm:ss"),
      [start]
    );
    const endDate = useMemo(
      () => moment(end).format("DD/MM/YYYY HH:mm:ss"),
      [end]
    );
    const paymentDueDate = useMemo(
      () => moment(end).add(paymentDays, "days").format("DD/MM/YYYY"),
      [end, paymentDays]
    );
    const totalPrice = useMemo(
      () => priceCalculation(price_per_ton) + priceUnit,
      [price_per_ton, priceUnit]
    );
    const totalAmount = useMemo(
      () =>
        priceCalculation(
          price_per_ton * (auditedWeight || realTransportedWeight)
        ),
      [price_per_ton, auditedWeight, realTransportedWeight]
    );

    const weightStatus = auditedWeight
      ? "Peso auditado"
      : "Auditoría pendiente";

    return (
      <CardStyled
        className={`${className} shadow-4dp m-2`}
        onClick={onClick}
        {...rest}
      >
        <Card.Body>
          <Row className="flex align-items-start">
            <Col md="4">
              <DivSegments>
                <div className="w-40">
                  <p className="mb-0 pt-0 mt-0 text-left">
                    <strong>Retiro</strong>
                  </p>
                  <p className="mb-0 pt-0 mt-0 text-left">{startDate}</p>
                </div>
                <AiOutlineArrowRight className="text-primary h1 ml-1 mr-1 w-10" />
                <div className="w-40">
                  <p className="mb-0 pt-0 mt-0 text-left">
                    <strong>Entrega</strong>
                  </p>
                  <p className="mb-0 pt-0 mt-0 text-left">{endDate}</p>
                </div>
              </DivSegments>
            </Col>
            <Col md="2">
              <DivSegments>
                <div className="mr-4">
                  <p className="mb-0 pt-0 mt-0 text-left">
                    <strong>Vencimiento pagos:</strong>
                  </p>
                  <p className="mb-0 pt-0 mt-0 text-left">{paymentDueDate}</p>
                </div>
              </DivSegments>
            </Col>
            <Col md="3">
              <DivSegments>
                <div className="mr-4 w-50">
                  <p className="mb-0 pt-0 mt-0 text-left">
                    <strong>Chofer</strong>
                  </p>
                  <p className="mb-0 pt-0 mt-0 text-left text-capitalize">
                    {name}
                  </p>
                  <p className="mb-0 pt-0 mt-0 text-left text-capitalize">
                    {lastName}
                  </p>
                </div>
                <div className="ml-4 w-50">
                  <p className="mb-0 pt-0 mt-0 text-left">
                    <strong>Equipo</strong>
                  </p>
                  <p className="mb-0 pt-0 mt-0 text-left">
                    {truckDomain || "N/D"}
                  </p>
                  <p className="mb-0 pt-0 mt-0 text-left">
                    {trailerDomain || "N/D"}
                  </p>
                </div>
              </DivSegments>
            </Col>
            <Col md="2">
              <DivSegments>
                <div className="w-100">
                  <p className="mb-0 pt-0 mt-0 text-center text-capitalize">
                    <strong>{totalPrice}</strong>
                  </p>
                  <Price>{tomador ? "A cobrar" : "A pagar"}</Price>
                  {realTransportedWeight || auditedWeight ? (
                    <OverlayTrigger
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={(props) => (
                        <Tooltip id="button-tooltip" {...props}>
                          {weightStatus}
                        </Tooltip>
                      )}
                    >
                      <Row className="justify-content-center">
                        <p className="mb-0 pt-1 mt-0 text-center text-capitalize">
                          <strong>{totalAmount}</strong>
                        </p>
                        {auditedWeight ? (
                          <GoVerified
                            size={20}
                            color={COLOR_PRIMARY_DARK}
                            style={{
                              marginTop: "auto",
                              marginBottom: "auto",
                              marginLeft: 4,
                            }}
                          />
                        ) : (
                          <GoUnverified
                            size={20}
                            color={COLOR_GRAY_TEXT}
                            style={{
                              marginTop: "auto",
                              marginBottom: "auto",
                              marginLeft: 4,
                            }}
                          />
                        )}
                      </Row>
                    </OverlayTrigger>
                  ) : (
                    <p className="mb-0 pt-1 mt-0 text-center">
                      <strong>P.R.T. N/D</strong>
                    </p>
                  )}
                </div>
              </DivSegments>
            </Col>
            <Col md="1">
              <p></p>
              <GoTriangleRight />
            </Col>
          </Row>
        </Card.Body>
      </CardStyled>
    );
  }
);

export default CardFinishedTrucks;
