import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { putUserProfileAPI } from "../../../api/modules";

// react-bootstrap components
import { Card, Form, Container, Row, Col, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { errorToast, successToast } from "../../../services/toastService";
import LogInput from "../../../components/atoms/LogInput";
import LogInputPhone from "../../../components/atoms/LogInputPhone";
import LogButton from "../../../components/atoms/LogButton";
import ModalInfo from "../../../components/organisms/modal-info/ModalInfo";
import ContentHeader from "../components/molecules/ContentHeader";
import AvatarLog from "../../../styledComponents/AvatarLog";
import DefaulUserPhoto from "../../../assets/img/default-avatar.png";
import { uploadUserData } from "../../../redux/actions/auth";
import { AiOutlineCloseCircle } from "react-icons/ai";

import LogDateInput from "../../../components/atoms/LogDateInput";
import { COLOR_PRIMARY } from "../../../constants/Colors";
import styled from "styled-components";
import DropZone from "../../../components/organisms/dropzone/DropZone";
import LogDatePicker from "../../../components/atoms/LogDatePicker";
import {
  setLoadingSpinerOff,
  setLoadingSpinerOn,
} from "../../../redux/actions/app";
import { taxStatus, uploadImagesToCloudinary } from "../../../utils/helpers";
import {
  ADMIN,
  DADOR_DE_CARGA,
  TOMADOR_DE_CARGA,
} from "../../../constants/UserType";
import LogSelectLocation from "../../../components/atoms/LogSelectLocation";
import LogSelect from "../../../components/atoms/LogSelect";

const Divider = styled.hr`
  background-color: ${COLOR_PRIMARY};
  height: 1px;
  width: 90%;
  opacity: 0.3;
  margin: "auto";
`;
const today = new Date();
const ProfileSchema = (role) =>
  Yup.object().shape({
    name: Yup.string().required("Nombre obligatorio"),
    last_name: Yup.string().required("Apellido obligatorio"),
    email: Yup.string().email("Correo inválido").required("Correo obligatorio"),
    cuit: Yup.string()
      .matches(
        /\b(20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]/,
        "CUIT inválido"
      )
      .required("CUIT obligatorio"),
    date_of_birth: Yup.date()
      .nullable()
      .max(
        new Date(
          today.getFullYear() - 16,
          today.getMonth(),
          today.getDate() + 1
        ),
        "Debe ser mayor de 16 años"
      ),
    license_one: Yup.mixed().nullable(),
    license_two: Yup.mixed().nullable(),
    license_date: Yup.date().nullable().min(new Date(), "Licencia vencida"),
    lnh_date: Yup.date().nullable().min(new Date(), "LINTI vencida"),
    reason: Yup.string()
      .min(2, "Muy corto")
      .max(50, "Muy largo")
      .required("Razón social obligatorio"),
    phone: Yup.string()
      .matches(/^\+\d{12}$/, "Ej. +543811234456")
      .required("Ingrese teléfono válido"),
    cbu_number: Yup.string().matches(
      /^\d{22}$/,
      "CBU debe tener 22 numeros sin espacios"
    ),
    ...(role !== ADMIN && {
      tax_status: Yup.string().required("Condición impositiva obligatorio"),
    }),
  });

function User() {
  const id = useSelector((state) => state.authApp.user._id);
  const [isEditing, setIsEditing] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [resetDropZone, setResetDropZone] = useState(false);

  const [isErrorModal, setIsErrorModal] = useState(false);
  const refInputPhoto = useRef();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.authApp.user);

  const handleSubmitAPI = async (values) => {
    setIsEditing(false);
    try {
      dispatch(setLoadingSpinerOn());
      const {
        email,
        cuit,
        image,
        license_one,
        license_two,
        linti_one,
        linti_two,
        ...rest
      } = values;
      const imageFields = [
        image,
        license_one,
        license_two,
        linti_one,
        linti_two,
      ];
      const uploadFields = [
        "image",
        "license_one",
        "license_two",
        "linti_one",
        "linti_two",
      ];

      await Promise.all(
        imageFields.map(async (field, index) => {
          if (field && field !== profile[uploadFields[index]]) {
            try {
              const newUpload = await uploadImagesToCloudinary(field.file);
              rest[uploadFields[index]] = newUpload[0];
            } catch (error) {
              console.log("Error imagen", error);
              errorToast("Intente con otra imagen");
              return;
            }
          } else {
            rest[uploadFields[index]] = field;
          }
        })
      );
      const { msg } = await putUserProfileAPI(id, rest);
      if (msg) {
        errorToast(msg || "Intente nuevamente");
        setIsEditing(false);
      } else {
        dispatch(uploadUserData(rest));
        successToast("Perfil editado exitosamente");
        setIsEditing(false);
      }
    } catch (error) {
      console.error("Error UserProfile", error);
      errorToast("Intente nuevamente");
    } finally {
      dispatch(setLoadingSpinerOff());
    }
  };

  const CustomToggle = React.forwardRef(
    ({ values, setFieldValue, ...props }, ref) => {
      const imageUrl =
        values.image && typeof values.image !== "string"
          ? URL.createObjectURL(values.image?.file)
          : values?.image || DefaulUserPhoto;

      useEffect(() => {
        return () => {
          if (values.image) {
            URL.revokeObjectURL(values.image);
          }
        };
      }, [values.image]);

      return (
        <AvatarLog
          id="dropdown-basic"
          ref={ref}
          src={imageUrl}
          roundedCircle
          className="shadow-4dp"
          onClick={(e) => {
            e.preventDefault();
            props.onClick(e);
          }}
        />
      );
    }
  );

  const onFileChange = (e, setFieldValue) => {
    let file = e.target.files[0];
    if (file) {
      setFieldValue("image", { file: file, url: file });
    }
  };
  const TaxStatusField = ({
    values,
    handleBlur,
    setFieldValue,
    errors,
    taxStatus,
  }) => (
    <Row>
      <Col md="4" className="pr-md-2">
        <Form.Group>
          <label>Condición impositiva</label>
          <LogSelect
            placeholder="Condición impositiva"
            cleanable={false}
            name="tax_status"
            searchable={false}
            items={taxStatus}
            onChange={(value) => {
              console.log({ value });
              setFieldValue("tax_status", value);
            }}
            readOnly={!isEditing}
            onBlur={handleBlur.tax_status}
            errors={errors}
            value={values.tax_status}
          />
        </Form.Group>
      </Col>
    </Row>
  );

  return (
    <>
      <Container fluid>
        <Formik
          enableReinitialize
          initialValues={{
            role: profile.role,
            name: profile?.name || "",
            last_name: profile?.last_name || "",
            email: profile?.email || "",
            reason: profile?.reason || "",
            phone: profile?.phone || "",
            cuit: profile?.cuit || "",
            image: profile?.image,
            is_danger: profile?.is_danger,
            license_date: profile?.license_date,
            license_one: profile?.license_one,
            license_two: profile?.license_two,
            linti_one: profile?.linti_one,
            linti_two: profile?.linti_two,
            lnh_date: profile?.lnh_date,
            date_of_birth: profile?.date_of_birth || null,
            cbu_number: profile?.cbu_number,
            bank_name: profile?.bank_name,
            tax_status: profile?.tax_status,
          }}
          validationSchema={ProfileSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmitAPI(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            dirty,
            resetForm,
          }) => (
            <Row>
              <ContentHeader className={`ml-3`} title={"Mi perfil"} />
              <Col md="12">
                <Card className="shadow-3dp">
                  <Card.Body>
                    <Form>
                      <Row>
                        <Col className="text-center">
                          <input
                            type="file"
                            className="d-none"
                            ref={refInputPhoto}
                            onChange={(e) => onFileChange(e, setFieldValue)}
                            accept="image/gif, image/jpeg, image/png"
                          />
                          <Dropdown>
                            <Dropdown.Toggle
                              as={(dropdownProps) => (
                                <CustomToggle
                                  {...dropdownProps}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                />
                              )}
                              id="dropdown-custom-components"
                            >
                              Custom toggle
                            </Dropdown.Toggle>

                            {isEditing && (
                              <Dropdown.Menu
                                style={{
                                  textAlign: "center",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                }}
                              >
                                <Dropdown.Item
                                  eventKey="1"
                                  onClick={() => {
                                    refInputPhoto.current.click();
                                  }}
                                >
                                  {values?.image ? "Editar" : "Subir"} foto
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            )}
                          </Dropdown>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <Form.Group>
                            <label>Nombre</label>
                            <LogInput
                              placeholder="Nombre"
                              type="text"
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              errors={errors}
                              value={values.name}
                              disabled={!isEditing}
                            />
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group>
                            <label>Apellido</label>
                            <LogInput
                              placeholder="Nombre"
                              type="text"
                              name="last_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              errors={errors}
                              value={values.last_name}
                              disabled={!isEditing}
                            />
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group>
                            <label>Correo electrónico</label>
                            <LogInput
                              placeholder="Correo electrónico"
                              type="email"
                              name="email"
                              disabled
                              onChange={handleChange}
                              onBlur={handleBlur}
                              errors={errors}
                              value={values.email}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4">
                          <Form.Group>
                            <label>Razón social</label>
                            <LogInput
                              placeholder="Razón social"
                              type="text"
                              name="reason"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              errors={errors}
                              value={values.reason}
                              disabled={!isEditing}
                            />
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group>
                            <label>Número de teléfono</label>
                            <LogInputPhone
                              placeholder="Teléfono"
                              className="mb-2 number-font"
                              name="phone"
                              onChange={(phone) => {
                                if (phone && !phone.startsWith("+")) {
                                  phone = "+" + phone;
                                }
                                setFieldValue("phone", phone);
                              }}
                              onBlur={handleBlur}
                              errors={errors}
                              value={values.phone}
                              disabled={!isEditing}
                            />
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group>
                            <label>CUIT</label>
                            <LogInput
                              className="number-font"
                              placeholder="Razón social"
                              type="text"
                              name="cuit"
                              disabled
                              onChange={handleChange}
                              onBlur={handleBlur}
                              errors={errors}
                              value={values.cuit}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {profile?.role_id?.name === TOMADOR_DE_CARGA ? (
                        <>
                          <Row>
                            <Col md="4">
                              <Form.Group>
                                <label>CBU</label>
                                <LogInput
                                  placeholder="C.B.U."
                                  type="text"
                                  name="cbu_number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  errors={errors}
                                  value={values.cbu_number}
                                  disabled={!isEditing}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="4">
                              <Form.Group>
                                <label>Banco</label>
                                <LogInput
                                  className="number-font"
                                  placeholder="Banco"
                                  type="text"
                                  name="bank_name"
                                  disabled={!isEditing}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  errors={errors}
                                  value={values.bank_name}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="4">
                              <Form.Group>
                                <label>Fecha nacimiento</label>
                                <LogDateInput
                                  readOnly={!isEditing}
                                  placeholder="Ingresá la fecha de nacimiento"
                                  name="date_of_birth"
                                  onChange={(e) =>
                                    setFieldValue(
                                      "date_of_birth",
                                      e ? new Date(e).toISOString() : null
                                    )
                                  }
                                  onBlur={handleBlur.date_of_birth}
                                  errors={errors}
                                  value={
                                    values?.date_of_birth
                                      ? new Date(values?.date_of_birth)
                                      : null
                                  }
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          <TaxStatusField
                            values={values}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            taxStatus={taxStatus}
                          />
                          <Divider />
                          <Row>
                            <Col md="6">
                              <label className="back-primary font-weight-bold">
                                Licencia frente
                              </label>
                              <DropZone
                                reset={resetDropZone}
                                readOnly={!isEditing}
                                image={values?.license_one}
                                uploadFile={(v) => {
                                  setFieldValue("license_one", {
                                    file: v,
                                    url: v,
                                  });
                                }}
                                errors={errors?.license_one}
                                removeFile={() => {
                                  setFieldValue("license_one", "");
                                }}
                                placeholder="Subir frente de licencia"
                              />
                            </Col>
                            <Col md="6" className="mt-2 mt-md-0">
                              <label className="back-primary font-weight-bold">
                                Licencia dorso
                              </label>
                              <DropZone
                                reset={resetDropZone}
                                readOnly={!isEditing}
                                image={values?.license_two}
                                uploadFile={(v) => {
                                  setFieldValue("license_two", {
                                    file: v,
                                    url: v,
                                  });
                                }}
                                errors={errors?.license_two}
                                removeFile={() => {
                                  setFieldValue("license_two", "");
                                }}
                                placeholder="Subir dorso de licencia"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <LogDatePicker
                                readOnly={!isEditing}
                                cleanable={true}
                                name="license_date"
                                placeholder="Vencimiento Licencia"
                                className="number-font mt-2"
                                onBlur={handleBlur}
                                errors={errors}
                                value={
                                  values.license_date
                                    ? new Date(values.license_date)
                                    : null
                                }
                                onChange={(value) => {
                                  setFieldValue("license_date", value);
                                }}
                              />
                            </Col>
                          </Row>
                          <Divider />
                          <Row>
                            <Col md="6">
                              <label className="back-primary font-weight-bold">
                                LNH frente
                              </label>
                              <DropZone
                                readOnly={!isEditing}
                                reset={resetDropZone}
                                image={values?.linti_one}
                                uploadFile={(v) => {
                                  setFieldValue("linti_one", {
                                    file: v,
                                    url: v,
                                  });
                                }}
                                errors={errors?.linti_one}
                                removeFile={() => {
                                  setFieldValue("linti_one", "");
                                }}
                                placeholder="Subir frente de LNH"
                              />
                            </Col>
                            <Col md="6" className="mt-2 mt-md-0">
                              <label className="back-primary font-weight-bold">
                                LNH dorso
                              </label>
                              <DropZone
                                reset={resetDropZone}
                                readOnly={!isEditing}
                                image={values?.linti_two}
                                uploadFile={(v) => {
                                  setFieldValue("linti_two", {
                                    file: v,
                                    url: v,
                                  });
                                }}
                                errors={errors?.linti_two}
                                removeFile={() => {
                                  setFieldValue("linti_two", "");
                                }}
                                placeholder="Subir dorso de LNH"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={6}>
                              <LogDatePicker
                                readOnly={!isEditing}
                                cleanable={true}
                                name="lnh_date"
                                placeholder="Vencimiento Linti"
                                className="number-font mt-2"
                                onBlur={handleBlur}
                                errors={errors}
                                value={
                                  values.lnh_date
                                    ? new Date(values.lnh_date)
                                    : null
                                }
                                onChange={(value) => {
                                  setFieldValue("lnh_date", value);
                                }}
                              />
                            </Col>
                          </Row>
                        </>
                      ) : null}
                      {profile?.role_id?.name === DADOR_DE_CARGA ? (
                        <TaxStatusField
                          values={values}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          errors={errors}
                          taxStatus={taxStatus}
                        />
                      ) : null}
                      <Row>
                        {isEditing ? (
                          <>
                            <Col md={6} className="text-center text-md-left">
                              <LogButton
                                color="secondary"
                                className="mt-3 w-100"
                                size="md"
                                style={{ maxWidth: "200px" }}
                                title="Cancelar"
                                border="curve"
                                block
                                onClick={() => {
                                  resetForm();
                                  setIsEditing(false);
                                  setResetDropZone(true);
                                }}
                              />
                            </Col>
                            <Col md={6} className="text-center text-md-right">
                              <LogButton
                                className="mt-3 w-100"
                                size="md"
                                style={{ maxWidth: "200px" }}
                                title="Guardar Cambios"
                                border="curve"
                                block
                                disabled={isSubmitting}
                                onClick={() => {
                                  if (!dirty) {
                                    errorToast(
                                      "Primero introduzca cambios en el formulario"
                                    );
                                  } else {
                                    handleSubmit();
                                    setResetDropZone(false);
                                  }
                                }}
                              />
                            </Col>
                          </>
                        ) : (
                          <Col md={6} className="text-center text-md-left">
                            <LogButton
                              className="mt-3 w-100"
                              size="md"
                              style={{ maxWidth: "200px" }}
                              title="Editar perfil"
                              border="curve"
                              block
                              onClick={() => setIsEditing(true)}
                            />
                          </Col>
                        )}
                      </Row>

                      <div className="clearfix"></div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Formik>
        <ModalInfo
          title={`Error al cargar imagen`}
          subTitle="Podés cargar por ahora imagenes con extensión *.jpg"
          isShow={isErrorModal}
          colorText="text-danger"
          icon={<AiOutlineCloseCircle className="display-3 text-danger mb-3" />}
          handleClose={() => setIsErrorModal(false)}
        />
      </Container>
    </>
  );
}

export default User;
