import React from "react";
import "../../styles/components/atoms/LogSelect.scss";
import PropTypes from "prop-types";
import { SelectPicker } from "rsuite";
import styled from "styled-components";

const SelectPickerStyled = styled(SelectPicker)`
  background-color: white;
  border: none;
  padding: 1px 1px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px #cccccc;
  height: 40px;
  display: flex;
  align-items: center;
`;

const LogSelect = ({
  items,
  className,
  errors,
  name,
  searchable = false,
  footer = "",
  cleanable,
  ...rest
}) => {
  return (
    <>
      <SelectPickerStyled
        className={className + " mt-1"}
        cleanable={cleanable}
        name={name}
        {...rest}
        data={items}
        searchable={searchable}
        block
        renderExtraFooter={() => footer}
      />
      {errors[name] && (
        <div className="mb-2 mt-0">
          <small className="text-danger">{errors[name]}.</small>
        </div>
      )}
    </>
  );
};

LogSelect.propTypes = {
  items: PropTypes.array.isRequired,
};

export default LogSelect;
