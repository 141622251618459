import React from "react";
import { Navbar } from "react-bootstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import LogButton from "../../atoms/LogButton";

import { COLOR_TEXT_WHITE, COLOR_PRIMARY } from "../../../constants/Colors";
import LogLogo from "../../atoms/LogLogo";

const NavbarStyled = styled(Navbar)`
  background: none !important;
  border-bottom: none !important;
`;

const DivButtonNav = styled.div`
  margin: auto;
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
`;

const DivAuthButtonNav = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

const NavButtonStyled = styled(LogButton)`
  color: ${COLOR_TEXT_WHITE};
  padding: 8px 35px;
  background: none;
  border: none;
  white-space: nowrap;
  &:hover {
    color: #ffffff;
    background: none;
    border: none;
  }
`;

const NavButtonSignInStyled = styled(LogButton)`
  color: ${COLOR_PRIMARY};
  padding: 8px 35px;
  background: "none";
  border: none;
  white-space: nowrap;
  &:hover {
    color: #ffffff;
    background: none;
    border: none;
  }
`;

const NavBarRoot = () => {
  const history = useHistory();
  return (
    <NavbarStyled expand="lg">
      <Navbar.Brand href={() => { }}><LogLogo className="ml-3 mt-0" /></Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarScroll" className="navbar-dark" color="light" />
      <Navbar.Collapse id="navbarScroll" className="navbar-dark" color="light">
        <DivButtonNav className="p-2" navbarScroll>
          <NavButtonStyled title="Inicio" color="light" border="curve" />
          <NavButtonStyled
            title="Transportistas"
            color="light"
            border="curve"
          />
          <NavButtonStyled
            title="Dadores de cargo"
            color="light"
            border="curve"
          />
          <NavButtonStyled title="Contacto" color="light" border="curve" />
        </DivButtonNav>
        <DivAuthButtonNav className="p-2" navbarScroll>
          <NavButtonSignInStyled
            title="Ingresá"
            color="light"
            border="curve"
            onClick={() => history.push("/sign-in")}
          />
          <LogButton
            title="Registrate"
            color="primary"
            border="curve"
            onClick={() => history.push("/which-user")}
          />
        </DivAuthButtonNav>
      </Navbar.Collapse>
    </NavbarStyled>
  );
};

export default NavBarRoot;
