import React from "react";
import { Badge, Button, Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LogButton from "../../../../../components/atoms/LogButton";
import styled from "styled-components";
import { COLOR_PRIMARY } from "../../../../../constants/Colors";

const BadgeLog1 = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 50px !important;
  text-align: center;
  /* display: inline-block; */
  padding: 5px 10px;
  /*  width: 20px; */
`;

const BadgeLog = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${COLOR_PRIMARY};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
`;

function NewDador({ searchTrip = false, handleClose = () => {} }) {
  const history = useHistory();

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center mt-5 h-100 ">
      <Row className="text-center mb-4">
        <Col>
          {searchTrip ? (
            <h1 className="text-primary h3 font-weight-bold">
              Para postularte primero debes completar los siguiente pasos
            </h1>
          ) : (
            <h1 className="text-primary h3 font-weight-bold">
              Vamos a crear un nuevo viaje!!!
            </h1>
          )}
        </Col>
      </Row>

      <Container className="mb-5" style={{ maxWidth: "500px" }}>
        <Row className="justify-content-center">
          <LogButton
            variant="primary"
            onClick={() => {
              history.push("/app/travels");
            }}
            title={"Crear Viaje"}
            border="curve"
            style={{ width: "120px" }}
          />
        </Row>
      </Container>
    </Container>
  );
}

export default NewDador;
