import React from "react";
import { Box } from "@material-ui/core";

const TabMaterialLog = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={{ xs: 0, sm: 0, md: 0, xl: 3 }}>{children}</Box>}
    </div>
  );
};

TabMaterialLog.propTypes = {};

export default TabMaterialLog;
