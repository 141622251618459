import React, { useEffect, useRef, useState } from "react";
import { Badge, Col, Container, Image, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import { COLOR_GRAY_TEXT, COLOR_PRIMARY } from "../../../constants/Colors";
import ChatWindow from "../chatWindow/ChatWindow";
import { FaAccessibleIcon } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { markAsRead, sendAdminSupport } from "../../../redux/actions/auth";
import { ADMIN } from "../../../constants/UserType";
import DefaultAvatar from "../../../assets/img/default-avatar.png";
import { IoMdSearch } from "react-icons/io";
import { MdOutlineClear } from "react-icons/md";
import { errorToast } from "../../../services/toastService";

const ModalLogStyled = styled(Modal)`
  opacity: 1;

  .modal-content {
    border-radius: 15px !important;
    padding: 20px;
    height: 90vh;
    max-height: 90vh;
  }
  .modal-body {
    overflow: hidden;
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;
const Divider = styled.hr`
  background-color: ${COLOR_PRIMARY};
  height: 1px;
  margin: 0px;
  width: 90%;
  opacity: 0.3;
`;

const BadgeLog = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  /* display: inline-block; */
  padding: 0px 10px;
`;

const ColStyled = styled(Col)`
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #d62929;
  }
`;

function ModalMsgAdmin({ show, onClose }) {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.authApp);
  const { listUsers } = useSelector((state) => state.chatReducer);
  const { privateMsg } = useSelector((state) => state.chatReducer);
  const { onLineClients } = useSelector((state) => state.chatReducer);
  const [selectUser, setSelectUser] = useState("");
  const [search, setSearch] = useState("");
  const uploadData = async (image) => {
    try {
      let data = new FormData();
      data.append("file", image);
      data.append("upload_preset", "ceqdn88c");
      const res = await fetch(
        "https://api.cloudinary.com/v1_1/dcbqcvers/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const file = await res.json();
      return file.secure_url;
    } catch (error) {
      console.error("Error Cloudinary", error);
      errorToast("Por favor intente nuevamente");
    }
  };

  useEffect(() => {
    if (
      selectUser &&
      listUsers.filter((ele) => ele._id === selectUser)[0]?.unReadMsg.length >
        0 &&
      show
    ) {
      dispatch(markAsRead({ attended_user: selectUser, token }));
    }
  }, [selectUser, listUsers, show]);

  useEffect(() => {
    if (privateMsg.unReadMsg?.length > 0 && show) {
      dispatch(markAsRead({ attended_user: user, token }));
    }
  }, [privateMsg, show]);

  useEffect(() => {
    if (!selectUser) {
      setSelectUser(listUsers[0]?._id);
    }
  }, []);

  return (
    <ModalLogStyled
      className="modal-exit"
      size={user.role_id.name === ADMIN ? "xl" : "md"}
      show={show}
      animation={false}
      centered
      onHide={() => onClose(false)}
    >
      <Modal.Body>
        <Row
          className="w-100 justify-content-center /* m-0 */"
          style={{ position: "absolute", top: 0, bottom: 0, left: 0 }}
        >
          {user.role_id.name === ADMIN ? (
            <Col key={3} className="h-100 pr-0 mr-0">
              <input
                key={1}
                style={{ outline: "none", position: "relative" }}
                className="form-control-sm mb-2 mx-auto w-100 bg-light border-0 py-3 pl-5"
                type="text"
                placeholder="Buscá un usuario"
                onChange={(e) => {
                  e.preventDefault();
                  setSearch(e.target.value);
                }}
                value={search}
              />
              <IoMdSearch
                size={22}
                key={2}
                style={{ top: 0, left: 0, position: "absolute" }}
                className="my-1 ml-4 text-primary"
              />
              {search ? (
                <MdOutlineClear
                  onClick={() => setSearch("")}
                  key={3}
                  style={{ top: 0, right: 0, position: "absolute" }}
                  className="my-2 mr-2 text-primary cursor-pointer"
                />
              ) : null}
              <ColStyled key={4} md={12} className="h-100 ">
                {listUsers
                  ?.filter(
                    ({ attended_user }) =>
                      attended_user.name
                        ?.toLowerCase()
                        .includes(search.toLocaleLowerCase()) ||
                      attended_user.last_name
                        ?.toLowerCase()
                        .includes(search.toLocaleLowerCase()) ||
                      attended_user.email
                        ?.toLowerCase()
                        .includes(search.toLocaleLowerCase())
                  )
                  ?.map(({ attended_user, unReadMsg }, index) => (
                    <React.Fragment key={index}>
                      <Row
                        className={`p-2  + ${
                          selectUser === attended_user._id
                            ? "bg-light shadow-4dp"
                            : ""
                        }`}
                        onClick={() => setSelectUser(attended_user._id)}
                      >
                        <Col
                          md={3}
                          style={
                            onLineClients.some(
                              (ele) => ele._id === attended_user._id
                            )
                              ? null
                              : { opacity: 0.5 }
                          }
                        >
                          <Image
                            roundedCircle
                            src={attended_user.image || DefaultAvatar}
                            alt="avatar 1"
                            className="w-100 mh-100"
                            //style={{ width: "45px", maxHeight: "100%" }}
                          />
                        </Col>
                        <Col
                          md={7}
                          style={
                            onLineClients.some(
                              (ele) => ele._id === attended_user._id
                            )
                              ? null
                              : { opacity: 0.5 }
                          }
                        >
                          <Row>
                            <small className="text-capitalize">
                              {attended_user.name +
                                " " +
                                attended_user.last_name}
                            </small>
                          </Row>
                          <Row>
                            <small className="">
                              {attended_user.email?.toLowerCase()}
                            </small>
                          </Row>

                          {/* <Row>
                            <small>estado</small>
                          </Row> */}
                        </Col>
                        <Col md={2} className={"mx-auto"}>
                          {unReadMsg?.length > 0 ? (
                            <BadgeLog as="h6">{unReadMsg?.length}</BadgeLog>
                          ) : null}
                        </Col>
                      </Row>
                      <Divider />
                    </React.Fragment>
                  ))}
              </ColStyled>
            </Col>
          ) : null}

          <Col
            key={2}
            md={user.role_id.name === ADMIN ? 8 : 12}
            className="h-100"
          >
            <ChatWindow
              title={"Soporte BuenaRuta"}
              data={
                user.role_id.name === ADMIN
                  ? selectUser
                    ? [
                        ...listUsers.filter((ele) => ele._id === selectUser)[0]
                          ?.readMsg,
                        ...listUsers.filter((ele) => ele._id === selectUser)[0]
                          ?.unReadMsg,
                      ]
                    : []
                  : privateMsg?.readMsg
                  ? [...privateMsg?.readMsg, ...privateMsg?.unReadMsg]
                  : []
              }
              roleSent={user.role_id.name}
              className={"h-100"}
              size={"100%"}
              handleSubmitAPI={async ({
                values,
                resetForm,
                setImage,
                image,
                id_receptor,
              }) => {
                try {
                  let document = "";
                  if (values?.document) {
                    document = await uploadData(image);
                  }

                  user.role_id.name === ADMIN
                    ? dispatch(
                        sendAdminSupport({
                          token,
                          msg: values.comment,
                          document: document,
                          to: selectUser,
                        })
                      )
                    : dispatch(
                        sendAdminSupport({
                          token,
                          document: document,
                          msg: values.comment,
                        })
                      );
                  resetForm();
                  setImage({});
                } catch (error) {
                  resetForm();
                  setImage({});
                }
              }}
            ></ChatWindow>{" "}
          </Col>
        </Row>
      </Modal.Body>
    </ModalLogStyled>
  );
}

export default ModalMsgAdmin;
