import React from "react";
import styled from "styled-components";
import NavBarRoot from "../../components/molecules/NavBarRoot/NavBarRoot";
import HeaderImg from "../../assets/img/HeaderImg.png";

const DivBackground = styled.div`
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat !important;
  background-size: cover !important;
`;

const Home = (props) => {
  return (
    <DivBackground style={{background: `url(${HeaderImg})`}}>
      <header>
        <NavBarRoot />
      </header>
    </DivBackground>
  );
};

export default Home;
