/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

// react-bootstrap components
import { Card, Form, Container, Row, Col } from "react-bootstrap";
import LogInput from "../../../../../components/atoms/LogInput";
import LogInputPhone from "../../../../../components/atoms/LogInputPhone";
import LogButton from "../../../../../components/atoms/LogButton";
import AvatarLog from "../../../../../styledComponents/AvatarLog";
import {
  getUserProfileAPI,
  putDadorToAdmin,
  putUserProfileAPI,
} from "../../../../../api/modules";
import { errorToast, successToast } from "../../../../../services/toastService";
import { useHistory, useParams } from "react-router";
import styled from "styled-components";
import ReactStars from "react-stars";
import UserTabs from "./UserTabs";
import { GoTriangleDown, GoTriangleRight } from "react-icons/go";
import queryString from "query-string";
import { useSelector } from "react-redux";
import {
  DADOR_DE_CARGA,
  TOMADOR_DE_CARGA,
} from "../../../../../constants/UserType";
import ModalQuestion from "../../../../../components/organisms/modal-question/ModalQuestion";
import LogDateInput from "../../../../../components/atoms/LogDateInput";
import DropZone from "../../../../../components/organisms/dropzone/DropZone";
import LogDatePicker from "../../../../../components/atoms/LogDatePicker";
import { COLOR_PRIMARY } from "../../../../../constants/Colors";
import LogSelect from "../../../../../components/atoms/LogSelect";
import { taxStatus } from "../../../../../utils/helpers";

const ProfileSchema = Yup.object().shape({
  name: Yup.string().required("Nombre obligatorio"),
  last_name: Yup.string().required("Apellido obligatorio"),
  email: Yup.string().email("Correo inválido").required("Correo obligatorio"),
  cuit: Yup.string()
    .matches(/\b(20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]/, "CUIT inválido")
    .required("CUIT obligatorio"),
  reason: Yup.string()
    .min(2, "Muy corto")
    .max(50, "Muy largo")
    .required("Razón social obligatorio"),
  phone: Yup.string()
    .matches(/^\+\d{12}$/, "Ej. +543811234456")
    .required("Teléfono obligatorio"),
  discount_factor: Yup.number()
    .min(0, "Numero mayor a cero")
    .max(99, "Valor Maximo 99")
    .required("Factor de descuento obligatorio"),
});

const AvatarLogAdmin = styled(AvatarLog)`
  cursor: initial;
  &:hover {
    opacity: 1;
  }
`;

const Divider = styled.hr`
  background-color: ${COLOR_PRIMARY};
  height: 1px;
  width: 90%;
  opacity: 0.3;
  margin: "auto";
`;

function UserDetails() {
  const history = useHistory();
  const params = useParams();
  const [profile, setProfile] = useState({});
  const [isDisable, SetIsDisable] = useState(true);
  const [viewTrips, setViewTrips] = useState(false);
  const [resetDropZone, setResetDropZone] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const { id } = params;
  const spinner = useSelector((state) => state.appReducer.isLoading);

  useEffect(() => {
    getFetchUserProfile();
    if (queryString.parse(window.location.search)?.detail) setViewTrips(true);
  }, []);

  const getFetchUserProfile = async () => {
    const { msg, user } = await getUserProfileAPI(id);

    if (msg) {
      errorToast(msg);
    } else {
      user?.status === "1" ? SetIsDisable(false) : SetIsDisable(true);
      setProfile(user || {});
    }
  };

  const handleClose = (ok) => {
    if (ok) {
      dadorToAdmin(profile?._id);
    } else {
      setShowQuestion(false);
    }
  };

  const handleSubmitAPI = async (values) => {
    const body = {
      discount_factor: values.discount_factor,
    };
    const resp = await putUserProfileAPI(profile._id, body);
    const { msg } = resp;

    if (msg) {
      errorToast(msg || "Algo malo ha pasado...");
    } else {
      successToast(`Usuario editado con éxito`);
      getFetchUserProfile();
    }
  };

  const dadorToAdmin = async (id) => {
    const body = { user_id: id };
    const { msg } = await putDadorToAdmin(body);
    if (msg) {
      errorToast(msg || "Algo malo ha pasado...");
    } else {
      successToast(`Usuario convertido a Admin`);
      history.push("/app/users");
    }
  };
  const handleAndroidId = async () => {
    const body = {
      android_id: null,
      push_token: null,
    };
    const resp = await putUserProfileAPI(profile._id, body);
    const { msg } = resp;

    if (msg) {
      errorToast(msg || "Algo malo ha pasado...");
    } else {
      successToast(`Android blanquedo con éxito`);
      getFetchUserProfile();
    }
  };

  const handleStateUser = async () => {
    const body = {
      status: isDisable ? "1" : "3",
    };
    const resp = await putUserProfileAPI(profile._id, body);
    const { msg } = resp;

    if (msg) {
      errorToast(msg || "Algo malo ha pasado...");
    } else {
      successToast(
        `Usuario ${isDisable ? "Habilitado" : "Deshabilitado"} con éxito`
      );
      getFetchUserProfile();
    }
  };
  const TaxStatusField = ({
    values,
    handleBlur,
    setFieldValue,
    errors,
    taxStatus,
  }) => (
    <Row>
      <Col md="4" className="pr-md-2">
        <Form.Group>
          <label>Condición impositiva</label>
          <LogSelect
            placeholder="Condición impositiva"
            cleanable={false}
            name="tax_status"
            searchable={false}
            items={taxStatus}
            onChange={(value) => {
              console.log({ value });
              setFieldValue("tax_status", value);
            }}
            readOnly={true}
            onBlur={handleBlur.tax_status}
            errors={errors}
            value={values.tax_status}
          />
        </Form.Group>
      </Col>
    </Row>
  );

  return (
    <>
      <Container fluid>
        <Col md="12">
          <Row>
            <ReactStars
              value={profile.score}
              isHalf={true}
              count={5}
              size={42}
              color2="#ffd700"
              color1="#CDE0EB"
              edit={false}
            />
            <h1 className="h4  text-primary font-weight-bold ml-3 mb-2 pt-1 mt-3">
              {profile?.role_id?.name}
            </h1>
          </Row>
        </Col>
        <Formik
          enableReinitialize
          initialValues={{
            role: profile.role,
            name: profile?.name || "",
            last_name: profile?.last_name || "",
            email: profile?.email || "",
            reason: profile?.reason || "",
            phone: profile?.phone || "",
            cuit: profile?.cuit || "",
            image: profile?.image,
            is_danger: profile?.is_danger,
            license_date: profile?.license_date,
            license_one: profile?.license_one,
            license_two: profile?.license_two,
            linti_one: profile?.linti_one,
            linti_two: profile?.linti_two,
            lnh_date: profile?.lnh_date,
            date_of_birth: profile?.date_of_birth || null,
            cbu_number: profile?.cbu_number,
            bank_name: profile?.bank_name,
            discount_factor: profile.discount_factor,
            tax_status: profile?.tax_status,
          }}
          validationSchema={ProfileSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmitAPI(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            setFieldValue,
          }) => (
            <Row>
              <Col md="12">
                <Card className="shadow-3dp mb-2">
                  <Card.Body>
                    <Form>
                      {!viewTrips ? (
                        <Row>
                          <Col className="text-center">
                            <AvatarLogAdmin
                              src={
                                values.image ||
                                "https://randomuser.me/api/portraits/men/43.jpg"
                              }
                              roundedCircle
                            />
                          </Col>
                        </Row>
                      ) : null}
                      <Row>
                        <Col md="4">
                          <Form.Group>
                            <label>Nombre y apellido</label>
                            <LogInput
                              placeholder="Nombre"
                              type="text"
                              name="name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              errors={errors}
                              readOnly
                              value={values.name + " " + values.last_name}
                            />
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group>
                            <label>Factor de descuento</label>
                            <LogInput
                              className="number-font"
                              placeholder="Factor de descuento"
                              type="number"
                              name="discount_factor"
                              onChange={(e) => {
                                const re = /^[0-9]*(\.[0-9]{0,2})?$/;
                                if (re.test(e.target.value)) {
                                  handleChange(e);
                                }
                              }}
                              onBlur={handleBlur}
                              errors={errors}
                              value={values.discount_factor}
                              step="0.01"
                              max="99"
                            />
                          </Form.Group>
                        </Col>
                        <Col md="4">
                          <Form.Group>
                            <label>Correo electrónico</label>
                            <LogInput
                              placeholder="Correo electrónico"
                              type="email"
                              name="email"
                              readOnly
                              onChange={handleChange}
                              onBlur={handleBlur}
                              errors={errors}
                              value={values.email}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {!viewTrips ? (
                        <>
                          <Row>
                            <Col md="4">
                              <Form.Group>
                                <label>Razón social</label>
                                <LogInput
                                  placeholder="Razón social"
                                  type="text"
                                  name="reason"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  errors={errors}
                                  readOnly
                                  value={values.reason}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="4">
                              <Form.Group>
                                <label>Número de teléfono</label>
                                <LogInputPhone
                                  placeholder="Teléfono"
                                  className="mb-2 number-font"
                                  name="phone"
                                  onChange={(phone) => {
                                    if (phone && !phone.startsWith("+")) {
                                      phone = "+" + phone;
                                    }
                                    setFieldValue("phone", phone);
                                  }}
                                  onBlur={handleBlur}
                                  disabled
                                  errors={errors}
                                  value={values.phone}
                                />
                              </Form.Group>
                            </Col>
                            <Col md="4">
                              <Form.Group>
                                <label>CUIT</label>
                                <LogInput
                                  className="number-font"
                                  placeholder="Razón social"
                                  type="text"
                                  name="cuit"
                                  readOnly
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  errors={errors}
                                  value={values.cuit}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                          {profile?.role_id?.name === TOMADOR_DE_CARGA ? (
                            <>
                              <Row>
                                <Col md="4">
                                  <Form.Group>
                                    <label>CBU</label>
                                    <LogInput
                                      placeholder="C.B.U."
                                      type="text"
                                      name="cbu_number"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      errors={errors}
                                      value={values.cbu_number}
                                      disabled={true}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md="4">
                                  <Form.Group>
                                    <label>Banco</label>
                                    <LogInput
                                      className="number-font"
                                      placeholder="Banco"
                                      type="text"
                                      name="bank_name"
                                      disabled={true}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      errors={errors}
                                      value={values.bank_name}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col md="4">
                                  <Form.Group>
                                    <label>Fecha nacimiento</label>
                                    <LogDateInput
                                      readOnly={true}
                                      placeholder="Ingresá la fecha de nacimiento"
                                      name="date_of_birth"
                                      onChange={(e) =>
                                        setFieldValue(
                                          "date_of_birth",
                                          e ? new Date(e).toISOString() : null
                                        )
                                      }
                                      onBlur={handleBlur.date_of_birth}
                                      errors={errors}
                                      value={
                                        values?.date_of_birth
                                          ? new Date(values?.date_of_birth)
                                          : null
                                      }
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <TaxStatusField
                                values={values}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                taxStatus={taxStatus}
                              />
                              <Divider />
                              <Row>
                                <Col md="6">
                                  <label className="back-primary font-weight-bold">
                                    Licencia frente
                                  </label>
                                  <DropZone
                                    reset={resetDropZone}
                                    readOnly={true}
                                    image={values?.license_one}
                                    uploadFile={(v) => {
                                      setFieldValue("license_one", {
                                        file: v,
                                        url: v,
                                      });
                                    }}
                                    errors={errors?.license_one}
                                    removeFile={() => {
                                      setFieldValue("license_one", "");
                                    }}
                                    placeholder="Subir frente de licencia"
                                  />
                                </Col>
                                <Col md="6" className="mt-2 mt-md-0">
                                  <label className="back-primary font-weight-bold">
                                    Licencia dorso
                                  </label>
                                  <DropZone
                                    reset={resetDropZone}
                                    readOnly={true}
                                    image={values?.license_two}
                                    uploadFile={(v) => {
                                      setFieldValue("license_two", {
                                        file: v,
                                        url: v,
                                      });
                                    }}
                                    errors={errors?.license_two}
                                    removeFile={() => {
                                      setFieldValue("license_two", "");
                                    }}
                                    placeholder="Subir dorso de licencia"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <LogDatePicker
                                    readOnly={true}
                                    cleanable={true}
                                    name="license_date"
                                    placeholder="Vencimiento Licencia"
                                    className="number-font mt-2"
                                    onBlur={handleBlur}
                                    errors={errors}
                                    value={
                                      values.license_date
                                        ? new Date(values.license_date)
                                        : null
                                    }
                                    onChange={(value) => {
                                      setFieldValue("license_date", value);
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Divider />
                              <Row>
                                <Col md="6">
                                  <label className="back-primary font-weight-bold">
                                    LNH frente
                                  </label>
                                  <DropZone
                                    readOnly={true}
                                    reset={resetDropZone}
                                    image={values?.linti_one}
                                    uploadFile={(v) => {
                                      setFieldValue("linti_one", {
                                        file: v,
                                        url: v,
                                      });
                                    }}
                                    errors={errors?.linti_one}
                                    removeFile={() => {
                                      setFieldValue("linti_one", "");
                                    }}
                                    placeholder="Subir frente de LNH"
                                  />
                                </Col>
                                <Col md="6" className="mt-2 mt-md-0">
                                  <label className="back-primary font-weight-bold">
                                    LNH dorso
                                  </label>
                                  <DropZone
                                    reset={resetDropZone}
                                    readOnly={true}
                                    image={values?.linti_two}
                                    uploadFile={(v) => {
                                      setFieldValue("linti_two", {
                                        file: v,
                                        url: v,
                                      });
                                    }}
                                    errors={errors?.linti_two}
                                    removeFile={() => {
                                      setFieldValue("linti_two", "");
                                    }}
                                    placeholder="Subir dorso de LNH"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md={6}>
                                  <LogDatePicker
                                    readOnly={true}
                                    cleanable={true}
                                    name="lnh_date"
                                    placeholder="Vencimiento Linti"
                                    className="number-font mt-2"
                                    onBlur={handleBlur}
                                    errors={errors}
                                    value={
                                      values.lnh_date
                                        ? new Date(values.lnh_date)
                                        : null
                                    }
                                    onChange={(value) => {
                                      setFieldValue("lnh_date", value);
                                    }}
                                  />
                                </Col>
                              </Row>
                            </>
                          ) : null}
                          {profile?.role_id?.name === DADOR_DE_CARGA ? (
                            <TaxStatusField
                              values={values}
                              handleBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              taxStatus={taxStatus}
                            />
                          ) : null}
                        </>
                      ) : null}

                      <Row className="mt-4">
                        {!viewTrips ? (
                          <Col
                            md={6}
                            className="d-flex justify-content-between my-2 my-sm-0"
                          >
                            <LogButton
                              size="xs"
                              title={
                                isDisable
                                  ? "Habilitar usuario"
                                  : "Deshabilitar usuario"
                              }
                              color={isDisable ? "primary" : "danger"}
                              border="curve"
                              block
                              onClick={handleStateUser}
                            />
                            <LogButton
                              size="xs"
                              title={"Editar usuario"}
                              color={"danger"}
                              border="curve"
                              block
                              disabled={!dirty}
                              onClick={handleSubmit}
                            />
                            {profile.role_id?.name === DADOR_DE_CARGA ? (
                              <LogButton
                                size="xs"
                                title={"Convertir Admin"}
                                color={"danger"}
                                border="curve"
                                block
                                onClick={() => setShowQuestion(true)}
                              />
                            ) : profile.in_action ? (
                              <LogButton
                                size="xs"
                                title={
                                  profile?.android_id
                                    ? "Blanquear Android"
                                    : "Android Libre"
                                }
                                disabled={!profile?.android_id}
                                color={"danger"}
                                border="curve"
                                block
                                onClick={() =>
                                  profile.android_id && handleAndroidId()
                                }
                              />
                            ) : null}
                          </Col>
                        ) : (
                          <Col md={6}></Col>
                        )}

                        <Col md={{ span: 2, offset: 4 }}>
                          {profile?.role_id?.name !== "ADMIN" ? (
                            <Row
                              onClick={() => {
                                resetForm();
                                setViewTrips((state) => !state);
                              }}
                              className="pl-3  mt-1 cursor-pointer"
                            >
                              <h5 className="text-primary font-weight-bold">
                                Detalles
                              </h5>

                              {viewTrips ? (
                                <GoTriangleDown size={26} />
                              ) : (
                                <GoTriangleRight size={26} />
                              )}
                            </Row>
                          ) : null}
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Formik>
        {viewTrips && profile._id ? (
          <UserTabs id={id} role={profile?.role_id?.name} />
        ) : null}
      </Container>
      {showQuestion && (
        <ModalQuestion
          isShow={showQuestion}
          handleClose={handleClose}
          title={`Convertir al usuario ${profile?.email} en Admin`}
          subTitle={""}
          okText={"Confirmar"}
          noText={"Cancelar"}
        />
      )}
    </>
  );
}

export default UserDetails;
