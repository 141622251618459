import React, { useEffect, useState } from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { CardLogBootstrapStyled } from "../../../../../styledComponents/CardBootstrapLog";
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  BarElement,
} from "chart.js";
import {
  getStatisticsDador,
  getStatisticsTomador,
} from "../../../../../api/modules";
import { errorToast } from "../../../../../services/toastService";
import { stringify } from "query-string";
import { chartColors } from "../Colors/colors";
import LogSelect from "../../../../../components/atoms/LogSelect";
import ModalRatings from "../../../../../components/organisms/modal-ratings/ModalRatings";
import { useHistory } from "react-router-dom";
import NewDador from "./NewDador";
const _ = require("lodash");

ChartJS.register(
  BarElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const CardLogBootstrapStyledHeight = styled(CardLogBootstrapStyled)`
  height: 125px;
  border-radius: 1px;
`;
const ChartContainer = styled.div`
  height: 100%;
  padding-top: 1rem;
  padding-bottom: 1 rem;
`;

const BadgeStyle = styled(Badge)`
  width: 40%;
`;

const DivDashBoardParent = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
const DivDashBoardBody = styled.div`
  max-width: 80%;
  width: 90%;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const DadorDashBoard = () => {
  const spinner = useSelector((state) => state.appReducer.isLoading);
  const { name } = useSelector((state) => state.authApp.user);
  const [statistics, setStatistics] = useState({ totalTrips: 5 });
  const [dataByType, setDataByType] = useState([]);
  const [dataByOrigin, setDataByOrigin] = useState([]);
  const [dataByDestiny, setDataByDestiny] = useState([]);
  const [state, setState] = useState(null);
  const [stateBack, setStateBack] = useState(null);
  const { pendingRating } = useSelector((state) => state.authApp.user);
  const [showModal, setShowModal] = useState(true);
  const history = useHistory();

  const fetchData = async () => {
    const response = await getStatisticsDador(state);
    if (response.msg) {
      errorToast(response.msg);
    } else {
      setStateBack(response.state);
      setState(response.state);
      setStatistics(response);
    }
  };
  const handleClose = (state) => {
    if (state) {
      history.push(`/app/list-travels?state=3`);
    } else {
      setShowModal(false);
    }
  };

  const formatData = () => {
    setDataByType(
      statistics.totalTrips.map((ele) => {
        return { name: ele.product_type_name, count: ele.childs_count };
      })
    );
    let byOrigin = statistics.totalTrips.map((ele) =>
      ele.origin_childs.map((ele2) => {
        return {
          origin: ele2.origin_site.site_name.toUpperCase(),
          [ele.product_type_name]: ele2.count,
        };
      })
    );
    let byDestiny = statistics.totalTrips.map((ele) =>
      ele.origin_childs.map((ele2) => {
        return {
          origin: ele2.destiny_site.site_name.toUpperCase(),
          [ele.product_type_name]: ele2.count,
        };
      })
    );
    setDataByOrigin(
      _(
        byOrigin.reduce(
          (accumulator, currentValue) => [...accumulator, ...currentValue],
          []
        )
      )
        .groupBy("origin")
        .map((g) =>
          _.mergeWith({}, ...g, (obj, src) =>
            _.isArray(obj) ? obj.concat(src) : undefined
          )
        )
        .value()
    );
    setDataByDestiny(
      _(
        byDestiny.reduce(
          (accumulator, currentValue) => [...accumulator, ...currentValue],
          []
        )
      )
        .groupBy("origin")
        .map((g) =>
          _.mergeWith({}, ...g, (obj, src) =>
            _.isArray(obj) ? obj.concat(src) : undefined
          )
        )
        .value()
    );
  };

  useEffect(() => {
    if (!stateBack || stateBack !== state) {
      fetchData();
    }
  }, [state]);
  useEffect(() => {
    statistics.total_product_type && formatData();
  }, [statistics]);
  return (
    <DivDashBoardParent className="p-4">
      <DivDashBoardBody>
        <Row className="mt-5">
          <Col>
            <h1 className="text-primary h3 font-weight-bold">
              {" "}
              ¡Bienvenido {name || ""}!
            </h1>
            {state > 2 ? (
              <LogSelect
                className="w-50 mt-4 font-weight-bolder"
                cleanable={false}
                placeholder="Tipo de licencia"
                items={[
                  {
                    label: "Tus viajes Finalizados",
                    value: 5,
                  },
                  {
                    label: "Tus viajes en camino",
                    value: 4,
                  },
                  {
                    label: "Tus viajes listos para viajar",
                    value: 3,
                  },
                ]}
                onChange={(v) => {
                  setState(v);
                }}
                value={state}
                errors={{}}
              />
            ) : null}
          </Col>
        </Row>

        {statistics.total_product_type ? (
          <>
            <Row className="mt-4">
              {statistics.totalTrips.map((ele, index) => (
                <Col md="4" key={index}>
                  <CardLogBootstrapStyledHeight className="shadow-6dp p-2 mb-4">
                    <Row className="d-flex justify-content-end m-0">
                      <BadgeStyle
                        pill
                        bg="primaryLight"
                        text="primary"
                        className="py-2 px-3 mr-2"
                      >
                        {ele?.product_type_name}
                      </BadgeStyle>
                    </Row>
                    <Row className="mt-0">
                      <Col>
                        <h2 className="display-6 font-weight-bold">
                          {ele.childs_count}
                        </h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex align-items-center">
                        <p className="text-font-info mb-0">Viajes</p>
                      </Col>
                    </Row>
                  </CardLogBootstrapStyledHeight>
                </Col>
              ))}
            </Row>
            <Row className="mt-4">
              <Col md="6">
                <CardLogBootstrapStyled className="shadow-6dp p-4 mb-4">
                  <strong>Viajes por producto</strong>
                  <ChartContainer>
                    <Pie
                      datasetIdKey="product"
                      options={{
                        plugins: {
                          datalabels: {
                            display: false,
                          },
                        },
                      }}
                      data={{
                        labels: dataByType?.map((ele) => ele.name),
                        datasets: [
                          {
                            label: "# of Votes",
                            data: dataByType?.map((ele) => ele.count),
                            backgroundColor: chartColors(
                              dataByType?.map((ele) => ele.name)
                            ),
                            borderColor: chartColors(
                              dataByType?.map((ele) => ele.name)
                            ),
                            borderWidth: 1,
                          },
                        ],
                      }}
                    />
                  </ChartContainer>
                </CardLogBootstrapStyled>
              </Col>
              <Col md="6">
                <CardLogBootstrapStyled className="shadow-6dp p-4 mb-4">
                  <strong>Productos por Origen</strong>
                  <ChartContainer>
                    <Bar
                      datasetIdKey="origin"
                      options={{
                        aspectRatio: 1,
                        plugins: {
                          datalabels: {
                            display: false,
                          },
                        },
                      }}
                      data={{
                        labels: dataByOrigin?.map((ele) => ele.origin),
                        datasets: dataByType?.map((ele) => {
                          return {
                            label: ele.name,
                            data: dataByOrigin?.map(
                              (color) => color[ele.name] || 0
                            ),
                            backgroundColor: chartColors([ele.name]),
                            stack: "Stack 0",
                          };
                        }),
                      }}
                    />
                  </ChartContainer>
                </CardLogBootstrapStyled>
              </Col>
              <Col md="6">
                <CardLogBootstrapStyled className="shadow-6dp p-4 mb-4">
                  <strong>Productos por Destino</strong>
                  <ChartContainer>
                    <Bar
                      datasetIdKey="destiny"
                      options={{
                        plugins: {
                          datalabels: {
                            display: false,
                          },
                        },
                        aspectRatio: 1,
                      }}
                      data={{
                        labels: dataByDestiny?.map((ele) => ele.origin),
                        datasets: dataByType?.map((ele) => {
                          return {
                            label: ele.name,
                            data: dataByDestiny?.map(
                              (color) => color[ele.name] || 0
                            ),
                            backgroundColor: chartColors([ele.name]),
                            stack: "Stack 0",
                          };
                        }),
                      }}
                    />
                  </ChartContainer>
                </CardLogBootstrapStyled>
              </Col>
            </Row>
          </>
        ) : !spinner ? (
          state > 2 ? (
            <DivEmpty>
              <h2 className="font-weight-bold h3 text-center">
                No tienes viajes{" "}
                {state === 5
                  ? "finalizados"
                  : state === 4
                  ? "en ejecucion"
                  : state === 3
                  ? "listos para viajar"
                  : ""}
              </h2>
            </DivEmpty>
          ) : (
            <NewDador />
          )
        ) : (
          <DivEmpty></DivEmpty>
        )}
        <ModalRatings
          isShow={pendingRating && showModal}
          handleClose={handleClose}
        />
      </DivDashBoardBody>
    </DivDashBoardParent>
  );
};

export default DadorDashBoard;
