import React, { useEffect, useState } from "react";

// react-bootstrap components
import { Container, Row, Col, Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import LogButton from "../../../../components/atoms/LogButton";
import LocationItem from "../../components/molecules/LocationItem";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import PaginationList from "../../../../components/organisms/pagination-list/PaginationList";
import { COLOR_PRIMARY, COLOR_RED } from "../../../../constants/Colors";
import {
  deleteLogicDriver,
  getMyDrivers,
} from "../../../../api/modules/driver";
import { successToast } from "../../../../services/toastService";

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const ContainerList = styled.div`
  overflow-y: overlay;
  padding-right: 10px;
`;

const BadgeLog = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  display: inline-block;
  padding: 0px 20px;
`;

const BadgeLogRed = styled(Badge)`
  background-color: ${COLOR_RED};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  display: inline-block;
  padding: 0px 20px;
`;

function MyDrivers() {
  const id = useSelector((state) => state.authApp.user._id);
  const history = useHistory();
  const INITIAL_ENTITY = {
    items: [],
    total: 0,
  };
  const [driversAPI, setDriversAPI] = useState(INITIAL_ENTITY);
  const [page, setPage] = useState(0);
  const numberList = 6;

  useEffect(() => {
    fetchData(numberList, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchData = async (limit = numberList, from = 0) => {
    setDriversAPI(INITIAL_ENTITY);
    const response = await getMyDrivers(limit, from, id);
    const { driversByTC, total } = response;
    if (driversByTC) {
      setDriversAPI({
        items: driversByTC,
        total: total,
      });
    } else {
      setDriversAPI(INITIAL_ENTITY);
    }
  };

  const handlePreviewPage = () => {
    fetchData(numberList, numberList * page - numberList);
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    fetchData(numberList, numberList * (page + 1));
  };

  const getAge = (date_of_birth) => {
    const today = new Date();
    const dateOfBirth = new Date(date_of_birth);
    const age = today.getFullYear() - dateOfBirth.getFullYear();
    return age;
  };

  const handleDeleteLogic = async (id, active) => {
    const body = {
      is_active: !active,
    };
    const resp = await deleteLogicDriver(id, body);
    const { msg } = resp;
    successToast(msg);
    history.push("/app/my-drivers");
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <ContainerList>
              {driversAPI.items.map(
                (
                  { is_active, name, last_name, cuit, _id, date_of_birth },
                  index
                ) => (
                  <LocationItem
                    noIcon
                    onClick={() =>
                      history.push(`/app/my-drivers/detail-driver/${_id}`)
                    }
                    key={index}
                  >
                    <Row>
                      <Col
                        md="3"
                        className="text-center d-flex justify-content-center align-items-center flex-wrap"
                      >
                        <small style={{ width: "100%" }}>
                          <p className="text-primary text-center mb-1">
                            Chofer
                          </p>
                        </small>
                        <br />
                        {is_active ? (
                          <BadgeLog
                            as="h5"
                            onClick={() => handleDeleteLogic(_id, is_active)}
                          >
                            Esta activo
                          </BadgeLog>
                        ) : (
                          <BadgeLogRed
                            as="h5"
                            onClick={() => handleDeleteLogic(_id, is_active)}
                          >
                            Inactivo
                          </BadgeLogRed>
                        )}
                      </Col>
                      <Col md="3" className="text-center">
                        <small>
                          <p className="text-primary text-center mb-1">
                            Nombre
                          </p>
                        </small>
                        <p className="h5 font-weight-bold mt-2">
                          {name} {last_name}
                        </p>
                      </Col>
                      <Col md="3" className="text-center">
                        <small>
                          <p className="text-primary text-center mb-1">Edad</p>
                        </small>
                        <p className="h5 font-weight-bold mt-2">
                          {getAge(date_of_birth)} años
                        </p>
                      </Col>
                      <Col md="3" className="text-center">
                        <small>
                          <p className="text-primary text-center mb-1">Cuit</p>
                        </small>
                        <p className="h5 font-weight-bold mt-2">
                          {cuit || "No posee"}
                        </p>
                      </Col>
                    </Row>
                  </LocationItem>
                )
              )}
              {driversAPI.items.length === 0 && (
                <h2 className="font-weight-bold h3">
                  Aún no tenés ningún chofer registrado
                </h2>
              )}
            </ContainerList>
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={driversAPI.items}
            total={driversAPI.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
        <Col md="12">
          <LogButton
            className="mt-3 "
            title="Agregar chofer"
            border="curve"
            block
            size="md"
            onClick={() => history.push("/app/my-drivers/detail-driver/12")}
          />
          <div className="clearfix"></div>
        </Col>
      </RowStyled>
    </>
  );
}

export default MyDrivers;
