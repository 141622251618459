import React, { useState } from "react";
import styled from "styled-components";
import { Card, Col, Row } from "react-bootstrap";
import LogButton from "../../../../../components/atoms/LogButton";
import { COLOR_PRIMARY } from "../../../../../constants/Colors";
import LogTextArea from "../../../../../components/atoms/LogTextArea";
import { Formik } from "formik";
import * as Yup from "yup";
import ReactStars from "react-stars";
import { postRating, putRating } from "../../../../../api/modules";
import { errorToast, successToast } from "../../../../../services/toastService";

const DivButtons = styled.div`
  display: flex;
  justify-content: space-between;
  /* flex-direction: row-reverse; */
  width: 100%;
`;

const Divider = styled.hr`
  background-color: ${COLOR_PRIMARY};
  height: 1px;
  width: 100%;
  opacity: 0.1;
  margin-bottom: 0.5rem;
`;

const ReputationAdmin = ({ score, comment, id_rating, refetch, tomador }) => {
  const ReputationAdminSchema = Yup.object().shape({
    comment: Yup.string().required("Comentario es obligatorio"),
    score: Yup.number().required("El puntaje es obligatorio"),
  });
  const [edit, setEdit] = useState(true);
  const handleSubmitAPI = async (body, resetForm) => {
    const { msg } = await putRating({ ...body, id_rating });

    if (msg) {
      return errorToast("Por favor intente nuevamente");
    }
    successToast("Rating editado con exito");
    refetch();
    setEdit((prev) => !prev);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ score, comment }}
      validationSchema={ReputationAdminSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmitAPI(values, resetForm);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleReset,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Col className="d-flex flex-column align-items-start">
          <ReactStars
            key={edit}
            value={values.score || 0}
            onChange={(score) =>
              handleChange({
                target: {
                  name: "score",
                  value: score,
                },
              })
            }
            half={edit}
            edit={!edit}
            count={5}
            size={36}
            activeColor="#ffd700"
            color="#CDE0EB"
            /* char={<BsStarFill />} */
          />
          {errors["score"] && (
            <div className="mb-2 mt-0">
              <small className="text-danger">{errors["score"]}.</small>
            </div>
          )}

          <LogTextArea
            style={{ width: "100%" /* , marginTop:"1rem" */ }}
            placeholder={`Ingresar comentario sobre el ${
              tomador ? "dador" : "tomador"
            }`}
            className="mb-2 number-font"
            name="comment"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            value={values.comment || ""}
            maxLength={160}
            readOnly={edit}
          />
          <DivButtons>
            <LogButton
              className="mt-0 pt-0 pb-0 pull-right"
              size="xs"
              title={edit ? "Editar" : "Cancelar"}
              variant={"primary"}
              /* border="curve" */
              block="true"
              onClick={() => {
                handleReset()
                setEdit((prev) => !prev);
              }}
            />
            {!edit ? (
              <LogButton
                className="mt-0 pt-0 pb-0"
                size="xs"
                title={tomador ? "Calificar dador" : "Calificar tomador"}
                variant={"primary"}
                /* border="curve" */
                block="true"
                onClick={handleSubmit}
              />
            ) : null}
          </DivButtons>
        </Col>
      )}
    </Formik>
  );
};

export default ReputationAdmin;
