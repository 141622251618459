import React, { useEffect, useState } from "react";
import { delTripAPI, getTripAPI } from "../../../../../../api/modules";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
  Carousel,
  Stack,
} from "react-bootstrap";
import { CardLogBootstrapStyled } from "../../../../../../styledComponents/CardBootstrapLog";
import moment from "moment";
import { BiTrash } from "react-icons/bi";
import { BsEye } from "react-icons/bs";
import ModalQuestion from "../../../../../../components/organisms/modal-question/ModalQuestion";
import {
  errorToast,
  successToast,
} from "../../../../../../services/toastService";

import {
  delCancelPostulation,
  putAcceptPostulation,
} from "../../../../../../api/modules";
import CardPostulates from "../../../../components/molecules/CardPostulates";
import { useDispatch, useSelector } from "react-redux";
import { deleteNotification } from "../../../../../../redux/actions/app";
import { ADMIN } from "../../../../../../constants/UserType";
const PAYMENT_TYPE_NAME = process.env.REACT_APP_TOMADOR_PAYMENT_NAME;

const DivContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;
const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;
const BtnInvisible = styled(Button)`
  background: none;
  border: none;
  &:hover {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
  &:active {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
  &:focus {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
`;

const MyOfferDetail = ({ id: propId }) => {
  const spinner = useSelector((state) => state.appReducer.isLoading);
  const { token, user } = useSelector((state) => state.authApp);
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const [isPostulationModal, setPostulationModal] = useState({
    view: false,
    mode: "A",
    body: {},
  });
  const reloadRoute =
    user.role_id?.name === ADMIN
      ? params?.id
        ? `/app/users/user-profile/${params?.id}`
        : "/app/travels-all?state=0"
      : "/app/list-travels?state=0";

  const [modalExit, setModalExit] = useState(false);
  const [totalAvailablePostulations, setTotalAvailablePostulations] =
    useState(0);
  const [entity, setEntity] = useState({
    truck_features: [],
    truck_accessories: [],
    truck_type: [],
    origin_site: {},
    destiny_site: {},
    product: {},
    charger_type: {},
    postulations: [],
  });

  useEffect(() => {
    getFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFetch = async () => {
    const id = propId || params?.id;
    const responsetrip = await getTripAPI(id);
    const { trip } = responsetrip?.data || {};

    if (trip) {
      dispatch(
        deleteNotification({
          id_trip: id,
          id_owner: user._id,
          type: "Postulacion",
        })
      );
      setEntity((prev) => {
        return {
          ...prev,
          ...trip,
        };
      });

      const totalPostulations = trip.postulations.filter(
        (p) => p.state === 0 && p.status
      );

      setTotalAvailablePostulations(totalPostulations.length);
    }
  };

  const handleCloseExitModal = async (response) => {
    if (response) {
      const response = await delTripAPI(entity._id);
      const { trip, msg } = response;
      if (trip) {
        successToast("Viaje eliminado exitosamente");
        history.push(reloadRoute);
      } else {
        errorToast(msg || "Intente nuevamente");
      }
      setModalExit(false);
    } else {
      setModalExit(false);
    }
  };

  return (
    <>
      <Container fluid className="mt-2">
        <Row>
          <Col md="12">
            <CardLogBootstrapStyled className="shadow-6dp p-4">
              <Row className="mb-4">
                <Col>
                  <h1 className="h3 font-weight-bold text-primary">
                    Viaje #ID:{" "}
                    <span className="number-font">{entity.id_trip}</span>
                  </h1>
                </Col>
                <Col className="d-flex justify-content-end">
                  <OverlayTrigger
                    key="view"
                    overlay={<Tooltip id={`view`}>Ver detalles</Tooltip>}
                  >
                    <BtnInvisible
                      onClick={() =>
                        history.push(`/app/traves/view-travel/${entity._id}`)
                      }
                    >
                      <BsEye />{" "}
                    </BtnInvisible>
                  </OverlayTrigger>
                  <OverlayTrigger
                    key="delete"
                    overlay={<Tooltip id={`delete`}>Eliminar</Tooltip>}
                  >
                    <BtnInvisible onClick={() => setModalExit(true)}>
                      <BiTrash />{" "}
                    </BtnInvisible>
                  </OverlayTrigger>
                </Col>
              </Row>
              <Row>
                <Col md="1">
                  <div className="circle-icon">
                    <span className="icon-ping text-primary"></span>
                  </div>
                </Col>
                <Col md="5">
                  <p>
                    <strong>Dirección de retiro:</strong>{" "}
                    {entity.origin_site.address}
                  </p>
                  <p>
                    <strong>Tipo de origen:</strong>{" "}
                    {entity?.origin
                      ? entity?.origin?.charAt(0).toUpperCase() +
                        entity?.origin?.slice(1).toLowerCase()
                      : "No Disponible"}
                  </p>
                </Col>
                <Col md="1">
                  <div className="circle-icon">
                    <span className="icon-calendar text-primary"></span>
                  </div>
                </Col>
                <Col md="5" className="mt-2">
                  <strong>Fecha de retiro:</strong>{" "}
                  {moment(entity.withdraw_date).format("DD/MM/YYYY")}
                </Col>
              </Row>
              <Row>
                <Col md="1">
                  <div className="circle-icon">
                    <span className="icon-ping text-primary"></span>
                  </div>
                </Col>
                <Col md="5">
                  <p>
                    <strong>Dirección de entrega:</strong>{" "}
                    {entity.destiny_site.address}
                  </p>
                  <p>
                    <strong>Tipo de destino:</strong>{" "}
                    {entity?.destiny
                      ? entity?.destiny?.charAt(0).toUpperCase() +
                        entity?.destiny?.slice(1).toLowerCase()
                      : "No Disponible"}
                  </p>
                </Col>
                <Col md="1">
                  <div className="circle-icon">
                    <span className="icon-calendar text-primary"></span>
                  </div>
                </Col>
                <Col md="5" className="mt-2">
                  <strong>Fecha de entrega:</strong>{" "}
                  {moment(entity.delivery_date).format("DD/MM/YYYY")}
                </Col>
              </Row>
            </CardLogBootstrapStyled>
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="mt-4 font-weight-bold h3">
              Postulaciones disponibles:
            </h1>
            <h4 className="mt-1 font-weight-bold text-info h5">
              Camiones ocupados: {entity?.trucks_number_accepted}/{" "}
              {entity?.trucks_number}
            </h4>
          </Col>
        </Row>
        <DivContainer className="mt-4">
          {totalAvailablePostulations > 0 &&
            entity.postulations.map((postulation, key) => {
              return (
                postulation.state === 0 &&
                postulation.status && (
                  <CardPostulates
                    id_trip={entity.id_trip}
                    discount_trip={entity.discount_trip}
                    truck_charge={entity.truck_charge}
                    score={postulation?.score}
                    key={key}
                    price={entity.price_per_truck}
                    price_type={entity.price_type}
                    postulation={postulation}
                    payment_type_name={
                      postulation?.payment_type_postulation
                        ?.payment_type_name ||
                      PAYMENT_TYPE_NAME ||
                      "E-cheq"
                    }
                    handleAcceptPostulation={async () => {
                      setPostulationModal((prev) => {
                        return {
                          ...prev,
                          view: true,
                          mode: "A",
                          body: {
                            entity,
                            postulation,
                          },
                        };
                      });
                    }}
                    handleCancelPostulation={async () => {
                      setPostulationModal((prev) => {
                        return {
                          ...prev,
                          view: true,
                          mode: "R",
                          body: {
                            entity,
                            postulation,
                          },
                        };
                      });
                    }}
                  />
                )
              );
            })}

          {!spinner ? (
            totalAvailablePostulations === 0 && (
              <h2 className="font-weight-bold h3 text-center">
                "Aun no tiene ofertas para este viaje"
              </h2>
            )
          ) : (
            <DivEmpty />
          )}
        </DivContainer>
      </Container>
      <ModalQuestion
        title="¿Estás seguro de esta accion?"
        subTitle={`${
          isPostulationModal.mode === "A" ? "Aprobar" : "Rechazar"
        } la postulación para tu viaje`}
        isShow={isPostulationModal.view}
        handleClose={async (payload) => {
          if (isPostulationModal.mode === "A" && payload) {
            const response = await putAcceptPostulation({
              id_trip: isPostulationModal.body.entity._id,
              id_postulation: isPostulationModal.body.postulation._id,
            });
            const { msg, error } = response;
            if (!error) {
              successToast(msg);
              history.push(reloadRoute);
            } else {
              errorToast(msg);
            }
          }
          if (isPostulationModal.mode === "R" && payload) {
            const response = await delCancelPostulation({
              id_trip: isPostulationModal.body.entity._id,
              id_postulation: isPostulationModal.body.postulation._id,
              reason:
                user.role_id?.name === ADMIN
                  ? "Eliminada por " + user.email
                  : "Eliminada por el dador",
            });
            const { msg, error } = response;
            if (!msg) {
              successToast("Postulación eliminada exitosamente");
              history.push(reloadRoute);
            } else {
              errorToast(msg);
            }
          }
          setPostulationModal((prev) => {
            return {
              ...prev,
              view: false,
              mode: "A",
              body: {},
            };
          });
        }}
      />
      <ModalQuestion
        title="¿Estás seguro de esta accion?"
        subTitle="Eliminar este viaje"
        isShow={modalExit}
        handleClose={handleCloseExitModal}
      />
    </>
  );
};

export default MyOfferDetail;
