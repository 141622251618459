import React from "react";
import { Badge, Button, Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LogButton from "../../../../../components/atoms/LogButton";
import styled from "styled-components";
import { COLOR_PRIMARY } from "../../../../../constants/Colors";

const BadgeLog1 = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 50px !important;
  text-align: center;
  /* display: inline-block; */
  padding: 5px 10px;
  /*  width: 20px; */
`;

const BadgeLog = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${COLOR_PRIMARY};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
`;

function NewTomador({ searchTrip = false, handleClose = () => {} }) {
  const history = useHistory();
  const steps = [
    {
      number: 1,
      description: "Completa tu perfil",
      buttonLabel: "Perfil",
      link: "/app/profile",
    },
    {
      number: 2,
      description: "Agreguemos tus camiones",
      buttonLabel: "Camiones",
      link: "/app/my-units/new-unit-form?type=truck",
    },
    {
      number: 3,
      description: "Ahora agrueguemos tus acoplados",
      buttonLabel: "Acoplados",
      link: "/app/my-units/new-unit-form?type=semi",
    },
    {
      number: 4,
      description: "Busca tu primer viaje!!",
      buttonLabel: "Viajes",
      link: "/app/my-travls-to-work",
    },
  ];
  return (
    <Container className="d-flex flex-column align-items-center justify-content-center ">
      <Row className="text-center mb-4">
        <Col>
          {searchTrip ? (
            <h1 className="text-primary h3 font-weight-bold">
              Para postularte primero debes completar los siguiente pasos
            </h1>
          ) : (
            <h1 className="text-primary h3 font-weight-bold">
              Estos son los pasos para comenzar!!!
            </h1>
          )}
        </Col>
      </Row>
      {steps.map((step, index) => (
        <Container className="mb-5" key={index} style={{ maxWidth: "500px" }}>
          <Row className="justify-content-start  align-items-center mb-2 ">
            <Col xs="auto">
              <BadgeLog as="h5">{step.number}</BadgeLog>
            </Col>
            <Col xs className="text-start p-0" md="auto">
              <h5>{step.description}</h5>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <LogButton
              variant="primary"
              onClick={() => {
                searchTrip && step.number === 4
                  ? handleClose()
                  : history.push(step.link);
              }}
              title={step.buttonLabel}
              border="curve"
              style={{ width: "120px" }}
            />
          </Row>
        </Container>
      ))}
    </Container>
  );
}

export default NewTomador;
