/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import PendingsTravelsTc from "./PendingsTravelsTc";
import PendingsDetails from "./PendingDetail";

const PendingTripsTc = ({ id, searchQuery, state }) => {
  const history = useHistory();
  const location = useLocation();
  const [tripId, setTripId] = useState(null);
  const [detailTrip, setDetailTrip] = useState(false);

  useEffect(() => {
    const { tripId, detailTrip } = queryString.parse(location.search);
    setTripId(tripId);
    /* setDetailTrip(detailTrip); */
  }, [location]);

  const getScreen = tripId ? (
    <PendingsDetails id={id} searchQuery={searchQuery} tripId={tripId} />
  ) : (
    <PendingsTravelsTc state={state} id={id} searchQuery={searchQuery} />
  );

  return <div>{getScreen} </div>;
};

PendingTripsTc.propTypes = {};

export default PendingTripsTc;
