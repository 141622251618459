import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CardLogBootstrapStyled } from "../../../../styledComponents/CardBootstrapLog";
import LogSelectMultiCustom from "../../../../components/atoms/LogSelectMultiCustom";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  getPushNotification,
  getTokensUsers,
  sendPushNotification,
} from "../../../../api/modules/admin";
import LogInput from "../../../../components/atoms/LogInput";
import useAutosizeTextArea from "../../../../components/organisms/auto-Resize/AutoResize";
import styled from "styled-components";
import LogButton from "../../../../components/atoms/LogButton";
import { errorToast, successToast } from "../../../../services/toastService";
import CardPushNotification from "../../components/molecules/CardPushNotification";
import PaginationList from "../../../../components/organisms/pagination-list/PaginationList";
import ModalUsersPush from "./Components/ModalUsersPush";

const PushSchema = Yup.object().shape(
  {
    title: Yup.string().required("El título es obligatorio"),
    body: Yup.string().required("El cuerpo del mensaje es obligatorio"),
    users: Yup.array().when("drivers", {
      is: (drivers) => !drivers || drivers.length === 0,
      then: Yup.array().min(1, "Debe seleccionar al menos un usuario o chofer"),
    }),
    drivers: Yup.array().when("users", {
      is: (users) => !users || users.length === 0,
      then: Yup.array().min(1, "Debe seleccionar al menos un usuario o chofer"),
    }),
  },
  ["drivers", "users"],
  ["users", "drivers"]
);
const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const AutosizeArea = styled.textarea`
  background: #ffffff;
  border-radius: 0;
  border: none;
  padding: 0;
  border: 1px solid #ededed;
  font-size: 1rem;
  line-height: 1.5;
  padding: 8px 0;
  resize: none;
  transition: border-color 150ms ease;
  width: 100%;
  overflow-y: hidden;
  :hover {
    border-color: #c6c6c6;
  }
  :focus {
    outline: none;
    border-color: #c6c6c6;
  }
`;

function SendPush() {
  const [users, setUsers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [usersPushModal, setUsersPushModal] = useState({
    isShow: false,
    data: {},
  });
  const closeUsersPushModal = () =>
    setUsersPushModal({ isShow: false, data: {} });
  const openUsersPushModal = (notificationData) =>
    setUsersPushModal({ isShow: true, data: notificationData });

  const textAreaRef = useRef(null);
  const [page, setPage] = useState(0);
  const numberList = 5;
  const INITIAL_STATE = {
    total: 0,
    notifications: [],
  };
  const [query, setQuery] = useState({
    limit: numberList,
    from: page,
  });
  const [notificationsData, setNotificationsData] = useState(INITIAL_STATE);
  const [refetch, setRefetch] = useState(false);

  const onRefetch = () => setRefetch((prev) => !prev);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    dirty,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      users: [],
      drivers: [],
      title: "",
      body: "",
    },
    onSubmit: async (values) => {
      const resp = await sendPushNotification({
        tokens: [...values.drivers, ...values.users],
        title: values.title,
        message: values.body,
        channelId: "Notifications",
      });

      const { failedTokens, invalidTokens, msg } = resp;

      if (msg) {
        errorToast(msg || "Intente Nuevamente");
        resetForm();
      } else if (failedTokens?.length !== 0 || invalidTokens?.length !== 0) {
        successToast(`Algunos mensajes fallaron`);
        resetForm();
      } else {
        successToast(`Mensajes enviados con exito`);
        resetForm();
      }
      setPage(0);
      setQuery((prev) => {
        return {
          ...prev,
          limit: numberList,
          from: 0,
        };
      });
      onRefetch();
    },

    validationSchema: PushSchema,
  });
  useAutosizeTextArea(textAreaRef.current, values.body, textAreaRef);
  const handlePreviewPage = () => {
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * page - numberList,
      };
    });
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * (page + 1),
      };
    });
  };

  const fetchData = async () => {
    try {
      const { users, drivers } = await getTokensUsers();
      setDrivers(
        drivers.map((ele) => {
          return { value: ele.push_token, label: ele.email };
        })
      );
      setUsers(
        users.map((ele) => {
          return { value: ele.push_token, label: ele.email };
        })
      );
    } catch (error) {
      console.error("SendPush", error);
    }
  };
  const fetchNotificationData = async () => {
    try {
      const { total, notifications } = await getPushNotification(query);
      setNotificationsData({ total, notifications });
    } catch (error) {
      console.error("fetchNotificationData", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [refetch]);

  useEffect(() => {
    fetchNotificationData(query);
  }, [query]);
  return (
    <Container fluid>
      <CardLogBootstrapStyled className="shadow-6dp p-4 ml-0 mr-0 mt-3 mb-4">
        <Row className="ml-0  mb-2">
          <h5 className="h5 text-primary font-weight-bold ">
            Enviar Notificaciones Push
          </h5>
        </Row>
        <Row>
          <Col md={6}>
            <LogSelectMultiCustom
              searchable={true}
              placeholder="Seleccione Usuarios"
              name="users"
              items={users}
              onChange={(v) => {
                setFieldValue("users", v);
              }}
              onBlur={handleBlur.users}
              errors={errors}
              value={values.users}
            ></LogSelectMultiCustom>
          </Col>
          <Col md={6} className="mt-2 mt-sm-0">
            <LogSelectMultiCustom
              searchable={true}
              placeholder="Seleccione Choferes"
              name="drivers"
              items={drivers}
              onChange={(v) => {
                setFieldValue("drivers", v);
              }}
              onBlur={handleBlur.drivers}
              errors={errors}
              value={values.drivers}
            ></LogSelectMultiCustom>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <label className="text-primary font-weight-bold">
              Ingresá el título del mensaje
            </label>
            <LogInput
              placeholder="Ingresá el título del mensaje"
              type="text"
              name="title"
              onChange={(e) => {
                const { value } = e.target;
                setFieldValue(
                  "title",
                  value.toLowerCase().charAt(0).toUpperCase() + value?.slice(1)
                );
              }}
              errors={errors}
              value={values.title}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            <label className="text-primary font-weight-bold">
              Ingresá el cuerpo del mensaje
            </label>
            <AutosizeArea
              id="review-text"
              name="body"
              onChange={handleChange}
              placeholder="Escribir mensaje"
              ref={textAreaRef}
              rows={1}
              value={values.body}
            />
            {errors.body && (
              <div className="mb-2 mt-0">
                <small className="text-danger">{errors.body}.</small>
              </div>
            )}
          </Col>
        </Row>
        <Row className="bt-3">
          <Col md={12} className="text-right">
            <LogButton title={"Enviar"} onClick={handleSubmit} />
          </Col>
        </Row>
      </CardLogBootstrapStyled>
      {notificationsData.notifications?.map((item, index) => (
        <CardPushNotification
          key={index}
          title={item.title}
          body={item.body}
          data={item.data}
          createsBy={item.createsBy}
          createdAt={item.createdAt}
          failed_drivers={item.failed_drivers}
          failed_users={item.failed_users}
          successful_drivers={item.successful_drivers}
          successful_users={item.successful_users}
          onClick={openUsersPushModal}
        />
      ))}
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={notificationsData?.notifications}
            total={notificationsData?.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
      </RowStyled>
      {usersPushModal?.isShow ? (
        <ModalUsersPush
          isShow={usersPushModal.isShow}
          data={usersPushModal.data}
          handleClose={closeUsersPushModal}
        />
      ) : null}
    </Container>
  );
}

export default SendPush;
