import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import PaginationList from "../../../../../../components/organisms/pagination-list/PaginationList";
import CardItemOfferTravel from "../../../../components/molecules/CardItemOfferTravel";
import { useSelector } from "react-redux";
import { getTripsCreatedByStateAPI } from "../../../../../../api/modules";
import { getAllTrips } from "../../../../../../api/modules/admin";

const ContainerList = styled.div`
  overflow-y: overlay;
  padding-right: 10px;
`;

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const MyOffers = ({ state, id, searchQuery }) => {
  const spinner = useSelector((state) => state.appReducer.isLoading);
  const history = useHistory();
  const location = useLocation();
  const INITIAL_STATE = {
    total: 0,
    items: [],
  };
  const addQueryParam = (key, value) => {
    const params = new URLSearchParams(location.search);
    params.set(key, value);
    history.push({ search: params.toString() });
  };
  const [tripStateOne, setTripStateOne] = useState(INITIAL_STATE);
  const [page, setPage] = useState(0);
  const numberList = 5;

  const INITIAL_ENTITY = {
    items: [],
    total: 0,
  };
  const [query, setQuery] = useState({
    limit: numberList,
    from: page,
    state,
    user_id: id,
    status: true,
  });
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  useEffect(() => {
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: 0,
      };
    });
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const fetchData = async () => {
    const tripsResponse = await getAllTrips({ ...query, ...searchQuery });

    const { trips, total } = tripsResponse;
    if (trips)
      setTripStateOne({
        ...INITIAL_STATE,
        items: trips,
        total,
      });
  };

  const handlePreviewPage = () => {
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * page - numberList,
      };
    });
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * (page + 1),
      };
    });
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <ContainerList>
              {tripStateOne.items.map((trip, index) => (
                <CardItemOfferTravel
                  onClick={() => {
                    const { _id } = trip;
                    addQueryParam("tripId", _id || "0");
                  }}
                  idTravel={trip._id}
                  product={trip.product}
                  postulations={
                    trip.postulations.filter((ele) => ele.state === 0).length
                  }
                  originName={trip.origin_site.site_name}
                  destinyName={trip.destiny_site.site_name}
                  withdrawDate={trip.withdraw_date}
                  deliveryDate={trip.delivery_date}
                  state="Pendientes"
                  key={index}
                  showId={trip.id_trip}
                >
                  <strong>{location.site_name}</strong> {location.address}
                </CardItemOfferTravel>
              ))}
              {tripStateOne.items.length === 0 &&
                (!spinner ? (
                  <h2 className="font-weight-bold h3 text-center">
                    Aún no tenés ninguna oferta
                  </h2>
                ) : (
                  <DivEmpty></DivEmpty>
                ))}
            </ContainerList>
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={tripStateOne.items}
            total={tripStateOne.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
      </RowStyled>
    </>
  );
};

MyOffers.propTypes = {};

export default MyOffers;
