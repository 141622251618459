/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import InActionsTravels from "./InActionsTravels";
import InActionsTravelsTrucks from "./InActionsTravelsTrucks";
import { useHistory, useLocation } from "react-router-dom";
import TripDetails from "./TripDetails/index";
import queryString from "query-string";

const InActionsTc = ({ id, searchQuery }) => {
  const history = useHistory();
  const location = useLocation();
  const [tripId, setTripId] = useState(null);
  const [detailTrip, setDetailTrip] = useState(false);


  useEffect(() => {
    const { tripId, detailTrip } = queryString.parse(window.location.search);
    setTripId(tripId);
    setDetailTrip(detailTrip);
  }, [location]);



  const getScreen =
    tripId && detailTrip ? (
      <TripDetails detailTrip={detailTrip} tripId={tripId} />
    ) : tripId ? (
      <InActionsTravelsTrucks tripId={tripId} idTc={id} />
    ) : (
      <InActionsTravels  id={id} searchQuery={searchQuery} />
    );

  return <div>{getScreen} </div>;
};

InActionsTc.propTypes = {};

export default InActionsTc;
