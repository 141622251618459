import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import LogInput from "../../../../../../../components/atoms/LogInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import CurrencyFormat from "react-currency-format";
import LogDateInput from "../../../../../../../components/atoms/LogDateInput";
import LogInputCurrency from "../../../../../../../components/atoms/LogInputCurrency";
import LogSelect from "../../../../../../../components/atoms/LogSelect";
import {
  getPaymentTypeAPI,
  postOrder,
} from "../../../../../../../api/modules";
import LogButton from "../../../../../../../components/atoms/LogButton";
import {
  errorToast,
  successToast,
} from "../../../../../../../services/toastService";

const OrderSchema = Yup.object().shape({
  date: Yup.date()
    .required("Fecha de OP es obligatorio")
    .nullable(),
  method: Yup.string()
    .required("Metodo pago es obligatorio")
    .nullable(),
  number: Yup.string().required("Numero de OP es obligatorio"),
  amount: Yup.number()
    .required("Monto es obligatorio")
    .positive("Monto debe ser mayor a cero")
    .test(
      "decimal-places",
      "Monto debe tener como máximo dos decimales",
      (value) =>
        value === undefined ||
        value === null ||
        /^\d+(\.\d{1,2})?$/.test(value.toString())
    ),
});

const SubmitOrder = ({ data, refetch }) => {
  const [paymentTypeAPI, setPaymentTypeAPI] = useState([]);
  const getData = async () => {
    const respPaymentTypeAPI = await getPaymentTypeAPI();
    const { paymentType } = respPaymentTypeAPI;
    try {
      let paymentTypeFormatted = paymentType.map((l) => ({
        _id: l._id,
        label: l.payment_type_name,
        value: l._id,
      }));
      setPaymentTypeAPI(paymentTypeFormatted);
    } catch (error) {
      setPaymentTypeAPI([]);
    }
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    dirty,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      date: null,
      method: null,
      number: "",
      amount: "",
    },
    onSubmit: async (values) => {
      const resp = await postOrder({
        ...values,
        liquidProduct_id: data?.lp_id?._id,
        id_tomador: data?.lp_id?.id_tomador,
        id_dador: data?.lp_id?.id_dador,
      });
      const { msg } = resp;
      if (msg) {
        errorToast(msg || "Intente Nuevamente");
        resetForm();
      } else {
        successToast(`OP cargado con exito`);
        resetForm();
      }
      refetch();
    },
    validationSchema: OrderSchema,
  });
  useEffect(() => {
    getData();
    return () => resetForm();
  }, []);
  return (
    <Container fluid style={{ maxWidth: 600 }}>
      <Row>
        <Col>
          <h1 className="h5 mt-3 mb-4 text-primary text-center font-weight-bold">
            {"LP nro.  " + data?.lp_id?.number}
          </h1>
        </Col>
      </Row>

      <Form.Group>
        <Row className="mb-3">
          <Col xs={3}>
            <label className="black-primary font-weight-bold mb-0 mt-2">
              Pago:
            </label>
          </Col>
          <Col xs={9}>
            <LogSelect
              name="method"
              placeholder="Forma de pago"
              items={paymentTypeAPI}
              onChange={(v) => {
                setFieldValue("method", v);
              }}
              onBlur={handleBlur.method}
              errors={errors}
              value={values.method}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={3}>
            <label className="black-primary font-weight-bold mb-0 mt-2">
              Fecha:
            </label>
          </Col>
          <Col xs={9}>
            <LogDateInput
              size="md"
              name="date"
              className="number-font p-0"
              placeholder="Fecha de OP"
              onBlur={handleBlur}
              errors={errors}
              value={values.date}
              onChange={(value) => {
                setFieldValue("date", value);
              }}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={3}>
            <label className="black-primary font-weight-bold mb-0 mt-2">
              {"Numero:"}
            </label>{" "}
          </Col>
          <Col xs={9}>
            <LogInput
              name="number"
              className="p-0"
              placeholder="Numero de OP"
              onBlur={handleBlur.number}
              type="text"
              errors={errors}
              value={values.number}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={3}>
            <label className="black-primary font-weight-bold mb-0 mt-2">
              Monto:
            </label>
          </Col>
          <Col xs={9}>
            <LogInputCurrency
              className="number-font p-1"
              placeholder="AR$"
              type="text"
              name="amount"
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={"$ "}
              onValueChange={(values) => {
                const { value } = values;
                const number = value ? parseFloat(value) : "";
                setFieldValue("amount", number);
              }}
              onBlur={handleBlur}
              errors={errors}
              value={values.amount}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="d-flex justify-content-center">
            <LogButton
              disabled={!dirty || isSubmitting}
              className="w-50"
              color="primary"
              onClick={handleSubmit}
              title={isSubmitting ? "Cargando..." : "Cargar OP"}
              border="curve"
            />
          </Col>
        </Row>
      </Form.Group>
    </Container>
  );
};

export default SubmitOrder;
