import React from "react";
import "../../styles/components/atoms/LogSelect.scss";
import PropTypes from "prop-types";
import { SelectPicker } from "rsuite";
import styled from "styled-components";

const SelectPickerStyled = styled(SelectPicker)`
  background-color: white;
  border: none;
  padding: 1px 1px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px #cccccc;
  height: 40px;
  display: flex;
  align-items: center;
`;

const DivItem = styled.div`
  display: flex;
  align-items: center;
`

const LogSelectLocation = ({
  items,
  className,
  errors,
  name,
  searchable = false,
  footer = "",
  iconName = 'icon-ping',
  ...rest
}) => {
  return (
    <>
      <SelectPickerStyled
        className={className + " mt-1"}
        cleanable={false}
        name={name}
        {...rest}
        data={items}
        searchable={searchable}
        block
        renderExtraFooter={() => footer}
        renderMenuItem={(label, item) => {
          return (
            <DivItem>
              <div className="circle-icon-list">
                <span className={iconName}></span>
              </div>
              <strong>{label}</strong>&nbsp;{item.address}
            </DivItem>
          );
        }}
      />
      {errors[name] && (
        <div className="mb-2 mt-0">
          <small className="text-danger">{errors[name]}.</small>
        </div>
      )}
    </>
  );
};

LogSelectLocation.propTypes = {
  items: PropTypes.array.isRequired,
};

export default LogSelectLocation;
