import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { delTripChild, getDetailsTripTc } from "../../../../../api/modules";
import { ModalPendingTravel } from "./ModalPendingTravel";
import { ModalPendingUnits } from "./ModalPendingUnits";
import CardItemTravelPending from "../../../components/molecules/CardItemTravelPending";
import CardItemOriginDestiny from "../../../components/molecules/CardItemOriginDestiny";
import { useDispatch, useSelector } from "react-redux";
import { deleteNotification } from "../../../../../redux/actions/app";
import TravelDetailModal from "../../MyTravelsToWork/TravelDetailModal";
import { errorToast, successToast } from "../../../../../services/toastService";
import ModalQuestion from "../../../../../components/organisms/modal-question/ModalQuestion";

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const ContainerList = styled.div`
  overflow-y: overlay;
  padding-right: 10px;
`;

const PendingsDetails = ({ id }) => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showModalUnits, setShowModalUnits] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [tripDetail, setTripDetail] = useState(null);
  const [tripDetails, setTripDetails] = useState(null);
  const [tripData, setTripData] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const { user } = useSelector((state) => state.authApp);
  const dispatch = useDispatch();
  const [detailModal, setDetailModal] = useState(false);

  useEffect(() => {
    fetchData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const onRefetch = () => setRefetch((prev) => !prev);

  const openCloseModal = (childTrip) => {
    setTripDetails(childTrip);
    setShowModal((prev) => !prev);
  };

  const openCloseModalUnits = (childTrip) => {
    setTripDetails(childTrip);
    setShowModalUnits((prev) => !prev);
  };

  const openDeleteModal = (childTrip) => {
    setTripDetails(childTrip);
    setDeleteModal((prev) => !prev);
  };

  const fetchData = async (id) => {
    const { trip } = await getDetailsTripTc(id);
    if (trip) {
      setTripData(trip);
      setTripDetail(trip?.tripdetails);
    } else {
      errorToast("El viaje dejo de estar pendiente");
      history.push(`/app/my-travels-tc?state=2`);
    }
    dispatch(
      deleteNotification({
        id_trip: id,
        id_owner: user._id,
        type: "Aceptada",
      })
    );
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <CardItemOriginDestiny
              viewDetail={() => setDetailModal(true)}
              originName={
                tripData?.origin_site.locality.name +
                ", " +
                tripData?.origin_site.locality.id_province?.name
              }
              destinyName={
                tripData?.destiny_site.locality.name +
                ", " +
                tripData?.destiny_site.locality.id_province?.name
              }
              deliveryDate={tripData?.delivery_date}
              withdrawDate={tripData?.withdraw_date}
              /* onClickDetail={handleContactShow} */
              destiny={tripData?.destiny}
              origin={tripData?.origin}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12"></Col>
        </Row>
        <Row>
          <Col md="12">
            <ContainerList>
              {tripDetail?.length ? (
                tripDetail?.map((item, index) =>
                  item.state === 2 ? (
                    <CardItemTravelPending
                      key={index}
                      openCloseModal={openCloseModal}
                      openCloseModalUnits={openCloseModalUnits}
                      deleteTripdetail={openDeleteModal}
                      item={item}
                      isDc={false}
                      originName={
                        tripData?.origin_site.locality.name +
                        ", " +
                        tripData?.origin_site.locality.id_province?.name
                      }
                      destinyName={
                        tripData?.destiny_site.locality.name +
                        ", " +
                        tripData?.destiny_site.locality.id_province?.name
                      }
                      withdrawDate={tripData?.withdraw_date}
                      deliveryDate={tripData?.delivery_date}
                      tripPrice={
                        tripData?.price_type === "2"
                          ? tripData.price_per_truck
                          : null
                      }
                      chargeType={tripData?.product.product_type_name}
                    ></CardItemTravelPending>
                  ) : null
                )
              ) : (
                <h2 className="font-weight-bold h3 text-center">
                  Aún no tenés ningún viaje pendiente
                </h2>
              )}
            </ContainerList>
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12"></Col>
      </RowStyled>
      <ModalPendingTravel
        show={showModal}
        openCloseModal={openCloseModal}
        onRefetch={onRefetch}
        trip={tripDetails}
      ></ModalPendingTravel>
      <ModalPendingUnits
        show={showModalUnits}
        openCloseModal={openCloseModalUnits}
        onRefetch={onRefetch}
        trip={tripDetails}
      ></ModalPendingUnits>
      {detailModal ? (
        <TravelDetailModal
          isShow={detailModal}
          handleClose={() => setDetailModal(false)}
          travelData={tripData}
        />
      ) : null}
      {deleteModal ? (
        <ModalQuestion
          title="¿Estás seguro de esta accion?"
          subTitle={`Eliminar viaje hijo ${
            "ID: " +
            (tripDetails?.parent_id || "n/d") +
            (tripDetails?.order || "")
          }`}
          isShow={deleteModal}
          handleClose={async (payload) => {
            if (payload) {
              const response = await delTripChild(tripDetails._id);
              const { msg } = response;
              if (!msg) {
                successToast("Viaje Hijo eliminado correctamente");
              } else {
                errorToast(msg);
              }
              onRefetch();
            }
            openDeleteModal();
          }}
        />
      ) : null}
    </div>
  );
};

export default PendingsDetails;
