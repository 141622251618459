import { ADMIN } from "../../../constants/UserType";
import {
  ERASE_ROOM,
  GET_ROOM_CHAT,
  LOGOUT_CHAT,
  MARK_AS_READ,
  NEW_MSG_ROOM,
  PUSH_LIST_ADMIN,
  PUSH_MSG_USER,
  PUSH_READMSG,
  PUSH_READMSG_CLIENT,
  SEND_ROOM_MESSAGE,
  UPDATE_LIST_ADMIN,
  UPDATE_ONLINE,
  MOUNTED_ROOM,
  MARK_AS_READ_ROOM,
  SEND_CHAT_APP,
  GET_ROOM_APP_CHAT,
  NEW_APP_ROOM,
  MOUNTED_APP_CHAT,
  ERASE_ROOM_CHAT,
  MARK_AS_READ_CHAT_APP,
} from "../../actionTypes";

const initialState = {
  listUsers: [],
  onLineClients: [],
  unReadMsgRoom: [],
  privateMsg: { unReadMsg: [], readMsg: [] },
  chatRoom: [],
  appChat: [],
  idMoundtedRoom: "",
  idMoundtedAppChat: "",
  unReadChatApp: [],
};

export const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LIST_ADMIN: {
      const { listUsers, privateMsg, unReadMsgRoom, unReadChatApp } = action.payload;
      return {
        ...state,
        listUsers: [/* ...state.listUsers, */ ...listUsers],
        unReadMsgRoom,
        unReadChatApp,
        privateMsg: {
          ...state.privateMsg,
          ...privateMsg,
        },
      };
    }

    case LOGOUT_CHAT:
      return initialState;

    case PUSH_READMSG: {
      let position = -1;
      state.listUsers.map(
        ({ attended_user, _id, unReadMsg, oldMsg }, index) => {
          if (
            _id ===
            (action.payload.id_driver?._id || action.payload.attended_user._id)
          ) {
            position = index;
          }
        }
      );
      if (position === -1) {
        return {
          ...state,
        };
      } else {
        const newMsg = { ...state.listUsers[position] };
        state.listUsers.splice(position, 1);
        newMsg.readMsg = [
          ...newMsg.readMsg,
          {
            msg: action.payload.msg,
            document: action.payload.document,
            creator_role: action.payload.creator_role,
            id_creator: action.payload.id_creator,
            createdAt: action.payload.createdAt,
            id_driver: action.payload.id_driver || null,
          },
        ];
        return {
          ...state,
          listUsers: [newMsg, ...state.listUsers],
        };
      }
    }

    case GET_ROOM_CHAT: {
      return {
        ...state,
        chatRoom: action.payload.roomMsg,
      };
    }
    case GET_ROOM_APP_CHAT: {
      return {
        ...state,
        appChat: action.payload.roomMsg,
      };
    }

    case MARK_AS_READ_ROOM: {
      const aux = state.unReadMsgRoom.filter(
        (ele) => ele?.room._id !== action.payload?.room
      );
      return {
        ...state,
        unReadMsgRoom: aux,
      };
    }

    case MARK_AS_READ_CHAT_APP: {
      const aux = state.unReadChatApp.filter(
        (ele) => ele?.room._id !== action.payload?.room
      );
      return {
        ...state,
        unReadChatApp: aux,
      };
    }

    case SEND_ROOM_MESSAGE: {
      return {
        ...state,
        chatRoom: [...state.chatRoom, action.payload.msg],
      };
    }

    case SEND_CHAT_APP: {
      return {
        ...state,
        appChat: [...state.appChat, action.payload.msg],
      };
    }

    case UPDATE_ONLINE: {
      return {
        ...state,
        onLineClients: action.payload,
      };
    }

    case PUSH_READMSG_CLIENT: {
      return {
        ...state,
        privateMsg: {
          ...state.privateMsg,
          readMsg: [
            ...state.privateMsg?.readMsg,
            {
              msg: action.payload.msg,
              document: action.payload.document,
              creator_role: action.payload.creator_role,
              id_creator: action.payload.id_creator,
              createdAt: action.payload.createdAt,
            },
          ],
        },
      };
    }

    case PUSH_LIST_ADMIN: {
      let position = -1;
      state.listUsers.map(
        ({ attended_user, _id, unReadMsg, oldMsg }, index) => {
          if (
            _id ===
            (action.payload.id_driver?._id || action.payload.attended_user._id)
          ) {
            position = index;
          }
        }
      );
      if (position === -1) {
        return {
          ...state,
          listUsers: [
            {
              _id: action.payload.msg.attended_user,
              unReadMsg: [action.payload],
              readMsg: [],
              attended_user:
                action.payload.id_driver || action.payload.attended_user,
            },
            ...state.listUsers,
          ],
        };
      } else {
        const newMsg = { ...state.listUsers[position] };
        state.listUsers.splice(position, 1);
        newMsg.unReadMsg = [
          ...newMsg.unReadMsg,
          {
            msg: action.payload.msg,
            document: action.payload.document,
            creator_role: action.payload.creator_role,
            id_creator: action.payload.id_driver
              ? null
              : action.payload.id_creator,
            createdAt: action.payload.createdAt,
            id_driver: action.payload.id_driver || null,
          },
        ];
        return {
          ...state,
          listUsers: [newMsg, ...state.listUsers],
        };
      }
    }
    case PUSH_MSG_USER: {
      return {
        ...state,
        privateMsg: {
          ...state.privateMsg,
          unReadMsg: [
            ...state.privateMsg.unReadMsg,
            {
              msg: action.payload.msg,
              document: action.payload.document,
              creator_role: action.payload.creator_role,
              id_creator: action.payload.id_creator,
              createdAt: action.payload.createdAt,
            },
          ],
        },
      };
    }

    case MARK_AS_READ: {
      if (action.payload.user === ADMIN) {
        let position = -1;
        state.listUsers.map(
          ({ attended_user, _id, unReadMsg, oldMsg }, index) => {
            if (_id === action.payload.attended_user) {
              position = index;
            }
          }
        );
        if (position === -1) {
          return {
            ...state,
          };
        } else {
          const newMsg = { ...state.listUsers[position] };
          const aux = [...state.listUsers];
          newMsg.readMsg = [...newMsg.readMsg, ...newMsg.unReadMsg];
          newMsg.unReadMsg = [];
          aux[position] = newMsg;

          return {
            ...state,
            listUsers: aux,
          };
        }
      } else {
        return {
          ...state,
          privateMsg: {
            ...state.privateMsg,
            unReadMsg: [],
            readMsg: [
              ...state.privateMsg?.readMsg,
              ...state.privateMsg?.unReadMsg,
            ],
          },
        };
      }
    }

    case NEW_MSG_ROOM: {
      if (state.idMoundtedRoom === action.payload?.msg?.room?._id) {
        return {
          ...state,
          chatRoom: [...state.chatRoom, action.payload.msg],
        };
      } else {
        if (action.payload?.role !== ADMIN) {
          const position = state.unReadMsgRoom.findIndex(
            (ele) => ele._id === action.payload?.msg?.room?._id
          );
          if (position === -1) {
            return {
              ...state,
              unReadMsgRoom: [
                {
                  _id: action.payload?.msg?.room?._id,
                  unReadMsg: [action.payload?.msg],
                  room: action.payload?.msg?.room,
                  size: 1,
                },
                ...state.unReadMsgRoom,
              ],
            };
          } else {
            const newMsg = { ...state.unReadMsgRoom[position] };
            const aux = [...state.unReadMsgRoom];
            newMsg.unReadMsg = [...newMsg.unReadMsg, action.payload?.msg];
            newMsg.size = ++newMsg.size;
            aux[position] = newMsg;
            return {
              ...state,
              unReadMsgRoom: aux,
            };
          }
        }
      }
    }

    case NEW_APP_ROOM: {
      if (state.idMoundtedAppChat === action.payload?.msg?.room?._id) {
        return {
          ...state,
          appChat: [...state.appChat, action.payload.msg],
        };
      } else {
        if (action.payload?.role !== ADMIN) {
          const position = state.unReadChatApp.findIndex(
            (ele) => ele._id === action.payload?.msg?.room?._id
          );
          if (position === -1) {
            return {
              ...state,
              unReadChatApp: [
                {
                  _id: action.payload?.msg?.room?._id,
                  unReadMsg: [action.payload?.msg],
                  room: action.payload?.msg?.room,
                  size: 1,
                },
                ...state.unReadChatApp,
              ],
            };
          } else {
            const newMsg = { ...state.unReadChatApp[position] };
            const aux = [...state.unReadChatApp];
            newMsg.unReadMsg = [...newMsg.unReadMsg, action.payload?.msg];
            newMsg.size = ++newMsg.size;
            aux[position] = newMsg;
            return {
              ...state,
              unReadChatApp: aux,
            };
          }
        }
      }
    }

    case ERASE_ROOM: {
      return {
        ...state,
        chatRoom: [],
        idMoundtedRoom: "",
      };
    }

    case ERASE_ROOM_CHAT: {
      return {
        ...state,
        appChat: [],
        idMoundtedAppChat: "",
      };
    }
    case MOUNTED_ROOM: {
      return {
        ...state,
        idMoundtedRoom: action.payload.room,
        chatRoom: [],
      };
    }

    case MOUNTED_APP_CHAT: {
      return {
        ...state,
        idMoundtedAppChat: action.payload.room,
        chatRoom: [],
      };
    }

    default:
      return { ...state };
  }
};
