import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getStatistics } from "../../../../../api/modules";
import { CardLogBootstrapStyled } from "../../../../../styledComponents/CardBootstrapLog";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS2, Tooltip, Legend } from "chart.js";

ChartJS2.register(Tooltip, Legend, ChartDataLabels);

const CardLogBootstrapStyledHeight = styled(CardLogBootstrapStyled)`
  height: 170px;
  justify-content: space-between;
`;
const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const titles = {
  childsDeleted: "Viaje Eliminados (hijos)",
  childsEjecution: "Viajes Ejecucion (hijos)",
  childsFinished: "Viajes Finalizados (hijos)",
  childsLpv: "Listos para viajar (hijos)",
  childsPending: "Viajes Pendientes (hijos)",
  dadores: "Dadores",
  postulationDeleted: "Postulaciones Eliminadas",
  tomadores: "Tomadores",
  totalAvailablePostulation: "Postulaciones",
  totalAvailableTrips: "Viajes totales (padres)",
  totalAvailableTrucks: "Unidades totales",
  totalAvailableUsers: 36,
  totalDrivers: "Choferes",
};

const DivDashBoardParent = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;
const DivDashBoardBody = styled.div`
  max-width: 80%;
  width: 90%;
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const ChartContainer = styled.div`
  height: 100%;
  padding-top: 1rem;
  padding-bottom: 1 rem;
`;

const AdminDashBoard = (props) => {
  const spinner = useSelector((state) => state.appReducer.isLoading);
  const { name } = useSelector((state) => state.authApp.user);
  const [statistics, setStatics] = useState({});

  useEffect(() => {
    getFetch();
  }, []);

  const getFetch = async () => {
    const response = await getStatistics();
    // const { totalAvailablePostulation,
    //   totalAvailableTrips,
    //   totalAvailableTrucks,
    //   totalAvailableUsers } = response;
    setStatics({ ...response });
  };
  return (
    <DivDashBoardParent className="p-4">
      <DivDashBoardBody>
        <Row className="mt-5">
          <Col>
            <h1 className="text-primary h3 font-weight-bold">
              {" "}
              ¡Bienvenido {name || ""}!
            </h1>{" "}
            <h4 className="h4 font-weight-bold">
              {" "}
              ¿Listo para comenzar con BuenaRuta?
            </h4>{" "}
          </Col>
        </Row>
        {Object.keys(statistics).length > 0 ? (
          <>
            <Row className="mt-4">
              {Object.keys(statistics).map((data, index) => (
                <Col md="3" key={index}>
                  <CardLogBootstrapStyledHeight className="shadow-6dp p-3 mb-4">
                    <Row>
                      <Col className="d-flex align-items-center">
                        <p className="text-font-info mb-0">{titles[data]}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h2 className="display-4 font-weight-bold">
                          {statistics[data] || 0}
                        </h2>
                      </Col>
                    </Row>
                  </CardLogBootstrapStyledHeight>
                </Col>
              ))}
            </Row>
            <Row className="mt-4">
              <Col md="12">
                <CardLogBootstrapStyled className="shadow-6dp p-4 mb-4">
                <h2 className="display-5 font-weight-bold">Viajes por estado</h2>
                  <ChartContainer>
                    <Pie
                      datasetIdKey="product"
                      data={{
                        labels: [
                          "Finalizados",
                          "Ejecucion",
                          "LPV",
                          "Pendientes",
                          "Eliminados",
                        ],
                        datasets: [
                          {
                            label: "Viajes",
                            data: statistics && [
                              statistics["childsFinished"],
                              statistics["childsEjecution"],
                              statistics["childsLpv"],
                              statistics["childsPending"],
                              statistics["childsDeleted"],
                            ],
                            backgroundColor: [
                              "rgba(255, 99, 132, 0.5)",
                              "rgba(54, 162, 235, 0.5)",
                              "rgba(255, 206, 86, 0.5)",
                              "rgba(75, 192, 192, 0.5)",
                              "rgba(153, 102, 255, 0.5)",
                            ],
                            borderColor: [
                              "rgba(255, 99, 132, 0.5)",
                              "rgba(54, 162, 235, 0.5)",
                              "rgba(255, 206, 86, 0.5)",
                              "rgba(75, 192, 192, 0.5)",
                              "rgba(153, 102, 255, 0.5)",
                            ],
                            borderWidth: 1,
                          },
                        ],
                      }}
                      options={{
                        layout: {
                          padding: 20,
                        },

                        responsive: true,
                        plugins: {
                          datalabels: {
                            formatter: function (value, context) {
                              if (value === 0) return "";
                              return (
                                context.chart.data.labels[context.dataIndex] +
                                "\n" +
                                (
                                  (value /
                                    context.chart.getDatasetMeta(0).total) *
                                  100
                                ).toFixed(2) +
                                "%"
                              );
                            },
                            color: "gray",
                            font: {
                              size: 14,
                              weight: "bold",
                            },
                          },
                          legend: {
                            position: "top",
                            labels: {
                              font: {
                                size: 16,
                                weight: "bold",
                              },
                            },
                          },
                        },
                      }}
                    />
                  </ChartContainer>
                </CardLogBootstrapStyled>
              </Col>
            </Row>
          </>
        ) : !spinner ? (
          <DivEmpty>
            <h2 className="font-weight-bold h3 text-center">
              Datos No disponibles
            </h2>
          </DivEmpty>
        ) : (
          <DivEmpty></DivEmpty>
        )}
      </DivDashBoardBody>
    </DivDashBoardParent>
  );
};

export default AdminDashBoard;
