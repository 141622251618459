import React from "react";

// Pages
import Home from "../../pages/Home/Home";
import SignIn from "../../pages/Auth/SignIn";
import WhichUser from "../../pages/Auth/WhichUser";
import SignUp from "../../pages/Auth/SignUp/SignUp";
import RecoverPassword from "../../pages/Auth/RecoverPassword/RecoverPassword";

export const AuthRouting = [
  { path: "/", key: "ROOT", exact: true, component: () => <Home /> },
  {
    path: "/sign-in",
    key: "SIGN_IN",
    exact: true,
    component: () => <SignIn />,
  },
  {
    path: "/recover-password",
    key: "RECOVER_PASSWORD",
    exact: true,
    component: () => <RecoverPassword />,
  },
  {
    path: "/which-user",
    key: "SWITCH_USER",
    exact: true,
    component: () => <WhichUser />,
  },
  {
    path: "/register",
    key: "REGISTER_USER",
    exact: true,
    component: () => <SignUp />,
  },
];
