import { apiHttp } from "../axiosApi.js";
import { charge, product, truckAccesory, paymentType } from "../config/apiRoute.js";

export const getChargeAPI = () =>
  apiHttp("GET", `${charge}`);

export const getProductsAPI = () =>
  apiHttp("GET", `${product}`);

export const getTruckAccesoryAPI = () =>
  apiHttp("GET", `${truckAccesory}`);

export const getPaymentTypeAPI = () =>
  apiHttp("GET", `${paymentType}`);
