import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  Badge,
  Card,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  Button,
} from "react-bootstrap";
import { CardLogBootstrapStyled } from "../../../../styledComponents/CardBootstrapLog";
import { AiOutlineArrowRight } from "react-icons/ai";
import { BsEye } from "react-icons/bs";
import { COLOR_PRIMARY, COLOR_RED } from "../../../../constants/Colors";
import { useHistory } from "react-router-dom";
import { errorToast, successToast } from "../../../../services/toastService";
import { executeTrip } from "../../../../api/modules/trip";
import LogTextNumber from "../../../../components/atoms/LogTextNumber";
import moment from "moment";
import { useSelector } from "react-redux";

const CardStyled = styled(Card)`
  border-radius: 15px;
  cursor: pointer;
  padding: 10px 20px;
  &:hover {
    opacity: 0.8;
  }
`;

const BadgeLog = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  display: inline-block;
  padding: 0px 20px;
`;

const BadgeLogRed = styled(Badge)`
  background-color: ${COLOR_RED};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  display: inline-block;
  padding: 0px 20px;
`;

const DivSegments = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
`;

const BtnInvisible = styled(Button)`
  background: none;
  border: none;
  &:hover {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
  &:active {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
  &:focus {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
`;

const CardItemOriginDestiny = ({
  id_trip,
  originName,
  destinyName,
  withdrawDate,
  deliveryDate,
  onClick,
  destiny,
  origin,
  className,
  viewDetail,
}) => {
  const history = useHistory();
  const { _id } = useSelector((state) => state.authApp.user);
  useEffect(() => {}, []);

  return (
    <CardStyled
      className={`${className} shadow-4dp m-2 text-capitalize `}
      onClick={onClick}
    >
      <Card.Body style={{ padding: "0px" }}>
        <Row>
          <Col md="5" sm="12">
            <Row className="">
              <Col md="2">
                <div className="circle-icon">
                  <span className="icon-ping text-primary"></span>
                </div>
              </Col>
              <Col md="10">
                <p className="m-0">
                  <strong style={{ color: "#92c31a", fontSize: "20px" }}>
                    Origen
                  </strong>
                </p>
                <p className="m-0">
                  <strong>Dirección: </strong> {originName?.toLowerCase()}
                </p>
                <p className="m-0">
                  <strong>Tipo de origen: </strong>{" "}
                  {origin?.charAt(0).toUpperCase() +
                    origin?.slice(1)?.toLowerCase()}
                </p>
                <p className="m-0">
                  <strong>Fecha: </strong>
                  <LogTextNumber
                    text={`${moment(withdrawDate).format("DD/MM/YYYY")}`}
                    style={{
                      color: "#212529",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                    km
                  />
                </p>
              </Col>
            </Row>
          </Col>
          <Col md="5" sm="12">
            <Row className="">
              <Col md="2">
                <div className="circle-icon">
                  <span className="icon-ping text-primary"></span>
                </div>
              </Col>
              <Col md="10">
                <p className="m-0">
                  <strong style={{ color: "#92c31a", fontSize: "20px" }}>
                    Destino
                  </strong>
                </p>
                <p className="m-0">
                  <strong>Dirección: </strong> {destinyName?.toLowerCase()}
                </p>
                <p className="m-0 text-capitalize">
                  <strong>Tipo de destino: </strong>{" "}
                  {destiny?.charAt(0).toUpperCase() +
                    destiny?.slice(1)?.toLowerCase()}
                </p>

                <p className="m-0">
                  <strong>Fecha: </strong>
                  <LogTextNumber
                    text={`${moment(deliveryDate).format("DD/MM/YYYY")}`}
                    style={{
                      color: "#212529",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                    km
                  />
                </p>
              </Col>
            </Row>
          </Col>
          <Col md="2" sm="12" className="d-flex justify-content-end">
            {viewDetail ? (
              <button
                style={{ color: "purple", background: "#f2f2f500" }}
                onClick={viewDetail}
              >
                Ver detalle
              </button>
            ) : (
              <button style={{ color: "purple", background: "#f2f2f500" }}>
                ver detalle
              </button>
            )}
          </Col>
        </Row>
      </Card.Body>
    </CardStyled>
  );
};

export default CardItemOriginDestiny;
