import { Formik } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import LogButton from "../../../../components/atoms/LogButton";
import LogInput from "../../../../components/atoms/LogInput";
import * as Yup from "yup";

const SecondStep = ({ handleSubmitChangePassword }) => {
  const ChangePasswordSchema = Yup.object({
    password: Yup.string().required("Nueva contraseña obligatorio"),
    confirmPass: Yup.string()
      .oneOf([Yup.ref("password"), null], "Contraseña debe ser el mismo")
      .required("Confirmar contraseña obligado"),
  });

  return (
    <>
      <Formik
        initialValues={{
          password: "",
          confirmPass: "",
        }}
        validationSchema={ChangePasswordSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmitChangePassword(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Row>
            <Col md="12">
              <h1 className="h2 mb-0 font-weight-bold">Cambiar contraseña</h1>
              <small className="text-info text-secondary font-weight-bold">
                Escriba su nueva contraseña
              </small>
            </Col>
            <Col md="12" className="mt-4">
              <LogInput
                placeholder="Nueva contraseña"
                className="mb-2"
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors}
                value={values.password}
              />
              <LogInput
                placeholder="Repite contraseña"
                className="mb-2"
                type="password"
                name="confirmPass"
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors}
                value={values.confirmPass}
              />
            </Col>
            <Col md="12">
              <LogButton
                type="submit"
                className="mt-3"
                title="Aceptar"
                border="curve"
                block
                onClick={handleSubmit}
              />
            </Col>
          </Row>
        )}
      </Formik>
    </>
  );
};

export default SecondStep;
