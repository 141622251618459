import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import PaginationList from "../../../../../components/organisms/pagination-list/PaginationList";
import CardItemTravelBasic from "../../../components/molecules/CardItemTravelBasic";
import {
  delTripAPI,
  getTripsCreatedByStateAPI,
} from "../../../../../api/modules";
import ModalQuestion from "../../../../../components/organisms/modal-question/ModalQuestion";
import { errorToast, successToast } from "../../../../../services/toastService";
import { useSelector } from "react-redux";

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;
const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const ContainerList = styled.div`
  overflow-y: overlay;
  padding-right: 10px;
`;

const PendingsTravels = ({ trips }) => {
  const spinner = useSelector((state) => state.appReducer.isLoading);
  const history = useHistory();
  const [page, setPage] = useState(0);
  const numberList = 5;
  const INITIAL_STATE = {
    total: 0,
    items: [],
  };
  const [tripsAPI, setTripsAPI] = useState(INITIAL_STATE);
  const [isPostulationModal, setPostulationModal] = useState({
    view: false,
    id: {},
  });

  useEffect(() => {
    fetchData(numberList, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async (limit = numberList, from = 0) => {
    const tripsResponse = await getTripsCreatedByStateAPI(
      numberList,
      from,
      "[2,3]"
    );
    const { total, trips } = tripsResponse;
    if (trips)
      setTripsAPI({
        ...INITIAL_STATE,
        total,
        items: trips,
      });
  };

  const handlePreviewPage = () => {
    fetchData(numberList, numberList * page - numberList);
    setPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    fetchData(numberList, numberList * (page + 1));
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <ContainerList>
              {tripsAPI?.items.length ? (
                tripsAPI.items.map((location, index) => {
                  return (
                    <CardItemTravelBasic
                      status={location.status}
                      showId={location.id_trip}
                      trucksNumber={location.trucks_number}
                      trucks_number_accepted={location.counterTripdetails}
                      originName={location.origin_site.site_name}
                      destinyName={location.destiny_site.site_name}
                      product_type_name={location.product.product_type_name}
                      withdrawDate={location.withdraw_date}
                      deliveryDate={location.delivery_date}
                      state="Pendientes"
                      drivers={location.drivers || null}
                      trucks={location.trucks}
                      idTrip={location._id}
                      key={index}
                      onClick={() =>
                        history.push(
                          `/app/list-travels/?state=1&tripId=${
                            location._id || "0"
                          }`
                        )
                      }
                      onDeleteTrip={(id) =>
                        setPostulationModal((prev) => {
                          return {
                            ...prev,
                            view: true,
                            id: id,
                          };
                        })
                      }
                    />
                  );
                })
              ) : !spinner ? (
                <h2 className="font-weight-bold h3 text-center">
                  Aún no tenés ningún viaje pendiente
                </h2>
              ) : (
                <DivEmpty></DivEmpty>
              )}
            </ContainerList>
            <ModalQuestion
              title="¿Estás seguro de esta accion?"
              subTitle={"Eliminar este viaje"}
              isShow={isPostulationModal.view}
              handleClose={async (payload) => {
                if (payload) {
                  const response = await delTripAPI(isPostulationModal.id);
                  const { trip, msg } = response;
                  if (trip) {
                    successToast(msg);
                    fetchData(numberList, 0);
                  } else {
                    errorToast(msg);
                  }
                }

                setPostulationModal((prev) => {
                  return {
                    ...prev,
                    view: false,
                    id: {},
                  };
                });
              }}
            />
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={tripsAPI.items}
            total={tripsAPI.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
      </RowStyled>
    </>
  );
};

export default PendingsTravels;
