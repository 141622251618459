/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Locations from "./views/Locations/Locations.js";
import UserProfile from "./views/UserProfile.js";
import { locations } from "./routes/locations";
import Travels from "./views/MyTravels/Travels.js";
import TravelsTC from "./views/MyTravelsTC/Travels";
import NewTravel from "./views/Travel/NewTravel.js";
import {
  ADMIN,
  DADOR_DE_CARGA,
  TOMADOR_DE_CARGA,
} from "../../constants/UserType";
import MyUnits from "./views/MyUnits/MyUnits.js";
import MyTravelsToWork from "./views/MyTravelsToWork/index.js";
import DadorDashBoard from "./views/Dashboard/Dador/index.js";
import TomadorDashBoard from "./views/Dashboard/Tomador/index.js";
import AdminDashBoard from "./views/Dashboard/Admin/index.js";
import Units from "./views/Units/Units.js";
import Users from "./views/Users/Users.js";
import DcUsers from "./views/DcUsers/DcUsers.js";
import TruckTypes from "./views/TruckTypes/TruckTypes.js";
import TruckCharacs from "./views/TruckCharac/TruckCharacs.js";
import MyDrivers from "./views/MyDrivers/MyDrivers.js";
import TravelsAll from "./views/Travels-All/TravelsAll.js";
import Drivers from "./views/DriversAdmin/Drivers.js";
import Claims from "./views/Claims/Claims.jsx";
import BillingAdmin from "./views/Billing/Admin/index.js";
import BillingDador from "./views/Billing/Users/BillingDador.js";
import BillingTomador from "./views/Billing/Users/BillingTomador.js";
import SendPush from "./views/PushNotifications/Index.jsx";
import NotFound from "./components/NotFound/NotFound.js";

const getRoutesInit = ({ name }) => {
  const routersByRole =
    name === DADOR_DE_CARGA
      ? [
          {
            path: "/",
            name: "Dashboard",
            icon: "icon-home",
            component: DadorDashBoard,
            layout: "/app",
          },
          {
            path: "/billing-dador",
            name: " Mis cuentas",
            icon: "icon-tranding",
            component: BillingDador,
            layout: "/app",
          },
          {
            path: "/travels",
            name: "Crear viaje",
            icon: "icon-plus-lg",
            component: NewTravel,
            layout: "/app",
          },
          {
            path: "/list-travels",
            name: "Mis viajes",
            icon: "icon-truck",
            component: Travels,
            layout: "/app",
          },
          {
            path: "/list-travels?state=2&modal=true",
            name: "Chat Chofer",
            icon: "icon-comments-o",
            component: Travels,
            layout: "/app",
          },
          {
            path: "/profile",
            name: "Perfil",
            icon: "icon-user",
            component: UserProfile,
            layout: "/app",
          },
          {
            path: "/locations",
            name: "Mis localizaciones",
            icon: "icon-ping",
            component: Locations,
            layout: "/app",
          },
        ]
      : name === TOMADOR_DE_CARGA
      ? [
          {
            path: "/",
            name: "Dashboard",
            icon: "icon-home",
            component: TomadorDashBoard,
            layout: "/app",
          },
          {
            path: "/billing-tomador",
            name: " Mis cuentas",
            icon: "icon-tranding",
            component: BillingTomador,
            layout: "/app",
          },
          {
            path: "/my-travls-to-work",
            name: "Buscar viajes",
            icon: "icon-magnify",
            component: MyTravelsToWork,
            layout: "/app",
          },
          {
            path: "/my-drivers",
            name: "Mis Choferes",
            icon: "icon-drivers-license-o",
            component: MyDrivers,
            layout: "/app",
          },
          {
            path: "/my-units",
            name: "Mis unidades",
            icon: "icon-truck",
            component: MyUnits,
            layout: "/app",
          },
          {
            path: "/my-travels-tc",
            name: "Mis viajes",
            icon: "icon-ping",
            component: TravelsTC,
            layout: "/app",
          },
          {
            path: "/profile",
            name: "Perfil",
            icon: "icon-user",
            component: UserProfile,
            layout: "/app",
          },
        ]
      : name === ADMIN
      ? [
          // Admin
          {
            path: "/",
            name: "Dashboard",
            icon: "icon-home",
            component: AdminDashBoard,
            layout: "/app",
          },
          {
            path: "/invoiceAdmin",
            name: " Mis cuentas",
            icon: "icon-tranding",
            component: BillingAdmin,
            layout: "/app",
          },
          {
            path: "/units",
            name: "Unidades",
            icon: "icon-truck",
            component: Units,
            layout: "/app",
          },
          {
            path: "/travels-all",
            name: "Viajes",
            icon: "icon-ping",
            component: TravelsAll,
            layout: "/app",
          },
          {
            path: "/users",
            name: "Usuarios",
            icon: "icon-users",
            component: Users,
            layout: "/app",
          },
          {
            path: "/drivers",
            name: "Choferes",
            icon: "icon-drivers-license-o",
            component: Drivers,
            layout: "/app",
          },
          {
            path: "/profile",
            name: "Perfil",
            icon: "icon-user",
            component: UserProfile,
            layout: "/app",
          },
          {
            path: "/truck-types",
            name: "Tipos de camión",
            icon: "icon-truck",
            component: TruckTypes,
            layout: "/app",
          },
          {
            path: "/truck-charac",
            name: "Características",
            icon: "icon-truck",
            component: TruckCharacs,
            layout: "/app",
          },
          {
            path: "/claims",
            name: "Reclamos",
            icon: "icon-comments-o",
            component: Claims,
            layout: "/app",
          },
          {
            path: "/send-push",
            name: "Notificaciones",
            icon: "icon-bell",
            component: SendPush,
            layout: "/app",
          },
        ]
      : [];

  const notFounded = [
    {
      path: "*",
      name: "NotFound",
      component: NotFound,
      layout: "/app",
      redirect: true,
    },
  ];

  return name
    ? [
        // sub routes
        ...locations({ name }),
        // sidebar routing
        ...routersByRole,
        ...notFounded,
      ]
    : [];
};

export default getRoutesInit;
