import React from "react";
import { Col, Form, Modal, Row, Stack } from "react-bootstrap";
import styled from "styled-components";
import { FiAlertCircle, FiX } from "react-icons/fi";

import { COLOR_GRAY_TEXT } from "../../../../../../../constants/Colors";
import LogInput from "../../../../../../../components/atoms/LogInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  patchShippingNote,
  patchTripDetails,
} from "../../../../../../../api/modules";
import {
  errorToast,
  successToast,
} from "../../../../../../../services/toastService";
import LogButton from "../../../../../../../components/atoms/LogButton";

const ModalLogStyled = styled(Modal)`
  opacity: 1;
  .modal-content {
    border-radius: 15px !important;
    padding: 20px;
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;

const ProfileSchema = Yup.object().shape({
  shipping_note: Yup.string().required("Remito Auditado es requerido"),
});

const DocumentContainer = styled(Row)`
  max-height: 200px;
  overflow: auto;
`;

const ContentModal = styled.div`
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #000;
  &:hover {
    color: #ff0000;
  }
`;

const ModalShippingNote = ({ isShow, handleClose, data, refetch }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    dirty,
    resetForm,
  } = useFormik({
    initialValues: {
      shipping_note: data?.shipping_note ? data?.shipping_note : "",
    },
    onSubmit: async (values) => {
      const { shipping_note } = values;
      const resp = await patchShippingNote(data._id, { shipping_note });
      const { msg } = resp;
      if (msg) {
        errorToast(msg || "Intente nuevamente");
        resetForm();
      } else {
        successToast(`Remito auditado con éxito`);
        handleClose();
        refetch();
        resetForm();
      }
    },
    validationSchema: ProfileSchema,
  });
  return (
    <ModalLogStyled
      show={isShow}
      onHide={() => {
        resetForm();
        handleClose();
      }}
      centered
      className="modal-exit"
    >
      <Modal.Header>
        <CloseButton
          onClick={() => {
            resetForm();
            handleClose();
          }}
        >
          <FiX />
        </CloseButton>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Row>
          <Col className="text-left">
            <h4 className="font-weight-bold text-primary h5">
              {"ID Viaje " +
                (data.parent_id && data.order
                  ? data.parent_id + data.order
                  : "No posee")}
            </h4>

            <hr />
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <label className="black-primary font-weight-bold">
                {"Modificar numero de remito"}
              </label>
              <LogInput
                placeholder="Remito"
                className="mt-1"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.shipping_note}
                errors={errors}
                name="shipping_note"
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="footer-modal">
        <Col className="d-flex justify-content-center">
          <LogButton
            className="w-50"
            color="primary"
            onClick={handleSubmit}
            disabled={!dirty}
            title={"Editar"}
            border="curve"
          />
        </Col>
      </Modal.Footer>
    </ModalLogStyled>
  );
};

export default ModalShippingNote;
