import React, { useEffect, useState } from "react";

// react-bootstrap components
import { Container, Row, Col, Badge, Card, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import LocationItem from "../../components/molecules/LocationItem";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { getTUnitsAPI } from "../../../../api/modules";
import PaginationList from "../../../../components/organisms/pagination-list/PaginationList";
import {
  COLOR_GRAY_TEXT,
  COLOR_PRIMARY,
  COLOR_PRIMARY_DARK,
} from "../../../../constants/Colors";
import ContentHeader from "../../components/molecules/ContentHeader";
import SearchComponent from "./SearchComponent";
import {
  deleteLogicDriver,
  editDriver,
  getAllDrivers,
} from "../../../../api/modules/driver";
import { GoArrowDown, GoTriangleDown, GoTriangleRight } from "react-icons/go";
import LogButton from "../../../../components/atoms/LogButton";
import { successToast } from "../../../../services/toastService";
import LogDatePicker from "../../../../components/atoms/LogDatePicker";

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const CardStyled = styled(Card)`
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const ContainerList = styled.div`
  overflow-y: overlay;
  padding-right: 10px;
`;

const ImageCard = styled.img`
  object-position: center;
  object-fit: contain;
  height: 100%;
  width: 100%;
`;

const ImageContainer = styled.div`
  height: 200px;
  width: 100%;
  align-content: center;
  text-align: center;
  border-width: 1px;
  border-radius: 20px;
  border-color: #ededed;
  border-style: dashed;
`;

const LicenseDivider = styled.hr`
  background-color: ${COLOR_PRIMARY};
  height: 1px;
  width: 90%;
  opacity: 0.3;
  margin: "auto";
`;

const BadgeLog = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  display: inline-block;
  padding: 0px 20px;
`;

const linkContainer = styled.a`
  width: 100% !important;
  background-color: green;
`;

const Divider = styled.hr`
  background-color: ${COLOR_PRIMARY};
  height: 1px;
`;

const getAge = (date_of_birth) => {
  const today = new Date();
  const dateOfBirth = new Date(date_of_birth);
  const age = today.getFullYear() - dateOfBirth.getFullYear();
  return age;
};

function Drivers() {
  const spinner = useSelector((state) => state.appReducer.isLoading);

  const id = useSelector((state) => state.authApp.user._id);
  const history = useHistory();
  const INITIAL_ENTITY = {
    items: [],
    total: 0,
  };
  const [driversAPI, setDriversAPI] = useState(INITIAL_ENTITY);
  const [page, setPage] = useState(0);
  const [details, setDetails] = useState({});
  const numberList = 6;
  const [query, setQuery] = useState({
    limit: numberList,
    from: page,
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const fetchData = async () => {
    setDriversAPI(INITIAL_ENTITY);
    const response = await getAllDrivers(query);
    const { drivers, total } = response;
    if (drivers) {
      setDriversAPI({
        items: drivers,
        total: total,
      });
    } else {
      setDriversAPI(INITIAL_ENTITY);
    }
  };

  const handleDeleteLogic = async (id, active) => {
    const body = {
      is_active: !active,
    };
    const resp = await deleteLogicDriver(id, body);
    const { msg } = resp;
    successToast(msg);
    fetchData();
  };

  const handleAndroidId = async (id) => {
    const body = {
      android_id: null,
      push_token: null,
    };
    const resp = await editDriver(id, body);
    const { msg } = resp;
    successToast(msg);
    fetchData();
  };

  const handlePreviewPage = () => {
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * page - numberList,
      };
    });
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * (page + 1),
      };
    });
  };

  return (
    <>
      <Container fluid>
        <SearchComponent
          onHandleSearchSubmit={({ status, name, cuit, cuitTom }) => {
            setPage(0);
            setQuery((prev) => {
              return {
                ...prev,
                limit: numberList,
                from: 0,
                status,
                cuit,
                name,
                cuitTom,
              };
            });
          }}
        />
        <Row>
          <Col md="12">
            <ContainerList>
              {driversAPI.items.length > 0 ? (
                driversAPI.items.map(
                  (
                    {
                      is_active,
                      name,
                      last_name,
                      email = "N/D",
                      date_of_birth,
                      cuit,
                      id_tomador,
                      license_one,
                      license_two,
                      _id,
                      in_action,
                      android_id,
                      license_date,
                      linti_one,
                      linti_two,
                      lnh_date,
                    },
                    index
                  ) => (
                    <CardStyled
                      noIcon
                      className={` shadow-4dp m-2`}
                      key={index}
                    >
                      <Card.Body>
                        <Row
                          onClick={() =>
                            setDetails((prev) => {
                              return {
                                ...prev,
                                [_id]: !prev[_id],
                              };
                            })
                          }
                          className="align-content-start align-align-items-center"
                        >
                          <Col md="2" className="text-center">
                            <BadgeLog as="h5">
                              {is_active ? "Activo" : "Inactivo"}
                            </BadgeLog>
                            <p className="h5 font-weight-bold mt-2 text-capitalize">
                              {name + " " + last_name || "No posee"}
                            </p>
                          </Col>
                          <Col md="2" className="text-center">
                            <small>
                              <p className="text-primary text-center mb-1">
                                Email
                              </p>
                            </small>
                            <p className="h5 font-weight-bold mt-2">
                              {email?.toLowerCase() || "n/d"}
                            </p>
                          </Col>
                          <Col md="2" className="text-center">
                            <small>
                              <p className="text-primary text-center mb-1">
                                Edad
                              </p>
                            </small>
                            <p className="h5 font-weight-bold mt-2">
                              {date_of_birth
                                ? getAge(date_of_birth) + " años"
                                : "n/d"}
                            </p>
                          </Col>
                          <Col md="3" className="text-center">
                            <small>
                              <p className="text-primary text-center mb-1">
                                Cuit
                              </p>
                            </small>
                            <p className="h5 font-weight-bold mt-2 text-capitalize">
                              {cuit || "No posee"}
                            </p>
                          </Col>
                          <Col md="2" className="text-center">
                            <BadgeLog as="h5">Empresa</BadgeLog>
                            <div>
                              <small>
                                <p className="font-weight-bold mb-0 text-capitalize">
                                  {id_tomador?.name +
                                    " " +
                                    id_tomador?.last_name || "No disponible"}
                                </p>
                                <p className="font-weight-bold mb-0 mt-0">
                                  Cuit: {id_tomador?.cuit || "N/D"}
                                </p>
                                <p className="font-weight-bold mb-0 mt-0">
                                  Teléfono: {id_tomador?.phone || "Teléfono"}
                                </p>
                              </small>
                            </div>
                          </Col>
                          <Col
                            className="d-flex align-items-center text-right justify-content-end pr-4"
                            md="1"
                          >
                            {details[_id] ? (
                              <GoTriangleDown />
                            ) : (
                              <GoTriangleRight />
                            )}
                          </Col>
                        </Row>
                        {details[_id] ? (
                          <>
                            <Divider />
                            <Row className="mb-2 mb-sm-0 mx-4 justify-content-between">
                              <LogButton
                                className="mt-0 pt-0 pb-0 mx-auto mx-sm-0 my-1 my-sm-0"
                                size="xs"
                                title={
                                  !is_active
                                    ? "Habilitar usuario"
                                    : "Deshabilitar usuario"
                                }
                                variant={is_active ? "primary" : "danger"}
                                /* border="curve" */
                                block
                                onClick={() =>
                                  handleDeleteLogic(_id, is_active)
                                }
                              />
                              {in_action ? (
                                <LogButton
                                  className="pt-0 pb-0 mx-auto mx-sm-0 my-1 my-sm-0 "
                                  size="xs"
                                  title={
                                    android_id
                                      ? "Blanquear Android"
                                      : "Android Libre"
                                  }
                                  disabled={!android_id}
                                  block
                                  onClick={() =>
                                    android_id && handleAndroidId(_id)
                                  }
                                />
                              ) : null}
                            </Row>

                            <Row className=" m-1 p-2">
                              <Col md="6">
                                <label className="back-primary font-weight-bold">
                                  Licencia frente
                                </label>
                                <ImageContainer>
                                  {license_one ? (
                                    <a
                                      href={license_one}
                                      target="_blank"
                                      className="w-100"
                                    >
                                      <ImageCard
                                        src={license_one}
                                        alt="imagen no disponible"
                                      />
                                    </a>
                                  ) : (
                                    <p>No subio Licencia</p>
                                  )}
                                </ImageContainer>
                              </Col>
                              <Col md="6">
                                <label className="back-primary font-weight-bold">
                                  Licencia dorso
                                </label>
                                <ImageContainer>
                                  {license_two ? (
                                    <a
                                      href={license_two}
                                      target="_blank"
                                      className="w-100"
                                    >
                                      <ImageCard
                                        src={license_two}
                                        alt="imagen no disponible"
                                      />
                                    </a>
                                  ) : (
                                    <p>No subio Licencia</p>
                                  )}
                                </ImageContainer>
                              </Col>
                              <Col md="6" style={{ maxWidth: 250 }}>
                                <label className="back-primary font-weight-bold mt-2">
                                  Vencimiento
                                </label>
                                <LogDatePicker
                                  readOnly={true}
                                  cleanable={true}
                                  name="license_date"
                                  placeholder="Vencimiento Licencia"
                                  className="number-font mt-2"
                                  errors={{}}
                                  value={
                                    license_date ? new Date(license_date) : null
                                  }
                                />
                              </Col>
                            </Row>
                            <LicenseDivider />
                            <Row className=" m-1 p-2">
                              <Col md="6">
                                <label className="back-primary font-weight-bold">
                                  LINTI frente
                                </label>
                                <ImageContainer>
                                  {linti_one ? (
                                    <a
                                      href={linti_one}
                                      target="_blank"
                                      className="w-100"
                                    >
                                      <ImageCard
                                        src={linti_one}
                                        alt="imagen no disponible"
                                      />
                                    </a>
                                  ) : (
                                    <p>No subio LINTI</p>
                                  )}
                                </ImageContainer>
                              </Col>
                              <Col md="6">
                                <label className="back-primary font-weight-bold">
                                  LINTI dorso
                                </label>
                                <ImageContainer>
                                  {linti_two ? (
                                    <a
                                      href={linti_two}
                                      target="_blank"
                                      className="w-100"
                                    >
                                      <ImageCard
                                        src={linti_two}
                                        alt="imagen no disponible"
                                      />
                                    </a>
                                  ) : (
                                    <p>No subio LINTI</p>
                                  )}
                                </ImageContainer>
                              </Col>
                              <Col md="6" style={{ maxWidth: 250 }}>
                                <label className="back-primary font-weight-bold mt-2">
                                  Vencimiento
                                </label>
                                <LogDatePicker
                                  readOnly={true}
                                  cleanable={true}
                                  name="lnh_date"
                                  placeholder="Vencimiento LINTI"
                                  className="number-font mt-2"
                                  errors={{}}
                                  value={lnh_date ? new Date(lnh_date) : null}
                                />
                              </Col>
                            </Row>
                          </>
                        ) : null}
                      </Card.Body>
                    </CardStyled>
                  )
                )
              ) : (
                <DivEmpty>
                  {!spinner ? (
                    <h2 className="font-weight-bold h3 text-center">
                      No hay choferes con el criterio de busqueda
                    </h2>
                  ) : null}
                </DivEmpty>
              )}
            </ContainerList>
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={driversAPI.items}
            total={driversAPI.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
      </RowStyled>
    </>
  );
}

export default Drivers;
