import React from "react";
import styled from "styled-components";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import { COLOR_PRIMARY, COLOR_RED } from "../../../../constants/Colors";
import moment from "moment";
import { useHistory } from "react-router-dom";

const CardStyled = styled(Card)`
  border-radius: 15px;
  cursor: pointer;
  padding: 10px 20px;
  z-index: 1;
  &:hover {
    opacity: 0.8;
  }
`;

const BadgeLogRed = styled(Badge)`
  background-color: ${COLOR_RED};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  display: inline-block;
  padding: 0px 20px;
`;

const BadgeLog = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  display: inline-block;
  padding: 0px 20px;
  margin-bottom: 0px;
`;

const DivSegments = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
`;

const DivSegmentsChild = styled.div`
  width: 50%;
`;

const CardItemTravelBasic = ({
  status,
  trucksNumber,
  trucks_number_accepted,
  originName,
  destinyName,
  withdrawDate,
  deliveryDate,
  product_type_name,
  onClick,
  className,
  state,
  btnIcon,
  idTrip,
  onDeleteTrip,
  showId,
  ...rest
}) => {
  const history = useHistory();
  return (
    <CardStyled
      className={`${className} shadow-4dp m-2 text-capitalize`}
      onClick={onClick}
      {...rest}
    >
      <Card.Body>
        <Row className="flex align-items-center">
          <Col md={4}>
            <p className="mb-1 mt-0 font-weight-bold font-italic text-center text-sm-left">
              {"ID: " + (showId || "n/d")}
            </p>
            <p className="mb-0 pt-0 mt-0 font-weight-bold text-center text-sm-left">
              {trucks_number_accepted}/{trucksNumber}{" "}
              {trucksNumber === 1 ? "Camión" : " Camiones "}
            </p>
            <p className="mb-0 mt-0 text-center text-sm-left">
              {status ? (
                <BadgeLog as="h5">{state}</BadgeLog>
              ) : (
                <BadgeLog as="h5">{state}</BadgeLog>
              )}
            </p>
          </Col>
          <Col md={6}>
            <DivSegments className="mt-3 mt-sm-0">
              <DivSegmentsChild>
                <p className="mb-0 pt-0 mt-0 font-weight-bold">{originName}</p>
                <p className="mb-0 pt-0 mt-0">
                  <span className="number-font">
                    {moment.utc(withdrawDate).format("DD/MM/YYYY")}
                  </span>{" "}
                </p>
              </DivSegmentsChild>
              <AiOutlineArrowRight className="text-primary h1 ml-2 mr-2" />
              <DivSegmentsChild>
                <p className="mb-0 pt-0 mt-0 ml-2 font-weight-bold">
                  {destinyName}
                </p>
                <p className="mb-0 pt-0 mt-0 ml-2">
                  <span className="number-font">
                    {moment.utc(deliveryDate).format("DD/MM/YYYY")}
                  </span>{" "}
                </p>
              </DivSegmentsChild>
            </DivSegments>
          </Col>

          <Col md={2} className="text-center text-sm-right mt-3 mt-sm-0 ">
            {btnIcon || (
              <p
                className="text-secondary mb-0"
                onClick={() => history.push(`?tripId=${idTrip || "0"}`)}
              >
                Ver detalle
              </p>
            )}
            {status && (
              <div
                style={{
                  marginTop: 20,
                  position: "relative",
                  zIndex: 100,
                  cursor: "no-drop",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteTrip(idTrip);
                }}
              >
                <p className="text-danger mb-0 zI">Eliminar viaje</p>
              </div>
            )}
          </Col>
        </Row>
      </Card.Body>
    </CardStyled>
  );
};

export default CardItemTravelBasic;
