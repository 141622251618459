import React from "react";
import { Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import LogInput from "../../../../components/atoms/LogInput";
import LogButton from "../../../../components/atoms/LogButton";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import LogFBBtn from "../../../../components/atoms/LogFBBtn";
import LogGoogleBtn from "../../../../components/atoms/LogGoogleBtn";
import { isValidEmail } from "../../../../api/modules";
import { errorToast, successToast } from "../../../../services/toastService";

const SignupOne = Yup.object().shape({
  email: Yup.string().email("Correo inválido").required("Correo obligatorio"),
  password: Yup.string()
    .min(2, "Muy corto")
    .max(50, "Muy largo")
    .required("Contraseña obligatorio"),
  confirmPass: Yup.string()
    .oneOf([Yup.ref("password"), null], "Contraseña debe ser el mismo")
    .required("Confirmar contraseña obligado"),
});

const StepOne = ({
  handleStepper,
  handleUpgradeUser,
  newUser,
  handleGoogleResponse,
  handleFbResponse,
}) => {
  const history = useHistory();

  return (
    <Formik
      initialValues={{
        email: newUser.email,
        password: newUser.password,
        confirmPass: newUser.password,
      }}
      validationSchema={SignupOne}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false);
        const { email } = values;
        const resp = await isValidEmail(email);
        const { isRegistered, msg } = resp;
        if (isRegistered) {
          errorToast(msg);
          return;
        }
        handleStepper(1);
        handleUpgradeUser(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Row>
          <Col md="12">
            <h1 className="h2 mb-0 font-weight-bold">Crear una cuenta</h1>
            <small className="text-info text-secondary font-weight-regular">
              ¿Ya tenés cuenta?
              <a
                href={() => {}}
                style={{ cursor: "pointer" }}
                className="text-secondary ml-1 font-weight-bold"
                onClick={() => history.push("/sign-in")}
              >
                Iniciar sesión
              </a>
            </small>
          </Col>
          <Col md="12" className="mt-4">
            <LogInput
              placeholder="Dirección de correo electrónico"
              className="mb-2"
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              value={values.email}
            />
            <LogInput
              placeholder="Contraseña"
              type="password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              value={values.password}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
            <LogInput
              placeholder="Confirmar contraseña"
              type="password"
              name="confirmPass"
              className="mt-2"
              onChange={handleChange}
              onBlur={handleBlur}
              errors={errors}
              value={values.confirmPass}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
          </Col>
          <Col md="12">
            <LogButton
              className="mt-3"
              title="Continuar"
              border="curve"
              block
              onClick={handleSubmit}
            />
          </Col>
          {/* <Col md="12">
            <div className="divider mt-2 mb-1">
              <span></span>
              <span>o</span>
              <span></span>
            </div>
          </Col>
          <Col md="12" className="d-flex justify-content-center">
            <LogGoogleBtn handleCallBackResponse={handleGoogleResponse} />
            <LogFBBtn handleCallBackResponse={handleFbResponse} />
          </Col> */}
        </Row>
      )}
    </Formik>
  );
};

export default StepOne;
