/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { errorToast, successToast } from "../../services/toastService";
import "bootstrap/dist/js/bootstrap.bundle.min";
import {
  postLogin,
  postAuthGoogle,
  putValidateEmail,
  putValidatePhone,
  putValidatePhoneGoogle,
} from "../../api/modules";

import LogCard from "../../components/atoms/LogCard";
import LogInput from "../../components/atoms/LogInput";
import LogButton from "../../components/atoms/LogButton";
import {
  DivAuthScreen,
  DivNavAuthStyled,
} from "../../styledComponents/DivNavAuthStyled";
import LogLogo from "../../components/atoms/LogLogo";
import LogGoogleBtn from "../../components/atoms/LogGoogleBtn";
import LogFBBtn from "../../components/atoms/LogFBBtn";
import { useDispatch } from "react-redux";
import { loginAuth } from "../../redux/actions/auth";
import ModalValidatePhone from "../../components/molecules/modal-validate-phone/ModalValidatePhone";

const SigninSchema = Yup.object().shape({
  email: Yup.string().email("Correo inválido").required("Correo obligatorio"),
  password: Yup.string()
    .min(2, "Muy corto")
    .max(50, "Muy largo")
    .required("Contraseña obligatorio"),
});

const SignIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [modalShowPhone, setModalShowPhone] = useState(false);
  const [email, setEmail] = useState(null);
  const [isRemember, setIsRemember] = useState(false);
  const [rememberData, setRememberData] = useState({});
  const [googleUser, setGoogleUser] = useState({});

  useEffect(() => {
    const { token } = queryString.parse(location.search);
    if (token) {
      validateEmail(token);
    }
  }, [location.search]);

  useEffect(() => {
    const remember_data = JSON.parse(localStorage.getItem("remember_data"));
    if (remember_data) {
      setRememberData(remember_data);
      setIsRemember(true);
    }
  }, []);

  const validateEmail = async (token) => {
    const response = await putValidateEmail(token);
    const { msg, status } = response;
    if (status === 500) {
      errorToast(msg);
      return;
    }
    successToast(msg);
    return response;
  };

  const handleSubmit = async (body) => {
    const response = await postLogin(body);
    const { msg, token } = response;
    setEmail(body.email);
    if (msg?.includes("Por favor valide su telefono para continuar")) {
      errorToast(msg);
      setModalShowPhone(true);
    } else if (msg) {
      errorToast(msg);
    } else {
      dispatch(loginAuth(token));
      successToast("Sesión iniciada con éxito");
      if (isRemember)
        localStorage.setItem("remember_data", JSON.stringify(body));
      else localStorage.removeItem("remember_data");

      history.push("/app");
    }
  };

  const handleGoogleResponse = async (body) => {
    if (body && body.id_token) {
      const { id_token } = body;
      const { msg, token } = await postAuthGoogle({ id_token });
      if (msg?.includes("Por favor valide su telefono para continuar")) {
        setGoogleUser({ id_token });
        errorToast(msg);
        setModalShowPhone(true);
      } else if (msg) {
        errorToast(msg);
      } else {
        dispatch(loginAuth(token));
        successToast("Sesión iniciada con éxito");
        history.push("/app");
      }
    }
  };

  const handlerModalPhone = async (body) => {
    if (googleUser?.id_token) {
      const { msg, status } = await putValidatePhoneGoogle({
        ...body,
        id_token: googleUser?.id_token,
      });
      if (status) {
        errorToast(msg);
      } else {
        successToast(msg);
        setModalShowPhone(false);
        handleGoogleResponse({ id_token: googleUser?.id_token });
        setGoogleUser({});
      }
    } else {
      const bodyRequest = {
        ...body,
        email: email,
      };
      const { msg, status } = await putValidatePhone(bodyRequest);
      if (status) {
        errorToast(msg);
      } else {
        successToast(msg);
        setModalShowPhone(false);
        handleSubmit(body);
      }
    }
  };

  return (
    <DivAuthScreen>
      <DivNavAuthStyled>
        <LogLogo className="mt-2" />
      </DivNavAuthStyled>
      <div className="content-page">
        <Row>
          <Col
            md="6"
            className="d-flex flex-column align-content-center justify-content-center"
          >
            <div className="ml-4 mt-5 mb-4">
              <h1 className="mb-0 font-weight-bold">
                Entregá tu carga <span className="text-primary"> a tiempo</span>{" "}
                y <span className="text-primary">de manera segura</span> con
                BuenaRuta
              </h1>
            </div>
          </Col>
          <Col md="6">
            <LogCard
              width="460px"
              className="pl-3 pr-3 pt-5 pb-5 p-md-5 rounded"
            >
              <Formik
                enableReinitialize
                initialValues={{
                  email:
                    rememberData.email ||
                    queryString.parse(location.search).email ||
                    "",
                  password: rememberData.password || "",
                }}
                validationSchema={SigninSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handleSubmit(values);
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Row>
                    <Col md="12">
                      <h1 className="h2 mb-0 font-weight-bold">
                        Inicio de sesión
                      </h1>
                      <small className="text-info text-secondary font-weight-bold">
                        ¿Sos nuevo?{" "}
                        <a
                          href="#"
                          style={{ cursor: "pointer" }}
                          className="text-secondary ml-1"
                          onClick={() => history.push("/which-user")}
                        >
                          Crear una cuenta
                        </a>
                      </small>
                    </Col>
                    <Col md="12" className="mt-4">
                      <LogInput
                        placeholder="Dirección de correo electrónico"
                        className="mb-2"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={errors}
                        value={values.email}
                      />
                      <LogInput
                        placeholder="Contraseña"
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit();
                          }
                        }}
                        onBlur={handleBlur}
                        errors={errors}
                        value={values.password}
                      />
                    </Col>
                    <Col md="5" className="mt-2">
                      <small>
                        <Form.Check
                          type="checkbox"
                          checked={isRemember}
                          label="Recordarme"
                          className="mt-0 check-inline text-secondary d-flex align-items-baseline"
                          onChange={(event) => {
                            const {
                              target: { checked },
                            } = event;
                            setIsRemember(checked);
                          }}
                        />
                      </small>
                    </Col>
                    <Col md="7" className="mt-2 pl-0 text-center">
                      <a
                        href="#"
                        className="text-secondary"
                        style={{ cursor: "pointer" }}
                        onClick={() => history.push("/recover-password")}
                      >
                        <span className="icon-security"></span>
                        <small className="ml-2 font-weight-bold">
                          ¿Olvidaste tu contraseña?{" "}
                        </small>
                      </a>
                    </Col>
                    <Col md="12">
                      <LogButton
                        type="submit"
                        className="mt-3"
                        title="Iniciar sesión"
                        border="curve"
                        block
                        onClick={handleSubmit}
                      />
                    </Col>
                    {/* <Col md="12">
                      <div className="divider mt-2 mb-1">
                        <span></span>
                        <span>o</span>
                        <span></span>
                      </div>
                    </Col>
                    <Col md="12" className="d-flex justify-content-center">
                      {<LogGoogleBtn
                        handleCallBackResponse={handleGoogleResponse}
                      />}
                      <LogFBBtn handleCallBackResponse={handleFbResponse} />
                    </Col> */}
                  </Row>
                )}
              </Formik>
            </LogCard>
          </Col>
        </Row>

        {modalShowPhone ? (
          <ModalValidatePhone
            isShow={modalShowPhone}
            handleClose={() => setModalShowPhone(false)}
            handleAction={handlerModalPhone}
          />
        ) : null}
      </div>
    </DivAuthScreen>
  );
};

export default SignIn;
