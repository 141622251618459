import React from "react";
import styled from "styled-components";
import { Card, Col, Row } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import { BsStarFill } from "react-icons/bs";
import ReactStars from "react-rating-stars-component";
import { toNumber } from "lodash";
import { priceCalculation } from "../../../../utils/helpers";

const CardStyled = styled(Card)`
  border-radius: 15px;
  cursor: pointer;
  padding: 10px 20px;
  &:hover {
    opacity: 0.8;
  }
`;

const ReactStarsStyled = styled(ReactStars)`
  .rating-contact-log {
    width: "500px";
  }
`;

const DivSegments = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
`;

const CardItemStartsTravelPending = ({
  state,
  price,
  className,
  item,
  isDc,
  onClickDetail,
  onClickDelete,
  ...rest
}) => {
  return (
    <CardStyled className={`${className} shadow-4dp m-2`} {...rest}>
      <Card.Body>
        <Row className="flex align-items-center">
          <Col md="2">
            <p className="font-weight-bold h5 text-center text-sm-left">
              {"ID: " + (item?.parent_id || "n/d") + (item.order || "")}
            </p>
          </Col>
          <Col md="2">
            <h5 className="mr-2 ">
              {/* <ReactStarsStyled
                className="rating-contact-log"
                value={4.5}
                count={5}
                size={24}
                activeColor="#ffd700"
                color="#CDE0EB"
                edit={false}
                isHalf={true}
              /> */}
              <strong>{"Camion: " + (item?.id_truck?.domain || "n/d")} </strong>
            </h5>
          </Col>

          <Col md="3">
            <DivSegments>
              <div className="mr-2">
                <p className="font-weight mt-3 h5 text-capitalize">
                  Precio Acordado:
                  {` ${priceCalculation(
                    price
                  )} / ${item.unit_truck_charge?.slice(0, 3)}`}
                </p>
              </div>
            </DivSegments>
          </Col>
          <Col md="3">
            <div className="d-flex justify-content-center">
              {state === 2 ? (
                <button
                  className="px-3 py-2"
                  style={{
                    background: "#dceeb191",
                    color: "#92c31a",
                    borderRadius: "22px",
                  }}
                >
                  {" "}
                  Pendiente{" "}
                </button>
              ) : (
                <button
                  className="px-3 py-2"
                  style={{
                    background: "#f5f5ae",
                    color: "#92c31a",
                    borderRadius: "22px",
                  }}
                >
                  {" "}
                  Por Viajar{" "}
                </button>
              )}
            </div>
          </Col>
          <Col
            md="2"
            className="d-flex justify-content-center justify-content-sm-end mt-3 mt-sm-0"
          >
            <DivSegments>
              <div md="2" sm="12">
                {state === 2 ? (
                  <button
                    disabled={true}
                    style={{
                      color: state === 2 ? "gray" : "purple",
                      background: "#f2f2f500",
                      width: "109px",
                    }}
                    onClick={() => console.log("No tengo el dato de contacto")}
                  >
                    Ver Contacto
                  </button>
                ) : (
                  <button
                    style={{
                      color: state === 2 ? "gray" : "purple",
                      background: "#f2f2f500",
                      width: "109px",
                    }}
                    onClick={onClickDetail}
                  >
                    Ver Contacto
                  </button>
                )}

                {state === 2 && (
                  <button
                    style={{
                      color: "red",
                      background: "#f2f2f500",
                      width: "109px",
                    }}
                    onClick={() => onClickDelete(item._id)}
                  >
                    Delete
                  </button>
                )}
              </div>
            </DivSegments>
          </Col>
        </Row>
      </Card.Body>
    </CardStyled>
  );
};

CardItemStartsTravelPending.propTypes = {};

export default CardItemStartsTravelPending;
