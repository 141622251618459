import React from 'react'
import LogCard from '../../../../components/atoms/LogCard'
import { Col, Row } from 'react-bootstrap'
import AvatarLog from '../../../../styledComponents/AvatarLog'
import ReactStars from 'react-rating-stars-component'
import { BsStarFill } from 'react-icons/bs'
import LogButton from '../../../../components/atoms/LogButton'
import { GoPrimitiveDot } from 'react-icons/go'
import styled from 'styled-components'
import moment from 'moment'
import CurrencyFormat from 'react-currency-format'
import { useHistory } from 'react-router-dom'
import { BiFontSize } from 'react-icons/bi'

const Title = styled.p`
  font-size: 1.1rem;
`

const LogCardStyled = styled(LogCard)`
  position: absolute;
  top: 0px;
  left: 0;
  transform: translate(50%, 25%);
  z-index: 50;
`


const ReactStarsStyled = styled(ReactStars)`
  .rating-contact-log {
    width: '500px';
  }
`

const CardContactLog = ({
  width,
  name,
  lastName,
  srcAvatar,
  phone,
  truckType,
  chassis,
  coupled
}) => {
  const history = useHistory()

  return (
    <LogCard
      width={width}
      className='shadow-4dp p-4 m-2'
    >
      <Row className='justify-content-center align-items-center'>
        <Col className='p-0 justify-content-center text-center'>
          <AvatarLog
            style={{
              position: 'initial',
              marginTop: '10px'
            }}
            width='50px'
            height='50px'
            src={srcAvatar}
            roundedCircle
          />
        </Col>
      </Row>
      <Row className='justify-content-center align-items-center'>
        <Col >
          <ReactStars
            className='rating-contact-log'
            value={4}
            count={5}
            size={18}
            activeColor='#ffd700'
            color='#CDE0EB'
            char={<BsStarFill />}
          />
        </Col>
      </Row>
      <Row className='align-items-center justify-content-center'>
        <Col className='text-center mt-3'>
          <small>
            <p className='font-weight-bold mt-3 h6' style={{ color: 'green' }}>
              {' '}
              Tu Chofer!{' '}
            </p>
          </small>
          <small>
            <p
              className='font-weight-bold mb-0 h4'
              style={{ marginBlock: '9px' }}
            >
              {name} {lastName}
            </p>
          </small>
        </Col>
      </Row>

      <small>
        <Row className='align-items-center justify-content-center mt-3'>
          <Col md={10} className='p-0'>
            <p className='mb-0 mt-0 h6 font-weight-bold'>
              Teléfono: <small>{phone}</small>
            </p>
            <p></p>
            <p className='mb-0 mt-0 h6 font-weight-bold'>
              Tipo de camion: <small>{truckType}</small>
            </p>
            <p></p>
            <p className='mb-0 mt-0 h6 font-weight-bold'>
              Chasis: <small>{chassis}</small>
            </p>
            <p></p>
            <p className='mb-0 mt-0 h6 font-weight-bold'>
              Acoplado: <small>{coupled}</small>
            </p>
          </Col>
        </Row>
      </small>
    </LogCard>
  )
}

CardContactLog.propTypes = {}

export default CardContactLog
