import axios from "axios";
import { apiHttp } from "../axiosApi.js";
import {
  allClaimsAdmin,
  auditedWeight,
  billingDador,
  billingTomador,
  billingsAdmin,
  claim,
  claimAdmin,
  closeClaim,
  dadorToAdmin,
  getReputation,
  getUserProfile,
  rating,
  shippingNote,
  statisticsCore,
  statisticsDador,
  statisticsTomador,
  uploadPhoto,
  usersInvoicePending,
} from "../config/apiRoute.js";
const API_URL_BACKEND = process.env.REACT_APP_BACKEND_API;

export const getUserProfileAPI = (id) =>
  apiHttp("GET", `${getUserProfile}${id}`);

export const getUserReputation = (id) =>
  apiHttp("GET", `${getReputation}${id}`);

export const getUsers = ({
  limit = 5,
  from = 0,
  search,
  cuit,
  role_id,
  status,
  tripState,
}) => {
  return apiHttp(
    "GET",
    `${getUserProfile}?limit=${limit}&from=${from}${
      search ? `&search=${search}` : ""
    }${cuit ? `&cuit=${cuit}` : ""}${role_id ? `&role_id=${role_id}` : ""}${
      status ? `&status=${status}` : ""
    }${tripState ? `&tripState=${tripState}` : ""}`
  );
};

export const getUsersByRoles = (limit = 5, from = 0, role = null) => {
  return apiHttp(
    "GET",
    `${getUserProfile}?limit=${limit}&from=${from}&role_id=${role}`
  );
};

export const getStatistics = () => {
  return apiHttp("GET", `${statisticsCore}`);
};

export const getStatisticsDador = (state) => {
  return apiHttp("GET", `${statisticsDador}${state ? `?state=${state}` : ""}`);
};

export const getStatisticsTomador = (state) => {
  return apiHttp(
    "GET",
    `${statisticsTomador}${state ? `?state=${state}` : ""}`
  );
};

export const patchTripDetails = (id, body) =>
  apiHttp("PATCH", `${auditedWeight}${id}`, body);

export const patchShippingNote = (id, body) =>
  apiHttp("PATCH", `${shippingNote}${id}`, body);

export const getUserPending = () => apiHttp("GET", `${usersInvoicePending}`);

export const getBillingsAdmin = ({ id_tomador, id_dador }) => {
  const queryString = id_tomador || id_dador ? "?" : "";
  const params = [];

  if (id_tomador) {
    params.push(`id_tomador=${id_tomador}`);
  }
  if (id_dador) {
    params.push(`id_dador=${id_dador}`);
  }

  return apiHttp("GET", `${billingsAdmin}${queryString}${params.join("&")}`);
};

export const getBillingsDador = () => {
  return apiHttp("GET", `${billingDador}`);
};
export const getBillingsTomador = () => {
  return apiHttp("GET", `${billingTomador}`);
};

export const putUserProfileAPI = (id, body) =>
  apiHttp("PUT", `${getUserProfile}${id}`, body);

export const putDadorToAdmin = (body) =>
  apiHttp("PUT", `${dadorToAdmin}`, body);

export const getUploadPhoto = (id) =>
  axios.get(`${API_URL_BACKEND}${uploadPhoto}/${id}`, {
    responseType: "arraybuffer",
  });

export const putUploadPhoto = (id, formData) => {
  return axios.put(`${API_URL_BACKEND}${uploadPhoto}/${id}`, formData, {
    responseType: "arraybuffer",
  });
};

export const postRating = (body) => {
  return apiHttp("POST", `${rating}/`, body);
};

export const getRating = ({ order, score, tripId, postId, limit, from }) => {
  return apiHttp(
    "GET",
    `${rating}?${tripId ? `&tripId=${tripId}` : ""}${
      postId ? `&postId=${postId}` : ""
    }${score ? `&stars=${score}` : ""}${order ? `&order=${order}` : ""}${
      limit ? `&limit=${limit}` : ""
    }${from ? `&from=${from}` : ""}`
  );
};

export const postClaim = (body) => {
  return apiHttp("POST", `${claim}/`, body);
};

export const putRating = (body) => {
  return apiHttp("PUT", `${rating}/`, body);
};
export const getClaims = (id) => {
  return apiHttp("GET", `${claim}/${id}`);
};

export const getClaimsAdmin = (id) => {
  return apiHttp("GET", `${claimAdmin}/${id}`);
};
export const putCloseClaim = (body) => {
  return apiHttp("PUT", `${closeClaim}/`, body);
};

export const getAllClaimsAdmin = ({
  limit,
  from = 0,
  state,
  cuitCreator,
  cuitClaimed,
  user_id,
}) => {
  return apiHttp(
    "GET",
    `${allClaimsAdmin}?limit=${limit}&from=${from}${
      user_id ? `&user_id=${user_id}` : ""
    }${state ? `&state=${state}` : ""}${
      cuitCreator ? `&cuitCreator=${cuitCreator}` : ""
    }${cuitClaimed ? `&cuitClaimed=${cuitClaimed}` : ""}`
  );
};
