import React from "react";
import styled from "styled-components";
import { Badge, Card, Col, Row, Stack } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import { COLOR_PRIMARY } from "../../../../constants/Colors";
import moment from "moment";

const CardStyled = styled(Card)`
  border-radius: 15px;
  cursor: pointer;
  padding: 10px 20px;
  &:hover {
    opacity: 0.8;
  }
`;

const BadgeLog = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  display: block;
  padding: 5px 20px;
  margin: 0px;
`;

const DivSegments = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
`;

const CardItemOfferTravel = ({
  idTravel,
  postulations,
  originName,
  destinyName,
  withdrawDate,
  deliveryDate,
  product,
  onClick,
  className,
  state,
  btnIcon,
  showId,
  ...rest
}) => {
  return (
    <CardStyled
      className={`${className} shadow-4dp m-2 text-capitalize`}
      onClick={onClick}
      {...rest}
    >
      <Card.Body>
        <Row className="flex align-items-center">
          <Col md="3">
            <Stack>
              <p className="mb-1 font-weight-bold font-italic text-center text-sm-left">{"ID: " + (showId || "n/d")}</p>
              <BadgeLog as="h5" className="text-center text-sm-left mb-2 mb-sm-0">
                {" "}
                <span className="number-font">{postulations}</span>{" "}
                {postulations === 1 ? "Oferta" : "Ofertas"}
              </BadgeLog>
            </Stack>
          </Col>
          <Col md="5">
            <Row>
              <Col sm={5}>
                <p className="mb-0 pt-0 mt-0 font-weight-bold">{originName}</p>
                <p className="mb-0 pt-0 mt-0">
                  Retiro{" "}
                  <span className="number-font">
                    {moment.utc(withdrawDate).format("DD/MM/YYYY")}
                  </span>
                </p>
              </Col>
              <Col sm={2} className="text-center">
                <AiOutlineArrowRight
                  className="text-primary h1 ml-2 mr-2"
                  size={25}
                />
              </Col>
              <Col sm={5}>
                {" "}
                <p className="mb-0 pt-0 mt-0 font-weight-bold">{destinyName}</p>
                <p className="mb-0 pt-0 mt-0">
                  {" "}
                  Entrega{" "}
                  <span className="number-font">
                    {moment.utc(deliveryDate).format("DD/MM/YYYY")}
                  </span>{" "}
                </p>
              </Col>
            </Row>
          </Col>
          <Col md="2">
            <Row className="mt-2 mt-sm-0">
              <Col md={12}>
               
                  <p className="mb-0 pt-0 mt-0 font-weight-bold">Producto</p>

                  <p className="mb-0 pt-0 mt-0">
                    {" "}
                    {product.product_type_name}{" "}
                  </p>
               
              </Col>
            </Row>
          </Col>
          <Col md="2" className="text-right">
            {btnIcon || <p className="text-secondary mb-0 text-center text-sm-left">Ver detalle</p>}
          </Col>
        </Row>
      </Card.Body>
    </CardStyled>
  );
};

export default CardItemOfferTravel;
