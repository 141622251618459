import React from "react";
import styled from "styled-components";
import { COLOR_GRAY_TEXT, COLOR_PRIMARY } from "../../constants/Colors";
import moment from 'moment';
import { DatePicker } from "rsuite";

const LogDatePickerStyled = styled(DatePicker)`
  background-color: white;
  border: none;
  width: 100%;
  padding: 2px 2px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px #CCCCCC;
  .rs-picker-toggle-value {
    color: ${COLOR_GRAY_TEXT} !important;
    padding-left: 32px !important;
  }

  .rs-picker-toggle-placeholder {
    padding-left: 32px !important;
  }
  .rs-picker-toggle-caret {
    left: 20px !important;
    color: ${COLOR_PRIMARY} !important;
  }
`;

const LogDatePicker = ({
  placeholder,
  className,
  errors,
  messageError,
  name,
  ...rest
}) => {
  // let mb0 = errors[name] ? "true" : "false";
  return (
    <>
      <LogDatePickerStyled
        {...{ ...rest, name }}
        placeholder={placeholder}
        className={`${className}`}
        format="dd/MM/yyyy"
        disabledDate={date => moment(date).isBefore(moment().subtract(1, "days"))}
        appearance="subtle"
        onSelect={() => {
          const btn = document.querySelector(".rs-btn.rs-btn-primary.rs-btn-sm")
          setTimeout(() => {btn?.click();}, 200)
        }}
        cleanable={false}
        locale={{
          sunday: "Do",
          monday: "Lu",
          tuesday: "Ma",
          wednesday: "Mi",
          thursday: "Jue",
          friday: "Vi",
          saturday: "Sa",
          ok: "Seleccionar",
          today: "Hoy",
          yesterday: "Ayer",
          hours: "Horas",
          minutes: "Minutos",
          seconds: "Segundos",
        }}
      />
      {errors[name] && (
        <div className="mb-2 mt-0">
          <small className="text-danger">{errors[name]}.</small>
        </div>
      )}
    </>
  );
};

export default LogDatePicker;
