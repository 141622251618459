import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import PaginationList from "../../../../../../components/organisms/pagination-list/PaginationList";
import CardItemTravelPostulated from "../../../../components/molecules/CardItemTravelPostulated";
import { useSelector } from "react-redux";
import { getAllTrips } from "../../../../../../api/modules/admin";
import LogSelect from "../../../../../../components/atoms/LogSelect";
import TravelDetailPostulation from "../../../MyTravelsTC/Postulates/TravelDetailPostulation";
import ModalQuestion from "../../../../../../components/organisms/modal-question/ModalQuestion";
import { delCancelPostulation } from "../../../../../../api/modules";
import {
  errorToast,
  successToast,
} from "../../../../../../services/toastService";

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const ContainerList = styled.div`
  overflow-y: overlay;
  padding-right: 10px;
`;
const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const PostulatedTravels = ({ state, id }) => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const numberList = 5;
  const INITIAL_STATE = {
    total: 0,
    items: [],
  };
  const modalInitialState = {
    show: false,
    _id: null,
    name: null,
    tripId: null,
  };
  const [modalExit, setModalExit] = useState(modalInitialState);
  const [tripsAPI, setTripsAPI] = useState(INITIAL_STATE);
  const [isShow, setIsShow] = useState(false);
  const [travelId, setTravelId] = useState({});
  const spinner = useSelector((state) => state.appReducer.isLoading);
  const user = useSelector((state) => state.authApp.user);
  const [query, setQuery] = useState({
    limit: numberList,
    from: page,
    state,
    user_id: id,
    status: "true",
  });
  const handleCloseExitModal = async (response) => {
    if (response) {
      const response = await delCancelPostulation({
        id_trip: modalExit?.tripId,
        id_postulation: modalExit._id,
        reason: "Eliminada por el " + (user?.email || "Admin"),
      });
      const { msg } = response;
      if (!msg) {
        successToast("Postulacion eliminada con exitosamente");
        history.replace("/app/users/user-profile/" + query.user_id);
        setPage(0);
        setQuery((state) => {
          return { ...state, page: 0 };
        });
      } else {
        errorToast(msg);
      }
    }
    setModalExit(modalInitialState);
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const fetchData = async () => {
    const tripsResponse = await getAllTrips(query);

    const { trips, total } = tripsResponse;
    if (trips)
      setTripsAPI({
        ...INITIAL_STATE,
        items: trips,
        total,
      });
  };

  const handlePreviewPage = () => {
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * page - numberList,
      };
    });
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * (page + 1),
      };
    });
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <LogSelect
              className="w-50 mb-2 ml-2 font-weight-bolder"
              cleanable={false}
              placeholder="Estado del viaje"
              items={[
                {
                  label: "Viajes activos",
                  value: "true",
                },
                {
                  label: "Viajes eliminados",
                  value: "false",
                },
              ]}
              onChange={(v) => {
                setPage(0);

                setQuery((prev) => {
                  return {
                    ...prev,
                    from: 0,
                    status: v,
                  };
                });
              }}
              value={query.status}
              errors={{}}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <ContainerList>
              {tripsAPI?.items.length ? (
                tripsAPI.items.map((trip, index) => (
                  <CardItemTravelPostulated
                    onClick={() => {
                      setIsShow(true);
                      setTravelId({
                        ...trip,
                        type: trip.price_type,
                        id: trip._id,
                      });
                    }}
                    onDelete={
                      query?.status === "true"
                        ? () =>
                            setModalExit({
                              show: true,
                              _id: trip?.postulation?._id,
                              name: trip.id_trip,
                              tripId: trip?._id,
                            })
                        : null
                    }
                    trucksNumber={trip.trucks_number}
                    originName={
                      trip?.origin_site.locality?.name +
                      ", " +
                      trip?.origin_site.locality?.id_province?.name
                    }
                    destinyName={
                      trip?.destiny_site.locality?.name +
                      ", " +
                      trip?.destiny_site?.locality?.id_province?.name
                    }
                    product_type_name={trip.product.product_type_name}
                    withdrawDate={trip.withdraw_date}
                    deliveryDate={trip.delivery_date}
                    idTrip={trip.id_trip}
                    userDc={trip.user_id_dc}
                    product={trip.product}
                    state="Pendientes"
                    key={index}
                    score={trip.score}
                  >
                    <strong>{trip.site_name}</strong> {trip.address}
                  </CardItemTravelPostulated>
                ))
              ) : (
                <DivEmpty>
                  {!spinner ? (
                    <h2 className="font-weight-bold h3 text-center">
                      No hay viajes disponibles
                    </h2>
                  ) : null}
                </DivEmpty>
              )}
            </ContainerList>
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={tripsAPI.items}
            total={tripsAPI.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
      </RowStyled>
      {isShow ? (
        <TravelDetailPostulation
          isShow={isShow}
          handleClose={() => {
            setIsShow(false);
            setTravelId({});
          }}
          /* handlePostulation={() => {
            setIsShow(false);
            setIsPostulate(true);
          }} */
          travelData={travelId}
        />
      ) : null}
      {modalExit.show ? (
        <ModalQuestion
          title="¿Estás seguro de esta accion?"
          subTitle={"Eliminar la postulacion para el viaje ID" + modalExit.name}
          isShow={modalExit.show}
          handleClose={handleCloseExitModal}
        />
      ) : null}
    </>
  );
};

export default PostulatedTravels;
