import React from "react";
import { store } from "../index";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { AuthRouting } from "./Auth/AuthRouting";
import AdminLayout from "../pages/Dashboard";
import { logOutAuth } from "../redux/actions/auth";
import NotFound from "../pages/Dashboard/components/NotFound/NotFound";
/**
 * Render a route with potential sub routes
 * https://reacttraining.com/react-router/web/example/route-config
 */

const RouteWithSubRoutes = (route) => {
  let render;
  if (localStorage.getItem("token")) {
    store.dispatch(logOutAuth());
  }

  render = (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );

  return render;
};

/**
 * Use this component for any new section of routes (any config object that has a "routes" property
 */

export const RenderRoutes = ({ routes }) => {
  return (
    <Router>
      <Switch>
        {routes.map((route, i) => {
          return <RouteWithSubRoutes key={route.key} {...route} />;
        })}
        <ProtectedRoute path="/app" component={AdminLayout} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.getItem("token") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      )
    }
  />
);

/* 
  Routers array
*/

const ROUTES = [
  ...AuthRouting,
  // {
  //   path: "/app",
  //   key: "APP",
  //   component: RenderRoutes,
  //   routes: [
  //     {
  //       path: "/app",
  //       key: "APP_ROOT",
  //       exact: true,
  //       component: () => <h1>App Index</h1>,
  //     },
  //     {
  //       path: "/app/page",
  //       key: "APP_PAGE",
  //       exact: true,
  //       component: () => <h1>App Page</h1>,
  //     },
  //   ],
  // },
];

export default ROUTES;
