/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import SearchTravels from "./SearchTravels";
import { Table } from "rsuite";
import {
  Button,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import styled from "styled-components";
import { Tab, Tabs } from "@material-ui/core";
import TabMaterialLog from "../../../../components/organisms/tabs-material/TabMaterialLog";
import { getToPostulateTripFiltered } from "../../../../api/modules/tomador";
import {
  priceCalculation,
  resetCurrency,
  typePriceString,
} from "../../../../utils/helpers";
import moment from "moment";
import LogButton from "../../../../components/atoms/LogButton";
import { useHistory, useLocation } from "react-router-dom";
import ModalPostulation from "../Modal-Postulation/ModalPostulation";
import MapOffers from "./MapOffers";
import PaginationList from "../../../../components/organisms/pagination-list/PaginationList";
import _, { toNumber } from "lodash";
import { AiOutlineConsoleSql } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setCollapseOff, setCollapseOn } from "../../../../redux/actions/app";
import ModalRating from "../Modal-Rating/ModalRating";
import TravelDetailModal from "./TravelDetailModal";
const { Column, HeaderCell, Cell } = Table;
const TAXES_LABEL = process.env.REACT_APP_TAXES_LABEL;

const TabsStyled = styled(Tabs)`
  width: 40%;
`;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const MyTravelsToWork = (props) => {
  const INITIAL_ENTITY = {
    items: [],
    total: 0,
  };
  const history = useHistory();
  const [value, setValue] = React.useState(0);
  const [page, setPage] = useState(0);
  const [isPostulate, setIsPostulate] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isRating, setIsRating] = useState(false);
  const [travelId, setTravelId] = useState({});
  const numberList = 20;
  const [trips, setTrips] = useState(INITIAL_ENTITY);
  const [sortType, setSortType] = useState("desc");
  const [sortColumn, setSortColumn] = useState("id_trip");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authApp.user);

  useEffect(() => {
    getFetch();
    dispatch(setCollapseOff());
    return () => dispatch(setCollapseOn());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getFetch = async (limit = numberList, from = 0) => {
    setTrips({ ...INITIAL_ENTITY });
    const response = await getToPostulateTripFiltered("");
    if (!response.ok) return;
    let { trips, total } = response.data;
    if (trips) {
      let tripsTransformed = [];
      for (let item of trips) {
        let namesTruckTypes = item.truck_type.map((tf) => tf.truck_type_name);
        let namesTruckFeatures = item.truck_features.map(
          (tf) => tf.truck_characteristic_name
        );
        const _item = {
          ...item,
          truck_features_s: convertItemIntoCommatsDot(namesTruckFeatures),
          truck_type_s: convertItemIntoCommatsDot(namesTruckTypes),
        };
        tripsTransformed = [...tripsTransformed, _item];
      }

      setTrips({
        ...INITIAL_ENTITY,
        items: _.orderBy(tripsTransformed, sortColumn.split(","), [sortType]),
        total,
      });
    }
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setSortColumn(sortColumn);
    setSortType(sortType);
    setTrips({
      ...trips,
      items: _.orderBy(trips.items, sortColumn.split(","), [sortType]),
    });
  };

  const convertItemIntoCommatsDot = (array) => {
    return [array.slice(0, -1).join(", "), array.slice(-1)[0]].join(
      array.length < 2 ? "" : " y "
    );
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePreviewPage = () => {
    getFetch(numberList, numberList * page - numberList);
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    getFetch(numberList, numberList * (page + 1));
  };

  return (
    <Container fluid>
      <SearchTravels
        handleResponse={(total, trips_filtered) => {
          let tripsTransformed = [];
          for (let item of trips_filtered) {
            let namesTruckTypes = item.truck_type.map(
              (tf) => tf.truck_type_name
            );
            let namesTruckFeatures = item.truck_features.map(
              (tf) => tf.truck_characteristic_name
            );
            const _item = {
              ...item,
              truck_features_s: convertItemIntoCommatsDot(namesTruckFeatures),
              truck_type_s: convertItemIntoCommatsDot(namesTruckTypes),
            };
            tripsTransformed = [...tripsTransformed, _item];
          }

          setTrips({
            ...INITIAL_ENTITY,
            items: tripsTransformed,
            total,
          });
        }}
      >
        Lorem ipsum dolor sit.
      </SearchTravels>
      <Row className="mt-2">
        <Col md="12">
          <TabsStyled
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="simple tabs example"
          >
            <Tab label="Tabla" {...a11yProps(0)} />
            <Tab label="Mapa" {...a11yProps(1)} />
          </TabsStyled>
          <TabMaterialLog value={value} index={0} className="p-0">
            <Table
              wordWrap={true}
              onSortColumn={handleSortColumn}
              sortColumn={sortColumn}
              sortType={sortType}
              className="mt-2 shadow-6dp"
              height={550}
              data={user ? trips.items : null}
            >
              <Column width={60} align="center" sortable>
                <HeaderCell>ID</HeaderCell>
                <Cell dataKey="id_trip">
                  {(rowData) => {
                    return (
                      <div
                        onClick={(data) => {
                          console.log("click en colmun", rowData);
                        }}
                      >
                        <p className="mb-0">{rowData.id_trip} </p>
                      </div>
                    );
                  }}
                </Cell>
              </Column>

              <Column width={100} align="center" sortable>
                <HeaderCell>Inicio</HeaderCell>
                <Cell
                  dataKey="withdraw_date"
                  onClick={(data) => {
                    console.log("click en colmun", data);
                  }}
                >
                  {(rowData) => {
                    return (
                      <>
                        <p
                          className="mb-0 number-font"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {moment
                            .utc(rowData.withdraw_date)
                            .format("DD/MM/YYYY")}{" "}
                        </p>
                      </>
                    );
                  }}
                </Cell>
              </Column>
              <Column width={130} align="center" sortable>
                <HeaderCell>Tipo de carga</HeaderCell>
                <Cell dataKey="charger_type[0].cargo_type_name">
                  {(rowData) => {
                    return (
                      <>
                        <p className="mb-0">
                          {rowData.charger_type[0].cargo_type_name ||
                            "No posee"}{" "}
                        </p>
                      </>
                    );
                  }}
                </Cell>
              </Column>
              <Column minWidth={160} flexGrow={1}>
                <HeaderCell>Tipo de Camión</HeaderCell>
                <Cell dataKey="truck_type_s">
                  {(rowData) => {
                    return (
                      <>
                        <p className="mb-0">
                          {rowData.truck_type_s || "No posee"}{" "}
                        </p>
                      </>
                    );
                  }}
                </Cell>
              </Column>
              <Column minWidth={160} flexGrow={1} sortable>
                <HeaderCell>Origen</HeaderCell>
                <Cell dataKey="origin_site.locality.id_province.name,origin_site.locality.name">
                  {(rowData) => {
                    return (
                      <>
                        <p className="mb-0">
                          {rowData?.origin_site?.locality?.name}{" "}
                        </p>
                        <p className="mb-0">
                          {rowData.origin_site.locality?.id_province?.name}{" "}
                        </p>
                      </>
                    );
                  }}
                </Cell>
              </Column>
              <Column minWidth={160} flexGrow={1} sortable>
                <HeaderCell>Destino</HeaderCell>
                <Cell dataKey="destiny_site.locality.id_province.name,destiny_site.locality.name">
                  {(rowData) => {
                    return (
                      <>
                        <p className="mb-0">
                          {rowData.destiny_site.locality?.name}{" "}
                        </p>
                        <p className="mb-0">
                          {rowData.destiny_site.locality?.id_province?.name}{" "}
                        </p>
                      </>
                    );
                  }}
                </Cell>
              </Column>
              <Column width={110} sortable>
                <HeaderCell>Precio</HeaderCell>
                <Cell dataKey="price_per_ton">
                  {(rowData) => {
                    return (
                      <>
                        <p
                          className="mb-0 number-font text-capitalize"
                          style={{ width: "100%" }}
                        >
                          {rowData.discount_trip || rowData.discount_dador ? (
                            <strong>
                              {priceCalculation(
                                rowData.price_per_ton,
                                typeof rowData.discount_trip === "number"
                                  ? rowData.discount_trip
                                  : user.discount_factor +
                                      rowData.discount_dador
                              )}
                            </strong>
                          ) : (
                            rowData.price_per_ton
                          )}
                          {"/" + rowData.unit_truck_charge?.slice(0, 3)}
                        </p>{" "}
                        <p
                          className="mb-0 mt-0"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          {typePriceString(rowData.price_type)}
                        </p>
                      </>
                    );
                  }}
                </Cell>
              </Column>
              <Column width={80} align="center" sortable>
                <HeaderCell>Score</HeaderCell>
                <Cell dataKey="score">
                  {(rowData) => {
                    function handleRating() {
                      setIsRating(true);
                      setTravelId({
                        id_trip: rowData.id_trip,
                        id: rowData._id,
                        score: rowData.score
                          ? toNumber(rowData.score).toFixed(2)
                          : 0,
                      });
                    }
                    return rowData.score ? (
                      <OverlayTrigger
                        key="delete"
                        overlay={<Tooltip>Calificaciones</Tooltip>}
                      >
                        <Button
                          className="rounded-pill"
                          variant="outline-info"
                          size="sm"
                          onClick={() => handleRating()}
                        >
                          {toNumber(rowData.score).toFixed(2)}
                        </Button>
                      </OverlayTrigger>
                    ) : (
                      <p className="mb-0">"N/D"</p>
                    );
                  }}
                </Cell>
              </Column>
              <Column width={100} className="text-center" sortable>
                <HeaderCell>Acción</HeaderCell>
                <Cell dataKey="price_type">
                  {(rowData) => {
                    function handleAction() {
                      setIsShow(true);
                      setTravelId({
                        ...rowData,
                        type: rowData.price_type,
                        id: rowData._id,
                        data: {
                          price_ton_postulation: resetCurrency(
                            rowData.discount_trip || rowData.discount_dador
                              ? priceCalculation(
                                  rowData.price_per_ton,
                                  typeof rowData.discount_trip === "number"
                                    ? rowData.discount_trip
                                    : user.discount_factor +
                                        rowData.discount_dador
                                )
                              : rowData.price_per_ton
                          ),
                          payment_terms_days_postulation:
                            rowData?.payment_terms_days,
                        },
                      });
                    }
                    function hanndleToPostulate(type) {
                      setIsPostulate(true);
                      setTravelId({
                        id: rowData._id,
                        type,
                        data: {
                          price_ton_postulation: resetCurrency(
                            rowData.discount_trip || rowData.discount_dador
                              ? priceCalculation(
                                  rowData.price_per_ton,
                                  typeof rowData.discount_trip === "number"
                                    ? rowData.discount_trip
                                    : user.discount_factor +
                                        rowData.discount_dador
                                )
                              : rowData.price_per_ton
                          ),
                          payment_terms_days_postulation:
                            rowData?.payment_terms_days,
                        },
                      });
                    }
                    return (
                      <>
                        <LogButton
                          title={
                            rowData.price_type === "1" ? "Ofertar" : "Aceptar"
                          }
                          size="sm"
                          border="curve"
                          onClick={() => hanndleToPostulate(rowData.price_type)}
                          color="acceptDark"
                        />
                        <div></div>
                        <a
                          style={{ cursor: "pointer" }}
                          className="text-acceptDark"
                          onClick={handleAction}
                        >
                          {" "}
                          Detalles{" "}
                        </a>
                      </>
                    );
                  }}
                </Cell>
              </Column>
            </Table>
          </TabMaterialLog>
          <TabMaterialLog value={value} index={1}>
            <MapOffers trip_filtered={trips.items} />
          </TabMaterialLog>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md="12">
          <h6 className=" h6 font-weight-bold font-italic m-0">
            {TAXES_LABEL || "Montos netos sin impuestos"}
          </h6>
        </Col>
      </Row>

      {travelId.id && isPostulate ? (
        <ModalPostulation
          idTrip={travelId.id}
          data={travelId.data}
          isPostulate={isPostulate}
          onClosePostulation={(payload) => {
            setIsPostulate(false);
            setTravelId({});

            if (payload === "success") {
              getFetch();
            }
          }}
          modalType={travelId.type}
        />
      ) : null}
      {travelId.id && isRating ? (
        <ModalRating
          tripId={travelId.id}
          showId={travelId.id_trip}
          score={travelId.score}
          isRating={isRating}
          onCloseRating={() => {
            setIsRating(false);
            setTravelId({});
          }}
        />
      ) : null}
      {isShow ? (
        <TravelDetailModal
          isShow={isShow}
          handleClose={() => {
            setIsShow(false);
            setTravelId({});
          }}
          handlePostulation={() => {
            setIsShow(false);
            setIsPostulate(true);
          }}
          travelData={travelId}
        />
      ) : null}
    </Container>
  );
};

export default MyTravelsToWork;
