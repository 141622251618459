import React from "react";
import { Col, Form, Modal, Row, Stack } from "react-bootstrap";
import styled from "styled-components";
import { FiAlertCircle, FiX } from "react-icons/fi";
import { BiStar } from "react-icons/bi";
import LogButton from "../../../../../../components/atoms/LogButton";
import { COLOR_GRAY_TEXT } from "../../../../../../constants/Colors";
import LogInput from "../../../../../../components/atoms/LogInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { patchTripDetails, postInvoice } from "../../../../../../api/modules";
import {
  errorToast,
  successToast,
} from "../../../../../../services/toastService";
import CurrencyFormat from "react-currency-format";
import LogDatePicker from "../../../../../../components/atoms/LogDatePicker";
import LogDateInput from "../../../../../../components/atoms/LogDateInput";

const ModalLogStyled = styled(Modal)`
  opacity: 1;
  .modal-content {
    border-radius: 15px !important;
    padding: 20px;
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;

const InvoiceSchema = Yup.object().shape({
  invoice_date: Yup.date()
    .required("Fecha de factura es obligatorio")
    .nullable(),
  invoice_number: Yup.string().required("Numero de factura es obligatorio"),
});

const DocumentContainer = styled(Row)`
  max-height: 200px;
  overflow: auto;
`;

const ContentModal = styled.div`
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #000;
  &:hover {
    color: #ff0000;
  }
`;

const ModalInvoice = ({ isShow, handleClose, data, refetch }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      invoice_number: "",
      invoice_date: null,
    },
    onSubmit: async (values) => {
      const { invoice_number, invoice_date } = values;
      const resp = await postInvoice({
        amount: data.reduce((total, row) => {
          const amount = row.auditedWeight * row.price_per_ton;
          return total + amount;
        }, 0),
        id_dador: data[0]?.id_dador._id,
        id_tomador: data[0]?.id_tomador._id,
        cuit: data[0]?.id_dador.cuit,
        invoice_number,
        invoice_date,
        trip_details: data.map((ele) => ele._id),
      });
      const { msg } = resp;
      if (msg) {
        errorToast(msg || "Intente Nuevamente");
        /* resetForm(); */
      } else {
        successToast(`Factura cargada con exito`);
        refetch();
        resetForm();
        handleClose();
      }
    },
    validationSchema: InvoiceSchema,
  });
  return (
    <ModalLogStyled
      show={isShow}
      onHide={() => {
        resetForm();
        handleClose();
      }}
      centered
      className="modal-exit"
    >
      <Modal.Header>
        <CloseButton
          onClick={() => {
            resetForm();
            handleClose();
          }}
        >
          <FiX />
        </CloseButton>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Row>
          <Col className="text-left">
            <h4 className="font-weight-bold text-primary h5">
              {"Ingresar Factura"}
            </h4>

            <hr />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="black-primary font-weight-bold mr-2">
              Monto total Calculado:
            </label>
            <CurrencyFormat
              value={
                data.reduce((total, row) => {
                  const amount = row.auditedWeight * row.price_per_ton;
                  return total + amount;
                }, 0) || "No Informado"
              }
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={"$"}
              renderText={(formattedValue) => <span>{formattedValue}</span>}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="black-primary font-weight-bold mr-2">
              Cuit Dador:
            </label>
            <span>{data[0]?.id_dador.cuit} </span>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="black-primary font-weight-bold mr-2">
              Cuit Tomador:
            </label>
            <span>{data[0]?.id_tomador.cuit} </span>
          </Col>
        </Row>

        <Form.Group>
          <Row>
            <Col xs={3}>
              <label className="black-primary font-weight-bold mb-0 mt-2">
                {"Fecha:"}
              </label>
            </Col>
            <Col xs={9}>
              <LogDateInput
                name="invoice_date"
                className="number-font"
                placeholder="Fecha de Factura"
                onBlur={handleBlur}
                errors={errors}
                value={values.invoice_date}
                onChange={(value) => {
                  setFieldValue("invoice_date", value);
                }}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={3}>
              <label className="black-primary font-weight-bold mb-0 mt-2">
                {"Numero:"}
              </label>{" "}
            </Col>
            <Col xs={9}>
              <LogInput
                name="invoice_number"
                className="p-0"
                placeholder="Numero de Factura"
                onBlur={handleBlur}
                type="text"
                errors={errors}
                value={values.invoice_number}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="footer-modal">
        <Col className="d-flex justify-content-center">
          <LogButton
            className="w-50"
            color="primary"
            onClick={handleSubmit}
            title={"Cargar Factura"}
            border="curve"
          />
        </Col>
      </Modal.Footer>
    </ModalLogStyled>
  );
};

export default ModalInvoice;
