import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../assets/img/Logo.png";

const LogLogoImg = styled.img`
  width: 140px;
  height: 24px;
  cursor: pointer;
`;
const LogLogo = (props) => {
  const history = useHistory();
  return <LogLogoImg {...props} onClick={() => history.push("/")} src={Logo} />;
};

export default LogLogo;
