import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import PaginationList from "../../../../../../components/organisms/pagination-list/PaginationList";
import CardItemTravel from "../../../../components/molecules/CardItemTravel";
import { useSelector } from "react-redux";
import { getAllTrips } from "../../../../../../api/modules/admin";
import { useHistory, useLocation } from "react-router-dom";
import { GoTriangleRight } from "react-icons/go";

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const ContainerList = styled.div`
  overflow-y: overlay;
  padding-right: 10px;
`;
const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const PendingsTravelsTc = ({ state, id, searchQuery }) => {
  const history = useHistory();
  const spinner = useSelector((state) => state.appReducer.isLoading);
  const [page, setPage] = useState(0);
  const location = useLocation();
  const numberList = 5;
  const INITIAL_STATE = {
    total: 0,
    items: [],
  };
  const addQueryParam = (key, value) => {
    const params = new URLSearchParams(location.search);
    params.set(key, value);
    history.push({ search: params.toString() });
  };
  const [tripsAPI, setTripsAPI] = useState(INITIAL_STATE);
  const [query, setQuery] = useState({
    limit: numberList,
    from: page,
    state,
    user_id: id,
    status: true,
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: 0,
      };
    });
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const fetchData = async () => {
    const tripsResponse = await getAllTrips({ ...query, ...searchQuery });

    const { trips, total } = tripsResponse;
    if (trips)
      setTripsAPI({
        ...INITIAL_STATE,
        items: trips,
        total,
      });
  };
  const handlePreviewPage = () => {
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * page - numberList,
      };
    });
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * (page + 1),
      };
    });
  };
  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <ContainerList>
              {tripsAPI?.items.length ? (
                tripsAPI.items.map((location, index) => (
                  <CardItemTravel
                    showId={location.id_trip}
                    key={index}
                    status={location.status}
                    trucksNumber={location.counterTripdetails}
                    originName={
                      location?.origin_site.locality.name +
                      ", " +
                      location?.origin_site.locality.id_province?.name
                    }
                    destinyName={
                      location?.destiny_site.locality.name +
                      ", " +
                      location?.destiny_site.locality.id_province?.name
                    }
                    product_type_name={location.product.product_type_name}
                    withdrawDate={location.withdraw_date}
                    deliveryDate={location.delivery_date}
                    state="Pendientes"
                    drivers={location.drivers || null}
                    trucks={location.trucks}
                    idTrip={location._id}
                    type={"tc"}
                    onClick={() => addQueryParam("tripId", location._id || "0")}
                    btnIcon={<GoTriangleRight />}
                  >
                    <strong>{location.site_name}</strong> {location.address}
                  </CardItemTravel>
                ))
              ) : !spinner ? (
                <h2 className="font-weight-bold h3 text-center">
                  Aún no tenés ninguna oferta
                </h2>
              ) : (
                <DivEmpty></DivEmpty>
              )}
            </ContainerList>
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={tripsAPI.items}
            total={tripsAPI.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
      </RowStyled>
    </div>
  );
};

export default PendingsTravelsTc;
