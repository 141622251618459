import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { COLOR_TEXT_WHITE } from "../../constants/Colors";

const StyledButton = styled(Button)`
  border-radius: ${(props) => props.border === "curve" && "100px"};
  font-weight: bold;
  ${'' /* padding: 10px 16px;
  width: 100%; */}
  color: ${COLOR_TEXT_WHITE};
`;

function LogButton({ title, color, className, size, border, ...rest }) {
  return (
    <StyledButton
      {...{ ...rest, border }}
      className={className}
      variant={color}
      size={size}
    >
      {title}
    </StyledButton>
  );
}

LogButton.propTypes = {
  title:  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  border: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default LogButton;
