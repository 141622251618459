import ROUTES, { RenderRoutes } from "./routers/Routes";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
import PuffLoader from "react-spinners/PuffLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { refreshData } from "./redux/actions/auth";
import { useEffect } from "react";
import 'rsuite/dist/rsuite.min.css'
import { CustomProvider  } from 'rsuite';
import esES from 'rsuite/locales/es_ES';
import { createTheme, ThemeProvider } from "@material-ui/core";
import { COLOR_PRIMARY, COLOR_SECONDARY } from "./constants/Colors"

LoadingOverlay.propTypes = undefined

const App = () => {
  const isLoading = useSelector((state) => state.appReducer.isLoading);
  const reloadSesion = useDispatch(refreshData);

  useEffect(() => {
    reloadSesion(refreshData());
  }, [reloadSesion]);

  const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: COLOR_PRIMARY,
      },
      secondary: {
        // This is green.A700 as hex.
        main: COLOR_SECONDARY,
      },
    },
    typography: {
      "fontFamily": `"Raleway", "Helvetica", "Arial", sans-serif`,
     }
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <CustomProvider  locale={esES}>
          <LoadingOverlay
            active={isLoading}
            spinner={<PuffLoader color={COLOR_PRIMARY} />}
          >
            <RenderRoutes routes={ROUTES} />
            <ToastContainer
              position="top-center"
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </LoadingOverlay>
        </CustomProvider >
      </ThemeProvider>
    </>
  );
};

export default App;
