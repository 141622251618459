import React from "react";
import { Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import LogInput from "../../../../components/atoms/LogInput";
import LogButton from "../../../../components/atoms/LogButton";
import * as Yup from "yup";
import LogInputPhone from "../../../../components/atoms/LogInputPhone";
import LogRadio from "../../../../components/atoms/LogRadio";
import { errorToast } from "../../../../services/toastService";

const StepTwo = ({ handleStepper, handleUpgradeUser, newUser, socialUser }) => {
  const SignupOne = socialUser
    ? Yup.object().shape({
        cuit: Yup.string()
          .matches(
            /\b(20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]/,
            "CUIT inválido"
          )
          .required("CUIT obligatorio"),
        socialReason: Yup.string()
          .min(2, "Muy corto")
          .max(50, "Muy largo")
          .required("Razón social obligatorio"),
        phoneNumber: Yup.string().required("Teléfono obligatorio"),
      })
    : Yup.object().shape({
        name: Yup.string().required("Nombre obligatorio"),
        lastName: Yup.string().required("Apellido obligatorio"),
        cuit: Yup.string()
          .matches(
            /\b(20|23|24|27|30|33|34)(\D)?[0-9]{8}(\D)?[0-9]/,
            "CUIT inválido"
          )
          .required("CUIT obligatorio"),
        socialReason: Yup.string()
          .min(2, "Muy corto")
          .max(50, "Muy largo")
          .required("Razón social obligatorio"),
        phoneNumber: Yup.string().required("Teléfono obligatorio"),
        accepted_agreement: Yup.boolean().oneOf(
          [true],
          "Por favor acepte las condiciones."
        ),
      });
  const agreementText = () => (
    <p className="ml-1 mb-0 " style={{ fontSize: "0.9rem" }}>
      <small style={{ color: "#636B70" }}>
        Al registrarme declaro que soy mayor de edad y acepto los{" "}
        <a
          onClick={() => {
            window.open(
              "about:blank",
              "_blank",
              "width=600,height=400,toolbar=no,menubar=no,scrollbars=no,resizable=no"
            );
          }}
          className="text-secondary cursor-pointer"
        >
          términos y condiciones
        </a>{" "}
        y la{" "}
        <a
          onClick={() => {
            window.open(
              "about:blank",
              "_blank",
              "width=600,height=400,toolbar=no,menubar=no,scrollbars=no,resizable=no"
            );
          }}
          className="text-secondary cursor-pointer"
        >
          política de privacidad
        </a>{" "}
        de BuenaRuta
      </small>
    </p>
  );
  return (
    <Formik
      initialValues={{
        name: newUser.name || "",
        lastName: newUser.lastName || "",
        cuit: newUser.cuit || "",
        socialReason: newUser.socialReason || "",
        phoneNumber: newUser.phoneNumber || "",
        accepted_agreement: false,
      }}
      validationSchema={SignupOne}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        handleUpgradeUser(values, true);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        setFieldValue,
      }) => (
        <Row>
          <Col md="12">
            <h1 className="h2 mb-0 font-weight-bold">¡Último paso!</h1>
            {socialUser === null && (
              <a
                href={() => {}}
                onClick={() => handleStepper(0)}
                className="text-secondary"
                style={{ cursor: "pointer" }}
              >
                Volver
              </a>
            )}
          </Col>

          <Col md="12" className="mt-4">
            {socialUser === null && (
              <>
                <LogInput
                  placeholder="Nombre"
                  className="mb-2"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  value={values.name}
                />
                <LogInput
                  placeholder="Apellido"
                  className="mb-2"
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  value={values.lastName}
                />
              </>
            )}
            <LogInput
              placeholder="CUIT (sin guiones)"
              className="mb-2 number-font"
              type="text"
              name="cuit"
              onChange={(e) => {
                const { value } = e.target;
                const re = /^[0-9\b]+$/;
                if (value === "" || re.test(value)) {
                  setFieldValue("cuit", value);
                }
              }}
              onBlur={handleBlur}
              errors={errors}
              value={values.cuit}
            />
            <LogInput
              placeholder="Razón social"
              className="mb-2"
              type="text"
              name="socialReason"
              onChange={(text) => handleChange(text)}
              onBlur={handleBlur}
              errors={errors}
              value={values.socialReason}
            />
            <LogInputPhone
              placeholder="Teléfono"
              className="mb-2 number-font"
              name="phoneNumber"
              onChange={(phoneNumber) => {
                setFieldValue("phoneNumber", phoneNumber);
              }}
              onBlur={handleBlur}
              errors={errors}
              value={values.phoneNumber}
            />
            <LogRadio
              error={errors.accepted_agreement}
              value={values.accepted_agreement}
              name="accepted_agreement"
              label={agreementText()}
              handleChange={handleChange}
              className="mt-3 mb-2"
              handleBlur={handleBlur}
            />
          </Col>
          <Col md="12">
            <LogButton
              className="mt-3"
              title="Crear cuenta"
              border="curve"
              block
              onClick={() =>
                !isValid
                  ? errorToast(
                      Object.values(errors)[0] || "Complete el formulario"
                    )
                  : handleSubmit()
              }
            />
          </Col>
        </Row>
      )}
    </Formik>
  );
};

export default StepTwo;
