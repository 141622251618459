import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import CardItemTravelBasic from "../../../components/molecules/CardItemTravelBasic";
import { GoTriangleRight } from "react-icons/go";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import PaginationList from "../../../../../components/organisms/pagination-list/PaginationList";
import { getTripsCreatedByStateAPI } from "../../../../../api/modules";
import ModalChatDriver from "../../../../../components/organisms/ModalChatDriver/ModalChatDriver";
import LogButton from "../../../../../components/atoms/LogButton";

const ContainerList = styled.div`
  height: auto;
  overflow-y: overlay;
  padding-right: 10px;
`;
const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;
const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const InActionsTravels = ({ trips, ...rest }) => {
  const location = useLocation();
  const history = useHistory();
  const spinner = useSelector((state) => state.appReducer.isLoading);
  const [page, setPage] = useState(0);
  const [modal, setModal] = useState(false);
  const numberList = 5;
  const INITIAL_STATE = {
    total: 0,
    items: [],
  };
  const [tripsAPI, setTripsAPI] = useState(INITIAL_STATE);
  const handleCloseModal = () => {
    setModal(false);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("modal", "false");
    history.push({ search: searchParams.toString() });
  };
  const handleOpenModal = () => {
    setModal(true);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("modal", "true");
    history.push({ search: searchParams.toString() });
  };
  const handlePreviewPage = () => {
    fetchData(numberList, numberList * page - numberList);
    setPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    fetchData(numberList, numberList * (page + 1));
  };
  const fetchData = async (limit = numberList, from = 0) => {
    const tripsResponse = await getTripsCreatedByStateAPI(
      numberList,
      from,
      "[3,4]"
    ); // status 4
    const { total, trips } = tripsResponse;
    if (trips)
      setTripsAPI({
        ...INITIAL_STATE,
        total,
        items: trips,
      });
  };
  useEffect(() => {
    fetchData(numberList, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("modal") === "true") {
      handleOpenModal();
    }
  }, [location.search]);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <ContainerList>
              {tripsAPI.items.length ? (
                <>
                  <LogButton
                    className="mt-2 ml-2"
                    onClick={handleOpenModal}
                    title="Chatear con chofer"
                  />

                  {tripsAPI?.items.map((location, index) => (
                    <CardItemTravelBasic
                      {...rest}
                      state="Ejecución"
                      btnIcon={<GoTriangleRight />}
                      key={index}
                      originName={location.origin_site.site_name}
                      destinyName={location.destiny_site.site_name}
                      withdrawDate={location.withdraw_date}
                      deliveryDate={location.delivery_date}
                      onClick={() => {
                        history.push(
                          `list-travels?state=2&tripId=${location._id || "0"}`
                        );
                      }}
                      trucks_number_accepted={location.counterTripdetails}
                      trucksNumber={location.trucks_number}
                      showId={location.id_trip}
                    />
                  ))}
                </>
              ) : (
                <DivEmpty>
                  {!spinner ? (
                    <h2 className="font-weight-bold h3 text-center">
                      No hay viajes disponibles
                    </h2>
                  ) : null}
                </DivEmpty>
              )}
            </ContainerList>
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={tripsAPI.items}
            total={tripsAPI.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
      </RowStyled>
      {modal ? (
        <ModalChatDriver isShow={modal} handleClose={handleCloseModal} />
      ) : null}
    </>
  );
};

export default InActionsTravels;
