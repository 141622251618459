import React, { useEffect, useState } from "react";

// react-bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { getUsersByRoles } from "../../../../api/modules";
import PaginationList from "../../../../components/organisms/pagination-list/PaginationList";
import ContentHeader from "../../components/molecules/ContentHeader";
import CustomItemList from "../../components/molecules/CustomItemList ";
import { GoTriangleRight } from "react-icons/go";

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const ContainerList = styled.div`
  overflow-y: overlay;
  padding-right: 10px;
`;

function DcUsers() {
  const id = useSelector((state) => state.authApp.user._id);
  const history = useHistory();
  const INITIAL_ENTITY = {
    items: [],
    total: 0,
  };
  const [users, setUsers] = useState(INITIAL_ENTITY);
  const [page, setPage] = useState(0);
  const numberList = 6;

  useEffect(() => {
    fetchData(numberList, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchData = async (limit = numberList, from = 0) => {
    setUsers(INITIAL_ENTITY);
    const response = await getUsersByRoles(6, from, '610d92c29aee9485732b203d');
    const { users, total } = response;
    if (users) {
      setUsers({
        items: users,
        total: total,
      })
    } else {
      setUsers(INITIAL_ENTITY);
    }
  }

  const handlePreviewPage = () => {
    fetchData(numberList, numberList * page - numberList);
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    fetchData(numberList, numberList * (page + 1));
  };

  return (
    <>
      <Container fluid>
        <ContentHeader
          title="Dadores de carga con viajes activos e inactivos"
        />
        <Row>
          <Col md="12">
            <ContainerList>
              {users.items.map(({ email, name, phone, last_name, cuit, _id, status }, index) => (
                <CustomItemList
                noIcon
                onClick={() =>
                  history.push(
                    `/app/users/user-profile/${_id}`
                  )
                }
                key={index}
              >
                <Row>
                  <Col className="text-center">
                    <small>
                      <p className="text-primary text-center mb-1">Cuit</p>
                    </small>
                    <p className="h5 font-weight-bold mt-2">{cuit || 'CUIT'}</p>
                  </Col>
                  <Col className="text-center">
                    <small>
                      <p className="text-primary text-center mb-1">Email</p>
                    </small>
                    <p className="h5 font-weight-bold mt-2">{email || 'Email'}</p>
                  </Col>
                  <Col className="text-center">
                    <small>
                      <p className="text-primary text-center mb-1">Nombre</p>
                    </small>
                    <p className="h5 font-weight-bold mt-2">{`${name} ${last_name}` || 'Nombre completo'}</p>
                  </Col>
                  <Col className="text-center">
                    <small>
                      <p className="text-primary text-center mb-1">Apellido</p>
                    </small>
                    <p className="h5 font-weight-bold mt-2">{last_name || 'Apellido'}</p>
                  </Col>
                  <Col
                    className="d-flex align-items-center text-right justify-content-end pr-4"
                  >
                    <GoTriangleRight />
                  </Col>
                </Row>
              </CustomItemList>
              ))}
              {users.items.length === 0 && (
                <h2 className="font-weight-bold h3">
                  Aún no tenés ningún vehículo registrado
                </h2>
              )}
            </ContainerList>
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={users.items}
            total={users.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
      </RowStyled>
    </>
  );
}

export default DcUsers;
