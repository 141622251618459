import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import LogButton from "../../atoms/LogButton";
const ContainerPagination = styled.div`
  width: 100%;
`;

const PaginationList = ({
  items,
  total,
  numberList,
  currPage,
  handlePreviewPage,
  handleNextPage,
}) => {
  const [isNext, setIsNext] = useState(true);

  useEffect(() => {
    if (items.length === total) {
      setIsNext(false);
      return;
    }
    const result = Math.trunc(items.length / numberList);
    setIsNext(result === 0 ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, total]);

  return (
    <ContainerPagination>
      <Row>
        <Col>
          {currPage ? (
            <LogButton
              title="Atrás"
              border="curve"
              onClick={handlePreviewPage}
            />
          ) : (
            ""
          )}
        </Col>
        <Col className="text-center">
          {null ? (
            <p>
              Tienes <span className="number-font">{total}</span> localizaciones
              disponibles
            </p>
          ) : (
            ""
          )}
        </Col>
        <Col className="text-right">
          {(isNext && ((currPage + 1) * numberList) !== total) && (
            <LogButton
              title="Siguiente"
              border="curve"
              onClick={handleNextPage}
            />
          )}
        </Col>
      </Row>
    </ContainerPagination>
  );
};

export default PaginationList;
