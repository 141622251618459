import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import {
  getDetailTrip,
  getDetailsTrip,
  getDetailsTripTc,
} from "../../../../../../api/modules";
import { ModalPendingTravel } from "./ModalPendingTravel";
import { ModalPendingUnits } from "./ModalPendingUnits";
import CardItemTravelPending from "../../../../components/molecules/CardItemTravelPending";
import CardItemOriginDestiny from "../../../../components/molecules/CardItemOriginDestiny";
import { useSelector } from "react-redux";

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const ContainerList = styled.div`
  overflow-y: overlay;
  padding-right: 10px;
`;

const PendingsDetails = ({id, tripId}) => {
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showModalUnits, setShowModalUnits] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [tripDetail, setTripDetail] = useState(null);
  const [tripDetails, setTripDetails] = useState(null);
  const [tripData, setTripData] = useState(null);
  const spinner = useSelector((state) => state.appReducer.isLoading);


  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const onRefetch = () => setRefetch((prev) => !prev);

  const openCloseModal = (childTrip) => {
    setTripDetails(childTrip);
    setShowModal((prev) => !prev);
  };

  const openCloseModalUnits = (childTrip) => {
    setTripDetails(childTrip);
    setShowModalUnits((prev) => !prev);
  };

  const fetchData = async () => {
    const response = await getDetailsTripTc(tripId, id);

    if (response) {
      setTripData(response.trip);
      setTripDetail(response.trip?.tripdetails);
    }
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <CardItemOriginDestiny
              originName={
                tripData?.origin_site.locality.name +
                ", " +
                tripData?.origin_site.locality.id_province?.name
              }
              destinyName={
                tripData?.destiny_site.locality.name +
                ", " +
                tripData?.destiny_site.locality.id_province?.name
              }
              deliveryDate={tripData?.delivery_date}
              withdrawDate={tripData?.withdraw_date}
              /* onClickDetail={handleContactShow} */
              destiny={tripData?.destiny}
              origin={tripData?.origin}
            />
          </Col>
        </Row>
        <Row>
          <Col md="12"></Col>
        </Row>
        <Row>
          <Col md="12">
            <ContainerList>
              {tripDetail?.length ? (
                tripDetail?.map((item, index) =>
                  item.state === 2 ? (
                    <CardItemTravelPending
                      key={index}
                      openCloseModal={openCloseModal}
                      openCloseModalUnits={openCloseModalUnits}
                      item={item}
                      isDc={true}
                      originName={
                        tripData?.origin_site.locality.name +
                        ", " +
                        tripData?.origin_site.locality.id_province?.name
                      }
                      destinyName={
                        tripData?.destiny_site.locality.name +
                        ", " +
                        tripData?.destiny_site.locality.id_province?.name
                      }
                      withdrawDate={tripData?.withdraw_date}
                      deliveryDate={tripData?.delivery_date}
                      tripPrice={
                        tripData.price_type === "2"
                          ? tripData.price_per_truck
                          : null
                      }
                      chargeType={tripData?.product.product_type_name}
                    ></CardItemTravelPending>
                  ) : null
                )
              ) : (
                <DivEmpty>
                  {!spinner ? (
                    <h2 className="font-weight-bold h3 text-center">
                      No hay viajes disponibles
                    </h2>
                  ) : null}
                </DivEmpty>
              )}
            </ContainerList>
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12"></Col>
      </RowStyled>
      <ModalPendingTravel
        show={showModal}
        openCloseModal={openCloseModal}
        onRefetch={onRefetch}
        trip={tripDetails}
      ></ModalPendingTravel>
      <ModalPendingUnits
        show={showModalUnits}
        openCloseModal={openCloseModalUnits}
        onRefetch={onRefetch}
        trip={tripDetails}
      ></ModalPendingUnits>
    </div>
  );
};

export default PendingsDetails;
