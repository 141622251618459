import { apiHttp } from "../axiosApi.js";
import {
  truckCharacteristic,
} from "../config/apiRoute.js";

export const postTruckCharacteristicAPI = (body) => apiHttp("POST", `${truckCharacteristic}`, body);


export const getTruckCharacteristicByTruckIdAPI = (id) =>
  apiHttp("GET", `${truckCharacteristic}/${id}`);


export const getTruckCharacteristicAPI = () =>
  apiHttp("GET", `${truckCharacteristic}`);

export const getTruckCharacteristicsAPI = (limit = 5, from = 0) =>
  apiHttp("GET", `${truckCharacteristic}?limit=${limit}&from=${from}`);