import React from "react";
import { Col, Form, Modal, Row, Stack } from "react-bootstrap";
import styled from "styled-components";
import { FiAlertCircle, FiX } from "react-icons/fi";
import { BiStar } from "react-icons/bi";
import LogButton from "../../../../../../components/atoms/LogButton";
import { COLOR_GRAY_TEXT } from "../../../../../../constants/Colors";
import LogInput from "../../../../../../components/atoms/LogInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { patchTripDetails } from "../../../../../../api/modules";
import {
  errorToast,
  successToast,
} from "../../../../../../services/toastService";

const ModalLogStyled = styled(Modal)`
  opacity: 1;
  .modal-content {
    border-radius: 15px !important;
    padding: 20px;
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;

const ProfileSchema = Yup.object().shape({
  auditedWeight: Yup.number()
    .required("Peso Auditado es requerido")
    .positive("El Peso Auditado debe ser mayor a cero")
    .test(
      "decimal-places",
      "El Peso Auditado debe tener como máximo tres decimales",
      (value) =>
        value === undefined ||
        value === null ||
        /^\d+(\.\d{1,3})?$/.test(value.toString())
    ),
});

const DocumentContainer = styled(Row)`
  max-height: 200px;
  overflow: auto;
`;

const ContentModal = styled.div`
  text-align: center;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #000;
  &:hover {
    color: #ff0000;
  }
`;

const ModalAuditedWeight = ({ isShow, handleClose, data, refetch }) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      auditedWeight:
        typeof data?.auditedWeight === "number" ? data?.auditedWeight : "",
    },
    onSubmit: async (values) => {
      const { auditedWeight } = values;
      const resp = await patchTripDetails(data._id, { auditedWeight });
      const { msg } = resp;
      if (msg) {
        errorToast(msg || "Intente nuevamente");
        resetForm();
      } else {
        successToast(`Peso auditado con éxito`);
        refetch();
        resetForm();
        handleClose();
      }
    },
    validationSchema: ProfileSchema,
  });
  return (
    <ModalLogStyled
      show={isShow}
      onHide={() => {
        resetForm();
        handleClose();
      }}
      centered
      className="modal-exit"
    >
      <Modal.Header>
        <CloseButton
          onClick={() => {
            resetForm();
            handleClose();
          }}
        >
          <FiX />
        </CloseButton>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Row>
          <Col className="text-left">
            <h4 className="font-weight-bold text-primary h5">
              {"ID Viaje " +
                (data.parent_id && data.order
                  ? data.parent_id + data.order
                  : "No posee")}
            </h4>
            {data?.auditedBy ? (
              <h6 className="font-weight-bold text-primary h6">
                {" "}
                {"Auditado por " + data.auditedBy.email}
              </h6>
            ) : null}
            <hr />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="black-primary font-weight-bold">
              Peso Informado por el chofer
            </label>
            <LogInput
              placeholder="Unidad"
              className="mt-1"
              type="text"
              value={data?.realTransportedWeight || "No Informado"}
              errors={{ number_trucks: "" }}
              name="number_trucks"
              readOnly
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="black-primary font-weight-bold">
              Documentacion enviada
            </label>
            <DocumentContainer>
              {data?.documents?.map((link, index) => (
                <Col xs={6}>
                  <a
                    key={index}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Documento {index + 1}
                  </a>
                </Col>
              ))}
            </DocumentContainer>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group>
              <label className="black-primary font-weight-bold">
                {data?.auditedBy
                  ? "Modificar peso auditado"
                  : "Ingresar peso verificado" +
                    " (" +
                    data?.unit_truck_charge?.charAt(0).toUpperCase() +
                    data?.unit_truck_charge?.slice(1) +
                    ")"}
              </label>
              <LogInput
                placeholder="Unidad"
                className="mt-1"
                type="number"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.auditedWeight}
                errors={errors}
                name="auditedWeight"
              />
            </Form.Group>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="footer-modal">
        <Col className="d-flex justify-content-center">
          <LogButton
            className="w-50"
            color="primary"
            onClick={handleSubmit}
            title={"Editar"}
            border="curve"
          />
        </Col>
      </Modal.Footer>
    </ModalLogStyled>
  );
};

export default ModalAuditedWeight;
