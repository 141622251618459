import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { socketMiddleware } from "./middleware/socketMiddleware";
const API_URL_BACKEND = process.env.REACT_APP_BACKEND_API



const middleware = [thunk, socketMiddleware(API_URL_BACKEND.substring(0, API_URL_BACKEND.length - 4))];

export default function configureStore(initialState) {
  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
  );
}
