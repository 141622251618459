import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import queryString from "query-string";
import MyOffers from "./MyOffers";
import MyOfferDetail from "./MyOfferDetail";

const ContainerList = styled.div`
  overflow-y: overlay;
`;

const OffersScreen = ({ trips, state, ...rest }) => {
  const location = useLocation();
  const [tripId, setTripId] = useState(null);

  useEffect(() => {
    const { tripId } = queryString.parse(location.search);
    setTripId(tripId);
  }, [location]);

  const getScreen = tripId ? <MyOfferDetail id={tripId} /> : <MyOffers />;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <ContainerList>{getScreen}</ContainerList>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OffersScreen;
