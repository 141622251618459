import React from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const LogInputPhone = ({
  placeholder,
  className,
  errors,
  messageError,
  name,
  handleBlur,
  ...rest
}) => {
  let mb0 = errors[name] ? "true" : "false";
  return (
    <>
      <PhoneInput
        containerStyle={{ width: "100%" }}
        autoFormat={false}
        /* specialLabel="Teléfono" */
        inputStyle={{
          width: "100%",
          backgroundColor: "#f6f6f4",
          border: "none",
          padding: "25px 5px",
          fontSize: "1rem",
          paddingLeft: 40,
        }}
        buttonStyle={{ border: "none", backgroundColor: "none" }}
        {...{ ...rest, name, mb0 }}
        className={`${className}  ${errors[name] && "is-invalid"}`}
        placeholder={placeholder}
        country={"ar"}
        onBlur={handleBlur}
      />
      {errors[name] && (
        <div className="mb-2 mt-0">
          <small className="text-danger">{errors[name]}.</small>
        </div>
      )}
    </>
  );
};

LogInputPhone.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default LogInputPhone;
