import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { Checkbox, Table } from "rsuite";
const { Column, HeaderCell, Cell, ColumnGroup } = Table;

function BillingTable({ data  }) {
  const [billingData, setBillingData] = useState([]);

  useEffect(() => {
    let runningBalance = data?.invoice_id?.amount; 

    const formattedBillingData = [
      {
        invoice_date: data?.invoice_id?.invoice_date,
        invoice_number: data?.invoice_id?.invoice_number,
        amount: data?.invoice_id?.amount,
        saldo: data?.invoice_id?.amount, 
      },
      ...data?.invoice_id.receipts.map((receipt) => {
        runningBalance -= receipt.receipt_amount;
        return {
          ...receipt,
          saldo: runningBalance,
        };
      }),
    ];

    setBillingData(formattedBillingData);
  }, [data]);

  return (
    <Row className="mt-4">
      <Col md="12">
        <Table
          height={550}
          bordered
          cellBordered
          wordWrap={true}
          data={billingData}
        >
          <Column flexGrow={1} align="center">
            <HeaderCell>Fecha</HeaderCell>
            <Cell dataKey="fecha">
              {(rowData) => (
                <p>
                  {moment
                    .utc(rowData.invoice_date || rowData.receipt_date)
                    .format("DD/MM/YYYY")}
                </p>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>Comprobante</HeaderCell>
            <Cell dataKey="comprobante">
              {(rowData) => (
                <p>{rowData.invoice_date ? "Factura" : "Recibo"}</p>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="right">
            <HeaderCell>Nro de Comprobante</HeaderCell>
            <Cell dataKey="nroComprobante">
              {(rowData) => (
                <p>{rowData.invoice_number || rowData.receipt_number}</p>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="right">
            <HeaderCell>Importe</HeaderCell>
            <Cell dataKey="importe">
              {(rowData) => (
                <p>
                  {" "}
                  <CurrencyFormat
                    value={rowData.amount || -rowData.receipt_amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={"$"}
                  />
                </p>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="right">
            <HeaderCell align="middle">Saldo</HeaderCell>
            <Cell dataKey="saldo">
              {(rowData) => (
                <p>
                  {" "}
                  <CurrencyFormat
                    value={rowData.saldo}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    prefix={"$"}
                  />
                </p>
              )}
            </Cell>
          </Column>
        </Table>
      </Col>
    </Row>
  );
}

export default BillingTable;
