/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Col,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
  Container,
} from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { getTripAPI } from "../../../../../api/modules";
import LogButton from "../../../../../components/atoms/LogButton";
import { CardLogBootstrapStyled } from "../../../../../styledComponents/CardBootstrapLog";
import {
  priceCalculation,
  resetCurrency,
  turnString,
} from "../../../../../utils/helpers";
import ModalPostulation from "../../Modal-Postulation/ModalPostulation";
import { IoIosArrowBack } from "react-icons/io";
import { Button } from "react-bootstrap";
import ModalMapView from "./ModalMapView/ModalMapView";
import { toNumber } from "lodash";
import { useSelector } from "react-redux";
import ModalRating from "../../Modal-Rating/ModalRating";
const PAYMENT_TYPE_NAME = process.env.REACT_APP_TOMADOR_PAYMENT_NAME;

const AvatarLog = styled(Image)`
  width: 45px;
`;

const DivInfoUser = styled.div`
  display: flex;
`;

const DivBtnShowInfo = styled.div`
  min-width: 100px;
  height: 50px;
  color: rgb(163, 199, 59);
  background-color: #effbcd;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 10px;
`;

const convertItemIntoCommatsDot = (array) => {
  return [array.slice(0, -1).join(", "), array.slice(-1)[0]].join(
    array.length < 2 ? "" : " y "
  );
};

const TravelDetail = () => {
  const params = useParams();
  const history = useHistory();
  const [entity, setEntity] = useState(null);
  const user = useSelector((state) => state.authApp.user);
  const [isPostulate, setIsPostulate] = useState(false);
  const [isModalMapView, setIsModalMapView] = useState(false);
  const [isRating, setIsRating] = useState(false);

  useEffect(() => {
    getFetch();
  }, []);

  const getFetch = async () => {
    const { id } = params;
    const response = await getTripAPI(id);
    const { trip } = response?.data || {};
    if (trip) {
      let namesTruckFeatures = trip.truck_features.map(
        (tf) => tf.truck_characteristic_name
      );
      let namesTruckAccessories = trip.truck_accessories.map(
        (tf) => tf.accesory_name
      );
      let namesTruckTypes = trip.truck_type.map((tf) => tf.truck_type_name);
      setEntity({
        ...trip,
        truck_features: convertItemIntoCommatsDot(namesTruckFeatures),
        truck_accessories: convertItemIntoCommatsDot(namesTruckAccessories),
        truck_type: convertItemIntoCommatsDot(namesTruckTypes),
      });
    }
  };

  return entity ? (
    <Container fluid>
      <Row>
        <Col>
          <Button
            variant="transparent"
            className="font-weight-bold h2"
            onClick={() => history.goBack()}
          >
            <h2 className="font-weight-bold h3">
              <IoIosArrowBack className="mr-2" />
              Detalles del viaje
            </h2>
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md="6">
          <DivInfoUser>
            <AvatarLog
              style={{ position: "initial", width: "60px" }}
              width="60px"
              height="60px"
              src="https://randomuser.me/api/portraits/men/43.jpg"
              roundedCircle
              className="mr-1"
            />
            <div
              className="ml-2 cursor-pointer"
              onClick={() => setIsRating(true)}
            >
              <p className="font-weight-bold mb-0 text-capitalize h4">
                {entity.user_id_dc.name} {entity.user_id_dc.last_name}
              </p>
              {entity._id && (
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-left`}>
                      {entity.score ? "Puntaje " + entity.score : "Sin puntaje"}
                    </Tooltip>
                  }
                >
                  <Row className="ml-0">
                    <ReactStars
                      value={toNumber(entity.score) || 0}
                      count={5}
                      size={36}
                      activeColor="#ffd700"
                      color="#CDE0EB"
                      isHalf={true}
                      edit={false}
                    />
                  </Row>
                </OverlayTrigger>
              )}
            </div>
          </DivInfoUser>
        </Col>
        <Col
          md="6"
          className="d-flex"
          style={{ justifyContent: "space-evenly" }}
        >
          <DivBtnShowInfo>{entity.product.product_type_name}</DivBtnShowInfo>

          <DivBtnShowInfo>
            {`Distancia: ${entity.kilometers} km`}
          </DivBtnShowInfo>

          <LogButton
            title="Ver mapa"
            border="curve"
            onClick={() => {
              setIsModalMapView(true);
            }}
            color="transparent"
            style={{ color: "black" }}
          ></LogButton>
        </Col>
      </Row>
      <Row>
        <Col md="6" className="mt-4">
          <CardLogBootstrapStyled className="shadow-6dp p-4">
            <Row>
              <Col className="d-flex align-items-center">
                <div className="circle-icon">
                  <span className="icon-ping text-primary"></span>
                </div>
                <p className="mb-0 text-primary h4">
                  <strong>Origen</strong>{" "}
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <p className="mb-0">
                  {" "}
                  <strong>Dirección:</strong> {entity.origin_site.address}
                </p>
                <p className="mb-0">
                  {" "}
                  <strong>Tipo de origen:</strong> {entity.origin}
                </p>
                <p className="mb-0">
                  {" "}
                  <strong>Fecha:</strong>{" "}
                  {moment(entity.withdraw_date).format("DD/MM/YYYY")}, turno{" "}
                  {turnString(entity.withdraw_turn).toLowerCase()}
                </p>
              </Col>
            </Row>
          </CardLogBootstrapStyled>
        </Col>
        <Col md="6" className="mt-4">
          <CardLogBootstrapStyled className="shadow-6dp p-4">
            <Row>
              <Col className="d-flex align-items-center">
                <div className="circle-icon">
                  <span className="icon-ping text-primary"></span>
                </div>
                <p className="mb-0 text-primary h4">
                  <strong>Destino</strong>{" "}
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <p className="mb-0">
                  {" "}
                  <strong>Dirección:</strong> {entity.destiny_site.address}
                </p>
                <p className="mb-0">
                  {" "}
                  <strong>Tipo de destino:</strong> {entity.destiny}
                </p>
                <p className="mb-0">
                  {" "}
                  <strong>Fecha:</strong>{" "}
                  {moment(entity.delivery_date).format("DD/MM/YYYY")}, turno{" "}
                  {turnString(entity.delivery_turn).toLowerCase()}
                </p>
              </Col>
            </Row>
          </CardLogBootstrapStyled>
        </Col>
      </Row>
      <Row>
        <Col md="6" className="mt-4">
          <CardLogBootstrapStyled className="shadow-6dp p-4">
            <Row>
              <Col className="d-flex align-items-center">
                <div className="circle-icon">
                  <span className="icon-truck text-primary"></span>
                </div>
                <p className="mb-0 text-primary h4">
                  <strong>Camiones</strong>{" "}
                </p>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <p className="mb-0">
                  <strong>Camiones solicitados:</strong> {entity.trucks_number}
                </p>
                <p className="mb-0">
                  <strong>Carga por camión:</strong> {entity.truck_charge}{" "}
                  {entity.unit_truck_charge}
                </p>
                <p className="mb-0">
                  <strong>Tipo:</strong> {entity.truck_type}
                </p>
                <p className="mb-0">
                  <strong>Características y accesorios:</strong>{" "}
                  {entity.truck_features}, &nbsp;
                  {entity.truck_accessories}
                </p>
              </Col>
            </Row>
          </CardLogBootstrapStyled>
        </Col>
        <Col md="6" className="mt-4">
          <CardLogBootstrapStyled
            className="shadow-6dp p-4"
            style={{ height: "100%" }}
          >
            <Row>
              <Col className="d-flex align-items-center">
                <div className="circle-icon">
                  <span className="icon-cash text-primary"></span>
                </div>
                <p className="mb-0 text-primary h4">
                  <strong>Tarifa</strong>{" "}
                </p>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col>
                <p className="mb-0 text-capitalize">
                  <strong>
                    {entity.discount_trip || entity.discount_dador
                      ? priceCalculation(
                          entity.price_per_ton,
                          typeof entity.discount_trip === "number"
                            ? entity.discount_trip
                            : user.discount_factor + entity.discount_dador
                        )
                      : entity.price_per_ton}
                    {"/" + entity.unit_truck_charge?.slice(0, 3)} (precio{" "}
                    {entity.price_type === "1" ? "abierto" : "cerrado"})
                  </strong>
                </p>
                <p className="mb-0">
                  <strong>Forma de pago:</strong>{" "}
                  {PAYMENT_TYPE_NAME || "E-cheq"}
                </p>
                <p className="mb-0">
                  <strong>Plazo de pagos en días:</strong>{" "}
                  {entity.payment_terms_days}
                </p>
              </Col>
            </Row>
          </CardLogBootstrapStyled>
        </Col>
      </Row>
      <Row>
        <Col>
          <h1 className="text-primary font-weight-bold h3 mt-3">
            Requisitos y condiciones:{" "}
            <span className="font-weight-normal h4" style={{ color: "black" }}>
              {entity.requirements_conditions || "No disponible"}
            </span>
          </h1>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <LogButton
            className="mt-3 w-100"
            title="Ofertar"
            border="curve"
            block
            size="lg"
            onClick={() => {
              setIsPostulate(true);
            }}
          />
        </Col>
      </Row>
      {entity._id && (
        <ModalPostulation
          idTrip={entity._id}
          data={{
            price_ton_postulation: resetCurrency(
              entity.discount_trip || entity.discount_dador
                ? priceCalculation(
                    entity.price_per_ton,
                    typeof entity.discount_trip === "number"
                      ? entity.discount_trip
                      : user.discount_factor + entity.discount_dador
                  )
                : entity.price_per_ton
            ),
            payment_terms_days_postulation: entity?.payment_terms_days,
          }}
          isPostulate={isPostulate}
          onClosePostulation={(payload) => {
            setIsPostulate(false);
            if (payload === "success") {
              history.push("/app/my-travls-to-work");
            }
          }}
          modalType={entity.price_type}
        ></ModalPostulation>
      )}
      {entity._id && (
        <ModalMapView
          isModalMapView={isModalMapView}
          entity={entity}
          onClosePostulation={(payload) => {
            setIsModalMapView(false);
          }}
        ></ModalMapView>
      )}

      <ModalRating
        tripId={entity._id}
        showId={entity.id_trip}
        score={entity.score}
        isRating={isRating}
        onCloseRating={() => {
          setIsRating(false);
        }}
      />
    </Container>
  ) : null;
};

export default TravelDetail;
