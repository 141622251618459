import React, { useEffect, useState } from "react";

// react-bootstrap components
import { Container, Row, Col, } from "react-bootstrap";
import { useSelector } from "react-redux";
import LogButton from "../../../../components/atoms/LogButton";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import PaginationList from "../../../../components/organisms/pagination-list/PaginationList";
import { getTruckCharacteristicsAPI } from "../../../../api/modules";
import CustomItemList from "../../components/molecules/CustomItemList ";

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const ContainerList = styled.div`
  overflow-y: overlay;
  padding-right: 10px;
`;

function TruckCharacs() {
  const id = useSelector((state) => state.authApp.user._id);
  const history = useHistory();
  const INITIAL_ENTITY = {
    items: [],
    total: 0,
  };
  const [trucksAPI, setTrucksAPI] = useState(INITIAL_ENTITY);
  const [page, setPage] = useState(0);
  const numberList = 6;

  useEffect(() => {
    fetchData(numberList, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchData = async (limit = numberList, from = 0) => {
    setTrucksAPI(INITIAL_ENTITY);
    const response = await getTruckCharacteristicsAPI(6, from);
    const { truckChars, total } = response;
    if (truckChars) {
      setTrucksAPI({
        items: truckChars,
        total: total,
      })
    } else {
      setTrucksAPI(INITIAL_ENTITY);
    }
  }


  const handlePreviewPage = () => {
    fetchData(numberList, numberList * page - numberList);
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    fetchData(numberList, numberList * (page + 1));
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <ContainerList>
              {trucksAPI.items.map(({ truck_characteristic_name, _id, _truckType }, index) => (
                <CustomItemList
                  noIcon
                  onClick={() => { }
                    // history.push(
                    //   `/app/my-units/edit-unit-form/${_id}`
                    // )
                  }
                  key={index}
                >
                  <Row>
                    <Col
                      md="6"
                      className="text-center d-flex justify-content-center align-items-center flex-wrap"
                    >
                      <small style={{ width: '100%' }}>
                        <p className="text-primary text-center mb-1">Nombre de la característica</p>
                      </small>
                      <p className="h5 font-weight-bold mt-2">{truck_characteristic_name || 'No posee'}</p>
                    </Col>
                    <Col md="6" className="text-center">
                      <small style={{ width: '100%' }}>
                        <p className="text-primary text-center mb-1">Tipos de camión asociados</p>
                      </small>
                      <p className="h5 font-weight-bold mt-2">{_truckType?.length || '0'}</p>
                    </Col>
                  </Row>
                </CustomItemList>
              ))}
              {trucksAPI.items.length === 0 && (
                <h2 className="font-weight-bold h3">
                  Aún no tenés ningún vehículo registrado
                </h2>
              )}
            </ContainerList>
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={trucksAPI.items}
            total={trucksAPI.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
        <Col md="12">
          <LogButton
            className="mt-3 btn-list-bottom"
            title="Agregar característica"
            border="curve"
            block
            color="primary"
            size="lg"
            onClick={() =>
              history.push("/app/truck-charac/charac-form")
            }
          />
          <div className="clearfix"></div>
        </Col>
      </RowStyled>
    </>
  );
}

export default TruckCharacs;
