import React from "react";
import { Modal } from "react-bootstrap";
import LogButton from "../../atoms/LogButton";
import styled from "styled-components";
import { FiAlertCircle } from "react-icons/fi";
import { COLOR_GRAY_TEXT } from "../../../constants/Colors";

const ModalLogStyled = styled(Modal)`
  opacity: 1;
  .modal-content {
    border-radius: 15px !important;
    padding: 20px;
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;

const ContentModal = styled.div`
  text-align: center;
`;

const ModalQuestion = ({
  isShow,
  handleClose,
  title,
  subTitle,
  icon,
  okText,
  noText,
}) => {
  return (
    <ModalLogStyled show={isShow} onHide={handleClose} className="modal-exit">
      <Modal.Body>
        <ContentModal>
          {icon || <FiAlertCircle className="display-3 text-primary mb-3" />}

          <h4 className="font-weight-bold text-primary">
            {title || "¿Estás seguro de esta accion?"}
          </h4>
          <p>{subTitle || "¿Estás seguro?"}</p>
        </ContentModal>
      </Modal.Body>
      <Modal.Footer className="footer-modal">
        <LogButton
          color="primary"
          onClick={() => handleClose(true)}
          title={okText || "Si, estoy de acuerdo"}
          border="curve"
        ></LogButton>
        <LogButton
          color="secondary"
          onClick={() => handleClose(false)}
          title={noText || "No estoy de acuerdo"}
          border="curve"
        ></LogButton>
      </Modal.Footer>
    </ModalLogStyled>
  );
};

export default ModalQuestion;
