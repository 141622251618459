import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import queryString from "query-string";

import LogCard from "../../../components/atoms/LogCard";

import {
  DivAuthScreen,
  DivNavAuthStyled,
} from "../../../styledComponents/DivNavAuthStyled";
import { useHistory, useLocation } from "react-router-dom";
import StepOne from "./Forms/StepOne";
import StepTwo from "./Forms/StepTwo";
import {
  postRegister,
  postRegisterGoogle,
  putValidatePhone,
  putValidatePhoneGoogle,
} from "../../../api/modules";
import { errorToast, successToast } from "../../../services/toastService";
import LogLogo from "../../../components/atoms/LogLogo";
import ModalValidatePhone from "../../../components/molecules/modal-validate-phone/ModalValidatePhone";
import { DADOR_DE_CARGA, TOMADOR_DE_CARGA } from "../../../constants/UserType";

const SignUp = () => {
  const location = useLocation();
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
    name: "",
    lastName: "",
    phoneNumber: "",
    reason: "",
    cuit: "",
  });
  const [modalShowPhone, setModalShowPhone] = useState(false);
  const [socialUser, setSocialUser] = useState(null);

  const userType =
    queryString.parse(location.search).type === "carriers"
      ? TOMADOR_DE_CARGA
      : DADOR_DE_CARGA;

  const handleSubmit = async (body) => {
    let bodyRequest;
    if (socialUser) {
      bodyRequest = {
        ...socialUser,
        reason: body.socialReason,
        phone: "+" + body.phoneNumber,
        cuit: body.cuit,
        role_id: userType,
      };
      const { msg, errors } = await postRegisterGoogle(bodyRequest);
      if (msg) {
        errorToast(msg || errors);
        history.push("/which-user");
      } else {
        successToast(
          "Usuario creado con éxito, Por favor valide su número de teléfono"
        );
        setModalShowPhone(true);
      }
    } else {
      bodyRequest = {
        email: body.email,
        password: body.password,
        name: body.name,
        last_name: body.lastName,
        reason: body.socialReason,
        phone: "+" + body.phoneNumber,
        cuit: body.cuit,
        role_id: userType,
        accepted_agreement: body.accepted_agreement || false,
      };
      const response = await postRegister(bodyRequest);
      const { msg, errors } = response;
      if (msg) {
        errorToast(msg || errors);
      } else {
        successToast(
          "Usuario creado con éxito, Por favor revise el correo electrónico y su número de teléfono para validarlos"
        );
        setModalShowPhone(true);
      }
    }
  };

  const handlerModalPhone = async (body) => {
    if (socialUser) {
      const { msg, status } = await putValidatePhoneGoogle({
        ...body,
        id_token: socialUser?.id_token,
      });
      if (status) {
        errorToast(msg);
      } else {
        successToast(msg);
        setModalShowPhone(false);
        history.push({
          pathname: "/sign-in",
          search: `?email=${""}`,
        });
      }
    } else {
      const bodyRequest = {
        ...body,
        email: newUser.email,
      };

      const { msg, status } = await putValidatePhone(bodyRequest);
      if (status) {
        errorToast(msg);
      } else {
        successToast(msg);
        setModalShowPhone(false);
        history.push({
          pathname: "/sign-in",
          search: `?email=${newUser.email}`,
        });
      }
    }
  };

  const handleUpgradeUser = (body, final = false) => {
    setNewUser((prevState) => {
      return {
        ...prevState,
        ...body,
      };
    });

    // final step for registering
    if (final)
      handleSubmit({
        ...newUser,
        ...body,
      });
  };

  const handleGoogleResponse = async (body) => {
    if (body) {
      const { id_token } = body;
      setSocialUser({
        id_token,
      });
      setStep(1);
    }
  };

  const handleFbResponse = async (body) => {
    //dispatch({ type: SET_LOADING_SPINNER_ON });
    //const response = await postAuthGoogle(body);
    //dispatch({ type: SET_LOADING_SPINNER_OFF });
  };

  const handleStepper = (nextStep) => {
    setStep(nextStep);
  };

  return (
    <DivAuthScreen>
      <DivNavAuthStyled>
        <LogLogo className="mt-2" />
      </DivNavAuthStyled>
      <div className="content-page">
        <Row>
          <Col
            md="6"
            className="d-flex flex-column align-content-center justify-content-center"
          >
            <div className="ml-4 mt-5 mb-4">
              <h1 className="mb-0 font-weight-bold">
                Entregá tu carga <span className="text-primary"> a tiempo</span>{" "}
                y <span className="text-primary">de manera segura</span> con
                BuenaRuta
              </h1>
            </div>
          </Col>
          <Col
            md="6"
            className="d-flex jusitfy-content-center align-items-center"
          >
            <LogCard
              width="460px"
              className="pl-3 pr-3 pt-5 pb-5 p-md-5 rounded"
            >
              {step === 0 ? (
                <StepOne
                  newUser={newUser}
                  handleStepper={handleStepper}
                  handleUpgradeUser={handleUpgradeUser}
                  handleGoogleResponse={handleGoogleResponse}
                  handleFbResponse={handleFbResponse}
                />
              ) : (
                <StepTwo
                  newUser={newUser}
                  socialUser={socialUser}
                  handleStepper={handleStepper}
                  handleUpgradeUser={handleUpgradeUser}
                />
              )}
            </LogCard>
          </Col>
        </Row>
        <ModalValidatePhone
          isShow={modalShowPhone}
          handleClose={() => {
            setModalShowPhone(false);

            history.push({
              pathname: "/sign-in",
              search: `?email=${newUser.email}`,
            });
          }}
          handleAction={handlerModalPhone}
        />
      </div>
    </DivAuthScreen>
  );
};

export default SignUp;
