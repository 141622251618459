import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import LogButton from "../../atoms/LogButton";
import styled from "styled-components";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { COLOR_GRAY_TEXT } from "../../../constants/Colors";

const ModalLogStyled = styled(Modal)`
  opacity: 1;
  .modal-content {
    border-radius: 15px !important;
    padding: 20px;
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;

const ContentModal = styled.div`
  text-align: center;
`;

const ContentFooterCenter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const ModalInfo = ({
  isShow,
  handleClose,
  title,
  subTitle,
  icon,
  okText,
  colorText,
  permanent = false,
}) => {
  useEffect(() => {
    let index;
    if (isShow) {
      index = setTimeout(() => {
        permanent || handleClose(true);
      }, 4000);
    }

    return () => {
      clearTimeout(index);
    };
  }, [isShow, handleClose]);

  return (
    <ModalLogStyled show={isShow} onHide={handleClose} className="modal-exit">
      <Modal.Body>
        <ContentModal>
          {icon || (
            <AiOutlineCheckCircle className="display-3 text-primary mb-3" />
          )}

          <h4 className={`font-weight-bold ${colorText || "text-primary"}`}>
            {title || "¿Estás seguro de esta accion?"}
          </h4>
          <p>{subTitle || "Success"}</p>
        </ContentModal>
      </Modal.Body>
      <Modal.Footer>
        <ContentFooterCenter>
          <LogButton
            color="primary"
            onClick={() => handleClose(true)}
            title={okText || "Listo"}
            border="curve"
            block
            style={{ width: 150 }}
          ></LogButton>
        </ContentFooterCenter>
      </Modal.Footer>
    </ModalLogStyled>
  );
};

ModalInfo.propTypes = {};

export default ModalInfo;
