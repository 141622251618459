import React from "react";
import styled from "styled-components";
import { Badge, Card, Col, Row, Stack } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import { COLOR_PRIMARY } from "../../../../constants/Colors";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import { BsStarFill } from "react-icons/bs";
import { toNumber } from "lodash";

const CardStyled = styled(Card)`
  border-radius: 15px;
  cursor: pointer;
  padding: 10px 20px;
  &:hover {
    opacity: 0.8;
  }
`;

const BadgeLog = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  display: inline-block;
  padding: 0px 20px;
`;

const DivSegments = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
`;
const DivUserInformation = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-top: 6px;
`;

const CardItemTravelPostulated = ({
  trucksNumber,
  originName,
  destinyName,
  withdrawDate,
  deliveryDate,
  product_type_name,
  onClick,
  className,
  state,
  btnIcon,
  idTrip,
  userDc,
  score = 0,
  onDelete,
  ...rest
}) => {
  const { name, last_name } = userDc;
  return (
    <CardStyled
      className={`${className} shadow-4dp m-2`}
      onClick={onClick}
      {...rest}
    >
      <Card.Body>
        <Row className="flex align-items-center">
          {/* <Row className="align-content-center align-align-items-center"> */}
          <Col md="2" className="d-flex justify-content-center p-0">
            <Stack>
              <ReactStars
                value={toNumber(score)}
                count={5}
                size={20}
                activeColor="#ffd700"
                color="#CDE0EB"
                char={<BsStarFill />}
                edit={false}
              />

              <p className="text-center font-weight-bold mb-0">
                {product_type_name}
              </p>
            </Stack>
          </Col>
          <Col md="1" className="text-center">
            <span className="font-weight-bold">{"ID " + idTrip}</span>
          </Col>
          <Col md="7">
            {/* <DivSegments> */}
            <Row className="flex align-items-center mt-2 mt-sm-0">
              <Col md="5" className="text-center text-sm-left">
                <p className="mb-0 pt-0 mt-0 font-weight-bold">{originName}</p>
                <p className="mb-0 pt-0 mt-0">
                  Retiro{" "}
                  <span className="number-font">
                    {moment.utc(withdrawDate).format("DD/MM/YYYY")}
                  </span>{" "}
                </p>
              </Col>
              <Col md="2" className="text-center">
                <AiOutlineArrowRight className="text-primary h1 ml-2 mr-2" />
              </Col>
              <Col md="5" className="text-center text-sm-left">
                <p className="mb-0 pt-0 mt-0 font-weight-bold">{destinyName}</p>
                <p className="mb-0 pt-0 mt-0">
                  {" "}
                  Entrega{" "}
                  <span className="number-font">
                    {moment.utc(deliveryDate).format("DD/MM/YYYY")}
                  </span>{" "}
                </p>
              </Col>
            </Row>

            {/* </DivSegments> */}
          </Col>
          {/* <Col md="1"></Col> */}
          <Col md="2" className="text-center text-sm-right mt-3 mt-sm-0">
            {btnIcon || <p className="text-secondary mb-0">Ver detalle</p>}
            {onDelete && (
              <p
                className="text-danger mb-0 zIndex-10"
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete();
                }}
              >
                Eliminar
              </p>
            )}
          </Col>
        </Row>
      </Card.Body>
    </CardStyled>
  );
};

export default CardItemTravelPostulated;
