import { Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import { CardLogBootstrapStyled } from "../../../../styledComponents/CardBootstrapLog";
import * as Yup from "yup";
import LogSelect from "../../../../components/atoms/LogSelect";
import LogButton from "../../../../components/atoms/LogButton";
import LogInput from "../../../../components/atoms/LogInput";

const SearchSchema = Yup.object().shape({
  unitType: Yup.string().nullable(),
  domain: Yup.string(),
  cuit: Yup.string(),
});

const unitTypeItems = [
  { value: "1", label: "Camión" },
  { value: "2", label: "Acoplado" },
  { value: "0", label: "Ambos" },
];

const SearchComponent = ({ onHandleSearchSubmit }) => {
  const handleSubmit = (values) => {
    onHandleSearchSubmit(values);
  };
  return (
    <CardLogBootstrapStyled className="shadow-6dp p-4 ml-2 mr-3 mt-3 mb-4">
      <Row className="mb-3">
        <Col>
          <h1 className="h4 text-primary font-weight-bold">
            Filtrar unidades por
          </h1>
        </Col>
      </Row>
      <Formik
        enableReinitialize
        initialValues={{
          unitType: "",
          domain: "",
          cuit: "",
        }}
        validationSchema={SearchSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <div>
            <div>
              <Row className="mb-3">
                <Col>
                  <label>Tipo de vehículo</label>
                  <LogSelect
                    placeholder="Tipo de vehículo"
                    defaultValue={"0"}
                    name="unitType"
                    items={unitTypeItems}
                    onChange={(v) => {
                      setFieldValue("unitType", v);
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.unitType}
                  />
                </Col>
                <Col>
                  <label>Dominio</label>
                  <LogInput
                    placeholder="Dominio"
                    name="domain"
                    items={[]}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("domain", value);
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.domain}
                  />
                </Col>
                <Col>
                  <label>Datos de la empresa</label>
                  <LogInput
                    placeholder="CUIT (sin guiones)"
                    className="mb-2 number-font"
                    type="text"
                    name="cuit"
                    onChange={(e) => {
                      const { value } = e.target;
                      const re = /^[0-9\b]+$/;
                      if (value === "" || re.test(value)) {
                        setFieldValue("cuit", value);
                      }
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.cuit}
                  />
                </Col>
              </Row>
            </div>
            <Row>
              <Col className="d-flex justify-content-end">
                <LogButton
                  title={
                    <span
                      className="icon-magnify h5 px-4"
                      style={{ borderRadius: "3px" }}
                    ></span>
                  }
                  onClick={handleSubmit}
                />
              </Col>
            </Row>
          </div>
        )}
      </Formik>
    </CardLogBootstrapStyled>
  );
};

export default SearchComponent;
