import {
  LOGIN_AUTH,
  LOGOUT_AUTH,
  REFRESH_DATA,
  UPLOAD_USER_DATA,
} from "../../actionTypes/index";
import jwt_decode from "jwt-decode";

const initialState = {
  token: null,
  user: {
    name: "",
    role_id: {},
  },
};

export function authApp(state = initialState, action) {
  switch (action.type) {
    case LOGIN_AUTH:
      const token = action.payload;
      const { user } = jwt_decode(token);
      localStorage.setItem("role", user?.role_id?.name);
      localStorage.setItem("token", token);
      return {
        ...state,
        token,
        user,
      };
    case REFRESH_DATA:
      if (action.payload) {
        return {
          ...state,
          token: action.payload?.localStorageToken,
          user: action.payload?.userInfo?.user,
        };
      } else {
        return state;
      }

    case UPLOAD_USER_DATA:
      const userData = action.payload;
      return {
        ...state,
        user: { ...state.user, ...userData },
      };
    case LOGOUT_AUTH:
      localStorage.removeItem("token");
      return initialState;
    default:
      return state;
  }
}
