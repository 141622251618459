import { Formik } from "formik";
import { Col, Container, Row } from "react-bootstrap";
import { CardLogBootstrapStyled } from "../../../../../../styledComponents/CardBootstrapLog";
import * as Yup from "yup";
import LogSelect from "../../../../../../components/atoms/LogSelect";
import LogInput from "../../../../../../components/atoms/LogInput";
import LogButton from "../../../../../../components/atoms/LogButton";

/* const SearchSchema = Yup.object().shape({
  state: Yup.string().nullable(),
  cuitCreator: Yup.string(),
  cuitClaimed: Yup.string(),
}); */

const SearchToInvoice = ({
  onHandleSearchSubmit,
  dador,
  tomador,
  query,
  setQuery,
  selectedDador,
  setSelectedDador,
}) => {
  const handleSubmit = (values) => {
    setQuery(values);
  };
  return (
    <CardLogBootstrapStyled className="shadow-6dp p-4 ml-0 mr-0 mt-3 mb-4">
      {/* <Row className="mb-3">
        <Col>
          <h1 className="h4 text-primary font-weight-bold">Facturacion</h1>
        </Col>
      </Row> */}
      <Formik
        enableReinitialize
        initialValues={{
          id_tomador: "",
          id_dador: selectedDador,
        }}
        /* validationSchema={SearchSchema} */
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Row className="pb-0 pb-md-2">
            <Col md={5}>
              <label>Dador</label>
              <LogSelect
                placeholder="CUIT - MAIL"
                defaultValue={""}
                name="id_dador"
                items={dador?.map((ele) => {
                  return {
                    label: "Cuit: " + ele.cuit + "   " + "Mail: " + ele.email,
                    value: ele._id,
                  };
                })}
                onChange={async (v) => {
                  setSelectedDador(v);
                }}
                searchable={true}
                onBlur={handleBlur}
                errors={errors}
                value={values.id_dador}
              />
            </Col>
            <Col md={5} className="my-2 my-md-0">
              <label>Tomador</label>
              <LogSelect
                placeholder="CUIT - MAIL"
                defaultValue={""}
                name="id_tomador"
                items={tomador?.map((ele) => {
                  return {
                    label: "Cuit: " + ele.cuit + "   " + "Mail: " + ele.email,
                    value: ele._id,
                  };
                })}
                onChange={(v) => {
                  setFieldValue("id_tomador", v);
                }}
                searchable={true}
                onBlur={handleBlur}
                errors={errors}
                value={values.id_tomador}
              />
            </Col>
            <Col md={2} className="text-right text-sm-center mt-auto">
              <LogButton
                title={
                  <span
                    className="icon-magnify h5 px-4 "
                    style={{ borderRadius: "3px" }}
                  ></span>
                }
                onClick={handleSubmit}
              />
            </Col>
          </Row>
        )}
      </Formik>
    </CardLogBootstrapStyled>
  );
};

export default SearchToInvoice;
