import React from "react";
import { Form } from "react-bootstrap";

function LogRadio({
  error,
  value,
  name,
  label,
  handleChange,
  className,
  handleBlur,
}) {
  return (
    <div className={className}>
      <Form.Check
        type="checkbox"
        name={name}
        label={label}
        onChange={handleChange}
        checked={value}
        isInvalid={!!error}
        onBlur={handleBlur}
      />
      {error && (
        <div className="mb-2 mt-1">
          <small className="text-danger">{error}.</small>
        </div>
      )}
    </div>
  );
}

export default LogRadio;
