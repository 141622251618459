import React from "react";
import { useEffect } from "react";
import { getAllClaimsAdmin } from "../../../../api/modules";
import { useState } from "react";
import { Badge, Card, Col, Container, Row, Stack } from "react-bootstrap";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PaginationList from "../../../../components/organisms/pagination-list/PaginationList";
import SearchComponent from "./SearchComponent";
import { COLOR_PRIMARY } from "../../../../constants/Colors";
import { useHistory } from "react-router-dom";

const CardStyled = styled(Card)`
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;
const BadgeLog = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  display: inline-block;
  padding: 0px 20px;
`;
const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;
const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

function Claims({ user_id }) {
  const INITIAL_ENTITY = {
    claims: [],
    total: 0,
  };
  const [data, setData] = useState(INITIAL_ENTITY);
  const spinner = useSelector((state) => state.appReducer.isLoading);
  const [page, setPage] = useState(0);
  const numberList = 6;
  const [query, setQuery] = useState({
    limit: numberList,
    from: page,
    user_id: user_id,
  });
  const history = useHistory();

  const onClick = (id_creator, id_trip, id_tripDetail, state = 2) => {
    history.push(
      `/app/users/user-profile/${id_creator}?tripId=${id_trip}&detailTrip=${id_tripDetail}&state=${
        state === 5 ? "3" : "2"
      }&claim=true&detail=true&userTab=0`
    );
  };

  useEffect(() => {
    fetchData();
  }, [query]);

  const fetchData = async () => {
    const { claims, total } = await getAllClaimsAdmin(query);
    if (claims) {
      setData({ claims, total });
    } else {
      setData(INITIAL_ENTITY);
    }
  };

  const handlePreviewPage = () => {
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * page - numberList,
      };
    });
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * (page + 1),
      };
    });
  };
  return (
    <Container fluid>
      {!user_id ? (
        <SearchComponent
          onHandleSearchSubmit={({ state, cuitCreator, cuitClaimed }) => {
            setPage(0);
            setQuery((prev) => {
              return {
                ...prev,
                limit: numberList,
                from: 0,
                state,
                cuitCreator,
                cuitClaimed,
              };
            });
          }}
        />
      ) : null}
      <Col className="px-0">
        {data?.claims?.length > 0 ? (
          data.claims.map((ele, index) => (
            <CardStyled
              key={index}
              className="shadow-4dp m-2"
              onClick={() => {
                onClick(
                  ele.id_creator._id,
                  ele.id_trip,
                  ele.id_tripDetail._id,
                  ele.id_tripDetail.state
                );
              }}
            >
              <Card.Body>
                <Row className="align-content-center align-align-items-center">
                  <Col md={3} className="text-center">
                    <small>
                      <p className="text-primary text-center mb-1">Estado</p>
                    </small>
                    <BadgeLog as="h5" className="mt-2">
                      {ele.status ? "Activo" : "Inactivo"}
                    </BadgeLog>
                  </Col>
                  <Col md={3}>
                    <small>
                      <p className="text-primary text-center mb-1">Trip Id</p>
                    </small>
                    <p className="font-weight-bold mt-2 text-center text-capitalize">
                      {ele.id_tripDetail._id}
                    </p>{" "}
                  </Col>
                  <Col md={3}>
                    <small>
                      <p className="text-primary text-center mb-1">Creador</p>
                    </small>

                    <p className="font-weight-bold mt-2 mb-0 text-center text-capitalize">
                      {ele.id_creator.name + " " + ele.id_creator.last_name}
                    </p>
                    <p className=" small mt-0 text-center text-capitalize">
                      {ele.id_creator.cuit}
                    </p>
                  </Col>

                  <Col md={3}>
                    <small>
                      <p className="text-primary text-center mb-1">Reclamado</p>
                    </small>

                    <p className="font-weight-bold mt-2 mb-0 text-center text-capitalize">
                      {ele.id_claimed.name + " " + ele.id_claimed.last_name}
                    </p>
                    <p className=" small mt-0 text-center text-capitalize">
                      {ele.id_claimed.cuit}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </CardStyled>
          ))
        ) : !spinner ? (
          <DivEmpty>
            <h2 className="font-weight-bold h3 text-center">
              Datos No disponibles
            </h2>
          </DivEmpty>
        ) : (
          <DivEmpty></DivEmpty>
        )}
      </Col>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={data.claims}
            total={data.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
      </RowStyled>
    </Container>
  );
}

export default Claims;
