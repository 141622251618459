// return - location
export const getCoordsByAddress = async (address) => {
  let api_key = process.env.REACT_APP_GOOGLE_APIKEY;

  let api_url = "https://maps.googleapis.com/maps/api/geocode/json";
  let response;

  let request_url =
    api_url +
    "?" +
    `address=${address}` +
    "&key=" +
    api_key +
    "&pretty=1" +
    "&components=country:AR" +
    "&no_annotations=1";

  // see full list of required and optional parameters:
  // https://opencagedata.com/api#forward
  const data = await fetch(request_url).then((response) => response.json());
  try {
    const { results } = data;
    const { location } = results[0].geometry;
    response = location;
  } catch (error) {
    response = error;
  }
  return response;
};
// return - formatted_address

let cache = {};
export const getAddressByCoors = async (lat, lng) => {
  let api_key = process.env.REACT_APP_GOOGLE_APIKEY;
  let api_url = "https://maps.googleapis.com/maps/api/geocode/json";
  let response;

  const cacheKey = `${lat},${lng}`;
  if (cache[cacheKey]) {
    console.log("getAddressByCoors", "POR CACHE");
    return cache[cacheKey];
  }
  let request_url =
    api_url +
    "?" +
    "latlng=" +
    encodeURIComponent(lat + "," + lng) +
    "&key=" +
    api_key +
    "&pretty=1" +
    "&no_annotations=1";
  // see full list of required and optional parameters:
  // https://opencagedata.com/api#forward
  const data = await fetch(request_url).then((response) => response.json());
  try {
    console.log("getAddressByCoors", data);
    let formated_data2 = {
      pais: data.results[1].address_components.filter((ele) =>
        ele.types.find((ele) => ele === "country")
      )[0]?.long_name,
      provincia: data.results[1].address_components.filter((ele) =>
        ele.types.find((ele) => ele === "administrative_area_level_1")
      )[0]?.long_name,
      departamento: data.results[1].address_components.filter((ele) =>
        ele.types.find((ele) => ele === "administrative_area_level_2")
      )[0]?.long_name,
      localidad: data.results[1].address_components.filter((ele) =>
        ele.types.find((ele) => ele === "locality")
      )[0]?.long_name,
      calle: data.results[1].address_components.filter((ele) =>
        ele.types.find((ele) => ele === "route")
      )[0]?.long_name,
      numero: data.results[1].address_components.filter((ele) =>
        ele.types.find((ele) => ele === "street_number")
      )[0]?.long_name,
    };

    const { pais, provincia, departamento, localidad, numero, calle } =
      formated_data2;
    let string = [
      calle ? (numero ? calle + " " + numero : calle) : "",
      localidad === departamento ? "" : localidad,
      departamento,
      provincia,
      pais,
    ]
      .filter((ele) => typeof ele === "string" && ele !== "")
      .join(", ");

    response = {
      ok: true,
      data: string,
      province: provincia,
      locality: departamento,
    };
    cache[cacheKey] = response;
  } catch (error) {
    response = { ok: false, data: error };
  }

  return response;
};
// return - address_components
export const getAddressByCoorsGeometry = async (lat, lng) => {
  let api_key = process.env.REACT_APP_GOOGLE_APIKEY;
  let api_url = "https://maps.googleapis.com/maps/api/geocode/json";
  let response;
  let request_url =
    api_url +
    "?" +
    "latlng=" +
    encodeURIComponent(lat + "," + lng) +
    "&key=" +
    api_key +
    "&pretty=1" +
    "&no_annotations=1";
  // see full list of required and optional parameters:
  // https://opencagedata.com/api#forward
  const data = await fetch(request_url).then((response) => response.json());
  console.log("debug data: ", data);
  try {
    const { results } = data;
    const { address_components } = results[1];
    response = address_components;
  } catch (error) {
    response = error;
  }
  return response;
};
