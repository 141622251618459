import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import LogButton from "../../../../components/atoms/LogButton";

const NotFound = () => {
  return (
    <Container
      fluid
      className="d-flex align-items-center justify-content-center position-absolute"
      style={{ top: 0, bottom: 0, left: 0, right: 0 }}
    >
      <Row className="justify-content-center text-center w-100">
        <Col md={8}>
          <h1>404</h1>
          <p>La página que estás buscando no existe.</p>
          <a href="/app" style={{ textDecoration: "none" }}>
            <LogButton
              title="Volver al inicio"
              color="primary"
              border="curve"
              block
            />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
