import React from "react";
import { Container } from "react-bootstrap";
import { Col, Form, Modal, Row, Stack } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CurrencyFormat from "react-currency-format";
import LogDateInput from "../../../../../../../components/atoms/LogDateInput";
import LogInput from "../../../../../../../components/atoms/LogInput";
import LogButton from "../../../../../../../components/atoms/LogButton";
import { postLp } from "../../../../../../../api/modules";
import {
  errorToast,
  successToast,
} from "../../../../../../../services/toastService";

const LpSchema = Yup.object().shape({
  date: Yup.date().required("Fecha de LP es obligatorio").nullable(),
  number: Yup.string().required("Numero de LP es obligatorio"),
});

function LpSubmit({ data, amount, refetch }) {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    dirty,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      date: null,
      number: "",
    },
    onSubmit: async (values) => {
      const resp = await postLp({
        ...values,
        amount: data.invoice_id?.amount - amount,
        invoice_id: data.invoice_id?._id,
        cuit: data.id_tomador.cuit,
      });
      const { msg } = resp;
      if (msg) {
        errorToast(msg || "Intente Nuevamente");
        resetForm();
      } else {
        successToast(`LP cargado con exito`);
        resetForm();
      }
      refetch();
    },

    validationSchema: LpSchema,
  });

  return (
    <Container fluid style={{ maxWidth: 600 }}>
      <Row>
        <Col className="text-left">
          <h4 className="font-weight-bold text-primary h5">
            {"Ingresar Liquido producto"}
          </h4>

          <hr />
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <label className="black-primary font-weight-bold mr-2">
            Factura:
          </label>
        </Col>
        <Col xs={9} className="text-right">
          <label className="black-primary ">
            {data?.invoice_id?.invoice_number}
          </label>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <label className="black-primary font-weight-bold mr-2">
            Tomador:
          </label>
        </Col>{" "}
        <Col xs={9} className="text-right">
          <span>{data.id_tomador.cuit} </span>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <label className="black-primary font-weight-bold mr-2">Monto:</label>
        </Col>
        <Col xs={9} className="text-right">
          <CurrencyFormat
            value={data.invoice_id?.amount - amount || "No Informado"}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={"$"}
            renderText={(formattedValue) => <span>{formattedValue}</span>}
          />
        </Col>
      </Row>
      <Form.Group>
        <Row className="mb-3">
          <Col xs={3}>
            <label className="black-primary font-weight-bold mb-0 mt-2">
              Fecha:
            </label>
          </Col>
          <Col xs={9}>
            <LogDateInput
              size="md"
              name="date"
              className="number-font p-0"
              placeholder="Fecha de LP"
              onBlur={handleBlur.date}
              errors={errors}
              value={values.date}
              onChange={(value) => {
                setFieldValue("date", value);
              }}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={3}>
            <label className="black-primary font-weight-bold mb-0 mt-2">
              {"Numero:"}
            </label>{" "}
          </Col>
          <Col xs={9}>
            <LogInput
              name="number"
              className="p-0"
              placeholder="Numero de LP"
              onBlur={handleBlur.number}
              type="text"
              errors={errors}
              value={values.number}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="d-flex justify-content-center">
            <LogButton
              disabled={!dirty || isSubmitting}
              className="w-50"
              color="primary"
              onClick={handleSubmit}
              title={isSubmitting ? "Cargando..." : "Cargar LP"}
              border="curve"
            />
          </Col>
        </Row>
      </Form.Group>
    </Container>
  );
}

export default LpSubmit;
