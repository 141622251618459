/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import moment from "moment";
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

import { CardLogBootstrapStyled } from "../../../../styledComponents/CardBootstrapLog";

import { useSelector } from "react-redux";
import { priceCalculation } from "../../../../utils/helpers";
import { GoUnverified, GoVerified } from "react-icons/go";
import {
  COLOR_GRAY_TEXT,
  COLOR_PRIMARY_DARK,
} from "../../../../constants/Colors";
const PAYMENT_TYPE_NAME = process.env.REACT_APP_TOMADOR_PAYMENT_NAME;
const TAXES_LABEL = process.env.REACT_APP_TAXES_LABEL;

function TravellDetailByProps({
  origin_site,
  destiny_site,
  auditedWeight,
  product,
  charger_type,
  origin,
  withdraw_date,
  destiny,
  delivery_date,
  requirements_conditions,
  payment_terms_days,
  phone_logic,
  truck_charge,
  price_per_ton,
  price_type,
  payment_type,
  factorDC,
  factorTC,
  factorTrip,
  additionl_destiny_indications,
  additional_origin_indications,
  unit,
  tomador = false,
}) {
  return (
    <Container fluid>
      <Row className="mt-2 mb-2">
        <Col md="12">
          <h1 className="h5 font-weight-bold mb-3">Punto de partida</h1>
          <CardLogBootstrapStyled className="shadow-6dp">
            <Card.Body>
              <Form>
                <Row>
                  <Col md="1" /* className="d-flex align-center" */>
                    <div className="circle-icon">
                      <span className="icon-ping text-primary"></span>
                    </div>
                  </Col>
                  <Col md="11">
                    <p>
                      <strong>Dirección de retiro:</strong>{" "}
                      {origin_site?.address || "No posee"}
                    </p>
                    <p>
                      <strong>Indicaciones adicionales:</strong>{" "}
                      {additional_origin_indications || "No posee"}
                    </p>
                    <p className="text-capitalize">
                      <strong>Tipo de origen:</strong>{" "}
                      {origin.toLowerCase() || "No posee"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="1">
                    <div className="circle-icon">
                      <span className="icon-calendar text-primary"></span>
                    </div>
                  </Col>
                  <Col md="11" className="mt-1">
                    <strong>Fecha de retiro:</strong>{" "}
                    {moment(withdraw_date).format("DD/MM/YYYY")}
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </CardLogBootstrapStyled>
        </Col>

        <Col md="12" className="mt-5">
          <h1 className="h5 font-weight-bold mb-3">Destino</h1>
          <CardLogBootstrapStyled className="shadow-6dp">
            <Card.Body>
              <Form>
                <Row>
                  <Col md="1">
                    <div className="circle-icon">
                      <span className="icon-ping text-primary"></span>
                    </div>
                  </Col>
                  <Col md="11">
                    <p>
                      <strong>Dirección de entrega:</strong>{" "}
                      {destiny_site?.address || "No posee"}
                    </p>
                    <p>
                      <strong>Indicaciones adicionales:</strong>{" "}
                      {additionl_destiny_indications || "No posee"}
                    </p>
                    <p className="text-capitalize">
                      <strong>Tipo de destino:</strong>{" "}
                      {destiny.toLowerCase() || "No posee"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="1">
                    <div className="circle-icon">
                      <span className="icon-calendar text-primary"></span>
                    </div>
                  </Col>
                  <Col md="11" className="mt-1">
                    <strong>Fecha de entrega:</strong>{" "}
                    {moment(delivery_date).format("DD/MM/YYYY")}
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </CardLogBootstrapStyled>
        </Col>

        <Col md="12" className="mt-5">
          <CardLogBootstrapStyled className="shadow-6dp">
            <Card.Body>
              <Form>
                <Row>
                  <Col md="1">
                    <div className="circle-icon">
                      <span className="icon-plant text-primary"></span>
                    </div>
                  </Col>
                  <Col md="11">
                    <p>
                      <strong>Producto a trasladar:</strong>{" "}
                      {product?.product_type_name || "No posee"}
                    </p>
                    <p>
                      <strong>Tipo de carga:</strong>{" "}
                      {charger_type?.cargo_type_name || "No posee"}
                    </p>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </CardLogBootstrapStyled>
        </Col>

        <Col md="12">
          <h1 className="h5 font-weight-bold mb-3 mt-5">
            Precios y condiciones{" "}
            <small className="text-font-info">
              ({TAXES_LABEL || "no incluyen IVA"})
            </small>
          </h1>
          <CardLogBootstrapStyled className="shadow-6dp">
            <Card.Body>
              <Form>
                <Row>
                  <Col md="1">
                    <div className="circle-icon">
                      <span className="icon-cash text-primary"></span>
                    </div>
                  </Col>
                  <Col md="11">
                    <p>
                      <strong>Precio por {unit ? unit + ":" : "N/D:"}</strong>{" "}
                      {priceCalculation(price_per_ton)}
                    </p>

                    <p>
                      <strong>
                        Precio {price_type === "1" ? "abierto" : "cerrado"} por
                        viaje:
                      </strong>{" "}
                      {priceCalculation(price_per_ton * truck_charge)}
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={(props) => (
                          <Tooltip id="button-tooltip" {...props}>
                            {auditedWeight
                              ? "Peso auditado"
                              : "Auditoría pendiente"}
                          </Tooltip>
                        )}
                      >
                        <span>
                          {auditedWeight ? (
                            <GoVerified
                              size={20}
                              color={COLOR_PRIMARY_DARK}
                              style={{
                                marginTop: -4,
                                marginLeft: 4,
                              }}
                            />
                          ) : (
                            <GoUnverified
                              size={20}
                              color={COLOR_GRAY_TEXT}
                              style={{
                                marginTop: -4,
                                marginLeft: 4,
                              }}
                            />
                          )}
                        </span>
                      </OverlayTrigger>
                    </p>
                    <p>
                      <strong>Forma de pago:</strong>{" "}
                      {tomador ? PAYMENT_TYPE_NAME || "E-cheq" : payment_type}
                    </p>
                    <p>
                      <strong>Teléfono de Contacto Lógico:</strong>{" "}
                      {phone_logic}
                    </p>
                    <p>
                      <strong>Plazos de pago en días:</strong>{" "}
                      {payment_terms_days}
                    </p>
                    <p>
                      <strong>Requisitos y condiciones:</strong>{" "}
                      {requirements_conditions || "No posee"}
                    </p>
                    {factorDC || factorTC || factorTrip ? (
                      <Row>
                        <Col md={4} className="pt-0">
                          <p>
                            <strong>Factor DC:</strong>{" "}
                            {typeof factorDC === "number"
                              ? factorDC
                              : "No posee"}
                          </p>
                        </Col>
                        <Col md={4} className="pt-0">
                          <p>
                            <strong>Factor TC:</strong>{" "}
                            {typeof factorTC === "number"
                              ? factorTC
                              : "No posee"}
                          </p>
                        </Col>
                        <Col md={4} className="pt-0">
                          <p>
                            <strong>Factor Trip:</strong>{" "}
                            {typeof factorTrip === "number"
                              ? factorTrip
                              : "No posee"}
                          </p>
                        </Col>
                      </Row>
                    ) : null}
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </CardLogBootstrapStyled>
        </Col>
      </Row>
    </Container>
  );
}

export default TravellDetailByProps;
