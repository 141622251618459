import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Badge, Card, Col, Row, Stack } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import { COLOR_PRIMARY, COLOR_RED } from "../../../../constants/Colors";
import { useHistory } from "react-router-dom";
import { errorToast, successToast } from "../../../../services/toastService";
import { executeTrip } from "../../../../api/modules/trip";
import moment from "moment";
import { useSelector } from "react-redux";

const CardStyled = styled(Card)`
  border-radius: 15px;
  cursor: pointer;
  padding: 10px 20px;
  &:hover {
    opacity: 0.8;
  }
`;

const BadgeLog = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  display: inline-block;
  padding: 0px 20px;
`;

const BadgeLogRed = styled(Badge)`
  background-color: ${COLOR_RED};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  display: inline-block;
  padding: 0px 20px;
`;

const DivSegments = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
`;

const CardItemTravel = ({
  status,
  trucksNumber,
  originName,
  destinyName,
  withdrawDate,
  deliveryDate,
  product_type_name,
  onClick,
  className,
  state,
  drivers,
  onRefetch,
  idTrip,
  trucks,
  btnIcon,
  type,
  showId,
  ...rest
}) => {
  const history = useHistory();
  const { _id } = useSelector((state) => state.authApp.user);
  useEffect(() => {}, []);

  const executeTrip = async () => {
    if (trucksNumber > trucks.length && trucksNumber > drivers.length) {
      errorToast(
        "No se puede ejecutar el viaje si no hay suficientes unidades o choferes"
      );
    } else {
    }
  };
  return (
    <CardStyled
      className={`${className} shadow-4dp m-2`}
      onClick={onClick}
      {...rest}
    >
      <Card.Body>
        <Row className="flex align-items-center">
          <Col md="auto" className="text-center text-sm-left">
            <p className="mb-1 mt-0 font-weight-bold font-italic text-center text-sm-left">
              {"ID: " + (showId || "n/d")}
            </p>
            <h5 className="mb-0">
              <strong>
                {trucksNumber} {trucksNumber === 1 ? "Camión" : "Camiones"}{" "}
              </strong>
            </h5>
            {status ? (
              <BadgeLog as="h5">{state}</BadgeLog>
            ) : (
              <BadgeLogRed as="h5">Viaje eliminado</BadgeLogRed>
            )}
          </Col>
          <Col md="6">
            <Row>
              <Col md="5">
                <p className="mb-0 pt-0 mt-0 font-weight-bold">{originName}</p>
                <p className="mb-0 pt-0 mt-0">
                  Retiro{" "}
                  <span className="number-font">
                    {moment.utc(withdrawDate).format("DD/MM/YYYY")}
                  </span>{" "}
                </p>
              </Col>
              <Col md="2" className="text-center">
                <AiOutlineArrowRight className="text-primary h1 ml-2 mr-2" />
              </Col>
              <Col md="5">
                <p className="mb-0 pt-0 mt-0 font-weight-bold">{destinyName}</p>
                <p className="mb-0 pt-0 mt-0">
                  {" "}
                  Entrega{" "}
                  <span className="number-font">
                    {moment.utc(deliveryDate).format("DD/MM/YYYY")}
                  </span>{" "}
                </p>
              </Col>
            </Row>
          </Col>
          <Col md="2">
            <Stack className="text-left text-sm-center mt-2 mt-sm-0">
              <p className="mb-0 pt-0 mt-0 font-weight-bold">Producto</p>
              <p className="mb-0 pt-0 mt-0">{product_type_name} </p>
            </Stack>
          </Col>{" "}
          <Col md="auto" className="text-center">
            {btnIcon || (
              <p
                className="text-secondary mb-0  mt-2 mt-sm-0"
                onClick={onClick}
              >
                Ver detalle
              </p>
            )}{" "}
          </Col>
        </Row>
      </Card.Body>
    </CardStyled>
  );
};

export default CardItemTravel;
