/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// react-bootstrap components
import { Container, Row, Col } from "react-bootstrap";

import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import TabMaterialLog from "../../../../components/organisms/tabs-material/TabMaterialLog";
import { Tab, Tabs } from "@material-ui/core";
import queryString from "query-string";
import Index from "./InActions/Index";
import MyOffers from "./MyOffers";
import Finished from "./Finished/Finished";
import Pending from "./Pendings/Pending";
import OffersScreen from "./MyOffers";

const DivParentTabs = styled.div`
  border-bottom: 1px solid #d1d0d0;
`;
const TabsStyled = styled(Tabs)`
  width: 80%;
  margin: 0px 30px;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0px;
    margin-right: 10px;
  }
`;

const TabStyled = styled(Tab)`
  span {
    font-size: 18px;
    font-weight: bolder !important;
  }
`;

function Travels() {
  const history = useHistory();
  const location = useLocation();
  const [value, setValue] = useState(false);

  useEffect(() => {
    const { state } = queryString.parse(location.search);
    setValue(parseInt(state || 0));
  }, [location]);

  const handleChange = (event, newValue) => {
    history.push(`/app/list-travels?state=${newValue}`);
    setValue(newValue);
  };

  /* useEffect(() => {
    history.push(`/app/list-travels?state=${value + 1}`);
  }, [value]); */

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <DivParentTabs>
        <TabsStyled
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="simple tabs example"
          scrollButtons="off"
          variant="scrollable"
        >
          <TabStyled label="Mis ofertas" {...a11yProps(0)} />
          <TabStyled label="Pendientes" {...a11yProps(1)} />
          <TabStyled label="En ejecución" {...a11yProps(2)} />
          <TabStyled label="Finalizados" {...a11yProps(3)} />
        </TabsStyled>
      </DivParentTabs>
      <Container fluid>
        <Row border={3}>
          <Col md="12" border={3}>
            <TabMaterialLog value={value} index={0}>
              <OffersScreen />
            </TabMaterialLog>
            <TabMaterialLog value={value} index={1}>
              <Pending />
            </TabMaterialLog>
            <TabMaterialLog value={value} index={2}>
              <Index state={value + 1} />
            </TabMaterialLog>
            <TabMaterialLog value={value} index={3}>
              <Finished />
            </TabMaterialLog>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Travels;
