/* eslint-disable no-unused-vars */
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  postTUnitAPI,
  putUnitByIdAPI,
  delTUnitByIdAPI,
  getUnitByIDAPI,
  getTruckTypeAPI,
  getTruckCharacteristicByTruckIdAPI,
  putUploadPhoto
} from "../../../../../api/modules";

import { BiTrash } from "react-icons/bi";

// react-bootstrap components
import {
  Card,
  Form,
  Container,
  Row,
  Col,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { errorToast, successToast } from "../../../../../services/toastService";
import LogInput from "../../../../../components/atoms/LogInput";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import ContentHeader from "../../../components/molecules/ContentHeader";
import ModalQuestion from "../../../../../components/organisms/modal-question/ModalQuestion";
import ModalInfo from "../../../../../components/organisms/modal-info/ModalInfo";
import queryString from "query-string";
import LogSelect from "../../../../../components/atoms/LogSelect";
import DropZone from "../../../../../components/organisms/dropzone/DropZone";
import { units } from "../../../../../constants/staticSelectItems";
import LogSelectMulti from "../../../../../components/atoms/LogSelectMultiCustom";

const BtnInvisible = styled(Button)`
  background: none;
  border: none;
  &:hover {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
  &:active {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
  &:focus {
    opacity: 0.7;
    background: none !important;
    color: black !important;
    border: none;
  }
`;
const RowDeleteStyled = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  right: 15px;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
`;

const CardLogBootstrapStyled = styled(Card)`
  padding: 10px;
  border-radius: 15px;
`;

function DetailUnit() {
  const [modalExit, setModalExit] = useState(false);
  const [successModal, setSuccessModal] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const [unitType, setUnitType] = useState(null);
  const [truckTypes, setTruckTypes] = useState([]);
  const [truckCharac, setTruckCharac] = useState([]);
  const params = useParams();
  const [entity, setEntity] = useState({
    id_truckType: {},
  });
  const [inFrontPhoto, setInFrontPhoto] = useState(null);
  const [backPhoto, setBackPhoto] = useState(null);
  const [fileErrors, setTileErrors] = useState({
    inFrontError: false,
    backError: false,
  });

  let ProfileSchema = null;
  if (unitType === "semi") {
    ProfileSchema = Yup.object().shape({
      domain: Yup.string().required("Dominio de la unidad es obligatorio"),
      brand: Yup.string(),
      model: Yup.string(),
      max_cargo: Yup.string().when("unit", (unit) => {
        if (unit)
          return Yup.string().required("Cantidad de carga máxima es obligatorio")
      }),
      unit: Yup.string().when("max_cargo", (max_cargo) => {
        if (max_cargo !== 0 && max_cargo === '')
          return Yup.string().required("Unidad de medida es obligatorio")
      }),
      id_truckType: Yup.string(),
      truck_features: Yup.array(),
    }, [['max_cargo', 'unit']]);
  } else {
    ProfileSchema = Yup.object().shape({
      domain: Yup.string().required("Dominio de la unidad es obligatorio"),
      brand: Yup.string().required("Marca es obligatorio"),
      model: Yup.string().required("Modelo es obligatorio"),
      max_cargo: Yup.string().when("unit", (unit) => {
        if (unit)
          return Yup.string().required("Cantidad de carga máxima es obligatorio")
      }),
      unit: Yup.string().when("max_cargo", (max_cargo) => {
        if (max_cargo !== 0 && max_cargo === '')
          return Yup.string().required("Unidad de medida es obligatorio")
      }),
    }, [['max_cargo', 'unit']]);
  }

  useEffect(() => {
    const { id } = params;
    if (id) {
      fetchApi(id);
    }
    const { type } = queryString.parse(location.search);
    setUnitType(type);
    const getFetchData = async () => {
      // ------------ truckTypes
      const respTruckTypeAPI = await getTruckTypeAPI();
      const { truckTypes } = respTruckTypeAPI;
      try {
        const truckTypesFormatted = truckTypes.map((l) => ({
          _id: l._id,
          label: l.truck_type_name,
          value: l._id,
        }));
        setTruckTypes(truckTypesFormatted);
      } catch (error) {
        setTruckTypes([]);
      }
    };

    getFetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchApi = async (id) => {
    const response = await getUnitByIDAPI(id);
    const { truck } = response;
    const { type } = queryString.parse(location.search);

    setEntity(truck);

    if (type === "semi") {
      getFetchTruckCharsSelect(truck.id_truckType._id)
    }
  };


  const handleDeleteItem = async (id) => {
    const resp = await delTUnitByIdAPI(id);
    const { msg } = resp;
    successToast(msg);
    history.push("/app/my-units");
  };

  const handleSuccessModal = async (response) => {
    if (response) history.push("/app/my-units");
  };
  const handleCloseExitModal = async (response) => {
    if (response) {
      await handleDeleteItem(entity._id);
      setModalExit(false);
    } else {
      setModalExit(false);
    }
  };

  const RowDelete = () => {
    return entity._id ? (
      <RowDeleteStyled>
        <OverlayTrigger
          key="delete"
          overlay={<Tooltip id={`delete`}>Eliminar</Tooltip>}
        >
          <BtnInvisible onClick={() => setModalExit(true)}>
            <BiTrash />{" "}
          </BtnInvisible>
        </OverlayTrigger>
      </RowDeleteStyled>
    ) : (
      ""
    );
  };

  const uploadImageOne = (file) => {
    setInFrontPhoto({
      file: file,
      url: file,
    });
  };
  const uploadImageTwo = (file) => {
    setBackPhoto({
      file: file,
      url: file,
    });
  };

  const getStringType = () => {
    return unitType === "semi" ? "Acoplado" : "Camión";
  };

  const getFetchTruckCharsSelect = async (value) => {
    setTruckCharac([]);
    let truckCharsArr = [];
    const resp = await getTruckCharacteristicByTruckIdAPI(value);
    const { truckChars } = resp;
    for (const truckC of truckChars) {
      truckCharsArr.push(truckC);
    }
    const truckCharsFormatted = truckCharsArr.map((l) => ({
      _id: l._id,
      label: l.truck_characteristic_name,
      value: l._id,
    }));
    setTruckCharac(truckCharsFormatted);
  };

  const getCharacterTruck = (truckCharsArr) => {
    try {
      const truckCharsFormatted = truckCharsArr.map((l) => l._id);
      return truckCharsFormatted;
    } catch (error) {
      return []
    }
  }

  const handleSubmitAPI = async (values) => {
    let body = null;
    body = {
      ...values,
      truck_type: unitType === 'truck' ? '1' : '2'
    };

    if (body.max_cargo === 0) {
      delete body['max_cargo']
    }

    if (unitType === 'truck') {
      delete body['id_truckType']
      delete body['truck_features']
    }
    // if (inFrontPhoto && backPhoto) {
    //   body = {
    //     ...values,
    //   };
    // } else {
    //   let responseError = {
    //     inFrontError: false,
    //     backError: false,
    //   };

    //   if (!inFrontPhoto) {
    //     responseError = { ...responseError, inFrontError: true };
    //   }
    //   if (!backPhoto) {
    //     responseError = { ...responseError, backError: true };
    //   }
    //   setTileErrors(responseError);
    //   return;
    // }

    let response;
    if (entity._id) {
      response = await putUnitByIdAPI(entity._id, body);
      successToast('Unidad editada con éxito')
      if (inFrontPhoto?.file) {
        const form = new FormData();
        form.append('file', inFrontPhoto?.file)
        await putUploadPhoto(entity?._id, form)
      }

      // history.push("/app/my-units");
      return;
    } else {
      // TODO preguntarle a carlos como subir foto relacionada a una entidad que aun no existe
      response = await postTUnitAPI(body);
    }
    const { msg, status } = response;
    if (status) {
      errorToast(msg);
    } else {
      setSuccessModal(true);
    }
  };

  return (
    <>
      <Container fluid>
        <Formik
          enableReinitialize
          initialValues={{
            domain: entity.domain || "",
            brand: entity.brand || "",
            model: entity.model || "",
            max_cargo: entity.max_cargo || "",
            unit: entity.unit || "",
            id_truckType: entity?.id_truckType?._id || '',
            truck_features: getCharacterTruck(entity.truck_features) || [],
          }}
          validationSchema={ProfileSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSubmitAPI(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <Row>
              <ContentHeader
                className={`ml-3`}
                title="Detalle de la unidad"
              />
              <Col md="12">
                <CardLogBootstrapStyled className="shadow-4dp pt-3">
                  <CardLogBootstrapStyled.Body>
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <label className="text-primary font-weight-bold">
                            Ingresá el dominio de tu{" "}
                            {getStringType().toLowerCase()}
                          </label>
                          <LogInput
                            placeholder="Ingresá el dominio"
                            type="text"
                            name="domain"
                            onChange={(e) => {
                              const { value } = e.target;
                              setFieldValue("domain", value ? value.toUpperCase() : '');
                            }}
                            onBlur={handleBlur}
                            errors={errors}
                            value={values.domain}
                            disabled
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group>
                          <label className="text-primary font-weight-bold">
                            Ingresá la cantidad de carga maxima de tu{" "}
                            {getStringType().toLowerCase()}{" "}
                            <span className="text-infoLight">(opcional)</span>
                          </label>
                          <Row>
                            <Col md="6">
                              <LogInput
                                className="number-font"
                                placeholder="Ingresá un número"
                                type="text"
                                name="max_cargo"
                                onChange={(e) => {
                                  const { value } = e.target;
                                  const re = /^[0-9\b]+$/;
                                  if (value === "" || re.test(value)) {
                                    const number = parseFloat(value || 0);
                                    setFieldValue("max_cargo", number);
                                  } else {
                                    setFieldValue("max_cargo", 0);
                                  }
                                }}
                                onBlur={handleBlur}
                                errors={errors}
                                value={values.max_cargo}
                                disabled

                              />
                            </Col>
                            <Col md="6">
                              {" "}
                              <LogSelect
                                placeholder="Unidad de medida"
                                name="unit"
                                items={units}
                                onChange={(v) => {
                                  setFieldValue("unit", v);
                                }}
                                onBlur={handleBlur}
                                errors={errors}
                                value={values.unit}
                                disabled

                              />
                            </Col>
                          </Row>
                        </Form.Group>
                      </Col>
                    </Row>
                  </CardLogBootstrapStyled.Body>
                </CardLogBootstrapStyled>
                <CardLogBootstrapStyled className="shadow-4dp pt-3 mt-4">
                  <CardLogBootstrapStyled.Body>
                    <Row>
                      <Col md="6">
                        <label className="text-primary font-weight-bold">
                          ¿ Qué modelo es tu {getStringType().toLowerCase()}?{" "}
                          <span className="text-infoLight">(opcional)</span>
                        </label>
                        <LogInput
                          placeholder="Ingresá el modelo"
                          type="text"
                          name="model"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errors={errors}
                          value={values.model}
                          disabled

                        />
                      </Col>
                      <Col md="6">
                        <label className="text-primary font-weight-bold">
                          Marca &nbsp;
                          <span className="text-infoLight">(opcional)</span>
                        </label>
                        <LogInput
                          placeholder="Ingresá la marca"
                          type="text"
                          name="brand"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          errors={errors}
                          value={values.brand}
                          disabled

                        />
                      </Col>
                    </Row>
                  </CardLogBootstrapStyled.Body>
                </CardLogBootstrapStyled>
                {unitType === "semi" && (
                  <CardLogBootstrapStyled className="shadow-4dp pt-3 mt-4">
                    <CardLogBootstrapStyled.Body>
                      <Row>
                        <Col md="6">
                          <label className="text-primary font-weight-bold">
                            Tipo de camión &nbsp;
                            <span className="text-infoLight">(opcional)</span>
                          </label>
                          <LogSelect
                            placeholder="Tipo de camión"
                            name="id_truckType"
                            items={truckTypes}
                            onChange={async (value) => {
                              getFetchTruckCharsSelect(value);
                              setFieldValue("id_truckType", value);
                            }}
                            onBlur={handleBlur}
                            errors={errors}
                            value={values.id_truckType}
                            disabled

                          />
                        </Col>
                        <Col md="6">
                          <label className="text-primary font-weight-bold">
                            Seleccioná las características{" "}
                            <span className="text-infoLight">(opcional)</span>
                          </label>
                          <LogSelectMulti
                            placeholder="Características"
                            className="mt-1"
                            name="truck_features"
                            items={truckCharac}
                            onChange={(v) => {
                              setFieldValue("truck_features", v);
                            }}
                            onBlur={handleBlur}
                            errors={errors}
                            value={values.truck_features}
                            disabled
                          />
                        </Col>
                      </Row>
                    </CardLogBootstrapStyled.Body>
                  </CardLogBootstrapStyled>
                )}
                <CardLogBootstrapStyled className="shadow-4dp pt-3 mt-4">
                  <CardLogBootstrapStyled.Header className="pl-0 pt-4 pr-0 ml-4 mr-4 mb-0">
                    <label className="text-primary font-weight-bold mb-0">
                      Tarjeta verde
                    </label>
                  </CardLogBootstrapStyled.Header>
                  <CardLogBootstrapStyled.Body>
                    <Row>
                      <Col md="6">
                        <label className="back-primary font-weight-bold">
                          Foto Tarjeta Verde (Frente)
                        </label>
                        <DropZone
                          uploadFile={uploadImageOne}
                          errors={fileErrors.inFrontError}
                          removeFile={() => {
                            setInFrontPhoto(null);
                          }}
                          placeholder="Subir foto de frente"
                        />
                      </Col>
                      <Col md="6">
                        <label className="back-primary font-weight-bold">
                          Foto Tarjeta Verde (Dorso)
                        </label>
                        <DropZone
                          uploadFile={uploadImageTwo}
                          errors={fileErrors.backError}
                          removeFile={() => {
                            setBackPhoto(null);
                          }}
                          placeholder="Subir foto de dorso"
                        />
                      </Col>
                    </Row>
                  </CardLogBootstrapStyled.Body>
                </CardLogBootstrapStyled>
              </Col>
              {/* <LogButton
                className="mt-4 btn-list-bottom"
                size="lg"
                title={`${entity._id ? "Editar" : "Agregar"
                  } ${getStringType().toLowerCase()}`}
                border="curve"
                block
                onClick={handleSubmit}
              /> */}
              <div className="clearfix"></div>
              <ModalQuestion
                title="¿Estás seguro de esta accion?"
                subTitle="¿Estás seguro?"
                isShow={modalExit}
                handleClose={handleCloseExitModal}
              />
              <ModalInfo
                title={`Tu ${getStringType().toLowerCase()} se guardó exitosamente`}
                subTitle="Podés usar esta nueva unidad a la hora de llevar a cabo un nuevo viaje"
                isShow={successModal}
                handleClose={handleSuccessModal}
              />
            </Row>
          )}
        </Formik>
      </Container>
    </>
  );
}

export default DetailUnit;
