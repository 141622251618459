import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { COLOR_TEXT_WHITE, COLOR_PRIMARY } from "../../constants/Colors";

// components
import LogLogo from "../../components/atoms/LogLogo";
// assets
import Flowers from "../../assets/img/flowers.png";
import Truck from "../../assets/img/truck.png";

const NavBarWhich = styled.div`
  display: flex;
  height: 65px;
  align-items: center;
  color: ${COLOR_TEXT_WHITE};
`;

const CardDetailStyled = styled.div`
  border-radius: 10px;
  text-align: center;
  padding: 30px;
  cursor: pointer;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.2);
  border-top: solid ${COLOR_PRIMARY} 10px;
  min-height: 350px;
  box-sizing: content-box;
`;

const CardDetailContentStyled = styled.div`
  text-align: left;
  margin: 30px;
`;

const WhichUser = () => {
  const history = useHistory();

  return (
    <div>
      <NavBarWhich>
        <Container fluid="md" className="ml-3 mt-4 pt-2">
          <LogLogo />
        </Container>
      </NavBarWhich>
      <div className="content-page">
        <Container fluid="md" className="mt-4">
          <Row>
            <Col md="12">
              <h2 className="h2 text-center font-weight-bold mb-5">
                ¡Comenzemos!{" "}
              </h2>
            </Col>
            <Col>
              <CardDetailStyled
                onClick={() =>
                  history.push({
                    pathname: "/register",
                    search: "?type=carriers",
                  })
                }
              >
                <CardDetailContentStyled>
                  <h1 className="mb-0 text-primary font-weight-bold">
                    Registrate como transportista
                  </h1>
                </CardDetailContentStyled>
                <img src={Truck} alt="Imagen de transportista" />
              </CardDetailStyled>
            </Col>
            <Col>
              <CardDetailStyled
                onClick={() =>
                  history.push({
                    pathname: "/register",
                    search: "?type=Loadgiver",
                  })
                }
              >
                <CardDetailContentStyled>
                  <h1 className="mb-0 text-primary font-weight-bold">
                    Registrate como dador de carga
                  </h1>
                </CardDetailContentStyled>
                <img src={Flowers} alt="Imagen de dador de carga" />
              </CardDetailStyled>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default WhichUser;
