import React from "react";
import PropTypes from "prop-types";
import { Card, Col, Row } from "react-bootstrap";
import { GoTriangleRight } from "react-icons/go";
import styled from "styled-components";

const CardStyled = styled(Card)`
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const LocationItem = ({ children, onClick, className, noIcon, ...rest }) => {
  return (
    <CardStyled
      className={`${className} shadow-4dp m-2`}
      onClick={onClick}
      {...rest}
    >
      <Card.Body>
        <Row className="align-content-center align-align-items-center">

          {
            !noIcon && (<Col className="d-flex align-items-center" md="1">
              <span className="icon-ping text-primary"></span>{" "}
            </Col>)
          }

          <Col className="text-left" md={noIcon ? 11 : 10}>
            {children}
          </Col>
          <Col
            className="d-flex align-items-center text-right justify-content-end pr-4"
            md="1"
          >
            <GoTriangleRight />
          </Col>
        </Row>
      </Card.Body>
    </CardStyled>
  );
};

LocationItem.propTypes = {
  className: PropTypes.string,
  handleClick: PropTypes.func,
};

export default LocationItem;
