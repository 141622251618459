import { apiHttp } from "../axiosApi.js";
import {
  allLocation,
  invoice,
  liquidProduct,
  order,
  postLocation,
  postulateLocation,
  receipt,
} from "../config/apiRoute.js";

export const getLocationsAPI = (limit = 5, from = 0) => {
  return apiHttp("GET", `${postLocation}?limit=${limit}&from=${from}`);
};
export const getLocalityAPI = (limit = 5, from = 0) => {
  return apiHttp("GET", `${postLocation}/locality`);
};

export const getLocationsAPIAll = () => {
  return apiHttp("GET", `${postLocation}`);
};

export const getLocationsAPISelectAll = () => {
  return apiHttp("GET", `${postLocation}/All`);
};

export const getLocationsToPostulate = async () => {
  try {
    const { data } = await apiHttp("GET", `${postulateLocation}`);
    return data;
  } catch (error) {
    console.error("errorLocations", error);
  }
};

export const getAllLocations = async () => {
  try {
    const { data } = await apiHttp("GET", `${allLocation}`);
    return data;
  } catch (error) {
    console.error("errorLocations", error);
  }
};

export const getLocationByIdAPI = (id) =>
  apiHttp("GET", `${postLocation}/${id}`);

export const postLocationAPI = (body) =>
  apiHttp("POST", `${postLocation}`, body);
export const postInvoice = (body) => apiHttp("POST", `${invoice}`, body);

export const postReceipt = (body) => apiHttp("POST", `${receipt}`, body);
export const postOrder = (body) => apiHttp("POST", `${order}`, body);

export const postLp = (body) => apiHttp("POST", `${liquidProduct}`, body);

export const getInovoice = (id) => apiHttp("GET", `${invoice}/${id}`);

export const putLocationByIdAPI = (id, body) =>
  apiHttp("PUT", `${postLocation}/${id}`, body);

export const delLocationByIdAPI = (id) =>
  apiHttp("DELETE", `${postLocation}/${id}`);
