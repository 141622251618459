import { getUserProfileAPI } from "../../../api/modules";
import {
  ERASE_ROOM,
  ERASE_ROOM_CHAT,
  GET_ROOM_APP_CHAT,
  GET_ROOM_CHAT,
  LOGIN_AUTH,
  LOGOUT_AUTH,
  MARK_AS_READ,
  MARK_AS_READ_CHAT_APP,
  MARK_AS_READ_ROOM,
  REFRESH_DATA,
  SEND_ADMIN_SUPPORT,
  SEND_CHAT_APP,
  SEND_ROOM_MESSAGE,
  UPLOAD_USER_DATA,
} from "../../actionTypes";
import jwt_decode from "jwt-decode";

export function loginAuth(token) {
  return {
    type: LOGIN_AUTH,
    payload: token,
  };
}
export const refreshData = () => async (dispatch) => {
  const localStorageToken = localStorage.getItem("token");
  if (!localStorageToken)
    return dispatch({
      type: REFRESH_DATA,
    });
  const userStored = jwt_decode(localStorageToken).user;
  const userInfo = await getUserProfileAPI(userStored._id);
  return dispatch({
    type: REFRESH_DATA,
    payload: {
      localStorageToken,
      userInfo,
    },
  });
};

export function uploadUserData(user) {
  return {
    type: UPLOAD_USER_DATA,
    payload: user,
  };
}
export function logOutAuth(token) {
  const localStorageToken = localStorage.getItem("token");
  if (!localStorageToken)
    return {
      type: LOGOUT_AUTH,
    };
  return {
    type: LOGOUT_AUTH,
    payload: localStorageToken,
  };
}

export function sendAdminSupport({ to, msg, document, token }) {
  return {
    type: SEND_ADMIN_SUPPORT,
    payload: { to, msg, document, token },
  };
}

export function sendRoomChat({ to, msg, document, token }) {
  return {
    type: SEND_ROOM_MESSAGE,
    payload: { to, msg, document, token },
  };
}

export function sendAppChat({ room, msg, document, token }) {
  return {
    type: SEND_CHAT_APP,
    payload: {
      room,
      msg,
      document,
      token,
    },
  };
}

export function getRoomChat({ token, room }) {
  return {
    type: GET_ROOM_CHAT,
    payload: { token, room },
  };
}

export function getRoomAppChat({ token, room }) {
  return {
    type: GET_ROOM_APP_CHAT,
    payload: { token, room },
  };
}

export function markAsRead({ attended_user, token }) {
  return {
    type: MARK_AS_READ,
    payload: { attended_user, token },
  };
}

export function eraseRoom() {
  return {
    type: ERASE_ROOM,
  };
}

export function eraseRoomChat() {
  return {
    type: ERASE_ROOM_CHAT,
  };
}

export function markAsReadRoom({ token, room }) {
  return {
    type: MARK_AS_READ_ROOM,
    payload: { token, room },
  };
}

export function markReadChatApp({ token, room }) {
  return {
    type: MARK_AS_READ_CHAT_APP,
    payload: { token, room },
  };
}
