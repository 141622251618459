import { Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import { CardLogBootstrapStyled } from "../../../../styledComponents/CardBootstrapLog";
import * as Yup from "yup";
import LogSelect from "../../../../components/atoms/LogSelect";
import LogDateInput from "../../../../components/atoms/LogDateInput";
import LogButton from "../../../../components/atoms/LogButton";
import {
  getChargeAPI,
  getLocationsAPISelectAll,
  getTripsSearchFiltered,
  getTruckTypeAPI,
  getLocalityAPI,
  getToPostulateTripFiltered,
  getLocationsToPostulate,
} from "../../../../api/modules";
import LogSelectLocation from "../../../../components/atoms/LogSelectLocation";
import { useEffect, useState } from "react";
import LogSelectMultiCustom from "../../../../components/atoms/LogSelectMultiCustom";
import { errorToast } from "../../../../services/toastService";
import moment from "moment";

const SearchSchema = Yup.object().shape({
  // withdraw_date: Yup.date().required("Fecha de entrega es obligatorio"),
  // origin: Yup.string().required("Origen es obligatorio"),
  // destiny: Yup.string().required("Destino es obligatorio"),
  // distanceMin: Yup.string().required("distanceMin es obligatorio"),
  // distanceMax: Yup.string().required("distanceMax es obligatorio"),
  // charger_type: Yup.array().required("charger_type es obligatorio"),
  // truck_type: Yup.array().required("truck_type es obligatorio"),
  // priceMin: Yup.string().required("priceMin es obligatorio"),
  // priceMax: Yup.string().required("priceMax es obligatorio"),
});

const distanceSelect = [
  { value: 100, label: "100 km" },
  { value: 300, label: "300 km" },
  { value: 600, label: "600 km" },
  { value: 1000, label: "1000 km" },
];

const SearchTravels = ({ handleResponse }) => {
  const [locationsAPI, setLocationsAPI] = useState([]);
  const [localitiesAPI, setLocalitiesAPI] = useState([]);

  const [destinys, setDestinys] = useState([]);
  const [origins, setOrigins] = useState([]);

  const [truckTypes, setTruckTypes] = useState([]);
  const [chargeTypes, setChargeTypes] = useState([]);

  useEffect(() => {
    handleFetch();
    return () => {};
  }, []);

  const handleFetch = async () => {
    const resp = await getLocationsToPostulate();
    console.log("resp.ok",resp)
    if (!resp) return;
    const { locations } = resp;

    let locationsFormatted;
    let truckTypesFormatted;
    let cargoTypesFormatted;
    let localityFormatted;
    try {
      locationsFormatted = resp.destinys.map((l) => ({
        _id: l?._id,
        label: l?.name + " - " + l?.id_province?.name,
        address: l?.address,
        value: l?._id,
        lat: l?.latitude,
        lng: l?.longitude,
      }));
      setDestinys(locationsFormatted);
    } catch (error) {
      setLocationsAPI([]);
    }
    try {
      localityFormatted = resp.origins.map((l) => ({
        _id: l?._id,
        label: l?.name + " - " + l?.id_province?.name,
        value: l?._id,
        id_province: l?.id_province,
      }));
      setOrigins(localityFormatted);
    } catch (error) {
      console.log(error);
      setLocalitiesAPI([]);
    }

    // ------------ truckTypes
    const respTruckTypeAPI = await getTruckTypeAPI();
    const { truckTypes } = respTruckTypeAPI;
    try {
      truckTypesFormatted = truckTypes.map((l) => ({
        _id: l._id,
        label: l.truck_type_name,
        value: l._id,
      }));
      setTruckTypes(truckTypesFormatted);
    } catch (error) {
      setTruckTypes([]);
    }

    // ------------ Charges
    const respCharge = await getChargeAPI();
    const { cargoTypes } = respCharge;
    try {
      cargoTypesFormatted = cargoTypes.map((l) => ({
        _id: l._id,
        label: l.cargo_type_name,
        value: l._id,
      }));
      setChargeTypes(cargoTypesFormatted);
    } catch (error) {
      setChargeTypes([]);
    }
  };

  const handleSearchSubmit = async (body) => {
    try {
      let query = "?";
      if (body.withdraw_date && body.withdraw_date !== "")
        query += `withdraw_date=${body.withdraw_date}&`;
      if (body.origin) query += `origin=${body.origin}&`;
      if (body.destiny) query += `destiny=${body.destiny}&`;
      if (body.distanceMin) query += `distanceMin=${body.distanceMin}&`;
      if (body.distanceMax) query += `distanceMax=${body.distanceMax}&`;
      if (body.charger_type.length)
        query += `charger_type=${JSON.stringify(body.charger_type)}&`;
      if (body.truck_type.length)
        query += `truck_type=${JSON.stringify(body.truck_type)}&`;
      query = query.substring(0, query.length - 1);

      const { data, msg } = await getToPostulateTripFiltered(query);

      if (data) {
        handleResponse(data.total, data.trips);
      } else {
        errorToast(msg);
      }
    } catch (error) {
      console.log("response", error);
    }
  };
  return (
    <CardLogBootstrapStyled className="shadow-6dp p-4 mt-3">
      <Formik
        enableReinitialize
        initialValues={{
          withdraw_date: null,
          origin: "",
          destiny: "",
          distanceMin: "",
          distanceMax: "",
          charger_type: [],
          truck_type: [],
          priceMin: "",
          priceMax: "",
        }}
        validationSchema={SearchSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleSearchSubmit(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <div>
            <Row className="mb-3 align-items-center">
              <Col>
                <h1 className="h4 text-primary font-weight-bold">
                  ¡Encontrá tu próximo viaje!
                </h1>
              </Col>
              <Col className="d-flex justify-content-between" md="3">
                <LogButton
                  className="ml-2"
                  title={
                    <span
                      className="h3 p-2"
                      style={{ borderRadius: "3px", fontSize: "1rem" }}
                      onClick={() => {
                        const btn = document.querySelector(
                          ".rs-picker-toggle-clean"
                        );

                        btn &&
                          setTimeout(() => {
                            btn.click();
                          }, 200);
                        resetForm();
                        handleSubmit();
                      }}
                    >
                      Todos
                    </span>
                  }
                  size="sm"
                />
                <LogButton
                  title={
                    <span
                      className="icon-magnify h3 p-2"
                      style={{ borderRadius: "3px", fontSize: "1rem" }}
                      onClick={() => {
                        handleSubmit();
                      }}
                    ></span>
                  }
                  size="sm"
                />
              </Col>
            </Row>
            <div>
              <Row className="mb-3">
                <Col>
                  <LogDateInput
                    name="withdraw_date"
                    placeholder="Fecha"
                    block
                    cleanable
                    className="number-font mt-1"
                    onChange={(value) => {
                      if (value) {
                        const newDate = moment(value).format("YYYY-MM-DD");
                        setFieldValue("withdraw_date", newDate);
                      } else {
                        setFieldValue("withdraw_date", "");
                      }
                    }}
                    errors={errors}
                  />
                </Col>

                <Col>
                  <LogSelectLocation
                    placeholder="Origen"
                    className="mt-1"
                    name="origin"
                    cleanable
                    searchable={origins.length === 0 ? false : true}
                    items={origins}
                    onChange={(value) => {
                      setFieldValue("origin", value);
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.origin}
                  />
                </Col>

                <Col>
                  <LogSelectLocation
                    placeholder="Destino"
                    name="destiny"
                    cleanable
                    searchable={destinys.length === 0 ? false : true}
                    items={destinys}
                    onChange={(v) => {
                      setFieldValue("destiny", v);
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.destiny}
                  />
                </Col>

                <Col>
                  <LogSelect
                    placeholder="Distancia mínimo"
                    cleanable
                    name="distanceMin"
                    items={distanceSelect}
                    onChange={(v) => {
                      setFieldValue("distanceMin", v);
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.distanceMin}
                  />
                </Col>

                <Col>
                  <LogSelect
                    placeholder="Distancia máximo"
                    name="distanceMax"
                    cleanable
                    items={distanceSelect}
                    onChange={(v) => {
                      setFieldValue("distanceMax", v);
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.distanceMax}
                  />
                </Col>
              </Row>
            </div>

            <div>
              <Row className="mb-4">
                <Col>
                  <LogSelectMultiCustom
                    placeholder="Tipo de Carga"
                    name="charger_type"
                    items={chargeTypes}
                    onChange={(v) => {
                      setFieldValue("charger_type", v);
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.charger_type}
                  />
                </Col>

                <Col>
                  <LogSelectMultiCustom
                    placeholder="Tipo de Camión"
                    cleanable
                    name="truck_type"
                    items={truckTypes}
                    onChange={(v) => {
                      setFieldValue("truck_type", v);
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.truck_type}
                  />
                </Col>
              </Row>
            </div>
            {/* <Row>
              <Col className='d-flex justify-content-end'>
                <LogButton
                  title={
                    <span
                      className='icon-magnify h3 px-4'
                      style={{ borderRadius: '3px' }}
                      onClick={() => {
                        handleSubmit()
                      }}
                    ></span>
                  }
                />
              </Col>
            </Row> */}
          </div>
        )}
      </Formik>
    </CardLogBootstrapStyled>
  );
};

export default SearchTravels;
