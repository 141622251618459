import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { COLOR_GRAY_TEXT } from "../../../../../../constants/Colors";
import styled from "styled-components";
import LogButton from "../../../../../../components/atoms/LogButton";
import { Formik } from "formik";
import * as Yup from "yup";
import { errorToast, successToast } from "../../../../../../services/toastService";
import LogSelect from "../../../../../../components/atoms/LogSelect";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getTrailersByUserAPI,
  getTUnitsByUserAPI,
} from "../../../../../../api/modules/unit";
import {
  addTruckToDetailTrip,
  assignmentToChildTrip,
} from "../../../../../../api/modules";

const ModalLogStyled = styled(Modal)`
  opacity: 1;
  .modal-content {
    border-radius: 15px !important;
    padding: 20px;
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;

const ContentModal = styled.div`
  text-align: left;
`;

const ContentFooterCenter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ModalPendingUnits = ({
  show,
  openCloseModal,
  onRefetch,
  trip,
}) => {
  const [units, setUnitsAPI] = useState([]);
  const [trailers, setTrailers] = useState([]);
  const [postulation, setPostulation] = useState(null);

  const { _id } = useSelector((state) => state.authApp.user);
  const history = useHistory();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getFetchUnits(_id);
    getFetchTrailers(_id);
  }, []);

  const getFetchUnits = async (id) => {
    const response = await getTUnitsByUserAPI(id);
    const { trucks } = response;
    if (trucks) {
      setUnitsAPI(trucks);
    } else {
      setUnitsAPI([]);
    }
  };

  const getFetchTrailers = async (id) => {
    const response2 = await getTrailersByUserAPI(id);
    const { trucks } = response2;
    if (trucks) {
      setTrailers(trucks);
    } else {
      setTrailers([]);
    }
  };

  const handleSubmitAPI = async (values, resetForm) => {
    const body = {
      ...(values.id_truck && { id_truck: values.id_truck }),
      ...(values.id_trailer && { id_trailer: values.id_trailer }),
    };

    const response = await assignmentToChildTrip(trip?._id, body);
    const { msg } = response;
    onRefetch();
    successToast(msg);
    let btn = document.querySelectorAll(".rs-picker-toggle-clean");
    btn && btn.forEach((item) => item.click());
    openCloseModal();
  };

  const trucksID = trip?.id_truck;
  const trucksMap = units
    ?.filter((item) => item.status)
    .map((item) => {
      return {
        id: item._id,
        label: item.domain + " " + item.brand,
        value: item._id,
      };
    });
  const trailersMap = trailers
    ?.filter((item) => item.status)
    .map((item) => {
      return {
        id: item._id,
        label: item.domain + " " + item.brand,
        value: item._id,
      };
    });
  return (
    <Formik
      enableReinitialize
      initialValues={{
        id_truck: trip?.id_truck?._id || "",
        id_trailer: trip?.id_trailer?._id || "",
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmitAPI(values, resetForm);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <ModalLogStyled show={show} className="modal-exit">
          <Modal.Body>
            <ContentModal>
              <h4 className="font-weight-bold text-primary h5">
                {trip?.id_truck || trip?.id_trailer ? "Editar unidad" : "Agregar unidad"}
              </h4>
              <hr />
            </ContentModal>
            <Row>
              <Col md="12">
                <Form.Group>
                  <label className="black-primary font-weight-bold">
                    Seleccione un Camion
                  </label>

                  <LogSelect
                    placeholder="Selecciona un camion"
                    className="mt-1"
                    iconLogName="icon-truck"
                    name="id_trucks"
                    items={trucksMap}
                    onChange={(v) => {
                      setFieldValue("id_truck", v);
                    }}
                    disabled={units.length === 0}
                    errors={errors}
                    value={values.id_truck}
                  />
                  <label className="black-primary font-weight-bold mt-3">
                    Seleccione un Trailer
                  </label>
                  <LogSelect
                    placeholder="Selecciona un trailer"
                    className="mt-1"
                    iconLogName="icon-truck"
                    name="id_trailer"
                    items={trailersMap}
                    onChange={(v) => {
                      setFieldValue("id_trailer", v);
                    }}
                    disabled={trailers.length === 0}
                    errors={errors}
                    value={values.id_trailer}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <ContentFooterCenter>
              <LogButton
                color="primary"
                onClick={() => {
                  handleSubmit();
                }}
                title={trip?.id_truck ? "Editar unidad" : "Agregar unidad"}
                border="curve"
                block
                disabled={!values.id_truck && !values.id_trailer}
                className="mr-4"
              ></LogButton>
              <LogButton
                color="secondary"
                onClick={() => {
                  let btn = document.querySelectorAll(
                    ".rs-picker-toggle-clean"
                  );
                  btn && btn.forEach((item) => item.click());
                  openCloseModal();
                }}
                title={"Cancelar"}
                border="curve"
                block
              ></LogButton>
            </ContentFooterCenter>
          </Modal.Footer>
        </ModalLogStyled>
      )}
    </Formik>
  );
};
