/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// react-bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import { Tab, Tabs } from "@material-ui/core";
import styled from "styled-components";
import TabMaterialLog from "../../../../../components/organisms/tabs-material/TabMaterialLog";
import UserReputation from "./UserReputation";
import TravelsAll from "../../Travels-All/TravelsAll";
import Claims from "../../Claims/Claims";
import queryString from "query-string";
import { toNumber } from "lodash";
import { useHistory, useLocation } from "react-router-dom";

//import TabMaterialLog from "../../../../components/organisms/tabs-material/TabMaterialLog";

const DivParentTabs = styled.div`
  border-bottom: 1px solid #d1d0d0;
`;
const TabsStyled = styled(Tabs)`
  width: 80%;
  margin: 0px 30px;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0px;
    margin-right: 10px;
  }
`;

const TabStyled = styled(Tab)`
  span {
    font-size: 18px;
    font-weight: bolder !important;
  }
`;

function UserTabs({ role, id }) {
  const [value, setValue] = useState(0);
  const location = useLocation();

  const onChange = (name, value) => {
    const query = queryString.parse(location.search);
    const params = new URLSearchParams({ ...query, [name]: value });
    history.push({ pathname: location.pathname, search: params.toString() });
  };
  const history = useHistory();
  useEffect(() => {
    const { userTab } = queryString.parse(window.location.search);
    if (userTab) setValue(toNumber(userTab));
  }, [window.location.search]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange("userTab", newValue);
  };
  return (
    <>
      <DivParentTabs>
        <TabsStyled
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="simple tabs example"
          scrollButtons="off"
          variant="scrollable"
        >
          <TabStyled label="Viajes" {...a11yProps(0)} />
          <TabStyled label="Ratings" {...a11yProps(1)} />
          <TabStyled label="Reclamos" {...a11yProps(2)} />
        </TabsStyled>
      </DivParentTabs>
      <Container fluid>
        <Row border={3}>
          <Col md="12" border={3}>
            <TabMaterialLog value={value} index={0}>
              <TravelsAll role={role} />
            </TabMaterialLog>
            <TabMaterialLog value={value} index={1}>
              <UserReputation id={id} role={role} />
            </TabMaterialLog>
            <TabMaterialLog value={value} index={2}>
              <Claims user_id={id} />
            </TabMaterialLog>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default UserTabs;
