import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Col, Modal, Row } from "react-bootstrap";
import { COLOR_GRAY_TEXT } from "../../../../../../constants/Colors";
import LogButton from "../../../../../../components/atoms/LogButton";
import useGoogleMapsApi from "../../../../../../services/useGoogleMapsApi";

const ContentFooterCenter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const ModalLogStyled = styled(Modal)`
  opacity: 1;
  width: 100%;
  .modal-content {
    height: 90vh;
    border-radius: 15px !important;
    padding: 20px;
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;

const ModalMapViewByProps = ({
  isModalMapView,
  onClosePostulation,
  entity,
}) => {
  const [isShow, setShowModal] = useState(false);
  const mapRef = useRef();
  const googleMapsApi = useGoogleMapsApi();

  useEffect(() => {
    setShowModal(isModalMapView);
  }, [isModalMapView, entity]);

  const handleLoadGoogleMap = () => {
    let map = mapRef.current;
    const lat = "-38.4192641";
    const lng = "-63.5989206";
    const myLatlng = new googleMapsApi.LatLng(lat.toString(), lng.toString());
    const mapOptions = {
      zoom: 7,
      center: myLatlng,
      scrollwheel: true,
      zoomControl: true,
      mapTypeId: googleMapsApi.MapTypeId.ROADMAP,
      clickableIcons: false,
      disableDefaultUI: true,
    };
    map = new googleMapsApi.Map(map, mapOptions);
    map.addListener("dragend", () => {});
    const directionsService = new googleMapsApi.DirectionsService();
    const directionsRenderer = new googleMapsApi.DirectionsRenderer({
      map: map,
    });

    const origin = new googleMapsApi.LatLng(
      entity.origin_site.latitude_random?.toString(),
      entity.origin_site.longitude_random?.toString()
    );
    const destination = new googleMapsApi.LatLng(
      entity.destiny_site.latitude_random?.toString(),
      entity.destiny_site.longitude_random?.toString()
    );

    directionsService
      .route({
        origin,
        destination,
        travelMode: googleMapsApi.TravelMode.DRIVING,
      })
      .then((result) => {
        directionsRenderer.setDirections(result);
      })
      .catch((e) => {
        window.alert("Directions request failed due to " + e);
      });

    const circleOrigin = new window.google.maps.Circle({
      center:
        origin /* new window.google.maps.LatLng(34.8984684, -88.5951751) */,
      radius: 5000,
      strokeColor: "#FF0000",
      strokeOpacity: 0.3,
      strokeWeight: 1,
      fillColor: "#FDEBD0",
      fillOpacity: 0.3,
    });
    const circleDestiny = new window.google.maps.Circle({
      center:
        destination /* new window.google.maps.LatLng(34.8984684, -88.5951751) */,
      radius: 5000,
      strokeColor: "#FF0000",
      strokeOpacity: 0.3,
      strokeWeight: 1,
      fillColor: "#FDEBD0",
      fillOpacity: 0.3,
    });
    circleOrigin.setMap(map);
    circleDestiny.setMap(map);
  };

  useEffect(() => {
    if (googleMapsApi && isShow) {
      handleLoadGoogleMap();
    }
  }, [googleMapsApi, isShow]);

  const handleClose = (payload) => {
    setShowModal(false);
    onClosePostulation();
  };

  return (
    <ModalLogStyled
      centered
      show={isShow}
      onHide={handleClose}
      dialogClassName="modal-90w"
      size={"xl"}
    >
      <Modal.Body className="d-flex flex-column">
        <Row>
          <Col>
            <h4 className="font-weight-bold text-primary h5">
              Vista previa de la rutas
            </h4>
            <hr />
          </Col>
        </Row>
        <Row className="flex-grow-1">
          <Col xs="12">
            <div className="h-100">
              <div id="map" ref={mapRef}></div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <ContentFooterCenter>
          <LogButton
            color="secondary"
            onClick={handleClose}
            title={"Cancelar"}
            border="curve"
            block
          ></LogButton>
        </ContentFooterCenter>
      </Modal.Footer>
    </ModalLogStyled>
  );
};

export default ModalMapViewByProps;
