export const test = "test";

// auth
export const login = "/auth/login";
export const refreshToken = "/refresh-token";
export const authGoogle = "/auth/google";
export const authValidateEmail = "/auth/validate-email/";
export const authValidatePassword = "/auth/recover-pass/";
export const authRecoverPassword = "/auth/recover-pass";
export const authValidatePhone = "/auth/validate-phone";
export const authValidatePhoneGoogle = "/auth/validate-phone-google";

export const registerGoogle = "/auth/google-register";
export const authChangePassword = "/user/change-pass/";

//
export const rating = "/rating";
export const claim = "/claim";
export const claimAdmin = "/claim/claimAdmin";
export const closeClaim = "/claim/closeClaim";
export const allClaimsAdmin = "/claim/claimAdmin/allClaims";

// admin
export const allTrips = "/trip/user-state";
export const allDrivers = "/driver/allDriver";
export const pushNotifications = "/push";
export const pushToken = "/push/token";

// user
export const register = "/user";
export const getUserProfile = "/user/";
export const uploadPhoto = "/upload/users";
export const getReputation = "/user/reputation/";
export const dadorToAdmin = "/user/dador-to-admin";

// locations
export const postLocation = "/location";
export const postulateLocation = "/location/locality-by-trip";
export const allLocation = "/location/locality-all";

// locations
export const postTrip = "/trip";
export const getLocationsByTrip = "/location/by-dador";

//

// cargo
export const charge = "/cargo";

// product
export const product = "/product";

// tipo de pago
export const paymentType = "/payment-type";

// unit
export const unit = "/unit";
export const unitByUser = "/unit/by-user";
export const uploadTruckPhoto = "/upload/trucks";

// truck
export const truck = "/truck";

// truckCharacteristic
export const truckCharacteristic = "/truck-characteristic";

// truck-accesory
export const truckAccesory = "/truck-accesory";

// tomador
export const toPostulate = "/trip/to-postulate";
export const sendPostulation = "/trip/postulation";
export const tripPostulated = "/trip/postulated";
export const tripPostulated1 = "/trip/postulated1";
export const tripPostulated2 = "/trip/postulated2";
export const shippingNote = "/trip-details/ShippingNote/"

// statist
export const statisticsCore = "/statistics-core/basics";
export const statisticsDador = "/statistics-core/totals-dador";
export const statisticsTomador = "/statistics-core/totals-tomador";
export const billingsAdmin = "/statistics-core/accounts-admin";
export const billingDador = "/statistics-core/accounts-dador";
export const billingTomador = "/statistics-core/accounts-tomador";
export const auditedWeight = "/statistics-core/auditWeight/";
export const usersInvoicePending = "/statistics-core/users-invoice";
export const invoice = "/statistics-core/invoice";
export const receipt = "/statistics-core/receipt";
export const order = "/statistics-core/order";
export const liquidProduct = "/statistics-core/lp";
// my drivers
export const driver = "/driver";
export const dadorDriver = "/driver/driver-dador";
