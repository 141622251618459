import { useEffect } from "react";

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (textAreaRef, value) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      const height = textAreaRef.style.height;
      textAreaRef.style.height = "0px";
      const scrollHeight = textAreaRef?.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.

      if (scrollHeight >= 99) {
        textAreaRef.style.overflowY = "auto";
        textAreaRef.style.height = "99px";
      } else {
        textAreaRef.style.overflowY = "hidden";
        textAreaRef.style.height = scrollHeight + "px";
      }
    }
  }, [textAreaRef, value]);
};

export default useAutosizeTextArea;
