import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { errorToast, successToast } from "../../../services/toastService";
import queryString from "query-string";

import { postRecoverPass, putChangePass } from "../../../api/modules";

import LogCard from "../../../components/atoms/LogCard";
import {
  DivAuthScreen,
  DivNavAuthStyled,
} from "../../../styledComponents/DivNavAuthStyled";
import LogLogo from "../../../components/atoms/LogLogo";
import FirstStep from "./Forms/FirstStep";
import SecondStep from "./Forms/SecondStep ";

const RecoverPassword = () => {
  const location = useLocation();
  const history = useHistory();

  const [step, setStep] = useState(0);
  const [tokenPassword, setTokenPassword] = useState(null);

  useEffect(() => {
    const { token } = queryString.parse(location.search);
    if (token) {
      setStep(1);
      setTokenPassword(token);
    }
  }, [location.search]);

  // eyJhbGciOiJIU…gwN30.j1SqUTkkGuahxiZXJgFA1_HZLk_ee5R01aORUO573rU
  const handleSubmitRecoverPassword = async (body) => {
    const response = await postRecoverPass(body);
    if (response.status) {
      errorToast(response.msg);
    } else {
      successToast(response.msg);
    }
  };
  const handleSubmitChangePassword = async (body) => {
    const response = await putChangePass(tokenPassword, body);
    if (response.status) {
      errorToast(response.msg);
    } else {
      history.push("/sign-in");
      successToast(response.msg);
    }
  };

  return (
    <DivAuthScreen>
      <DivNavAuthStyled>
        <LogLogo className="mt-2" />
      </DivNavAuthStyled>
      <div className="content-page">
        <Row>
          <Col
            md="6"
            className="d-flex flex-column align-content-center justify-content-center"
          >
            <div className="ml-4 mt-5 mb-4">
              <h1 className="mb-0 font-weight-bold">
                Entregá tu carga <span className="text-primary"> a tiempo</span>{" "}
                y <span className="text-primary">de manera segura</span> con
                BuenaRuta
              </h1>
            </div>
          </Col>
          <Col md="6">
            <LogCard
              width="460px"
              className="pl-3 pr-3 pt-5 pb-5 p-md-5 rounded"
            >
              {step === 0 ? (
                <FirstStep
                  handleSubmitRecoverPassword={handleSubmitRecoverPassword}
                />
              ) : (
                <SecondStep
                  handleSubmitChangePassword={handleSubmitChangePassword}
                />
              )}
            </LogCard>
          </Col>
        </Row>
      </div>
    </DivAuthScreen>
  );
};

export default RecoverPassword;
