/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import InActionsTravels from "./InActionsTravels";
import InActionsTravelsTrucks from "./InActionsTravelsTrucks";
import { useHistory, useLocation } from "react-router-dom";
import TripDetails from "./TripDetails/index";
import styled from "styled-components";
import queryString from "query-string";

const ContainerList = styled.div`
  overflow-y: overlay;
`;

const InActions = ({ id, searchQuery }) => {
  const history = useHistory();
  const location = useLocation();
  const [tripId, setTripId] = useState(null);
  const [detailTrip, setDetailTrip] = useState(false);

  useEffect(() => {
    const { tripId, detailTrip } = queryString.parse(location.search);
    setTripId(tripId);
    setDetailTrip(detailTrip);

  }, [location]);

  const getScreen =
    tripId && detailTrip ? (
      <TripDetails detailTrip={detailTrip} tripId={tripId} />
    ) : tripId ? (
      <InActionsTravelsTrucks tripId={tripId} />
    ) : (
      <InActionsTravels id={id} searchQuery={searchQuery} />
    );

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <ContainerList>{getScreen}</ContainerList>
          </Col>
        </Row>
      </Container>
    </>
  );
};

InActions.propTypes = {};

export default InActions;
