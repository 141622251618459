import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { COLOR_GRAY_TEXT } from "../../../../../../constants/Colors";
import styled from "styled-components";
import LogButton from "../../../../../../components/atoms/LogButton";
const TAXES_LABEL = process.env.REACT_APP_TAXES_LABEL;

const ModalLogStyled = styled(Modal)`
  opacity: 1;
  .modal-content {
    border-radius: 15px !important;
    padding: 20px;
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;

const ContentModal = styled.div`
  text-align: left;
`;

const ContentFooterCenter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const DisplayInfo = styled.div`
  background-color: #f6f6f4;
  color: ${COLOR_GRAY_TEXT};
  border: none;
  padding: 5px;
  border-radius: 2px;
`;

const OpentripModal = ({
  paymentType,
  paymentDays,
  price_ton_postulation,
  number_trucks,
  price_truck_portulation,
  requirements_conditions,
  isPostulate,
  handleAcceptPostulation,
  onClosePostulation,
}) => {
  const [isShow, setShowModal] = useState(false);

  const handleClose = (payload) => {
    setShowModal(false);
    onClosePostulation(payload);
  };

  const handleSubmit = (payload) => {
    setShowModal(false);
    handleAcceptPostulation();
  };
  useEffect(() => {
    setShowModal(isPostulate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPostulate]);

  return (
    <ModalLogStyled show={isShow} onHide={handleClose} className="modal-exit">
      <Modal.Body>
        <ContentModal>
          <h4 className="font-weight-bold text-primary h5">
            Precios y condiciones
          </h4>
          <hr />
        </ContentModal>

        <Row>
          <Col md="12">
            <Form.Group>
              <label className="black-primary font-weight-bold">
                Unidades ofrecidas por el tomador
              </label>
              <DisplayInfo>{number_trucks}</DisplayInfo>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Form.Group>
              <label className="black-primary font-weight-bold">
                Precio por tonelada
              </label>
              <DisplayInfo>{price_ton_postulation}</DisplayInfo>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <label className="black-primary font-weight-bold">
                Precio por camión
              </label>
              <DisplayInfo>{price_truck_portulation}</DisplayInfo>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Form.Group>
              <label className="font-weight-bold text-black">
                Forma de pago
              </label>
              <DisplayInfo>{paymentType}</DisplayInfo>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <label className="font-weight-bold text-black">
                Plazo de pagos en días
              </label>
              <DisplayInfo>{paymentDays}</DisplayInfo>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="font-weight-bold text-black">
              Requisitos y condiciones
            </label>
            <DisplayInfo>
              {requirements_conditions || "No especifica"}
            </DisplayInfo>
          </Col>
        </Row>
        <h6 className=" h6 font-weight-bold font-italic m-0">
          <small style={{ color: "inherit" }}>
            {TAXES_LABEL || "Montos netos sin impuestos"}
          </small>
        </h6>
      </Modal.Body>
      <Modal.Footer>
        <ContentFooterCenter>
          <LogButton
            color="primary"
            onClick={handleSubmit}
            title={"Aceptar oferta"}
            border="curve"
            block
            className="mr-4"
          ></LogButton>
          <LogButton
            color="secondary"
            onClick={handleClose}
            title={"Cancelar"}
            border="curve"
            block
          ></LogButton>
        </ContentFooterCenter>
      </Modal.Footer>
    </ModalLogStyled>
  );
};

export default OpentripModal;
