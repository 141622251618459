import React, { useState } from "react";
import { Col, Row, Card, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { BsFillFilePdfFill } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import ModalShippingNote from "./ModalShippingNote";
import { useSelector } from "react-redux";
import { TOMADOR_DE_CARGA } from "../../../../../../../constants/UserType";
import { GoUnverified, GoVerified } from "react-icons/go";
import { COLOR_GRAY_TEXT, COLOR_PRIMARY_DARK } from "../../../../../../../constants/Colors";

const StyledImage = styled(Image)`
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
`;
const CardLogBootstrapStyled = styled(Card)`
  padding: 10px;
  border-radius: 15px;
`;

const Documentation = ({ data, refetch }) => {
  const { token, user } = useSelector((state) => state.authApp);

  const pdf = data?.documents?.filter((file) => file.includes(".pdf"));
  const img = data?.documents?.filter((file) => !file.includes(".pdf"));
  const [showModal, setShowModal] = useState(false);
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Row>
        <Col md="4">
          <CardLogBootstrapStyled className="shadow-4dp pt-3 mt-4">
            <CardLogBootstrapStyled.Header className="p-0 ml-4 mr-4 mb-0">
              <label className="text-primary font-weight-bold mb-0">
                Peso real transportado:
              </label>
            </CardLogBootstrapStyled.Header>
            <CardLogBootstrapStyled.Body className="pt-2 pb-0 ml-1">
              <Row>
                <Col md="12 text-capitalize">
                  <p>
                    {data?.realTransportedWeight || data?.auditedWeight
                      ? (data?.auditedWeight || data.realTransportedWeight) +
                        " " +
                        (data.unit_truck_charge ? data.unit_truck_charge : "KG")
                      : "No disponible"}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={(props) => (
                        <Tooltip id="button-tooltip" {...props}>
                          {data?.auditedWeight
                            ? "Peso auditado"
                            : "Auditoría pendiente"}
                        </Tooltip>
                      )}
                    >
                      <span>
                        {data?.auditedWeight ? (
                          <GoVerified
                            size={20}
                            color={COLOR_PRIMARY_DARK}
                            style={{
                              marginTop: -4,
                              marginLeft: 4,
                            }}
                          />
                        ) : (
                          <GoUnverified
                            size={20}
                            color={COLOR_GRAY_TEXT}
                            style={{
                              marginTop: -4,
                              marginLeft: 4,
                            }}
                          />
                        )}
                      </span>
                    </OverlayTrigger>
                  </p>
                </Col>
              </Row>
            </CardLogBootstrapStyled.Body>
          </CardLogBootstrapStyled>
        </Col>
        <Col md="4">
          <CardLogBootstrapStyled className="shadow-4dp pt-3 mt-4">
            <CardLogBootstrapStyled.Header className="p-0 ml-4 mr-4 mb-0">
              <label className="text-primary font-weight-bold mb-0">
                Remito
              </label>
              {user.role_id.name === TOMADOR_DE_CARGA ? (
                <BiEdit
                  className="cursor-pointer ml-1 mb-1"
                  onClick={() => setShowModal(true)}
                />
              ) : null}
            </CardLogBootstrapStyled.Header>
            <CardLogBootstrapStyled.Body className="pt-2 pb-0 ml-1">
              <Row>
                <Col md="12">
                  <label>
                    {data?.shipping_note ? data.shipping_note : "No disponible"}
                  </label>
                </Col>
              </Row>
            </CardLogBootstrapStyled.Body>
          </CardLogBootstrapStyled>
        </Col>
        <Col md="4">
          <CardLogBootstrapStyled className="shadow-4dp pt-3 mt-4">
            <CardLogBootstrapStyled.Header className="p-0 ml-4 mr-4 mb-0">
              <label className="text-primary font-weight-bold mb-0">
                Comentarios:
              </label>
            </CardLogBootstrapStyled.Header>
            <CardLogBootstrapStyled.Body className="pt-2 pb-0 ml-1">
              <Row>
                <Col md="12">
                  {data?.comment ? (
                    <label>{data?.comment}</label>
                  ) : (
                    <label>No introdujo comentarios</label>
                  )}
                </Col>
              </Row>
            </CardLogBootstrapStyled.Body>
          </CardLogBootstrapStyled>
        </Col>
      </Row>
      {pdf?.length > 0 ? (
        <CardLogBootstrapStyled className="shadow-4dp pt-3 mt-4">
          <CardLogBootstrapStyled.Header className="pl-0 pt-4 pr-0 ml-4 mr-4 mb-0">
            <label className="text-primary font-weight-bold mb-0">
              Archivos
            </label>
          </CardLogBootstrapStyled.Header>
          <CardLogBootstrapStyled.Body>
            <Row>
              {pdf.map((doc, index) => (
                <Col md="3" key={index}>
                  <a href={doc} target="_blank" key={index}>
                    <BsFillFilePdfFill size={30} />
                    <label
                      className="text-primary font-weight-bold ml-1"
                      role="button"
                    >
                      Archivo{index + 1}
                    </label>
                  </a>
                </Col>
              ))}
            </Row>
          </CardLogBootstrapStyled.Body>
        </CardLogBootstrapStyled>
      ) : null}
      {img?.length > 0 ? (
        <CardLogBootstrapStyled className="shadow-4dp pt-3 mt-4">
          <CardLogBootstrapStyled.Header className="pl-0 pt-4 pr-0 ml-4 mr-4 mb-0">
            <label className="text-primary font-weight-bold mb-0">
              Imagenes
            </label>
          </CardLogBootstrapStyled.Header>
          <CardLogBootstrapStyled.Body>
            <Row>
              {img ? (
                img.map((doc, index) => (
                  <Col md="6" key={index}>
                    <a href={doc} target="_blank">
                      <StyledImage key={index} src={doc} alt="" />
                    </a>
                  </Col>
                ))
              ) : (
                <h2>No hay documentos disponible</h2>
              )}
            </Row>
          </CardLogBootstrapStyled.Body>
        </CardLogBootstrapStyled>
      ) : null}
      {showModal ? (
        <ModalShippingNote
          isShow={showModal}
          handleClose={closeModal}
          data={data}
          refetch={refetch}
        />
      ) : null}
    </div>
  );
};

export default Documentation;
