import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Navbar,
  Container,
  Nav,
  Dropdown,
  Button,
  Row,
  Col,
  Image,
} from "react-bootstrap";
import { CgMenu } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { logOutAuth } from "../../../../redux/actions/auth";
import DefaultAvatar from "../../../../assets/img/default-avatar.png";


import styled from "styled-components";
import {
  ADMIN,
  DADOR_DE_CARGA,
  TOMADOR_DE_CARGA,
} from "../../../../constants/UserType";
import { FaRegCommentAlt, FaRegEnvelope, FaTruckMoving } from "react-icons/fa";
import ModalMsgAdmin from "../../../../components/organisms/modal-msg-admin/ModalMsgAdmin";
import ModalQuestion from "../../../../components/organisms/modal-question/ModalQuestion";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const AvatarLog = styled(Image)`
  width: 45px;
  height: 45px;
`;
const TextUser = styled.p`
  line-height: 1px;
  margin-bottom: 0px;
  margin-top: 15px;
`;

const StyledMenu = styled(Dropdown.Menu)`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 60vh;
  max-width: 250px;
  position: relative;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #d62929;
  }
`;

function Header() {
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state) => state.authApp.user);
  const unReadMsg = useSelector(
    (state) => state.chatReducer.privateMsg?.unReadMsg
  );
  const { listUsers, unReadMsgRoom, unReadChatApp } = useSelector(
    (state) => state.chatReducer
  );
  const roleName = useSelector((state) => state.authApp.user?.role_id?.name);
  const { notifications } = useSelector((state) => state.appReducer);

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const role =
    roleName === DADOR_DE_CARGA
      ? "Dador de carga"
      : roleName === TOMADOR_DE_CARGA
      ? "Tomador de carga"
      : "Administrador";

  const mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onmousedown = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
    };
    document.body.appendChild(node);
  };

  const handlerLogOut = () => {
    dispatch(logOutAuth());
    history.push("/");
  };

  return (
    <Navbar bg="white" /* expand="lg" */ className="NavBar">
      <Container fluid>
        <div
          key={5}
          className="d-flex justify-content-center align-items-center ml-2 ml-lg-0"
        >
          <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}
          >
            <CgMenu />
          </Button>
        </div>

        <Nav key={1} className="nav mr-auto" navbar></Nav>

        <Nav key={2}>
          <Nav.Item key={1}>
            <Nav.Link
              data-toggle="dropdown"
              onClick={(e) => e.preventDefault()}
            >
              <span
                className={`icon-help text-primary mt-1 pt-0`}
                style={{
                  fontSize: 18,
                }}
              ></span>
            </Nav.Link>
          </Nav.Item>
          <Dropdown key={3} as={Nav.Item}>
            <Dropdown.Toggle
              as={Nav.Link}
              data-toggle="dropdown"
              variant="default"
            >
              {unReadMsgRoom?.length > 0 ? (
                <span
                  className="badge badge-pill badge-primary"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  {unReadMsgRoom?.length}
                </span>
              ) : null}
              <FaRegCommentAlt
                size={18}
                className="text-primary mt-1 pt-0"
              ></FaRegCommentAlt>
            </Dropdown.Toggle>
            <StyledMenu className="dropdown-menu-right">
              {unReadMsgRoom.length > 0 ? (
                unReadMsgRoom.map((ele, index) => (
                  <React.Fragment key={index}>
                    <Dropdown.Item
                      key={index}
                      onClick={(e) => {
                        if (user?.role_id.name === DADOR_DE_CARGA) {
                          history.push(
                            `/app/list-travels?state=${
                              ele.room.state === 3 || ele.room.state === 2
                                ? 2
                                : 3
                            }&tripId=${ele.room.id_trip}&detailTrip=${
                              ele.room._id
                            }&detailTab=4`
                          );
                        } else if (user?.role_id.name === TOMADOR_DE_CARGA) {
                          history.push(
                            `/app/my-travels-tc?state=${
                              ele.room.state === 3 || ele.room.state === 2
                                ? 3
                                : 4
                            }&tripId=${ele.room.id_trip}&detailTrip=${
                              ele.room._id
                            }&detailTab=3`
                          );
                        }
                      }}
                    >
                      <Row className="px-2">
                        <Col xs={10} className="p-0">
                          <small className="text-primary font-weight-bold">
                            Nuevo mensaje
                          </small>
                        </Col>
                        <Col xs={2} className="text-right p-0">
                          <small
                            style={{ fontSize: 10 }}
                            className="badge badge-pill badge-primary text-center mr-2"
                          >
                            {ele.size}
                          </small>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <span
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {ele.room?.order && ele.room?.parent_id
                              ? "ID viaje: " +
                                ele.room.order +
                                ele.room.parent_id
                              : "ID: " + ele._id}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-truncate">
                          <span
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {ele.unReadMsg[ele.unReadMsg?.length - 1]?.msg
                              ? "Msj: " +
                                ele.unReadMsg[ele.unReadMsg?.length - 1]?.msg
                              : "Imagen"}
                          </span>
                        </Col>
                      </Row>
                    </Dropdown.Item>
                    <Dropdown.Divider className="m-0 p-0" />
                  </React.Fragment>
                ))
              ) : (
                <Dropdown.Item onClick={(e) => e.preventDefault()}>
                  Sin mensajes
                </Dropdown.Item>
              )}
            </StyledMenu>
          </Dropdown>
          <Dropdown key={4} as={Nav.Item}>
            <Dropdown.Toggle
              as={Nav.Link}
              data-toggle="dropdown"
              variant="default"
            >
              {notifications?.length > 0 ? (
                <span
                  className="badge badge-pill badge-primary"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  {notifications?.length}
                </span>
              ) : null}
              <span
                className="icon-bell text-primary mt-1 pt-0"
                style={{
                  fontSize: 18,
                }}
              ></span>
            </Dropdown.Toggle>
            <StyledMenu className="dropdown-menu-right">
              {notifications.length > 0 ? (
                notifications.map((ele, index) => (
                  <React.Fragment key={index}>
                    <Dropdown.Item
                      key={index}
                      onClick={(e) => {
                        if (location.pathname + location.search === ele.link) {
                          history.go(0);
                        } else {
                          history.push(ele.link);
                        }
                      }}
                    >
                      <Row className="px-2">
                        <Col xs={12} className="p-0">
                          <small className="text-primary font-weight-bold text-capitalize">
                            {ele.title}
                          </small>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <span
                            className="text-wrap"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {ele.comment}
                          </span>
                        </Col>
                      </Row>
                    </Dropdown.Item>
                    <Dropdown.Divider className="m-0 p-0" />
                  </React.Fragment>
                ))
              ) : (
                <Dropdown.Item onClick={(e) => e.preventDefault()}>
                  Sin notificacion
                </Dropdown.Item>
              )}
            </StyledMenu>
          </Dropdown>
          <Dropdown key={5} as={Nav.Item}>
            <Dropdown.Toggle
              as={Nav.Link}
              data-toggle="dropdown"
              variant="default"
            >
              {unReadChatApp?.length > 0 ? (
                <span
                  className="badge badge-pill badge-primary"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  {unReadChatApp?.length}
                </span>
              ) : null}
              <FaTruckMoving
                size={18}
                className="text-primary mt-1 pt-0"
              ></FaTruckMoving>
            </Dropdown.Toggle>
            <StyledMenu className="dropdown-menu-right">
              {unReadChatApp.length > 0 ? (
                unReadChatApp.map((ele, index) => (
                  <React.Fragment key={index}>
                    <Dropdown.Item
                      key={index}
                      onClick={(e) => {
                        if (user?.role_id.name === DADOR_DE_CARGA) {
                          history.push(
                            `/app/list-travels?state=${
                              ele.room.state === 3 || ele.room.state === 2
                                ? 2
                                : 3
                            }&tripId=${ele.room.id_trip}&detailTrip=${
                              ele.room._id
                            }&detailTab=5`
                          );
                        } else if (user?.role_id.name === TOMADOR_DE_CARGA) {
                          history.push(
                            `/app/my-travels-tc?state=${
                              ele.room.state === 3 || ele.room.state === 2
                                ? 3
                                : 4
                            }&tripId=${ele.room.id_trip}&detailTrip=${
                              ele.room._id
                            }&detailTab=4`
                          );
                        }
                      }}
                    >
                      <Row className="px-2">
                        <Col xs={10} className="p-0">
                          <small className="text-primary font-weight-bold">
                            Nuevo mensaje
                          </small>
                        </Col>
                        <Col xs={2} className="text-right p-0">
                          <small
                            style={{ fontSize: 10 }}
                            className="badge badge-pill badge-primary text-center mr-2"
                          >
                            {ele.size}
                          </small>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <span
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {ele.room?.order && ele.room?.parent_id
                              ? "ID viaje: " +
                                ele.room.order +
                                ele.room.parent_id
                              : "ID: " + ele._id}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="text-truncate">
                          <span
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {ele.unReadMsg[ele.unReadMsg?.length - 1]?.msg
                              ? "Msj: " +
                                ele.unReadMsg[ele.unReadMsg?.length - 1]?.msg
                              : "Imagen"}
                          </span>
                        </Col>
                      </Row>
                    </Dropdown.Item>
                    <Dropdown.Divider className="m-0 p-0" />
                  </React.Fragment>
                ))
              ) : (
                <Dropdown.Item onClick={(e) => e.preventDefault()}>
                  Sin mensajes
                </Dropdown.Item>
              )}
            </StyledMenu>
          </Dropdown>
          <Nav.Item key={2}>
            <Nav.Link
              onClick={(e) => {
                e.preventDefault();
                setShow(() => true);
              }}
            >
              {unReadMsg?.length ||
              listUsers.filter((ele) => ele.unReadMsg?.length > 0).length ? (
                <span
                  className="badge badge-pill badge-primary"
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                  }}
                >
                  {unReadMsg?.length ||
                    listUsers.filter((ele) => ele.unReadMsg?.length > 0).length}
                </span>
              ) : null}
              <FaRegEnvelope
                size={18}
                className="text-primary mt-1 pt-0"
              ></FaRegEnvelope>
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Nav key={3} navbar className="d-xs-none">
          <Dropdown as={Nav.Item}>
            <Dropdown.Toggle
              aria-expanded={false}
              aria-haspopup={true}
              as={Nav.Link}
              className="p-0 m-0"
            >
              <Row>
                <Col className="pr-0">
                  <AvatarLog src={user?.image || DefaultAvatar} roundedCircle />
                </Col>
                <Col  className="pl-2 d-none d-sm-block">
                  <TextUser className="text-primary font-weight-bold">
                    {user?.name
                      ? `${user.name} ${user.last_name}`
                      : "Cargando..."}
                  </TextUser>
                  <TextUser>
                    <small>{role || "Sin rol"}</small>
                  </TextUser>
                </Col>
              </Row>
            </Dropdown.Toggle>
            <Dropdown.Menu
              aria-labelledby="navbarDropdownMenuLink"
              className="dropdown-menu-right mr-4"
            >
              <Dropdown.Item onClick={(e) => history.push("/app/profile")}>
                Cuenta
              </Dropdown.Item>
              <Dropdown.Item onClick={handlerLogOut}>
                Cerrar sesión
              </Dropdown.Item>
              <div className="divider"></div>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>

        {show ? (
          <ModalMsgAdmin key={4} show={show} onClose={setShow}></ModalMsgAdmin>
        ) : null}
      </Container>
    </Navbar>
  );
}

export default Header;
