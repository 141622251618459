import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import PaginationList from "../../../../../components/organisms/pagination-list/PaginationList";
import CardItemTravelPostulated from "../../../components/molecules/CardItemTravelPostulated";
import {
  delCancelPostulation,
  getToPostulated1,
} from "../../../../../api/modules";
import { useDispatch, useSelector } from "react-redux";
import { deleteNotification } from "../../../../../redux/actions/app";
import TravelDetailPostulation from "./TravelDetailPostulation";
import ModalQuestion from "../../../../../components/organisms/modal-question/ModalQuestion";
import { errorToast, successToast } from "../../../../../services/toastService";

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const ContainerList = styled.div`
  overflow-y: overlay;
  padding-right: 10px;
`;
const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const PostulatedTravels = ({ state }) => {
  useEffect(() => {
    fetchData(numberList, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const history = useHistory();
  const [page, setPage] = useState(0);
  const numberList = 5;
  const INITIAL_STATE = {
    total: 0,
    items: [],
  };
  const [isShow, setIsShow] = useState(false);
  const [travelId, setTravelId] = useState({});
  const [tripsAPI, setTripsAPI] = useState(INITIAL_STATE);
  const spinner = useSelector((state) => state.appReducer.isLoading);
  const modalInitialState = {
    show: false,
    _id: null,
    name: null,
    tripId: null,
  };
  const [modalExit, setModalExit] = useState(modalInitialState);
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.authApp);

  const fetchData = async (limit = numberList, from = 0) => {
    const tripsResponse = await getToPostulated1(numberList, from, state);

    const { trips, total } = tripsResponse;
    if (trips)
      setTripsAPI({
        ...INITIAL_STATE,
        items: trips,
        total,
      });
    if (state === 2) {
      dispatch(
        deleteNotification({
          id_owner: user._id,
          type: "Cancelada",
        })
      );
    }
  };

  const handleCloseExitModal = async (response) => {
    if (response) {
      const response = await delCancelPostulation({
        id_trip: modalExit?.tripId,
        id_postulation: modalExit._id,
        reason: "Eliminada por el tomador",
      });
      const { msg } = response;
      if (!msg) {
        successToast("Postulacion eliminada con exitosamente");
        history.replace("/app/my-travels-tc?state=0");
        setPage(0);
      } else {
        errorToast(msg);
      }
    }
    setModalExit(modalInitialState);
  };

  const handlePreviewPage = () => {
    fetchData(numberList, numberList * page - numberList);
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    fetchData(numberList, numberList * (page + 1));
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <h1 className="h4 text-primary font-weight-bold mb-3 mt-3">
              Viajes postulados
            </h1>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <ContainerList>
              {tripsAPI?.items.length ? (
                tripsAPI.items.map((trip, index) => (
                  <CardItemTravelPostulated
                    onClick={() => {
                      setIsShow(true);
                      setTravelId({
                        ...trip,
                        type: trip.price_type,
                        id: trip._id,
                      });
                    }}
                    trucksNumber={trip.trucks_number}
                    originName={
                      trip?.origin_site.locality.name +
                      ", " +
                      trip?.origin_site.locality.id_province?.name
                    }
                    destinyName={
                      trip?.destiny_site.locality.name +
                      ", " +
                      trip?.destiny_site.locality.id_province?.name
                    }
                    product_type_name={trip.product.product_type_name}
                    withdrawDate={trip.withdraw_date}
                    deliveryDate={trip.delivery_date}
                    idTrip={trip.id_trip}
                    userDc={trip.user_id_dc}
                    product={trip.product}
                    state="Pendientes"
                    key={index}
                    score={trip.score}
                    onDelete={
                      state === "0"
                        ? () =>
                            setModalExit({
                              show: true,
                              _id: trip?.postulation?._id,
                              name: trip.id_trip,
                              tripId: trip?._id,
                            })
                        : null
                    }
                  >
                    <strong>{trip.site_name}</strong> {trip.address}
                  </CardItemTravelPostulated>
                ))
              ) : !spinner ? (
                <h2 className="font-weight-bold h3 text-center">
                  Aún no tenés ningún viaje pendiente
                </h2>
              ) : (
                <DivEmpty></DivEmpty>
              )}
            </ContainerList>
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={tripsAPI.items}
            total={tripsAPI.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
      </RowStyled>
      {isShow ? (
        <TravelDetailPostulation
          isShow={isShow}
          handleClose={() => {
            setIsShow(false);
            setTravelId({});
          }}
          travelData={travelId}
        />
      ) : null}
      {modalExit.show ? (
        <ModalQuestion
          title="¿Estás seguro de esta accion?"
          subTitle={"Eliminar la postulacion para el viaje ID" + modalExit.name}
          isShow={modalExit.show}
          handleClose={handleCloseExitModal}
        />
      ) : null}
    </>
  );
};

export default PostulatedTravels;
