import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { BiEdit } from "react-icons/bi";
import { Table } from "rsuite";
import { getBillingsTomador } from "../../../../../api/modules";
import ModalLp from "./Components/ModalLp";
import { CardLogBootstrapStyled } from "../../../../../styledComponents/CardBootstrapLog";
const { Column, HeaderCell, Cell, ColumnGroup } = Table;

const TAXES_LABEL = process.env.REACT_APP_TAXES_LABEL;
function BillingTomador() {
  const [lpModal, setLpModal] = useState({
    isShow: false,
    data: {},
    amount: 0,
  });

  const INITIAL_STATE = {
    total: 0,
    data: [],
    id_dador: null,
    id_tomador: null,
  };

  const [tomadorBalance, setTomadorBalance] = useState("");
  const [tomadorLp, setTomadorLp] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [billing, setBilling] = useState(INITIAL_STATE);

  const openLpModal = (rowData) => {
    const trips = rowData.invoice_id?.trip_details;
    const filtered = billing.data?.filter((ele) => trips?.includes(ele._id));
    const amount = filtered.reduce((accumulator, rowData) => {
      const discount = rowData.discount_trip
        ? rowData.price_per_ton *
          rowData.auditedWeight *
          (rowData.discount_trip / 100)
        : rowData.price_per_ton *
          rowData.auditedWeight *
          ((rowData.discount_dador + rowData.discount_tomador) / 100);

      return accumulator + parseFloat(discount.toFixed(2));
    }, 0);
    setLpModal({ isShow: true, data: rowData, amount: amount });
  };
  const closeLpModal = () => setLpModal({ isShow: false, data: {}, amount: 0 });

  const calculateTomadorBalance = () => {
    const uniqueLpIds = new Set();
    let sum = 0;

    billing.data?.forEach((item) => {
      const lpId = item.lp_id?._id;

      if (typeof lpId === "string" && !uniqueLpIds.has(lpId)) {
        uniqueLpIds.add(lpId);

        const amount = item.lp_id?.amount || 0;
        const paymentOrderAmounts =
          item.lp_id?.paymentOrder_id?.map(
            (paymentOrder) => paymentOrder.amount
          ) || [];

        const totalPaymentOrderAmount = paymentOrderAmounts.reduce(
          (accumulator, currentItem) => accumulator + currentItem,
          0
        );

        sum += amount - totalPaymentOrderAmount;
      }
    });

    setTomadorBalance(sum);
  };

  const calculateLpTomador = () => {
    const uniqueLpIds = new Set();
    let sum = 0;

    billing.data?.forEach((item) => {
      const lpId = item.lp_id?._id;

      if (typeof lpId === "string" && !uniqueLpIds.has(lpId)) {
        uniqueLpIds.add(lpId);

        const amount = item.lp_id?.amount || 0;

        sum += amount;
      }
    });

    setTomadorLp(sum);
  };

  const getData = async () => {
    try {
      const { total, accounts, id_dador, id_tomador } =
        await getBillingsTomador();
      setBilling({ total, data: accounts, id_dador, id_tomador });
      if (lpModal.data) {
        let updatedData = accounts.find((ele) => ele._id === lpModal.data._id);
        setLpModal((prev) => {
          return { ...prev, data: updatedData };
        });
      }
    } catch (error) {
      console.error("Billing Admin", error);
    }
  };
  const onRefetch = () => setRefetch((prev) => !prev);

  useEffect(() => {
    getData();
  }, [refetch]);

  useEffect(() => {
    calculateTomadorBalance();
    calculateLpTomador();
  }, [billing]);

  return (
    <Container fluid>
      <Row /* className="mt-5" */>
        <Col>
          <h1 className="text-primary h3 font-weight-bold">Mis cuentas</h1>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md="12">
          <h1 className=" h6 font-weight-bold font-italic">
            {TAXES_LABEL || "Montos netos sin impuestos"}
          </h1>
        </Col>

        <Col md="12">
          <Table
            rowHeight={30}
            hover={false}
            height={550}
            bordered
            cellBordered
            headerHeight={70}
            wordWrap={true}
            data={billing.data}
            rowClassName="p-0"
          >
            <ColumnGroup header="Viajes Finalizados" align="center">
              <Column flexGrow={2}>
                <HeaderCell>ID viaje</HeaderCell>
                <Cell dataKey="id_viaje">
                  {(rowData) => {
                    return (
                      <>
                        <p className="mb-0">
                          {rowData.parent_id + rowData.order || "N/A"}{" "}
                        </p>
                      </>
                    );
                  }}
                </Cell>
              </Column>
              <Column flexGrow={3}>
                <HeaderCell>Importe</HeaderCell>
                <Cell dataKey="billing_amount">
                  {(rowData) => {
                    return typeof rowData.auditedWeight === "number" ? (
                      <CurrencyFormat
                        value={
                          (rowData.discount_trip
                            ? rowData.price_per_ton * rowData.auditedWeight
                            : rowData.price_per_ton * rowData.auditedWeight) -
                          (rowData.discount_trip
                            ? rowData.price_per_ton *
                              rowData.auditedWeight *
                              (rowData.discount_trip / 100)
                            : rowData.price_per_ton *
                              rowData.auditedWeight *
                              ((rowData.discount_dador +
                                rowData.discount_tomador) /
                                100))
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix={"$"}
                        renderText={(formattedValue) => (
                          <div>
                            <span>{formattedValue}</span>
                          </div>
                        )}
                      />
                    ) : (
                      <div>
                        <span>En Auditoria</span>
                      </div>
                    );
                  }}
                </Cell>
              </Column>
            </ColumnGroup>
            <ColumnGroup header="Tomador" align="center">
              <Column flexGrow={3} rowSpan={(rowData) => rowData.row_span}>
                <HeaderCell>L. P.</HeaderCell>
                <Cell dataKey="lp_number">
                  {(rowData) => {
                    return rowData.invoice_id?.invoice_number ? (
                      rowData.lp_id ? (
                        <>
                          <span>{rowData.lp_id?.number + " "}</span>
                          <BiEdit
                            className="cursor-pointer ml-1 mb-1"
                            onClick={() => openLpModal(rowData)}
                          />
                        </>
                      ) : (
                        <>
                          <span>N/A</span>
                        </>
                      )
                    ) : (
                      <p className="mb-0">N/A</p>
                    );
                  }}
                </Cell>
              </Column>
              <Column flexGrow={3} rowSpan={(rowData) => rowData.row_span}>
                <HeaderCell>Saldo LP</HeaderCell>
                <Cell dataKey="tomador_balance">
                  {(rowData) => {
                    return rowData.lp_id?.number ? (
                      <CurrencyFormat
                        value={
                          rowData.lp_id?.amount -
                          rowData.lp_id?.paymentOrder_id?.reduce(
                            (accumulator, currentItem) => {
                              return accumulator + currentItem.amount;
                            },
                            0
                          )
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix={"$"}
                      />
                    ) : (
                      <p className="mb-0">N/A</p>
                    );
                  }}
                </Cell>
              </Column>
            </ColumnGroup>
          </Table>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <CardLogBootstrapStyled className="shadow-6dp p-4 ml-0 mr-0 mt-3 mb-4">
            <Row>
              <Col xs={6}>
                {" "}
                <label className="black-primary font-weight-bold mr-2">
                  Importe total viajes:
                </label>
              </Col>
              <Col xs={6}>
                <CurrencyFormat
                  value={
                    billing.data
                      .filter((item) => typeof item.auditedWeight === "number")
                      ?.reduce((sum, item) => {
                        return sum + item.price_per_ton * item.auditedWeight;
                      }, 0) -
                    billing.data
                      .filter((item) => typeof item.auditedWeight === "number")
                      ?.reduce((sum, item) => {
                        return (
                          sum +
                          (item.discount_trip
                            ? item.price_per_ton *
                              item.auditedWeight *
                              (item.discount_trip / 100)
                            : item.price_per_ton *
                              item.auditedWeight *
                              ((item.discount_dador + item.discount_tomador) /
                                100))
                        );
                      }, 0)
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                  renderText={(formattedValue) => (
                    <div>
                      <span>{formattedValue}</span>
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                {" "}
                <label className="black-primary font-weight-bold mr-2">
                  Importe total LP:
                </label>
              </Col>
              <Col xs={6}>
                <CurrencyFormat
                  value={tomadorLp}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                  renderText={(formattedValue) => (
                    <div>
                      <span>{formattedValue}</span>
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                {" "}
                <label className="black-primary font-weight-bold mr-2">
                  Saldo de LP:
                </label>
              </Col>
              <Col xs={6}>
                <CurrencyFormat
                  value={tomadorBalance}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                  renderText={(formattedValue) => (
                    <div>
                      <span>{formattedValue}</span>
                    </div>
                  )}
                />
              </Col>
            </Row>
          </CardLogBootstrapStyled>
        </Col>
      </Row>

      {lpModal?.isShow ? (
        <ModalLp
          isShow={lpModal.isShow}
          data={lpModal.data}
          amount={lpModal.amount}
          handleClose={closeLpModal}
          refetch={onRefetch}
        />
      ) : null}
    </Container>
  );
}

export default BillingTomador;
