/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";

import { Button, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import LogLogo from "../../../../components/atoms/LogLogo";
import LogLogoXs from "../../../../components/atoms/LogLogoXs";
import LogButton from "../../../../components/atoms/LogButton";
import styled from "styled-components";
import { logOutAuth } from "../../../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BsArrowDown } from "react-icons/bs";
import { AiOutlineArrowDown, AiOutlineArrowRight } from "react-icons/ai";
import { setCollapseOff, setCollapseOn } from "../../../../redux/actions/app";
import {
  COLOR_PRIMARY,
  COLOR_PRIMARY_DARK,
} from "../../../../constants/Colors";
import { GoTriangleDown, GoTriangleRight } from "react-icons/go";

const SideShadow = styled.div`
  box-shadow: 0 -20px 5px 0 rgb(0 0 0 / 5%), 0 -10px 10px 0 rgb(0 0 0 / 10%),
    0 2px 4px -1px rgb(0 0 0 / 1%) !important;
`;

const SideTitle = styled.p`
  font-size: 16px !important;
`;

const TooltipStyled = styled(Tooltip)`
  color: #0000;
  .tooltip-inner {
    font-size: 16px !important;
    margin-left: 10px;
    font-weight: bold;
    color: #ffff;
    background-color: ${COLOR_PRIMARY_DARK};
    opacity: 1;
  }
`;

const DivProfileSettings = styled.div`
  display: flex;
  justify-content: center;
`;

function Sidebar({ color, image, routes }) {
  const location = useLocation();
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const history = useHistory();
  const { collapse } = useSelector((state) => state.appReducer);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  let isMobile = width <= 992;
  useEffect(() => {
    if (isMobile && !collapse) dispatch(setCollapseOn());
  }, [isMobile]);

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  return (
    <div
      className={`${collapse || isMobile ? "sidebar" : "sidebarxs"}`}
      data-image={image}
      data-color={color}
    >
      <div
        className={`${
          collapse || isMobile ? "sidebar-background" : "sidebarxs-background"
        }`}
      />
      <SideShadow
        className={`${
          collapse || isMobile ? "sidebar-wrapper" : "sidebarxs-wrapper"
        }`}
      >
        {collapse || isMobile ? (
          <div className="d-flex align-items-center justify-content-start pr-5 pt-4 pb-0 pl-5">
            <LogLogo style={{ width: "160px", height: 28 }} />
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center pt-4 pb-0 ">
            <LogLogoXs style={{ width: "28px", height: 28 }} />
          </div>
        )}

        <div className="d-flex justify-content-start mt-3">
          {!isMobile ? (
            collapse ? (
              <GoTriangleRight
                onClick={() => dispatch(setCollapseOff())}
                className="text-primary h4 ml-1 mr-1 w-100 cursor-pointer"
              />
            ) : (
              <GoTriangleDown
                onClick={() => dispatch(setCollapseOn())}
                className="text-primary h4 ml-1 mr-1 w-100 cursor-pointer"
              />
            )
          ) : null}
        </div>
        <Nav>
          {routes.map((prop, key) => {
            if (!prop.redirect)
              return (
                <li
                  className={
                    prop.upgrade
                      ? "active active-pro"
                      : activeRoute(prop.layout + prop.path)
                  }
                  key={key}
                >
                  {collapse || isMobile ? (
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link mt-0 ml-4 pl-4 side-titles"
                      activeClassName="active"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span
                        className={`${prop.icon} text-primary mr-4`}
                        style={{
                          fontSize: 16,
                        }}
                      ></span>
                      <SideTitle>{prop.name}</SideTitle>
                    </NavLink>
                  ) : (
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link mt-0 side-titles"
                      activeClassName="active"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <OverlayTrigger
                        placement={"right"}
                        delay={{ hide: 450, show: 100 }}
                        overlay={
                          <TooltipStyled id={`tooltip-left`}>
                            {prop.name}
                          </TooltipStyled>
                        }
                      >
                        <span
                          className={`${prop.icon} text-primary mr-4`}
                          style={{
                            fontSize: 16,
                          }}
                        ></span>
                      </OverlayTrigger>
                    </NavLink>
                  )}
                </li>
              );
            return null;
          })}
          <li>
            {isMobile && (
              <DivProfileSettings>
                <LogButton
                  onClick={() => {
                    dispatch(logOutAuth());
                    history.push("/");
                  }}
                  className="mx-4"
                  block
                  title="Cerrar sesión"
                />
              </DivProfileSettings>
            )}
          </li>
        </Nav>
      </SideShadow>
    </div>
  );
}

export default Sidebar;
