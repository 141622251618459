import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";

const SelectGroup = styled(Form.Group)`
  label:first-child {
    color: black !important;
    font-weight: bold;
  }
`;

const LogRadioGroupWrapper = ({ children, errors, name, label, ...rest }) => {
  return (
    <SelectGroup {...rest}>
      {label && <label>{label}</label>}
      {children}
      {errors[name] && (
        <div className="mb-2 mt-0">
          <small className="text-danger">{errors[name]}.</small>
        </div>
      )}
    </SelectGroup>
  );
};

export default LogRadioGroupWrapper;
