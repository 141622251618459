import {
  SET_LOADING_SPINNER_ON,
  SET_LOADING_SPINNER_OFF,
  SET_COLLAPSE_OFF,
  SET_COLLAPSE_ON,
  UPDATE_NOTIFICATION,
  PUSH_NOTIFICATION,
  DELETE_NOTIFICATION,
  LOGOUT_AUTH,
} from "../actionTypes";

const initialState = {
  isLoading: false,
  collapse: true,
  notifications: [],
};

export function appReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_NOTIFICATION: {
      return {
        ...state,
        notifications: action.payload,
      };
    }
    case LOGOUT_AUTH: {
      return initialState;
    }

    case PUSH_NOTIFICATION: {
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    }
    case DELETE_NOTIFICATION: {
      const queryKeys = Object.keys(action.payload);
      return {
        ...state,
        notifications: state.notifications.filter((ele) => {
          for (let i = 0; i < queryKeys.length; i++) {
            if (ele[queryKeys[i]] !== action.payload[queryKeys[i]]) {
              return true;
            }
          }
          return false;
        }),
      };
    }

    case SET_LOADING_SPINNER_ON:
      return {
        ...state,
        isLoading: true,
      };
    case SET_LOADING_SPINNER_OFF:
      return {
        ...state,
        isLoading: false,
      };

    case SET_COLLAPSE_OFF:
      return {
        ...state,
        collapse: false,
      };

    case SET_COLLAPSE_ON:
      return {
        ...state,
        collapse: true,
      };
    default:
      return state;
  }
}
