import { apiHttp } from "../axiosApi.js";
import {
  login,
  register,
  authGoogle,
  registerGoogle,
  authValidateEmail,
  authRecoverPassword,
  authChangePassword,
  authValidatePhone,
  refreshToken,
  authValidatePhoneGoogle,
} from "../config/apiRoute.js";

export const getAppToken = () => apiHttp("GET", `${test}/token`);

export const postRegister = (body) => apiHttp("POST", `${register}`, body);

export const postAuthGoogle = (body) => apiHttp("POST", `${authGoogle}`, body);

export const postRegisterGoogle = (body) =>
  apiHttp("POST", `${registerGoogle}`, body);

export const putValidateEmail = (token) =>
  apiHttp("PUT", `${authValidateEmail}${token}`, {});

export const postRecoverPass = (body) =>
  apiHttp("POST", `${authRecoverPassword}`, body);

export const putChangePass = (token, body) =>
  apiHttp("PUT", `${authChangePassword}${token}`, body);

export const putValidatePhone = (body) =>
  apiHttp("PUT", `${authValidatePhone}`, body);

export const putValidatePhoneGoogle = (body) =>
  apiHttp("PUT", `${authValidatePhoneGoogle}`, body);

export const isValidEmail = (email) =>
  apiHttp("GET", `/auth/isUserRegistered/${email}`);

export const postLogin = (body) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    device: "WEB",
  };
  return apiHttp("POST", `${login}`, body, { headers });
};

export const postRefreshToken = (body) =>
  apiHttp("POST", `${refreshToken}`, body);
