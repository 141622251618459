import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import CardItemTravelTruck from "../../../components/molecules/CardItemTravelTruck";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import queryString from "query-string";
import { getTripAPI, getTripChilds } from "../../../../../api/modules";
import { useState } from "react";
import CardItemOriginDestiny from "../../../components/molecules/CardItemOriginDestiny";
import PaginationList from "../../../../../components/organisms/pagination-list/PaginationList";

const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const InActionsTravelsTrucks = ({ tripId, ...rest }) => {
  const INITIAL_STATE = {
    total: 0,
    items: [],
  };
  const [page, setPage] = useState(0);
  const numberList = 5;
  const [tripsAPI, setTripsAPI] = useState(INITIAL_STATE);
  const [tripDetail, setTripDetail] = useState(null);
  const history = useHistory();
  const fetchData = async (limit = numberList, from = 0) => {
    const tripsResponse = await getTripChilds(tripId, "[3,4]", limit, from);
    const { total, tripsDetails } = tripsResponse;
    if (tripsDetails)
      setTripsAPI({
        ...INITIAL_STATE,
        total,
        items: tripsDetails,
      });
  };

  const fetchDetails = async (id) => {
    try {
      const response = await getTripAPI(id);

      if (response) setTripDetail(response.data);
    } catch (error) {
      console.log("pending error", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchDetails(tripId);
  }, []);

  const handlePreviewPage = () => {
    fetchData(numberList, numberList * page - numberList);
    setPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    fetchData(numberList, numberList * (page + 1));
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <CardItemOriginDestiny
              originName={tripDetail?.trip.origin_site.site_name}
              destinyName={tripDetail?.trip.destiny_site.site_name}
              deliveryDate={tripDetail?.trip.delivery_date}
              withdrawDate={tripDetail?.trip.withdraw_date}
              /* onClick={() =>
                  history.push(`/app/traves/travel-detail/${tripId}`)
                } */
              destiny={tripDetail?.trip.destiny}
              origin={tripDetail?.trip.origin}
            />
            {tripsAPI?.items.map((location, index) => (
              <CardItemTravelTruck
                showId={
                  location?.parent_id && location.order
                    ? location?.parent_id + location.order
                    : null
                }
                name={
                  location.id_driver
                    ? location.id_driver?.name
                    : location.id_tomador?.name
                }
                lastName={
                  location.id_driver
                    ? location.id_driver?.last_name
                    : location.id_tomador?.last_name
                }
                state={location.state}
                id={location._id}
                truckDomain={location.id_truck?.domain}
                trailerDomain={location.id_trailer?.domain}
                key={index}
                onClick={() =>
                  history.push(
                    `my-travels-tc?state=3&tripId=${tripId}&detailTrip=${location._id}`
                  )
                }
              >
                <strong>{location.site_name}</strong> {location.address}
              </CardItemTravelTruck>
            ))}
            {tripsAPI?.items.length === 0 && (
              <DivEmpty>
                <p>No hay localizaciones disponibles</p>
              </DivEmpty>
            )}
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={tripsAPI.items}
            total={tripsAPI.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
      </RowStyled>
    </>
  );
};

export default InActionsTravelsTrucks;
