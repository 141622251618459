import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import { COLOR_GRAY_TEXT, COLOR_PRIMARY } from "../../../../constants/Colors";
import ReactStars from "react-rating-stars-component";
import { BsStarFill } from "react-icons/bs";
import LogTextArea from "../../../../components/atoms/LogTextArea";
import { getRating } from "../../../../api/modules";
import moment from "moment";
import PaginationList from "../../../../components/organisms/pagination-list/PaginationList";

const StarsContent = styled(Row)`
  display: flex;
  align-content: center;
  height: 38px;
  overflow: hidden;
`;
const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;
const ModalLogStyled = styled(Modal)`
  opacity: 1;
  .modal-content {
    border-radius: 15px !important;
    padding: 20px;
    overflow: auto;
    height: 80vh;
  }
  .modal-body {
    position: relative;
    overflow-y: hidden;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #d62929;
    }
    &:hover {
      overflow-y: overlay;
    }
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;

const ContentModal = styled.div`
  text-align: left;
`;

const ContentFooterCenter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

function ModalRating({ tripId, showId, isRating, onCloseRating, score, postId }) {
  const [isShow, setShowModal] = useState(false);
  const [page, setPage] = useState(0);
  const numberList = 5;
  const INITIAL_STATE = {
    total: 0,
    rating: [],
  };
  const [data, setData] = useState(INITIAL_STATE);
  const [query, setQuery] = useState({
    limit: numberList,
    from: page,
  });

  const handlePreviewPage = () => {
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * page - numberList,
      };
    });
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * (page + 1),
      };
    });
  };

  const handleClose = () => {
    onCloseRating();
    setShowModal(false);
  };

  const getFetch = async () => {
    const { rating, total } = await getRating({ ...query, tripId, postId });

    if (rating) {
      setData({ rating, total });
    } else {
      setData(INITIAL_STATE);
    }
  };

  useEffect(() => {
    setShowModal(isRating);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRating]);

  useEffect(() => {
    getFetch();
  }, [query]);

  return (
    <ModalLogStyled show={isShow} onHide={handleClose} className="modal-exit">
      <Modal.Body>
        <ContentModal>
          <h4 className="font-weight-bold text-primary h5">
            {tripId ? "Trip ID: " + showId : "Post ID: " + postId}
          </h4>
          <Row>
            <Col md={"auto"}>
              <h1 className="font-weight-bold text-primary align-middle">
                {score}
              </h1>
            </Col>
            <Col md={"auto"}>
              <StarsContent>
                <ReactStars
                  className="p-0 m-0"
                  key={1}
                  value={Number(score)}
                  isHalf={true}
                  edit={false}
                  count={5}
                  size={36}
                  activeColor="#ffd700"
                  color="#CDE0EB"
                />
              </StarsContent>
              <Row>
                <span className="ml-1 h6"> {data?.total} calificaciones</span>
              </Row>
            </Col>
          </Row>

          <Row className="pt-3 text-center">
            <Col xs={"auto"}>
              <div className="dropdown">
                <button
                  className="btn btn-primary dropdown-toggle text-white p-2 pt-1 rounded-pill"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  Ordenar
                </button>
                <div className="dropdown-menu">
                  <span
                    className="dropdown-item"
                    onClick={() => {
                      setPage(0);
                      setQuery((prev) => {
                        return {
                          ...prev,
                          order: "desc",
                          from: 0,
                        };
                      });
                    }}
                  >
                    Más recientes
                  </span>
                  <span
                    className="dropdown-item"
                    onClick={() => {
                      setPage(0);
                      setQuery((prev) => {
                        return {
                          ...prev,
                          order: "asc",
                          from: 0,
                        };
                      });
                    }}
                  >
                    Más antiguos
                  </span>
                </div>
              </div>
            </Col>
            <Col xs={"auto"} className="text-right">
              <div className="dropdown">
                <button
                  className="btn btn-primary dropdown-toggle text-white  p-2 rounded-pill"
                  type="button"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  {query.score ? (
                    <>
                      Calificacion {query.score}
                      <BsStarFill className="ml-1 mb-2" />
                    </>
                  ) : (
                    "Calificacion"
                  )}
                </button>
                <div className="dropdown-menu">
                  <span
                    className="dropdown-item"
                    onClick={() => {
                      setPage(0);
                      setQuery((prev) => {
                        return {
                          ...prev,
                          score: 0,
                          from: 0,
                        };
                      });
                    }}
                  >
                    Todas
                  </span>
                  <span
                    className="dropdown-item"
                    onClick={() => {
                      setPage(0);
                      setQuery((prev) => {

                        return {
                          ...prev,
                          score: 1,
                          from: 0,
                        };
                      });
                    }}
                  >
                    <BsStarFill className="ml-1" color={COLOR_PRIMARY} />
                  </span>
                  <span
                    className="dropdown-item"
                    onClick={() => {
                      setPage(0);
                      setQuery((prev) => {
                        return {
                          ...prev,
                          score: 2,
                          from: 0,
                        };
                      });
                    }}
                  >
                    <BsStarFill className="ml-1" color={COLOR_PRIMARY} />
                    <BsStarFill className="ml-1" color={COLOR_PRIMARY} />
                  </span>
                  <span
                    className="dropdown-item"
                    onClick={() => {
                      setPage(0);
                      setQuery((prev) => {
                        return {
                          ...prev,
                          score: 3,
                          from: 0,
                        };
                      });
                    }}
                  >
                    <BsStarFill className="ml-1" color={COLOR_PRIMARY} />
                    <BsStarFill className="ml-1" color={COLOR_PRIMARY} />
                    <BsStarFill className="ml-1" color={COLOR_PRIMARY} />
                  </span>
                  <span
                    className="dropdown-item"
                    onClick={() => {
                      setPage(0);
                      setQuery((prev) => {
                        return {
                          ...prev,
                          score: 4,
                          from: 0,
                        };
                      });
                    }}
                  >
                    <BsStarFill className="ml-1" color={COLOR_PRIMARY} />
                    <BsStarFill className="ml-1" color={COLOR_PRIMARY} />
                    <BsStarFill className="ml-1" color={COLOR_PRIMARY} />
                    <BsStarFill className="ml-1" color={COLOR_PRIMARY} />
                  </span>
                  <span
                    className="dropdown-item"
                    onClick={() => {
                      setPage(0);
                      setQuery((prev) => {
                        return {
                          ...prev,
                          score: 5,
                          from: 0,
                        };
                      });
                    }}
                  >
                    <BsStarFill className="ml-1" color={COLOR_PRIMARY} />
                    <BsStarFill className="ml-1" color={COLOR_PRIMARY} />
                    <BsStarFill className="ml-1" color={COLOR_PRIMARY} />
                    <BsStarFill className="ml-1" color={COLOR_PRIMARY} />
                    <BsStarFill className="ml-1" color={COLOR_PRIMARY} />
                  </span>
                </div>
              </div>
            </Col>
          </Row>

          <hr />
        </ContentModal>

        <Row>
          <Col md={12} className="d-flex flex-column align-items-start">
            {data.rating?.length > 0
              ? data.rating?.map((ele, index) => (
                  <React.Fragment key={index}>
                    <ReactStars
                      key={ele._id}
                      value={ele.score}
                      isHalf={true}
                      edit={false}
                      count={5}
                      size={24}
                      activeColor="#ffd700"
                      color="#CDE0EB"
                    />
                    <span className="h6">{ele.comment}</span>
                    <small>{moment(ele.createdAt).format("DD/MM/YY")}</small>
                    <div
                      className="divider mt-1 mb-2 "
                      style={{ opacity: 0.3 }}
                    >
                      <span></span>
                    </div>
                  </React.Fragment>
                ))
              : null}
          </Col>
        </Row>
        <RowStyled>
          <Col md="12">
            <PaginationList
              items={data.rating}
              total={data.total}
              currPage={page}
              handlePreviewPage={handlePreviewPage}
              handleNextPage={handleNextPage}
              numberList={numberList}
            />
          </Col>
        </RowStyled>
      </Modal.Body>
    </ModalLogStyled>
  );
}

export default ModalRating;
