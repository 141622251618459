import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import { getClaims, postClaim } from "../../../../../../../api/modules";
import { errorToast } from "../../../../../../../services/toastService";
import { useEffect } from "react";
import { useState } from "react";
import ChatWindow from "../../../../../../../components/organisms/chatWindow/ChatWindow";
import { useSelector } from "react-redux";

const Claim = ({ id_trip, id_tripDetail }) => {
  const user = useSelector((state) => state.authApp.user);
  const [claims, setClaims] = useState({});
  const uploadData = async (image) => {
    try {
      let data = new FormData();
      data.append("file", image);
      data.append("upload_preset", "ceqdn88c");
      const res = await fetch(
        "https://api.cloudinary.com/v1_1/dcbqcvers/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const file = await res.json();
      return file.secure_url;
    } catch (error) {
      console.log("Error Cloudinary", error);
      errorToast("Por favor intente nuevamente");
    }
  };

  const handleSubmitAPI = async ({
    values,
    resetForm,
    setImage,
    image,
    id_receptor,
  }) => {
    try {
      let document = "";
      if (values?.document) {
        document = await uploadData(image);
      }
      const { msg } = await postClaim({
        ...values,
        document,
        id_trip,
        id_tripDetail,
        id_receptor,
      });

      resetForm();
      setImage({});
      getAllClaims();

      if (msg) {
        errorToast(msg);
      }
    } catch (error) {
      console.log("error", error);
      resetForm();
      setImage({});
      getAllClaims();
      /* errorToast(error); */
    }
  };
  const getAllClaims = async () => {
    const { msg, claim, claimDetails } = await getClaims(id_tripDetail);
    if (!msg) {
      setClaims({ claim, claimDetails });
    } else {
      errorToast(msg);
    }
  };
  useEffect(() => {
    getAllClaims();
  }, []);

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md={10}>
          <ChatWindow
            title={"Mensajes de Reclamo"}
            handleSubmitAPI={handleSubmitAPI}
            data={claims.claimDetails}
            roleSent={user?.role_id?.name}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Claim;
