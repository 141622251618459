import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const CardDivStyled = styled.div`
  background: white;
  color: black;
  padding: 25px 20px;
  border: 2px solid white;
  box-shadow: 4px 6px 20px 0px rgba(0, 0, 0, 0.469);
  margin: auto;

  width: ${(props) => props.width && props.width};

  @media only screen and (max-width: 600px) {
    width: auto !important;
  }
`;

const LogCard = ({ width, children, ...rest }) => {
  return (
    <CardDivStyled {...rest} width={width}>
      {children}
    </CardDivStyled>
  );
};

LogCard.propTypes = {
  width: PropTypes.number,
}
export default LogCard;
