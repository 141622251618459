import React from "react";
import { Slider } from "rsuite";
import { FaTruckMoving } from "react-icons/fa";
import styled from "styled-components";

const FaTruckMovingIcon = styled(FaTruckMoving)`
  position: relative;
  z-index: 99;
  bottom: 8px;
  right: 8px;
`;

const SliderLogStyled = styled(Slider)`
  .bar-progress-log {
    .rs-slider-progress-bar {
      background: #92c31a !important;
    }
  }
`;

const SliderLog = (props) => {
  return (
    <SliderLogStyled
      progress
      handleTitle={<FaTruckMovingIcon className="text-primary" />}
      barClassName="bar-progress-log"
      defaultValue={50}
      onChange={(value) => {
        console.log(value);
      }}
    />
  );
};

export default SliderLog;
