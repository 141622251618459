import React from "react";
import styled from "styled-components";
import { COLOR_GRAY_TEXT, COLOR_PRIMARY } from "../../constants/Colors";
import { SelectPicker } from "rsuite";
import { FaRegClock } from "react-icons/fa";

const ContentTime = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
`

const LogDatePickerStyled = styled(SelectPicker)`
  width: 100%;
  background-color: white;
  border: none;
  padding: 2px 2px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px #CCCCCC;
  .rs-picker-toggle-value {
    color: ${COLOR_GRAY_TEXT} !important;
    padding-left: 32px !important;
  }

  .rs-picker-toggle-placeholder {
    padding-left: 32px !important;
  }
  .rs-picker-toggle-caret {
    left: 20px !important;
    color: ${COLOR_PRIMARY} !important;
    display: none !important;
  }
`;

const LogTimerPicker = ({
  placeholder,
  className,
  errors,
  messageError,
  name,
  items,
  ...rest
}) => {
  // let mb0 = errors[name] ? "true" : "false";
  return (
    <>
      <ContentTime>
        <LogDatePickerStyled
          {...{ ...rest, name }}
          placeholder={placeholder}
          className={`${className}`}
          data={items}
          name={name}
          {...rest}
          appearance="subtle"
          searchable={false}
          cleanable={false}
          block
        />
        <FaRegClock className="icon-clock-time" />
      </ContentTime>
      {errors[name] && (
        <div className="mb-2 mt-0">
          <small className="text-danger">{errors[name]}.</small>
        </div>
      )}
    </>
  );
};

export default LogTimerPicker;
