import React from "react";
import PropTypes from "prop-types";
import { FormControl } from "react-bootstrap";
import styled from "styled-components";
import { COLOR_GRAY_TEXT } from "../../constants/Colors";

const LogFormControl = styled(FormControl)`
  background-color: #f6f6f4;
  color: ${COLOR_GRAY_TEXT};
  border: none;
  padding: 25px 5px;
  border-radius: 2px;
  margin-bottom: ${(props) => props.mb0 === "true" && 0}px !important;
  ::placeholder {
    padding-left: 14px !important;
    font-size: 14px;
    color: ${COLOR_GRAY_TEXT};
  }
  ::-moz-placeholder {
    padding-left: 14px !important;
    color: red;
    font-size: 14px;
    color: ${COLOR_GRAY_TEXT};
    text-indent: 10px;
  }
`;

const LogInput = ({
  placeholder,
  className,
  errors,
  messageError,
  name,
  refEl,
  ...rest
}) => {
  let mb0 = errors[name] ? "true" : "false";
  return (
    <>
      <LogFormControl
        ref={refEl}
        {...{ ...rest, name, mb0 }}
        className={`${className}  ${errors[name] && "is-invalid"}`}
        placeholder={placeholder}
      />
      {errors[name] && (
        <div className="mb-2 mt-0">
          <small className="text-danger">{errors[name]}.</small>
        </div>
      )}
    </>
  );
};

LogInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default LogInput;
