import styled from "styled-components";
import { COLOR_TEXT_WHITE } from "../constants/Colors";

export const DivAuthScreen = styled.div`
  min-height: 100vh;
  padding: 30px;
  color: ${COLOR_TEXT_WHITE};
  background: #171a1f;
  background: -moz-linear-gradient(left, #171a1f 1%, #292f38 100%);
  background: -webkit-linear-gradient(left, #171a1f 1%, #292f38 100%);
  background: linear-gradient(to right, #171a1f 1%, #292f38 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#171a1f', endColorstr='#292f38',GradientType=1 );

  @media only screen and (max-width: 600px) {
    height: auto !important;
  }
`;

export const DivNavAuthStyled = styled.div`
  margin-bottom: 20px;
  margin-top: 0px;
  position: absolute;
`;
