import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";

const H1Styled = styled.h1`
  color: #263238;
`;
const ContentHeader = ({
    title,
    rowClassName = "",
    colClassName = "",
    className = "",
}) => {
    return (
        <Row className={rowClassName}>
            <Col md="12" className={colClassName + ' mb-3'}>
                <H1Styled className={`${className} h5 font-weight-bolder`}>{title}</H1Styled>
            </Col>
        </Row>
    );
};

ContentHeader.propTypes = {
    title: PropTypes.string.isRequired,
    rowClassName: PropTypes.string,
    colClassName: PropTypes.string,
    className: PropTypes.string,
};

export default ContentHeader;
