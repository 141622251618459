import { toast } from "react-toastify";

const successToast = (msg, time = 3000) => {
  toast.success(msg, {
    autoClose: time,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const errorToast = (msg, time = 6000) => {
  if (typeof msg === "string") {
    toast.error(msg, {
      autoClose: time,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  } else if (typeof msg === "object") {
    for (const i of msg) {
      toast.error(i.msg, {
        autoClose: time,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }
};

export { successToast, errorToast };
