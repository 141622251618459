import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import PaginationList from "../../../../../components/organisms/pagination-list/PaginationList";
import CardItemTravel from "../../../components/molecules/CardItemTravel";
import {
  getToPostulated,
  getToPostulated2,
  getTripAPI,
} from "../../../../../api/modules";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const ContainerList = styled.div`
  overflow-y: overlay;
  padding-right: 10px;
`;
const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const PendingsTravels = () => {
  const spinner = useSelector((state) => state.appReducer.isLoading);
  const [page, setPage] = useState(0);
  const [refetch, setRefetch] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const numberList = 5;
  const INITIAL_STATE = {
    total: 0,
    items: [],
  };
  const [tripsAPI, setTripsAPI] = useState(INITIAL_STATE);
  const [trip, setTrip] = useState(null);
  const addQueryParam = (key, value) => {
    const params = new URLSearchParams(location.search);
    params.set(key, value);
    history.push({ search: params.toString() });
  };

  useEffect(() => {
    fetchData(numberList, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const fetchData = async (limit = numberList, from = 0) => {
    const tripsResponse = await getToPostulated2(limit, from, 2);

    const { trips, total } = tripsResponse;
    if (trips)
      setTripsAPI({
        ...INITIAL_STATE,
        items: trips,
        total,
      });
  };

  const handlePreviewPage = () => {
    fetchData(numberList, numberList * page - numberList);
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    fetchData(numberList, numberList * (page + 1));
  };

  return (
    <div>
      <Container fluid>
        <Row>
          <Col md="12">
            <ContainerList>
              {tripsAPI?.items.length ? (
                tripsAPI.items.map((location, index) => (
                  <CardItemTravel
                    key={index}
                    showId={location.id_trip}
                    status={location.status}
                    trucksNumber={location.counterTripdetails}
                    originName={
                      location?.origin_site.locality.name +
                      ", " +
                      location?.origin_site.locality.id_province?.name
                    }
                    destinyName={
                      location?.destiny_site.locality.name +
                      ", " +
                      location?.destiny_site.locality.id_province?.name
                    }
                    product_type_name={location.product.product_type_name}
                    withdrawDate={location.withdraw_date}
                    deliveryDate={location.delivery_date}
                    state="Pendientes"
                    drivers={location.drivers || null}
                    trucks={location.trucks}
                    idTrip={location._id}
                    type={"tc"}
                    onClick={() => addQueryParam("tripId", location._id || "0")}
                  >
                    <strong>{location.site_name}</strong> {location.address}
                  </CardItemTravel>
                ))
              ) : !spinner ? (
                <h2 className="font-weight-bold h3 text-center">
                  Aún no tenés ninguna oferta
                </h2>
              ) : (
                <DivEmpty></DivEmpty>
              )}
            </ContainerList>
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={tripsAPI.items}
            total={tripsAPI.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
      </RowStyled>
    </div>
  );
};

export default PendingsTravels;
