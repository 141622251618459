import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { COLOR_GRAY_TEXT } from "../../../../../constants/Colors";
import styled from "styled-components";
import LogButton from "../../../../../components/atoms/LogButton";
import { Formik } from "formik";
import { successToast } from "../../../../../services/toastService";
import LogSelect from "../../../../../components/atoms/LogSelect";
import { useSelector } from "react-redux";
import { getMyDrivers } from "../../../../../api/modules/driver";
import {
  addDriverToDetailTrip,
  assignmentToChildTrip,
} from "../../../../../api/modules";

const ModalLogStyled = styled(Modal)`
  opacity: 1;
  .modal-content {
    border-radius: 15px !important;
    padding: 20px;
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;

const ContentModal = styled.div`
  text-align: left;
`;

const ContentFooterCenter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const ModalPendingTravel = ({
  show,
  openCloseModal,
  onRefetch,
  trip,
}) => {
  const [drivers, setDriversAPI] = useState([]);
  const { user } = useSelector((state) => state.authApp);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getFetchDrivers(user?._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trip]);

  const getFetchDrivers = async (id) => {
    if (id) {
      const response = await getMyDrivers(20, 0, id);
      const { driversByTC } = response;
      if (driversByTC) {
        setDriversAPI(driversByTC);
      } else {
        setDriversAPI([]);
      }
    }
  };

  const handleSubmitAPI = async (values, resetForm) => {
    const body = values.id_driver
      ? { id_driver: values.id_driver }
      : { reset_driver: true };
    const response = await assignmentToChildTrip(trip?._id, body);
    const { msg } = response;
    onRefetch();
    successToast(msg);
    let btn = document.querySelectorAll(".rs-picker-toggle-clean");
    btn && btn.forEach((item) => item.click());
    openCloseModal();
  };

  const driversMap = [...drivers.filter((item) => item.is_active), user].map(
    (item) => {
      return {
        id: item._id,
        label: item.name + " " + item.last_name,
        value: item._id,
      };
    }
  );
  return (
    <Formik
      enableReinitialize
      initialValues={{
        id_driver: trip?.is_tomador
          ? trip?.id_tomador?._id
          : trip?.id_driver?._id || "",
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmitAPI(values, resetForm);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        dirty,
      }) => (
        <ModalLogStyled show={show} className="modal-exit">
          <Modal.Body>
            <ContentModal>
              <h4 className="font-weight-bold text-primary h5">
                {trip?.id_driver || trip?.is_tomador
                  ? "Editar choferes"
                  : "Agregar chofer"}
              </h4>
              <hr />
            </ContentModal>
            <Row>
              <Col md="12">
                <Form.Group>
                  <label className="black-primary font-weight-bold">
                    Seleccione el chofer
                  </label>

                  <LogSelect
                    placeholder="Selecciona un chofer"
                    className="mt-1"
                    iconLogName="icon-truck"
                    name="id_driver"
                    items={driversMap}
                    onChange={(v) => {
                      setFieldValue("id_driver", v);
                    }}
                    disabled={drivers.length === 0}
                    errors={errors}
                    value={values.id_driver}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <ContentFooterCenter>
              <LogButton
                color="primary"
                onClick={() => {
                  handleSubmit();
                }}
                title={
                  trip?.id_driver || trip?.is_tomador
                    ? values?.id_driver
                      ? "Editar choferes"
                      : "Reiniciar Asignacion"
                    : "Agregar chofer"
                }
                border="curve"
                block
                disabled={!dirty}
                className="mr-4"
              ></LogButton>
              <LogButton
                color="secondary"
                onClick={() => {
                  let btn = document.querySelectorAll(
                    ".rs-picker-toggle-clean"
                  );
                  btn && btn.forEach((item) => item.click());
                  openCloseModal();
                }}
                title={"Cancelar"}
                border="curve"
                block
              ></LogButton>
            </ContentFooterCenter>
          </Modal.Footer>
        </ModalLogStyled>
      )}
    </Formik>
  );
};
