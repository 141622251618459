import { Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import { CardLogBootstrapStyled } from "../../../../styledComponents/CardBootstrapLog";
import * as Yup from "yup";
import LogSelect from "../../../../components/atoms/LogSelect";
import LogButton from "../../../../components/atoms/LogButton";
import LogInput from "../../../../components/atoms/LogInput";
import { ADMIN_ID, DADOR_ID, TOMADOR_ID } from "../../../../constants/UserId";

const SearchSchema = Yup.object().shape({
  cuit: Yup.string(),
  name: Yup.string(),
  status: Yup.string().nullable(),
});

const statusItems = [
  { value: "0", label: "Todos" },
  { value: "1", label: "Habilitado" },
  { value: "2", label: "Eliminado" },
  { value: "3", label: "Deshabilitado" },
];

const tripsStatus = [
  { value: "2", label: "Pendiente" },
  { value: "3", label: "Listo para Viajar" },
  { value: "4", label: "Ejecucion" },
  { value: "5", label: "Finalizado" },
];

const roleItems = [
  { value: 0, label: "Todos" },
  { value: TOMADOR_ID, label: "Tomador" },
  { value: DADOR_ID, label: "Dador" },
  { value: ADMIN_ID, label: "Admin" },
];

const SearchComponent = ({ onHandleSearchSubmit }) => {
  const handleSubmit = (values) => {
    onHandleSearchSubmit(values);
  };
  return (
    <CardLogBootstrapStyled className="shadow-6dp p-4 ml-2 mr-3 mt-3 mb-4">
      <Row className="mb-3">
        <Col>
          <h1 className="h4 text-primary font-weight-bold">
            Filtrar usuarios por
          </h1>
        </Col>
      </Row>
      <Formik
        enableReinitialize
        initialValues={{
          cuit: "",
          name: "",
          status: "",
          role_id: "",
          tripState: "",
        }}
        validationSchema={SearchSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <div>
            <div>
              <Row className="mb-3">
                <Col md={4} xxl={2}>
                  <label>CUIT</label>
                  <LogInput
                    placeholder="CUIT (sin guiones)"
                    className="mb-2 number-font"
                    type="text"
                    name="cuit"
                    onChange={(e) => {
                      const { value } = e.target;
                      const re = /^[0-9\b]+$/;
                      if (value === "" || re.test(value)) {
                        setFieldValue("cuit", value);
                      }
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.cuit}
                  />
                </Col>
                <Col md={4} xxl={2}>
                  <label>Nombre completo</label>
                  <LogInput
                    placeholder="Nombre completo"
                    name="name"
                    items={[]}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("name", value);
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.name}
                  />
                </Col>
                <Col md={4} xxl={2}>
                  <label>Estado usuario</label>
                  <LogSelect
                    placeholder="Estado"
                    name="status"
                    defaultValue={"0"}
                    items={statusItems}
                    onChange={(v) => {
                      setFieldValue("status", v);
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.status}
                  />
                </Col>
                <Col md={4} xxl={2}>
                  <label>Rol</label>
                  <LogSelect
                    placeholder="Usuario"
                    name="Rol"
                    defaultValue={"0"}
                    items={roleItems}
                    onChange={(v) => {
                      setFieldValue("role_id", v);
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.role_id}
                  />
                </Col>
                <Col md={4} xxl={2}>
                  <label>Estado viaje</label>
                  <LogSelect
                    placeholder="Estado viaje"
                    name="tripState"
                    defaultValue={"0"}
                    items={tripsStatus}
                    onChange={(v) => {
                      setFieldValue("tripState", v);
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.tripState}
                  />
                </Col>
              </Row>
            </div>
            <Row>
              <Col className="d-flex justify-content-end">
                <LogButton
                  title={
                    <span
                      className="icon-magnify h5 px-4"
                      style={{ borderRadius: "3px" }}
                    ></span>
                  }
                  onClick={handleSubmit}
                />
              </Col>
            </Row>
          </div>
        )}
      </Formik>
    </CardLogBootstrapStyled>
  );
};

export default SearchComponent;
