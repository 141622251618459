import React, { useEffect, useRef, useState } from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  COLOR_GRAY_TEXT,
  COLOR_PRIMARY,
} from "../../../../../../../constants/Colors";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  eraseRoom,
  getRoomChat,
  sendRoomChat,
} from "../../../../../../../redux/actions/auth";
import { ADMIN } from "../../../../../../../constants/UserType";
import { errorToast } from "../../../../../../../services/toastService";
import ChatWindow from "../../../../../../../components/organisms/chatWindow/ChatWindow";

const Divider = styled.hr`
  background-color: ${COLOR_PRIMARY};
  height: 1px;
  margin: 0px;
  width: 90%;
  opacity: 0.3;
`;

const BadgeLog = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  /* display: inline-block; */
  padding: 0px 10px;
`;

function ChatByTrip({ id_trip, id_tripDetail }) {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.authApp);
  const { listUsers } = useSelector((state) => state.chatReducer);
  const { chatRoom } = useSelector((state) => state.chatReducer);
  const { onLineClients } = useSelector((state) => state.chatReducer);
  const [selectUser, setSelectUser] = useState("");
  const [search, setSearch] = useState("");
  const uploadData = async (image) => {
    try {
      let data = new FormData();
      data.append("file", image);
      data.append("upload_preset", "ceqdn88c");
      const res = await fetch(
        "https://api.cloudinary.com/v1_1/dcbqcvers/image/upload",
        {
          method: "POST",
          body: data,
        }
      );
      const file = await res.json();
      return file.secure_url;
    } catch (error) {
      console.log("Error Cloudinary", error);
      errorToast("Por favor intente nuevamente");
    }
  };

  

  useEffect(() => {
    dispatch(getRoomChat({ token, room: id_tripDetail }));
    return () => dispatch(eraseRoom());
  }, [id_tripDetail]);

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md={10}>
          <ChatWindow
            title={"Chat "}
            data={chatRoom}
            roleSent={user.role_id.name}
            /* className={"h-100"}
            size={"100%"} */
            handleSubmitAPI={async ({
              values,
              resetForm,
              setImage,
              image,
              id_receptor,
            }) => {
              try {
                let document = "";
                if (values?.document) {
                  document = await uploadData(image);
                }

                dispatch(
                  sendRoomChat({
                    token,
                    msg: values.comment,
                    document: document,
                    to: id_tripDetail,
                  })
                );

                resetForm();
                setImage({});
              } catch (error) {
                resetForm();
                setImage({});
              }
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default ChatByTrip;
