import React, { useEffect, useState } from "react";

// react-bootstrap components
import { Container, Row, Col, Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import LocationItem from "../../components/molecules/LocationItem";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { getTUnitsAPI } from "../../../../api/modules";
import PaginationList from "../../../../components/organisms/pagination-list/PaginationList";
import { COLOR_PRIMARY } from "../../../../constants/Colors";
import ContentHeader from "../../components/molecules/ContentHeader";
import SearchComponent from "./SearchComponent";

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const ContainerList = styled.div`
  overflow-y: overlay;
  padding-right: 10px;
`;

const BadgeLog = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  display: inline-block;
  padding: 0px 20px;
`;

function Units() {
  const spinner = useSelector((state) => state.appReducer.isLoading);

  const id = useSelector((state) => state.authApp.user._id);
  const history = useHistory();
  const INITIAL_ENTITY = {
    items: [],
    total: 0,
  };
  const [trucksAPI, setTrucksAPI] = useState(INITIAL_ENTITY);
  const [page, setPage] = useState(0);
  const numberList = 6;
  const [query, setQuery] = useState({
    limit: numberList,
    from: page,
  });

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const fetchData = async (limit = numberList, from = 0) => {
    setTrucksAPI(INITIAL_ENTITY);
    const response = await getTUnitsAPI(query);
    const { trucks, total } = response;
    if (trucks) {
      setTrucksAPI({
        items: trucks,
        total: total,
      });
    } else {
      setTrucksAPI(INITIAL_ENTITY);
    }
  };

  const handlePreviewPage = () => {
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * page - numberList,
      };
    });
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * (page + 1),
      };
    });
  };

  return (
    <>
      <Container fluid>
        <ContentHeader title="Unidades del sistema" />
        <SearchComponent
          onHandleSearchSubmit={({ unitType, domain, cuit }) => {
            setPage(0);
            setQuery((prev) => {
              return {
                ...prev,
                limit: numberList,
                from: 0,
                unitType,
                cuit,
                domain,
              };
            });
          }}
        />
        <Row>
          <Col md="12">
            <ContainerList>
              {trucksAPI.items.length > 0 ? (
                trucksAPI.items.map(
                  (
                    {
                      domain,
                      brand,
                      truck_type,
                      max_cargo,
                      model,
                      unit,
                      _id,
                      user_id,
                    },
                    index
                  ) => (
                    <LocationItem
                      noIcon
                      onClick={() =>
                        history.push(
                          `/app/units/unit-detail/${_id}?type=${
                            truck_type === "1" ? "truck" : "semi"
                          }`
                        )
                      }
                      key={index}
                    >
                      <Row>
                        <Col
                          md="3"
                          className="text-center d-flex justify-content-center align-items-center flex-wrap"
                        >
                          <small style={{ width: "100%" }}>
                            <p className="text-primary text-center mb-1">
                              Tipo de vehículo
                            </p>
                          </small>
                          <br />
                          <BadgeLog as="h5">
                            {truck_type === "1" ? "Camión" : "Acoplado"}
                          </BadgeLog>
                        </Col>
                        <Col md="2" className="text-center">
                          <small>
                            <p className="text-primary text-center mb-1">
                              Modelo
                            </p>
                          </small>
                          <p className="h5 font-weight-bold mt-2 text-capitalize">
                            {model || "No posee"}
                          </p>
                        </Col>
                        <Col md="2" className="text-center">
                          <small>
                            <p className="text-primary text-center mb-1">
                              Dominio
                            </p>
                          </small>
                          <p className="h5 font-weight-bold mt-2">{domain}</p>
                        </Col>
                        <Col md="3" className="text-center">
                          <small>
                            <p className="text-primary text-center mb-1">
                              Cantidad de carga maxima
                            </p>
                          </small>
                          <p className="h5 font-weight-bold mt-2 text-capitalize">
                            {max_cargo || "No posee"} {unit}
                          </p>
                        </Col>
                        <Col md="2" className="text-center">
                          <BadgeLog as="h5">Empresa</BadgeLog>
                          <div>
                            <small>
                              <p className="font-weight-bold mb-0 text-capitalize">
                                {user_id?.name + " " + user_id?.last_name ||
                                  "No disponible"}
                              </p>
                              <p className="font-weight-bold mb-0 mt-0">
                                Cuit: {user_id?.cuit || "N/D"}
                              </p>
                              <p className="font-weight-bold mb-0 mt-0">
                                Teléfono: {user_id?.phone || "Teléfono"}
                              </p>
                            </small>
                          </div>
                        </Col>
                      </Row>
                    </LocationItem>
                  )
                )
              ) : (
                <DivEmpty>
                  {!spinner ? (
                    <h2 className="font-weight-bold h3 text-center">
                      No hay unidades con el criterio de busqueda
                    </h2>
                  ) : null}
                </DivEmpty>
              )}
            </ContainerList>
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={trucksAPI.items}
            total={trucksAPI.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
      </RowStyled>
    </>
  );
}

export default Units;
