import { Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import { CardLogBootstrapStyled } from "../../../../styledComponents/CardBootstrapLog";
import * as Yup from "yup";
import LogSelect from "../../../../components/atoms/LogSelect";
import LogButton from "../../../../components/atoms/LogButton";
import LogInput from "../../../../components/atoms/LogInput";

const SearchSchema = Yup.object().shape({
  status: Yup.string().nullable(),
  name: Yup.string(),
  cuit: Yup.string(),
  cuitTom: Yup.string(),
});

const statusItems = [
  { value: 0, label: "Todos" },
  { value: 1, label: "Inactivo" },
  { value: 2, label: "Activo" },
];

const SearchComponent = ({ onHandleSearchSubmit }) => {
  const handleSubmit = (values) => {
    onHandleSearchSubmit(values);
  };
  return (
    <CardLogBootstrapStyled className="shadow-6dp p-4 ml-2 mr-3 mt-3 mb-4">
      <Row className="mb-3">
        <Col>
          <h1 className="h4 text-primary font-weight-bold">Choferes</h1>
        </Col>
      </Row>
      <Formik
        enableReinitialize
        initialValues={{
          status: "",
          name: "",
          cuit: "",
          cuitTom: "",
        }}
        validationSchema={SearchSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <div>
            <div>
              <Row className="mb-3">
                <Col>
                  <label>Estado</label>
                  <LogSelect
                    placeholder="Estado"
                    defaultValue={""}
                    name="status"
                    items={statusItems}
                    onChange={(v) => {
                      setFieldValue("status", v);
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.status}
                  />
                </Col>
                <Col>
                  <label>Nombre</label>
                  <LogInput
                    placeholder="Nombre"
                    name="name"
                    items={[]}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("name", value);
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.name}
                  />
                </Col>
                <Col>
                  <label>Datos del chofer</label>
                  <LogInput
                    placeholder="CUIT (sin guiones)"
                    className="mb-2 number-font"
                    type="text"
                    name="cuit"
                    onChange={(e) => {
                      const { value } = e.target;
                      const re = /^[0-9\b]+$/;
                      if (value === "" || re.test(value)) {
                        setFieldValue("cuit", value);
                      }
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.cuit}
                  />
                </Col>
                <Col>
                  <label>Empresa</label>
                  <LogInput
                    placeholder="CUIT (sin guiones)"
                    className="mb-2 number-font"
                    type="text"
                    name="cuitTom"
                    onChange={(e) => {
                      const { value } = e.target;
                      const re = /^[0-9\b]+$/;
                      if (value === "" || re.test(value)) {
                        setFieldValue("cuitTom", value);
                      }
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.cuitTom}
                  />
                </Col>
              </Row>
            </div>
            <Row>
              <Col className="d-flex justify-content-end">
                <LogButton
                  title={
                    <span
                      className="icon-magnify h5 px-4"
                      style={{ borderRadius: "3px" }}
                    ></span>
                  }
                  onClick={handleSubmit}
                />
              </Col>
            </Row>
          </div>
        )}
      </Formik>
    </CardLogBootstrapStyled>
  );
};

export default SearchComponent;
