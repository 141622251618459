import React, { useEffect, useRef, useState } from "react";
import ChatInbox from "./ChatInbox";
import Chat from "./Chat";
import { Formik } from "formik";
import * as Yup from "yup";
import { Col, Row, Card, Container, Form, Stack } from "react-bootstrap";
import styled from "styled-components";
import { toNumber } from "lodash";
import { GoFilePdf } from "react-icons/go";
import { errorToast } from "../../../services/toastService";
import useAutosizeTextArea from "../auto-Resize/AutoResize";
import LogButton from "../../atoms/LogButton";
import { FiPaperclip } from "react-icons/fi";

const MAX_FILE_SIZE = 102400 * 100; //100KB * ..
const validFileType = [
  "application/pdf",
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/tiff",
];
const ClaimSchema = Yup.object().shape(
  {
    comment: Yup.string()
      .min(1, "Mínimo 1 caracter")
      .max(300, "Máximo 300 caracteres")
      .when("document", {
        is: (document) => !(document?.length > 5),
        then: Yup.string().required("Ingrese comentario o adjunte un archivo"),
      }),
    document: Yup.string().when("comment", {
      is: (comment) => !(comment?.length >= 1 && comment?.length < 301),
      then: Yup.string().required("Adjunto es obligatorio"),
    }),
  },
  ["document", "comment"],
  ["comment", "document"]
);
const CancelButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  font-size: 1.4rem;
`;

const StyledCardBody = styled(Card.Body)`
  overflow-y: auto;
  position: relative;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #d62929;
  }
`;

const AutosizeArea = styled.textarea`
  background: #ffffff;
  border-radius: 0;
  border: none;
  padding: 0;
  border-bottom: 1px solid #ededed;
  font-size: 14px;
  line-height: 1.5;
  padding: 8px 0;
  resize: none;
  transition: border-color 150ms ease;
  width: 100%;
  overflow-y: hidden;
  :hover {
    border-color: #c6c6c6;
  }
  :focus {
    outline: none;
    border-color: #c6c6c6;
  }
`;
const StyledButton = styled.button`
  max-height: 2.3rem;
`;
const StyledLogButton = styled(LogButton)`
  max-height: 2.3rem;
`;

function ChatWindow({
  title,
  inbox,
  handleSubmitAPI,
  data,
  roleSent,
  active,
  id_receptor,
  subTitle,
  size,
  className,
}) {
  const [comment, setComment] = useState("");
  const [image, setImage] = useState({});
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const textAreaRef = useRef(null);
  useAutosizeTextArea(textAreaRef.current, comment, textAreaRef);
  const handleUpload = () => {
    inputRef.current?.click();
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollTo({
      top: messagesEndRef.current?.scrollHeight,
      behavior: "smooth",
    });
  };

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      setTimeout(scrollToBottom, 1200);
      return;
    }
    setTimeout(scrollToBottom, 1000);
  }, [data]);

  return (
    <Card className={className}>
      <Card.Header
        className="d-flex-col justify-content-between align-items-center px-3 pt-3 pb-0"
        style={{ borderTop: "4px solid #ffa900" }}
      >
        <Row className="d-flex justify-content-between align-items-center m-0 pb-1">
          <h5 className="align-text-top mb-0">{title || "Reclamante"}</h5>
          <span className="badge bg-warning me-3">
            {toNumber(data?.length || 0)}
          </span>
        </Row>
        {subTitle ? subTitle : null}
      </Card.Header>
      <Formik
        enableReinitialize
        initialValues={{
          comment: "",
          document: "",
        }}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={ClaimSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          handleSubmitAPI({ values, resetForm, setImage, image, id_receptor });
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
          isSubmitting,
          setFieldValue,
        }) => (
          <>
            {" "}
            <StyledCardBody
              style={{ height: size || "25rem" }}
              ref={messagesEndRef}
            >
              {!(image?.name?.length > 0) ? (
                data?.length > 0 ? (
                  data.map((ele, index) =>
                    ele.creator_role?.name === roleSent ? (
                      <Chat
                        key={index}
                        name={
                          (ele.id_creator?.name || ele.id_driver?.name) +
                          " " +
                          (ele.id_creator?.last_name ||
                            ele.id_driver?.last_name)
                        }
                        date={ele.createdAt}
                        document={ele.document}
                        comment={ele.comment || ele.msg}
                        avatar={ele.id_creator?.image || ele.id_driver?.image}
                      />
                    ) : (
                      <ChatInbox
                        key={index}
                        name={
                          (ele.id_driver?.name || ele.id_creator?.name) +
                          " " +
                          (ele.id_driver?.last_name || ele.id_creator?.last_name)
                        }
                        date={ele.createdAt}
                        document={ele.document}
                        comment={ele.comment || ele.msg}
                        avatar={ele.id_creator?.image || ele.id_driver?.image}
                      />
                    )
                  )
                ) : null
              ) : (
                <Container
                  fluid
                  className="d-flex h-100 w-100 bg-light justify-content-center align-items-center position-relative"
                >
                  <Row className="m-2 h-100 mw-100 overflow-hidden text-center align-content-center">
                    <>
                      <CancelButton
                        onClick={() => {
                          setImage({});
                          setFieldValue("document", "");
                        }}
                      >
                        x
                      </CancelButton>
                      {image?.type !== "application/pdf" ? (
                        <img
                          className="mh-100 w-100"
                          src={URL.createObjectURL(image)}
                          alt="fallo carga"
                        />
                      ) : (
                        <Stack gap={3}>
                          <div>
                            <GoFilePdf size={100} />
                          </div>
                          <div>
                            {" "}
                            <small>{image?.name}</small>
                          </div>
                        </Stack>
                      )}
                    </>
                  </Row>
                </Container>
              )}
            </StyledCardBody>
            <Card.Footer className="text-muted d-flex justify-content-start align-items-center px-2 py-3">
              {active ? (
                active?.status ? (
                  <Container fluid>
                    <Row>
                      <Form.Control
                        ref={inputRef}
                        name="document"
                        type="file"
                        accept={validFileType.join(",")}
                        className="d-none"
                        value={values.document}
                        onChange={(file) => {
                          if (file.target.files[0]) {
                            if (file.target.files[0].size <= MAX_FILE_SIZE) {
                             
                              if (
                                validFileType.includes(
                                  file.target.files[0].type
                                )
                              ) {
                                setImage(file.target.files[0]);
                                handleChange(file);
                                return;
                              } else {
                                errorToast("Formato no aceptado");
                              }
                            } else {
                              errorToast("Archivo mayor a 10MB");
                            }
                          }
                          setImage({});
                          setFieldValue("document", "");
                        }}
                        onBlur={handleBlur}
                      />

                      <Col
                        xs={"auto"}
                        className="p-0 d-flex justify-content-center"
                      >
                        <StyledButton
                          className="bg-light"
                          onClick={handleUpload}
                        >
                          <FiPaperclip />
                        </StyledButton>
                      </Col>
                      <Col className="p-0">
                        <AutosizeArea
                          id="review-text"
                          name="comment"
                          onChange={(event1, event2) => {
                            setComment(event1.target.value);
                            handleChange(event1);
                          }}
                          placeholder="Escribir mensaje"
                          ref={textAreaRef}
                          rows={1}
                          value={values.comment}
                        />
                      </Col>

                      <Col
                        xs={"auto"}
                        /*  md={3} lg={2} */ className="p-0 d-flex justify-content-center"
                      >
                        {" "}
                        <StyledLogButton
                          color="primary"
                          onClick={handleSubmit}
                          title={"Enviar"}
                          className="m-0 mh-10"
                          /* border="curve" */
                        ></StyledLogButton>
                      </Col>
                    </Row>

                    {errors["comment"] && (
                      <div className="mb-2 mt-0">
                        <small className="text-danger">
                          {errors["comment"]}.
                        </small>
                      </div>
                    )}
                  </Container>
                ) : null
              ) : (
                <Container fluid>
                  <Row>
                    <Form.Control
                      ref={inputRef}
                      name="document"
                      type="file"
                      accept={validFileType.join(",")}
                      className="d-none"
                      value={values.document}
                      onChange={(file) => {
                        if (file.target.files[0]) {
                          if (file.target.files[0].size <= MAX_FILE_SIZE) {
                            
                            if (
                              validFileType.includes(file.target.files[0].type)
                            ) {
                              setImage(file.target.files[0]);
                              handleChange(file);
                              return;
                            } else {
                              errorToast("Formato no aceptado");
                            }
                          } else {
                            errorToast("Archivo mayor a 10MB");
                          }
                        }
                        setImage({});
                        setFieldValue("document", "");
                      }}
                      onBlur={handleBlur}
                    />

                    <Col
                      xs={"auto"}
                      className="p-0 d-flex justify-content-center"
                    >
                      <StyledButton className="bg-light" onClick={handleUpload}>
                        <FiPaperclip />
                      </StyledButton>
                    </Col>
                    <Col className="p-0">
                      <AutosizeArea
                        id="review-text"
                        name="comment"
                        onChange={(event1, event2) => {
                          setComment(event1.target.value);

                          handleChange(event1);
                        }}
                        placeholder="Escribir mensaje"
                        ref={textAreaRef}
                        rows={1}
                        value={values.comment}
                      />
                    </Col>

                    <Col
                      xs={"auto"}
                      /*  md={3} lg={2} */ className="p-0 d-flex justify-content-center"
                    >
                      {" "}
                      <StyledLogButton
                        color="primary"
                        onClick={handleSubmit}
                        title={"Enviar"}
                        className="m-0 mh-10"
                        /* border="curve" */
                      ></StyledLogButton>
                    </Col>
                  </Row>

                  {errors["comment"] && (
                    <div className="mb-2 mt-0">
                      <small className="text-danger">
                        {errors["comment"]}.
                      </small>
                    </div>
                  )}
                </Container>
              )}
            </Card.Footer>
          </>
        )}
      </Formik>
    </Card>
  );
}

export default ChatWindow;
