import React from "react";
import styled from "styled-components";
import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import moment from "moment";

const CardStyled = styled(Card)`
  border-radius: 15px;
  /* cursor: pointer; */
  padding: 10px 20px;
  &:hover {
    opacity: 0.8;
  }
`;

const BodyContent = styled.div`
  overflow: hidden;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

const StyledTooltip = styled(Tooltip)`
  &.custom-tooltip {
    .tooltip-inner {
      max-width: 90vw;
      width: 100%;
      /*    max-width: 900px; */
    }
  }
`;

const CardPushNotification = ({
  title,
  body,
  data,
  createsBy,
  createdAt,
  failed_drivers,
  failed_users,
  successful_drivers,
  successful_users,
  className,
  onClick,
  ...rest
}) => {
  return (
    <CardStyled className={`${className} shadow-4dp mb-2`} {...rest}>
      <Card.Body>
        <Row className="flex align-items-start">
          <Col md="2" className=" p-0">
            <p className="text-center font-weight-bold mb-0 pt-0 mt-0">
              Título
            </p>
            <p className="text-center  mb-0 pt-0 mt-0">{title}</p>
          </Col>
          <Col md="4" className="text-center">
            <p className="text-center font-weight-bold mb-0 pt-0 mt-0">
              Cuerpo
            </p>

            <OverlayTrigger
              placement="top"
              overlay={
                <StyledTooltip
                  id={`tooltip-${title}`}
                  className="custom-tooltip"
                >
                  {body}
                </StyledTooltip>
              }
            >
              <BodyContent>{body}</BodyContent>
            </OverlayTrigger>
          </Col>
          <Col md="2" className="text-center text-sm-left">
            <p className="text-center mb-0 pt-0 mt-0 font-weight-bold">
              Fecha:
            </p>
            <p className="text-center mb-0 pt-0 mt-0">
              {moment(createdAt).format("DD/MM/YYYY HH:mm")}
            </p>
          </Col>
          <Col md="2" className="text-center text-sm-left">
            <p className="text-center mb-0 pt-0 mt-0 font-weight-bold">
              Creador
            </p>
            <p className="text-center mb-0 pt-0 mt-0">{createsBy?.email}</p>
          </Col>
          <Col md="2" className="text-center mt-3 my-sm-auto">
            <button
              style={{
                color: "purple",
                fontWeight: "800",
                background: "#f2f2f500",
              }}
              onClick={() =>
                onClick({
                  failed_drivers,
                  failed_users,
                  successful_drivers,
                  successful_users,
                })
              }
            >
              Usuarios
            </button>
          </Col>
        </Row>
      </Card.Body>
    </CardStyled>
  );
};

export default CardPushNotification;
