/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import PendingsTravels from "./PendingsTravels";
import PendingsDetails from "./PendingDetail";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";

const ContainerList = styled.div`
  overflow-y: overlay;
`;

const PendingTripsDc = ({ id, searchQuery, state }) => {
  const location = useLocation();
  const [tripId, setTripId] = useState(null);

  useEffect(() => {
    const { tripId } = queryString.parse(location.search);
    setTripId(tripId);
  }, [location]);

  const getScreen = tripId ? (
    <PendingsDetails id={id} searchQuery={searchQuery} tripId={tripId} />
  ) : (
    <PendingsTravels state={state} id={id} searchQuery={searchQuery} />
  );

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <ContainerList>{getScreen}</ContainerList>
          </Col>
        </Row>
      </Container>
    </>
  );
};

PendingTripsDc.propTypes = {};

export default PendingTripsDc;
