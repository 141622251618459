import React from "react";
import { Col, Image, Row, Stack } from "react-bootstrap";
import { GoFilePdf } from "react-icons/go";
import DefaultAvatar from "../../../assets/img/default-avatar.png";
import moment from "moment";

function Chat({ name, date, document, comment, avatar }) {
  return (
    <Col
      xs={{ span: 11, offset: 1 }}
      md={{ span: 9, offset: 3 }}
      className="p-2 mb-2 rounded shadow-sm bg-light"
    >
      <Row className="m-0 d-flex justify-content-between ">
        <p className="small mb-1 text-capitalize">{name}</p>
        <p className="small mb-1 mt-0 text-capitalize">
          {moment(date).format("DD/MM/YY HH:mm")}
        </p>
      </Row>

      <Row className="d-flex flex-row justify-content-end m-0">
        <Col xs={12} md={10}>
          {document ? (
            document.endsWith(".pdf") ? (
              <Stack gap={3}>
                <div>
                  <GoFilePdf size={100} />
                </div>
                {/* <div>
                {" "}
                <small>{image.name}</small>
              </div> */}
              </Stack>
            ) : (
              <a href={document} target="_blank">
                {" "}
                <img
                  className="mh-100 w-100 mb-1"
                  src={document}
                  alt="fallo carga"
                />
              </a>
            )
          ) : null}
          {comment ? (
            <p
              className="small p-2 ms-3 mb-3 rounded"
              style={{ backgroundColor: "#e5e9ee" }}
            >
              {comment}
            </p>
          ) : null}
        </Col>
        <Col xs={0} md={2} className="p-0 ">
          <Image
            roundedCircle
            src={avatar || DefaultAvatar}
            alt="avatar 1"
            className="w-100 mh-100 d-none d-md-block"
            //style={{ width: "45px", maxHeight: "100%" }}
          />
        </Col>
      </Row>
    </Col>
  );
}

export default Chat;
