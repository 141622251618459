import React from "react";
import { Col, Row, Card, Image } from "react-bootstrap";
import styled from "styled-components";
import ReactStars from "react-rating-stars-component";
import Camion from "../../../../../../../assets/img/Camion.png";
import { BsStarFill } from "react-icons/bs";


const CardLogBootstrapRightStyled = styled(Card)`
  padding: 40px 10px;
  border-radius: 15px;
  height: 100%;
`;

const AvatarLog = styled(Image)`
  width: 100%;
  border-radius: 5px;
  margin: 5px;
`;
const RightData = styled(Row)`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
`;
const ReactStarsStyled = styled(ReactStars)`
  .rating-contact-log {
    width: "500px";
  }
`;

const Driver = ({ data }) => {
  return (
    <div>
      <Row>
        <Col md="4">
          <AvatarLog src="https://randomuser.me/api/portraits/men/43.jpg" />
        </Col>
        <Col md="1"></Col>
        <Col md="7">
          <RightData className="mb-1">
            <h2 className="yellow--text font-weight-bold text-capitalize">
              {data?.name + " " + data?.last_name}
            </h2>
            <h4 className="text-success  /* mb-1 mt-1 */">Transportista</h4>
            <h5 className="mt-4 mb-0">Puntuación</h5>
            <ReactStarsStyled
              className="rating-contact-log"
              value={4}
              count={5}
              size={32}
              activeColor="#ffd700"
              color="#CDE0EB"
              char={<BsStarFill />}
            />
          </RightData>
          <RightData className="mt-5">
            <p className=" font-weight-bold mb-3 ">Informacion de contacto</p>

            <Col></Col>

            <Row>
              <Col md="5">
                <p className="mb-0">Teléfono</p>
              </Col>
              <Col md="7">
                <p>{data?.phone}</p>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <p className="mb-0">E-mail</p>
              </Col>
              <Col md="7">
                <p>{data?.email}</p>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <p className="mb-0">CUIT</p>
              </Col>
              <Col md="7">
                <p>{data?.cuit}</p>
              </Col>
            </Row>
            <Row>
              <Col md="5">
                <p className="mb-0">Razón Social</p>
              </Col>
              <Col md="7">
                <p>{data?.reason}</p>
              </Col>
            </Row>
          </RightData>
        </Col>
      </Row>
    </div>
  );
};

Driver.propTypes = {};

export default Driver;
