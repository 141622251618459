import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { List, Input } from "rsuite";
import styled from "styled-components";

const CustomScrollContainer = styled.div`
  height: 200px;
  padding-left: 2px;
  padding-right: 2px;

  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #808080;
    border-radius: 5px;
  }
`;

const PushTable = ({ drivers, users }) => {
  const [driverSearch, setDriverSearch] = useState("");
  const [userSearch, setUserSearch] = useState("");
  return (
    <Container fluid>
      <Row>
        <Col md={6}>
          {drivers?.length > 0 ? (
            <>
              <Row className="ml-0  mt-2">
                <h6 className="h6 text-primary font-weight-bold ">Choferes</h6>
              </Row>
              <Input
                placeholder="Buscar chofer"
                onChange={(value) => setDriverSearch(value)}
                style={{ marginBottom: "10px" }}
              />
              <CustomScrollContainer>
                <List data={drivers}>
                  {drivers
                    ?.filter((ele) => ele?.email?.includes(driverSearch))
                    .map((item, index) => (
                      <List.Item key={index} className="mx-1" index={index}>
                        {item.email}
                      </List.Item>
                    ))}
                </List>
              </CustomScrollContainer>
            </>
          ) : (
            <Row className="ml-0  mt-2">
              <h6 className="h6 text-primary font-weight-bold ">
                Sin Choferes
              </h6>
            </Row>
          )}
        </Col>
        <Col md={6}>
          {users?.length > 0 ? (
            <>
              <Row className="ml-0  mt-2">
                <h6 className="h6 text-primary font-weight-bold ">Tomadores</h6>
              </Row>
              <Input
                placeholder="Buscar Usuario"
                onChange={(value) => setUserSearch(value)}
                style={{ marginBottom: "10px" }}
              />
              <CustomScrollContainer>
                <List data={users}>
                  {users
                    ?.filter((ele) => ele?.email?.includes(userSearch))
                    .map((item, index) => (
                      <List.Item key={index} index={index}>
                        {item.email}
                      </List.Item>
                    ))}
                </List>
              </CustomScrollContainer>
            </>
          ) : (
            <Row className="ml-0  mt-2">
              <h6 className="h6 text-primary font-weight-bold ">
                Sin Tomadores{" "}
              </h6>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default PushTable;
