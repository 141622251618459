import React from "react";
import { Form } from "react-bootstrap";

const LogRadioGroup = ({ value, className, name, label, id, ...rest }) => {
  return (
    <>
      <div className="custom-control custom-radio custom-control-inline">
        <Form.Check
          id={`${name}${Math.random(9)}`}
          type={`radio`}
          name={name}
          className={className + ' form-check-input'}
          value={value}
          {...rest}
        />
        <label htmlFor={id}>{label}</label>
      </div>
    </>
  );
};

export default LogRadioGroup;
