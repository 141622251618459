import axios from "axios";
import { apiHttp } from "../axiosApi.js";
import { unit, unitByUser, uploadTruckPhoto } from "../config/apiRoute.js";
const API_URL_BACKEND = process.env.REACT_APP_BACKEND_API;

export const getTUnitsAPI = ({
  limit = 5,
  from = 0,
  unitType,
  domain,
  cuit,
}) => {
  return apiHttp(
    "GET",
    `${unit}?limit=${limit}&from=${from}${
      unitType ? `&unitType=${unitType}` : ""
    }${domain ? `&domain=${domain}` : ""}${cuit ? `&cuit=${cuit}` : ""}`
  );
};
export const getTUnitsByUserAPI = (limit = 5, from = 0) => {
  return apiHttp("GET", `${unitByUser}?truck_type=1`);
};

export const getTrailersByUserAPI = (limit = 5, from = 0) => {
  return apiHttp("GET", `${unitByUser}?truck_type=2`);
};

export const getAllUnitsByUserAPI = (limit = 5, from = 0, truck_type = 0) => {
  return apiHttp(
    "GET",
    `${unitByUser}?limit=${limit}&from=${from}&truck_type=${truck_type}`
  );
};

export const getUnitByIDAPI = (id) => apiHttp("GET", `${unit}/${id}`);

export const postTUnitAPI = (body) => apiHttp("POST", `${unit}`, body);

export const putUnitByIdAPI = (id, body) =>
  apiHttp("PUT", `${unit}/${id}`, body);

export const delTUnitByIdAPI = (id) => apiHttp("DELETE", `${unit}/${id}`);

export const getUploadTruckPhoto = (id) =>
  axios.get(`${API_URL_BACKEND}${uploadTruckPhoto}/${id}`, {
    responseType: "arraybuffer",
  });

export const putUploadTruckPhoto = (id, formData) => {
  return axios.put(`${API_URL_BACKEND}${uploadTruckPhoto}/${id}`, formData, {
    responseType: "arraybuffer",
  });
};
