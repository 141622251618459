import React from "react";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  padding: 10px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function CloseButton({ handleClose }) {
  return (
    <CloseButtonContainer onClick={handleClose}>
      <AiOutlineClose size={22} />
    </CloseButtonContainer>
  );
}

export default CloseButton;
