import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import LogInput from "../../atoms/LogInput";
import { Formik } from "formik";
import LogButton from "../../atoms/LogButton";

const ModalValidatePhone = ({ isShow, handleClose, handleAction }) => {
  return (
    <Formik
      initialValues={{
        code: "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        handleAction(values);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Modal show={isShow} onHide={handleClose} className="no-fade">
          <Modal.Header closeButton>
            <Modal.Title className="text-center">
              Confirmar código para validar número de teléfono
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md="12" className="mb-2">
                <p>
                  Se ha enviado un código de verificación a tu número de
                  teléfono para ser verificado por BuenaRuta{" "}
                </p>
              </Col>
              <Col md="12" className="mb-3">
                <LogInput
                  placeholder="Código de teléfono"
                  className="mb-2"
                  type="text"
                  name="code"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors}
                  value={values.code}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <LogButton title="Cerrar" onClick={handleClose} color="danger" />
            <LogButton title="Enviar" onClick={handleSubmit} color="primary" />
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
};

export default ModalValidatePhone;
