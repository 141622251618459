import { combineReducers } from "redux";
import { authApp } from "./auth";
import { appReducer } from "./app";
import { chatReducer } from "./auth/chatReducer"

export default combineReducers({
  authApp,
  appReducer,
  chatReducer
});
