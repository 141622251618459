import { Col, Container, Row } from "react-bootstrap";
import { GoTriangleRight } from "react-icons/go";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import CardItemTravelBasic from "../../../components/molecules/CardItemTravelBasic";
import RangeDatePicker from "../../../../../components/atoms/RangeDatePicker";
import { useEffect, useState } from "react";
import { AiFillStar, AiOutlineSearch, AiOutlineStar } from "react-icons/ai";
import moment from "moment";
import { useSelector } from "react-redux";
import PaginationList from "../../../../../components/organisms/pagination-list/PaginationList";
import { getTripsCreatedByStateAPI } from "../../../../../api/modules";
import { errorToast } from "../../../../../services/toastService";

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;

const ContainerList = styled.div`
  height: auto;
  overflow-y: overlay;
  padding-right: 10px;
`;
const Search = styled.div`
  /* background-color: white; */
  border: none;
  width: 10%;
  padding: 2px 2px;
  border-radius: 6px;
  /* box-shadow: 0px 0px 5px #cccccc; */
  margin-left: 3px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #92c31a;
  :hover {
    cursor: pointer;
  }
`;

const FinishedTravels = () => {
  const history = useHistory();
  const [toDate, setToDate] = useState([]);
  const [fetchDate, setFetchDate] = useState(true);
  const spinner = useSelector((state) => state.appReducer.isLoading);
  const [page, setPage] = useState(0);
  const numberList = 5;
  const INITIAL_STATE = {
    total: 0,
    items: [],
  };
  const [tripsAPI, setTripsAPI] = useState(INITIAL_STATE);

  const fetchData = async ({ limit = numberList, from = 0 }) => {
    let since = "";
    let to = "";
    if (toDate.length > 0) {
      since = moment(toDate[0]).format("YYYY-MM-DD");
      to = moment(toDate[1]).format("YYYY-MM-DD");
    }
    const tripsResponse = await getTripsCreatedByStateAPI(
      limit,
      from,
      "[5]",
      since,
      to
    );
    const { total, trips, msg } = tripsResponse;
    if (trips)
      setTripsAPI({
        ...INITIAL_STATE,
        total,
        items: trips,
      });
    if (msg) {
      errorToast(msg);
      setTripsAPI({
        ...INITIAL_STATE,
      });
    }
  };

  useEffect(() => {
    setPage(0);
    fetchData({});
  }, [fetchDate]);

  const handlePreviewPage = () => {
    fetchData({ numberList, from: numberList * page - numberList });
    setPage((prev) => prev - 1);
  };

  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    fetchData({ numberList, from: numberList * (page + 1) });
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <ContainerList>
              <Row className="align-content-center m-0">
                <Col
                  md="6"
                  className="d-flex flex-wrap align-items-center justify-content-start pt-2 pb-1 pl-2"
                >
                  <RangeDatePicker
                    name="desdeHasta"
                    placeholder="Desde - Hasta"
                    className={"number-font"}
                    ranges={[
                      {
                        label: "Limpiar",
                        value: [1, 1],
                      },
                    ]}
                    onChange={(value) => {
                      if (value[0] === 1 && value[1] === 1) {
                        setToDate([]);
                      } else {
                        setToDate(value);
                      }
                    }}
                    onClean={() => console.log("Presione clean")}
                    value={toDate}
                  />
                  <Search onClick={() => setFetchDate((value) => !value)}>
                    <AiOutlineSearch size={25} />
                  </Search>
                </Col>
                <Col
                  md="6"
                  className="d-flex flex-wrap align-items-center justify-content-end"
                ></Col>
              </Row>
              {tripsAPI?.items.length ? (
                tripsAPI?.items.map((location, index) => (
                  <CardItemTravelBasic
                    state="Finalizado"
                    btnIcon={
                      location.pendingRating ? (
                        <AiOutlineStar
                          size={26}
                          className="text-primary mb-3"
                        />
                      ) : (
                        <AiFillStar size={26} className="text-primary mb-3" />
                      )
                    }
                    key={index}
                    originName={location.origin_site.site_name}
                    destinyName={location.destiny_site.site_name}
                    withdrawDate={location.withdraw_date}
                    deliveryDate={location.delivery_date}
                    onClick={() => {
                      history.push(
                        `list-travels?state=3&tripId=${location._id || "0"}`
                      );
                    }}
                    trucks_number_accepted={location.counterTripdetails}
                    trucksNumber={location.trucks_number}
                    showId={location.id_trip}
                  ></CardItemTravelBasic>
                ))
              ) : (
                <DivEmpty>
                  {!spinner ? (
                    <h2 className="font-weight-bold h3 text-center">
                      No hay viajes disponibles para la fecha indicada
                    </h2>
                  ) : null}
                </DivEmpty>
              )}
            </ContainerList>
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={tripsAPI.items}
            total={tripsAPI.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
      </RowStyled>
    </>
  );
};

export default FinishedTravels;
