const COLOR_PRIMARY = "#97C233";
const COLOR_PRIMARY_DARK = "#88B125";
const COLOR_SECONDARY = "#217DBA";
const COLOR_TEXT_WHITE = "#fafafa";
const COLOR_BLACK_TEXT = "#212121";
const COLOR_GRAY_TEXT = "#797878";
const COLOR_RED = "#F73939";

export {
  COLOR_PRIMARY,
  COLOR_GRAY_TEXT,
  COLOR_SECONDARY,
  COLOR_TEXT_WHITE,
  COLOR_BLACK_TEXT,
  COLOR_PRIMARY_DARK,
  COLOR_RED,
};
