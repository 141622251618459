export const chartColors = (array) => {
 
  const colors = {
    Sorgo: "rgba(255, 99, 132, 0.5)",
    Maiz: "rgba(54, 162, 235, 0.5)",
    Trigo: "rgba(255, 206, 86, 0.5)",
    Arroz: "rgba(75, 192, 192, 0.5)",
    Girasol: "rgba(153, 102, 255, 0.5)",
    Soja: "rgba(255, 159, 64, 0.5)",
  };
  return array?.map((ele) => colors[ele]);
};
