import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { COLOR_GRAY_TEXT } from "../../constants/Colors";

const LogFormControl = styled(Form.Control)`
  background-color: white;
  color: ${COLOR_GRAY_TEXT};
  border: none;
  padding: 10px 5px;
  border-radius: 2px;
  width: 100%;
  box-shadow: 0px 0px 5px #CCCCCC;
  margin-bottom: ${(props) => props.mb0 === "true" && 0}px !important;
  ::placeholder {
    padding-left: 14px !important;
    font-size: 14px;
    color: ${COLOR_GRAY_TEXT};
  }
  ::-moz-placeholder {
    padding-left: 14px !important;
    color: red;
    font-size: 14px;
    color: ${COLOR_GRAY_TEXT};
    text-indent: 10px;
  }
`;

const LogTextArea = ({
  placeholder,
  className,
  errors,
  messageError,
  name,
  ...rest
}) => {
  let mb0 = errors[name] ? "true" : "false";
  return (
    <>
      <LogFormControl
        {...{ ...rest, name, mb0 }}
        className={`${className}  ${errors[name] && "is-invalid"}`}
        placeholder={placeholder}
        as="textarea"
        rows={3}
      />

      {errors[name] && (
        <div className="mb-2 mt-0">
          <small className="text-danger">{errors[name]}.</small>
        </div>
      )}
    </>
  );
};


export default LogTextArea;
