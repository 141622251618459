/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
// react-bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import TabMaterialLog from "../../../../components/organisms/tabs-material/TabMaterialLog";
import { Tab, Tabs } from "@material-ui/core";
import queryString from "query-string";
import SearchBar from "./Components/SearchBar";
import PostulatedTravels from "./Tomador/Postulates";
import InActions from "./Dador/InActions/Index";
import Finished from "./Dador/Finished/Finished";
import FinishedTC from "./Tomador/Finished/FinishedTC";
import InActionsTc from "./Tomador/InActions/Index";
import PendingTripsTc from "./Tomador/Pendings/PendingTripsTC";
import PendingTripsDc from "./Dador/Pendings/PendingTripsDc";
import OffersScreen from "./Dador/MyOffers";

const DivParentTabs = styled.div`
  border-bottom: 1px solid #d1d0d0;
`;
const TabsStyled = styled(Tabs)`
  width: 90%;
  margin: 0px 30px;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0px;
    margin-right: 10px;
  }
`;

const TabStyled = styled(Tab)`
  span {
    font-size: 18px;
    font-weight: bolder !important;
  }
`;

function TravelsAll({ role }) {
  /* const id = useSelector((state) => state.authApp.user._id); */
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [searchQuery, setSearchQuery] = useState({});
  const [value, setValue] = React.useState(0);
  const params = useParams();
  const { id } = params;

  useEffect(() => {}, []);

  useEffect(() => {
    const { state } = queryString.parse(window.location.search);
    setValue(parseInt(state || 0));
  }, []);

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (!id) {
      history.push(`/app/travels-all?state=${value}`);
    } else {
      history.push(`/app/users/user-profile/${id}`);
    }
  }, [/* value, */ searchQuery]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    if (!id) {
      history.push(`/app/travels-all?state=${newValue}`);
    } else {
      history.push(`/app/users/user-profile/${id}`);
    }
    setValue(newValue);
  };
  return (
    <>
      {!id ? <SearchBar query={setSearchQuery} /> : null}
      {role === "TOMADOR_DE_CARGA" ? (
        <>
          <DivParentTabs>
            <TabsStyled
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="simple tabs example"
              scrollButtons="off"
              variant="scrollable"
            >
              <TabStyled label="Postulados" {...a11yProps(0)} />
              <TabStyled label="Pendientes" {...a11yProps(1)} />
              <TabStyled label="En ejecucion" {...a11yProps(2)} />
              <TabStyled label="Finalizados" {...a11yProps(3)} />
            </TabsStyled>
          </DivParentTabs>
          <Container fluid>
            <Row>
              <Col md="12">
                <TabMaterialLog value={value} index={0}>
                  <PostulatedTravels state={1} id={id} />
                </TabMaterialLog>
                <TabMaterialLog value={value} index={1}>
                  <PendingTripsTc state={2} id={id} searchQuery={searchQuery} />
                </TabMaterialLog>
                <TabMaterialLog value={value} index={2}>
                  <InActionsTc id={id} searchQuery={searchQuery} />
                </TabMaterialLog>
                <TabMaterialLog value={value} index={3}>
                  <FinishedTC id={id} searchQuery={searchQuery} />
                </TabMaterialLog>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <>
          <DivParentTabs>
            <TabsStyled
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              aria-label="simple tabs example"
              scrollButtons="off"
              variant="scrollable"
            >
              <TabStyled label="Ofertas" {...a11yProps(0)} />
              <TabStyled label="Pendientes" {...a11yProps(1)} />
              <TabStyled label="En ejecución" {...a11yProps(2)} />
              <TabStyled label="Finalizados" {...a11yProps(2)} />
            </TabsStyled>
          </DivParentTabs>
          <Container fluid>
            <Row border={3}>
              <Col md="12" border={3}>
                <TabMaterialLog value={value} index={0}>
                  <OffersScreen state={1} id={id} searchQuery={searchQuery} />
                </TabMaterialLog>
                <TabMaterialLog value={value} index={1}>
                  <PendingTripsDc state={2} id={id} searchQuery={searchQuery} />
                </TabMaterialLog>
                <TabMaterialLog value={value} index={2}>
                  <InActions id={id} searchQuery={searchQuery} />
                </TabMaterialLog>
                <TabMaterialLog value={value} index={3}>
                  <Finished id={id} searchQuery={searchQuery} />
                </TabMaterialLog>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </>
  );
}

export default TravelsAll;
