import React from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import { COLOR_GRAY_TEXT } from "../../constants/Colors";

const TextNumberStyled = styled.small`
  font-family: "sans-serif, arial" !important;
  padding: 0;
  margin: 0;
  color: ${(props) => props.color && props.color};
  font-weight: ${(props) => props.fontWeight && props.fontWeight};
  font-size: ${(props) => props.fontSize && props.fontSize};

`

const LogTextNumber = ({
  text,
  style,
}) => {
  return (
    <>
      <TextNumberStyled style={style}>{text}</TextNumberStyled>
    </>
  );
};


export default LogTextNumber;
