import { Image } from "react-bootstrap";
import styled from "styled-components";

const AvatarStyled = styled(Image)`
  width: ${props => props.width || '135px'};
  height:  ${props => props.height || '135px'};
  position: relative;
  top: -25px;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    opacity: 0.7;
  }
`;


export default AvatarStyled;