import React from "react";
import styled from "styled-components";
import { Card, Col, Row } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import SliderLog from "../../../../components/atoms/SliderLog";
import moment from "moment";
import { priceCalculation } from "../../../../utils/helpers";

const CardStyled = styled(Card)`
  border-radius: 15px;
  cursor: pointer;
  padding: 10px 20px;
  &:hover {
    opacity: 0.8;
  }
`;

const DivSegments = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
`;

const CardItemTravelPending = ({
  item,
  openCloseModal,
  openCloseModalUnits,
  deleteTripdetail,
  isDc,
  className,
  originName,
  destinyName,
  withdrawDate,
  deliveryDate,
  tripPrice,
  chargeType,
  distance,
}) => {
  return (
    <CardStyled className={`${className} shadow-4dp m-2 text-capitalize`}>
      <Card.Body>
        <Row className="flex align-items-center">
          <Col md="2" className="text-center text-sm-left">
            <h5 className="mb-2 mb-sm-0">
              <strong>
                {"ID: " + (item?.parent_id || "n/d") + (item.order || "")}
              </strong>
              {/* <SliderLog /> */}
            </h5>
          </Col>

          <Col md="2">
            <Row className="text-center">
              <Col>
                <div className="mb-0 pt-0 mt-0 text-center text-sm-left">
                  <strong>Chofer:</strong>
                  <p className="mb-0 pt-0 mt-0">
                    {item?.is_tomador
                      ? item?.id_tomador?.name +
                        " " +
                        item?.id_tomador?.last_name
                      : item?.id_driver
                      ? item?.id_driver?.name + " " + item?.id_driver?.last_name
                      : "Sin Asignar"}
                  </p>
                </div>
                <div className="mb-1 pt-0 mt-1 text-center text-sm-left">
                  <strong>Camion:</strong>
                  <p className="mb-0 pt-0 mt-0">
                    {item?.id_truck?.domain || "Sin Asignar"}
                  </p>
                </div>
                <div className="mb-0 pt-0 mt-0 text-center text-sm-left">
                  <strong>Trailer:</strong>
                  <p className="mb-0 pt-0 mt-0">
                    {item?.id_trailer?.domain || "Sin Asignar"}
                  </p>
                </div>
              </Col>
            </Row>
          </Col>

          <Col md={isDc ? 5 : 4}>
            <DivSegments>
              <div className="mr-2 ">
                <p className="mb-0 pt-0 mt-1">
                  <strong>Retiro </strong>
                  <span className="number-font font-italic">
                    {moment.utc(withdrawDate).format("DD/MM/YYYY")}
                  </span>{" "}
                </p>
                <p className="mb-0 pt-0 mt-0">{originName?.toLowerCase()}</p>
              </div>
              <AiOutlineArrowRight className="text-primary h1 ml-1 mr-1" />
              <div className="ml-2">
                <p className="mb-0 pt-0 mt-1">
                  <strong>Entrega </strong>
                  <span className="number-font font-italic">
                    {moment.utc(deliveryDate).format("DD/MM/YYYY")}
                  </span>{" "}
                </p>
                <p className="mb-0 pt-0 mt-0">{destinyName?.toLowerCase()}</p>
              </div>
            </DivSegments>
          </Col>
          <Col md={isDc ? 2 : 2}>
            <DivSegments>
              <div className="mr-2 mt-2 mt-sm-0">
                <p className="mb-0 pt-0 mt-0">
                  {priceCalculation(
                    item.price_per_ton,
                    item.discount_trip ||
                      item.discount_dador + item.discount_tomador
                  ) +
                    " / " +
                    item.unit_truck_charge?.slice(0, 3)}
                </p>
                <p className="mb-0 pt-0 mt-0">Carga: {chargeType}</p>
              </div>
            </DivSegments>
          </Col>
          {!isDc && (
            <Col md="2" className="text-center mt-2 mt-sm-0 text-sm-left">
              <p
                className="text-secondary mb-0"
                onClick={() => openCloseModal(item)}
              >
                {item?.id_driver || item?.is_tomador
                  ? "Editar chofer"
                  : "Agregar chofer"}
              </p>
              <p
                className="text-secondary mb-0"
                onClick={() => openCloseModalUnits(item)}
              >
                {item?.id_truck || item?.id_trailer
                  ? "Editar unidad"
                  : "Agregar unidad"}
              </p>
              <p
                className="text-danger mb-0"
                onClick={() => deleteTripdetail(item)}
              >
                {"Eliminar"}
              </p>
            </Col>
          )}
        </Row>
      </Card.Body>
    </CardStyled>
  );
};

CardItemTravelPending.propTypes = {};

export default CardItemTravelPending;
