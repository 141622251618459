/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from "react";
import { Col, Row, Card, Form } from "react-bootstrap";
import styled from "styled-components";
import { AiOutlineArrowDown } from "react-icons/ai";

import useGoogleMapsApi from "../../../../../../../../services/useGoogleMapsApi";
import { useEffect } from "react";
import { Timeline } from "rsuite";
import Spinner from "@rsuite/icons/legacy/Spinner";
import moment from "moment";
import { getTrackingDetails } from "../../../../../../../../api/modules";

const CardLogStyled = styled(Card)`
  padding: 10px 10px;
  border-radius: 6px;
  height: 100%;
  .card-header {
    border: none !important;
  }

  .card-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 991px) {
    height: 400px;
    margin-top: 30px;
  }
`;

const GPS = ({
  detailTrip,
  state,
  origin,
  destiny,
  registerStates,
  showId,
}) => {
  const mapRef = useRef(null);
  const INITIAL_STATE = {
    total: 0,
    items: [],
  };
  const [tripsAPI, setTripsAPI] = useState(INITIAL_STATE);
  const [addLines, setAddLines] = useState(false);
  const googleMapsApi = useGoogleMapsApi();
  const [location, setLocation] = useState({
    lat: -38.4192641,
    lng: -63.5989206,
  });
  let polynes;
  let map;
  let centerControlDiv;
  let centerControl;

  function createCenterControl(map) {
    const controlButton = document.createElement("button");

    // Set CSS for the control.
    controlButton.style.backgroundColor = "#fff";
    controlButton.style.border = "2px solid #fff";
    controlButton.style.borderRadius = "3px";
    controlButton.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlButton.style.color = "rgb(25,25,25)";
    controlButton.style.cursor = "pointer";
    controlButton.style.fontFamily = "Roboto,Arial,sans-serif";
    controlButton.style.fontSize = "16px";
    controlButton.style.fontWeight = 800;
    controlButton.style.width = "40px";
    controlButton.style.lineHeight = "38px";
    controlButton.style.margin = "8px 0 22px";
    controlButton.style.padding = "0 5px";
    controlButton.style.textAlign = "center";
    controlButton.style.justifyContent = "center";
    controlButton.textContent = "Unir";
    controlButton.title = "Unir puntos";
    controlButton.type = "button";
    // Setup the click event listeners: simply set the map to Chicago.
    controlButton.addEventListener("click", () => {
      setAddLines((state) => {
        state ? addLine() : removeLine();
        return !state;
      });
    });
    return controlButton;
  }

  const handleLoadGoogleMap = ({
    lat = "-38.4192641",
    lng = "-63.5989206",
  }) => {
    map = mapRef.current;
    const myLatlng = new googleMapsApi.LatLng(lat.toString(), lng.toString());
    let bounds = new googleMapsApi.LatLngBounds();
    let pathCoordinates = [];
    const mapOptions = {
      zoom: 8,
      center: myLatlng,
      scrollwheel: true,
      zoomControl: true,
      mapTypeId: googleMapsApi.MapTypeId.ROADMAP,
      clickableIcons: true,
      disableDefaultUI: true,
      fullscreenControl: true,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: googleMapsApi.MapTypeControlStyle.HORIZONTAL_BAR,
        position: googleMapsApi.ControlPosition.TOP_LEFT,
      },
      scaleControl: true,
      streetViewControl: true,
    };
    map = new googleMapsApi.Map(map, mapOptions);
    map.addListener("dragend", () => {
      setLocation({
        lat: map.center.lat(),
        lng: map.center.lng(),
      });
    });
    const formatDistance = (distanceMeters) => {
      if (distanceMeters < 1000) {
        return `${Math.round(distanceMeters)} mts`;
      } else {
        return `${(distanceMeters / 1000).toFixed(1)} km`;
      }
    };

    tripsAPI?.items.map((position, index) => {
      const distance = parseFloat(position.address);
      let contentString = `
      <div class='property_title'>Posicion: ${index}/${
        tripsAPI?.items.length - 1
      }</div>
      <div class='property_subTitle'>Velocidad: ${Math.trunc(
        position.speed * 3.6
      )}Km/h</div>
      <div class='property_subTitle'>Fecha:  ${moment(position.dateTime).format(
        "D/MM/YY, HH:mm:ss",
        "America/Buenos_Aires"
      )}</div>
      <div class='property_subTitle'>Precisión: ${formatDistance(distance)}</div>
      `;

      var infowindow = new googleMapsApi.InfoWindow({
        content: contentString,
      });

      let markers = new googleMapsApi.Marker({
        position: {
          lat: Number(position.latitude),
          lng: Number(position.longitude),
        },
        map,
        title: `Posicion:${index}/${tripsAPI?.items.length - 1}`,
        zIndex: index,
      });
      pathCoordinates.push({
        lat: Number(position.latitude),
        lng: Number(position.longitude),
      });
      markers?.addListener("click", function () {
        infowindow.open(map, markers);
      });
      bounds.extend(markers.position);
    });
    tripsAPI && map.fitBounds(bounds);
    polynes = new googleMapsApi.Polyline({
      path: pathCoordinates,
      geodesic: true,
      strokeColor: "#FF0000",
      strokeOpacity: 1,
      strokeWeight: 3,
      map: map,
    });
    centerControlDiv = document.createElement("div");
    // Create the control.
    centerControl = createCenterControl(map);

    // Append the control to the DIV.
    centerControlDiv.appendChild(centerControl);
    centerControlDiv.style.paddingRight = "10px";
    centerControlDiv.style.paddingBottom = "70px";
    map.controls[googleMapsApi.ControlPosition.RIGHT_CENTER].push(
      centerControlDiv
    );
  };

  function addLine() {
    polynes.setMap(map);
    centerControl.style.fontWeight = 800;
  }

  function removeLine() {
    polynes.setMap(null);
    centerControl.style.fontWeight = 300;
  }

  const fetchData = async () => {
    const tripsResponse = await getTrackingDetails(detailTrip);
    const { length, trackings } = tripsResponse;
    if (trackings)
      setTripsAPI({
        ...INITIAL_STATE,
        total: length,
        items: trackings,
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (googleMapsApi) {
      handleLoadGoogleMap(location);
    }
  }, [googleMapsApi, tripsAPI]);

  return (
    <div>
      <Row xs="1" sm="1" md="2" className="mt-4">
        <Col>
          <Row>
            <Col md="6">
              <CardLogStyled className="shadow-4dp">
                <CardLogStyled.Text>
                  <Col className="align-content-center justify-content-center">
                    <p className="text-infoLight mb-0 mt-1 text-capitalize text-center">
                      {origin}
                    </p>
                    <div className="d-flex flex-column align-content-center justify-content-center align-items-center">
                      <AiOutlineArrowDown className="text-primary h1 mr-2" />
                    </div>
                    <p className="text-infoLight mb-0 mt-1 text-capitalize text-center">
                      {destiny}
                    </p>
                  </Col>
                </CardLogStyled.Text>
              </CardLogStyled>
            </Col>
            <Col md="6">
              <CardLogStyled className="shadow-4dp">
                <CardLogStyled.Text className="text-center text-break">
                  <h4 className="font-weight-bolder font-italic h5 mb-0">
                    {showId ? "ID: " + showId : detailTrip}
                  </h4>
                </CardLogStyled.Text>
              </CardLogStyled>
            </Col>
          </Row>

          <Row className="mt-4">
            <Col>
              <p>El transportista confirmó tu pedido</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Timeline
                endless={state === 5 && false} /* className="custom-timeline "*/
              >
                {(state === 3 || state === 4 || state === 5) && (
                  <Timeline.Item>
                    <h5 className="text-uppercase text-primary font-weight-bold">
                      Listo para viajar
                    </h5>

                    <p>
                      {registerStates.find((ele) => ele.state === 3)
                        ? moment(
                            registerStates.find((ele) => ele.state === 3)
                              ?.createdAt
                          ).format("DD/MM/YY, HH:mm:ss")
                        : "No disponible"}
                    </p>
                  </Timeline.Item>
                )}
                {state === 4 || state === 5 ? (
                  <Timeline.Item>
                    <h5 className="text-uppercase text-primary font-weight-bold">
                      Carga Retirada
                    </h5>

                    <p>
                      {registerStates.find((ele) => ele.state === 4)
                        ? moment(
                            registerStates.find((ele) => ele.state === 4)
                              ?.createdAt
                          ).format("DD/MM/YY, HH:mm:ss")
                        : "No disponible"}
                    </p>
                  </Timeline.Item>
                ) : (
                  <Timeline.Item
                    dot={
                      <Spinner
                        spin={true}
                        style={{
                          size: 30,
                          backgroundColor: "white",
                          borderRadius: "50%",
                          fontSize: 36,
                          position: "absolute",
                          padding: "8px",
                          left: "-13px",
                          top: "-13px",
                          borderRadius: "50%",
                        }}
                      />
                    }
                  >
                    <h5 className="text-uppercase text-muted">
                      Carga Retirada
                    </h5>
                  </Timeline.Item>
                )}
                {state === 5 ? (
                  <Timeline.Item>
                    <h5 className="text-uppercase text-primary font-weight-bold">
                      Carga Entregada
                    </h5>
                    <p>
                      {registerStates.find((ele) => ele.state === 5)
                        ? moment(
                            registerStates.find((ele) => ele.state === 5)
                              ?.createdAt
                          ).format("DD/MM/YY, HH:mm:ss")
                        : "No disponible"}
                    </p>
                  </Timeline.Item>
                ) : (
                  <Timeline.Item
                    dot={
                      <Spinner
                        spin={true}
                        style={{
                          size: 30,
                          backgroundColor: "white",
                          borderRadius: "50%",
                          fontSize: 36,
                          position: "absolute",
                          padding: "8px",
                          left: "-13px",
                          top: "-13px",
                          borderRadius: "50%",
                        }}
                      />
                    }
                  >
                    <h5 className="text-uppercase text-muted">
                      Carga Entregada
                    </h5>
                  </Timeline.Item>
                )}
              </Timeline>
            </Col>
          </Row>
        </Col>
        <Col className="d-flex align-items-end">
          <MapContainer>
            <div id="map" ref={mapRef}></div>
          </MapContainer>
        </Col>
      </Row>
    </div>
  );
};

GPS.propTypes = {};

export default GPS;
