import { Formik } from "formik";
import { Col, Row } from "react-bootstrap";
import { CardLogBootstrapStyled } from "../../../../styledComponents/CardBootstrapLog";
import * as Yup from "yup";
import LogSelect from "../../../../components/atoms/LogSelect";
import LogInput from "../../../../components/atoms/LogInput";
import LogButton from "../../../../components/atoms/LogButton";
import {
  DADOR_DE_CARGA,
  TOMADOR_DE_CARGA,
} from "../../../../constants/UserType";

const SearchSchema = Yup.object().shape({
  state: Yup.string().nullable(),
  cuitCreator: Yup.string(),
  cuitClaimed: Yup.string(),
});

const state = [
  { value: 2, label: "Activo" },
  { value: 1, label: "Inactivo" },
  { value: 0, label: "Ambos" },
];

const SearchComponent = ({ onHandleSearchSubmit }) => {
  const handleSubmit = (values) => {
    onHandleSearchSubmit(values);
  };
  return (
    <CardLogBootstrapStyled className="shadow-6dp p-4 ml-2 mr-3 mt-3 mb-4">
      <Row className="mb-3">
        <Col>
          <h1 className="h4 text-primary font-weight-bold">Reclamos</h1>
        </Col>
      </Row>
      <Formik
        enableReinitialize
        initialValues={{
          state: null,
          cuitCreator: "",
          cuitClaimed: "",
        }}
        validationSchema={SearchSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <div>
            <div>
              <Row className="mb-3">
                <Col>
                  <label>Estado</label>
                  <LogSelect
                    placeholder="Estado"
                    defaultValue={""}
                    name="state"
                    items={state}
                    onChange={(v) => {
                      setFieldValue("state", v);
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.state}
                  />
                </Col>
                <Col>
                  <label>Cuit Reclamado</label>
                  <LogInput
                    placeholder="CUIT (sin guiones)"
                    className="mb-2 number-font"
                    type="text"
                    name="cuitClaimed"
                    onChange={(e) => {
                      const { value } = e.target;
                      const re = /^[0-9\b]+$/;
                      if (value === "" || re.test(value)) {
                        setFieldValue("cuitClaimed", value);
                      }
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.cuitClaimed}
                  />
                </Col>
                <Col>
                  <label>Cuit creador</label>
                  <LogInput
                    placeholder="CUIT (sin guiones)"
                    className="mb-2 number-font"
                    type="text"
                    name="cuitCreator"
                    onChange={(e) => {
                      const { value } = e.target;
                      const re = /^[0-9\b]+$/;
                      if (value === "" || re.test(value)) {
                        setFieldValue("cuitCreator", value);
                      }
                    }}
                    onBlur={handleBlur}
                    errors={errors}
                    value={values.cuitCreator}
                  />
                </Col>
              </Row>
            </div>
            <Row>
              <Col className="d-flex justify-content-end">
                <LogButton
                  title={
                    <span
                      className="icon-magnify h5 px-4"
                      style={{ borderRadius: "3px" }}
                    ></span>
                  }
                  onClick={handleSubmit}
                />
              </Col>
            </Row>
          </div>
        )}
      </Formik>
    </CardLogBootstrapStyled>
  );
};

export default SearchComponent;
