import React from "react";
import {
  Col,
  Row,
  Card,
  Image,
} from "react-bootstrap";
import styled from "styled-components";
import ReactStars from "react-rating-stars-component";
import Camion from "../../../../../../../../assets/img/Camion.png";

const CardLogBootstrapStyled = styled(Card)`
  padding: 40px 10px;
  border-radius: 15px;
  width: 300px;
  height: 100%;
  .card-header {
    border: none !important;
  }
`;

const CardLogBootstrapRightStyled = styled(Card)`
  padding: 40px 10px;
  border-radius: 15px;
`;

const AvatarLog = styled(Image)``;

const Driver = (props) => {
  return (
    <div>
      <Row>
        <Col md="6" className="d-flex justify-content-center">
          <CardLogBootstrapStyled className="shadow-4dp">
            <CardLogBootstrapStyled.Header className="d-flex justify-content-center">
              <AvatarLog
                src="https://randomuser.me/api/portraits/men/43.jpg"
                roundedCircle
              />
            </CardLogBootstrapStyled.Header>
            <CardLogBootstrapStyled.Text className="text-center">
              <p className="mb-1 mt-4 font-weight-lighter">Transportista</p>
              <h4 className="black--text font-weight-bold mb-1">
                Juan José Macías
              </h4>
              <p>Yerba Buena, Tucumán.</p>
              <div className="d-flex justify-content-center">
                <ReactStars count={4} size={32} activeColor="#ffd700" />
              </div>
            </CardLogBootstrapStyled.Text>
          </CardLogBootstrapStyled>
        </Col>
        <Col md="6">
          <CardLogBootstrapRightStyled className="shadow-4dp mb-4">
            <CardLogBootstrapRightStyled.Text className="text-center">
              <img src={Camion} alt="" srcset="" />
              <h4 className="black--text font-weight-bold mb-1 mt-4">
                Modelo vehículo
              </h4>
              <p>Yerba Buena, Tucumán.</p>
            </CardLogBootstrapRightStyled.Text>
          </CardLogBootstrapRightStyled>

          <CardLogBootstrapRightStyled className="shadow-4dp">
            <CardLogBootstrapRightStyled.Text className="text-center">
              <h4 className="black--text font-weight-bold mb-2">
                Juan José Macías
              </h4>
              <h1 className="h1 font-weight-bold text-secondaryDark">AR 122 BB.</h1>
            </CardLogBootstrapRightStyled.Text>
          </CardLogBootstrapRightStyled>
        </Col>
      </Row>
    </div>
  );
};

Driver.propTypes = {};

export default Driver;
