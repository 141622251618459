import React, { useEffect, useState } from "react";

// react-bootstrap components
import { Container, Row, Col, Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import LogButton from "../../../../components/atoms/LogButton";
import LocationItem from "../../components/molecules/LocationItem";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { getAllUnitsByUserAPI } from "../../../../api/modules";
import PaginationList from "../../../../components/organisms/pagination-list/PaginationList";
import { COLOR_PRIMARY } from "../../../../constants/Colors";
import { Tab, Tabs, makeStyles } from "@material-ui/core";


const useStyles = makeStyles({
  flexContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
});

const ContainerList = styled.div`
  overflow-y: overlay;
  padding-right: 10px;
`;
const TabsStyled = styled(Tabs)`
  width: 90%;
  margin: 0px 30px;
  @media (max-width: 991px) {
    width: 100%;
    margin: 0px;
    margin-right: 10px;
  }
`;
const TabStyled = styled(Tab)`
  span {
    font-size: 18px;
    font-weight: bolder !important;
  }
`;

const BadgeLog = styled(Badge)`
  background-color: ${COLOR_PRIMARY};
  color: white;
  border-radius: 5px !important;
  text-align: center;
  display: inline-block;
  padding: 0px 20px;
`;

function MyUnits() {
  const classes = useStyles();

  const id = useSelector((state) => state.authApp.user._id);
  const history = useHistory();
  const INITIAL_ENTITY = {
    items: [],
    total: 0,
  };
  const [trucksAPI, setTrucksAPI] = useState(INITIAL_ENTITY);
  const [page, setPage] = useState(0);
  const numberList = 6;
  const [value, setValue] = React.useState(1);
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    fetchData(numberList, page, value);
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, value]);

  const fetchData = async (limit, from = 0, value = 0) => {
    const response = await getAllUnitsByUserAPI(limit, from, value);
    const { trucks, total } = response;
    if (trucks) {
      setTrucksAPI({
        items: trucks,
        total: total,
      });
    } else {
      setTrucksAPI(INITIAL_ENTITY);
    }
  };

  const handlePreviewPage = () => {
    fetchData(numberList, numberList * page - numberList, value);
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    fetchData(numberList, numberList * (page + 1), value);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <TabsStyled
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="simple tabs example"
            scrollButtons="off"
            variant="scrollable"
            classes={{ flexContainer: classes.flexContainer }}
          >
            <TabStyled label="Camiones" value={1} {...a11yProps(1)} />
            <TabStyled label="Acoplados" value={2} {...a11yProps(2)} />
          </TabsStyled>
        </Row>
        <Row>
          <Col md="12">
            <ContainerList>
              {trucksAPI.items.map(
                (
                  { domain, brand, truck_type, max_cargo, model, unit, _id },
                  index
                ) => (
                  <LocationItem
                    noIcon
                    onClick={() =>
                      history.push(
                        `/app/my-units/edit-unit-form/${_id}?type=${
                          truck_type === "1" ? "truck" : "semi"
                        }`
                      )
                    }
                    key={index}
                  >
                    <Row>
                      <Col
                        md="3"
                        className="text-center d-flex justify-content-center align-items-center flex-wrap"
                      >
                        <small style={{ width: "100%" }}>
                          <p className="text-primary text-center mb-1">
                            Tipo de vehículo
                          </p>
                        </small>
                        <br />
                        <BadgeLog as="h5">
                          {truck_type === "1" ? "Camión" : "Acoplado"}
                        </BadgeLog>
                      </Col>
                      <Col md="3" className="text-center">
                        <small>
                          <p className="text-primary text-center mb-1">
                            Modelo
                          </p>
                        </small>
                        <p className="h5 font-weight-bold mt-2">
                          {model || "No posee"}
                        </p>
                      </Col>
                      <Col md="3" className="text-center">
                        <small>
                          <p className="text-primary text-center mb-1">
                            Dominio
                          </p>
                        </small>
                        <p className="h5 font-weight-bold mt-2">{domain}</p>
                      </Col>
                      <Col md="3" className="text-center">
                        <small>
                          <p className="text-primary text-center mb-1">
                            Cantidad de carga maxima
                          </p>
                        </small>
                        <p className="h5 font-weight-bold mt-2">
                          {max_cargo || "No posee"} {unit}
                        </p>
                      </Col>
                    </Row>
                  </LocationItem>
                )
              )}
              {trucksAPI.items.length === 0 && (
                <h2 className="font-weight-bold h3">
                  Aún no tenés ningún vehículo registrado
                </h2>
              )}
            </ContainerList>
          </Col>
          <Col md="12" className="mt-2">
            <PaginationList
              items={trucksAPI.items}
              total={trucksAPI.total}
              currPage={page}
              handlePreviewPage={handlePreviewPage}
              handleNextPage={handleNextPage}
              numberList={numberList}
            />
          </Col>
          <Row className="w-100 mx-0">
            <Col
              md={6}
              className="d-flex justify-content-center justify-content-md-start"
            >
              <LogButton
                className="mt-3 w-100 py-md-3 "
                title="Agregar camión"
                border="curve"
                block
                color="secondaryDark"
                size="md"
                onClick={() =>
                  history.push("/app/my-units/new-unit-form?type=truck")
                }
                style={{ maxWidth: "200px" }}
              />
            </Col>

            <Col
              md={6}
              className="d-flex justify-content-center justify-content-md-end"
            >
              <LogButton
                className="mt-3 w-100 py-md-3 "
                title="Agregar acoplado"
                border="curve"
                block
                size="md"
                onClick={() =>
                  history.push("/app/my-units/new-unit-form?type=semi")
                }
                style={{ maxWidth: "200px" }}
              />
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
}

export default MyUnits;
