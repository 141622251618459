import React from "react";
import styled from "styled-components";
import { COLOR_GRAY_TEXT, COLOR_PRIMARY } from "../../constants/Colors";
import moment from "moment";
import { DateRangePicker } from "rsuite";

const RangeDatePickerStyled = styled(DateRangePicker)`
  background-color: white;
  border: none;
  width: 80%;
  padding: 2px 2px;
  border-radius: 6px;
  box-shadow: 0px 0px 5px #cccccc;
  .rs-picker-toggle-value {
    color: ${COLOR_GRAY_TEXT} !important;
    padding-left: 32px !important;
  }

  .rs-picker-toggle-placeholder {
    padding-left: 32px !important;
  }
  .rs-picker-toggle-caret {
    left: 20px !important;
    color: ${COLOR_PRIMARY} !important;
  }
`;

const RangeDatePicker = ({ placeholder, name, className, ...rest }) => {
  return (
    <RangeDatePickerStyled
      {...{ ...rest, name }}
      className={"number-font"}
      cleanable={false}
      disabledDate={(date) => moment(date).isAfter(moment())}
      placeholder={placeholder}
      format="yyyy-MM-dd"
      appearance="subtle"
      locale={{
        sunday: "Do",
        monday: "Lu",
        tuesday: "Ma",
        wednesday: "Mi",
        thursday: "Jue",
        friday: "Vi",
        saturday: "Sa",
        ok: "Seleccionar",
        today: "Hoy",
        yesterday: "Ayer",
        hours: "Horas",
        minutes: "Minutos",
        seconds: "Segundos",
        last7Days: "7 Días",
      }}
      onSelect={() => {
        const btn = document.querySelector(".rs-btn.rs-btn-primary.rs-btn-sm");
        setTimeout(() => {
          btn.click();
        }, 200);
      }}
      character="/"
    />
  );
};

export default RangeDatePicker;
