import {
  SET_LOADING_SPINNER_ON,
  SET_LOADING_SPINNER_OFF,
  SET_COLLAPSE_ON,
  SET_COLLAPSE_OFF,
  DELETE_NOTIFICATION,

} from "../actionTypes/index";

export function setCollapseOn(){
  return{
    type: SET_COLLAPSE_ON
  }
}
export function setCollapseOff(){
  return{
    type: SET_COLLAPSE_OFF
  }
}

export function setLoadingSpinerOn() {
  return {
    type: SET_LOADING_SPINNER_ON,
  };
}
export function setLoadingSpinerOff() {
  return {
    type: SET_LOADING_SPINNER_OFF,
  };
}
export function deleteNotification(body){
  return {
    type: DELETE_NOTIFICATION,
    payload : body
  }
}

