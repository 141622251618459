import { AiOutlineConsoleSql } from "react-icons/ai";
import { apiHttp } from "../axiosApi.js";
import {
  getLocationsByTrip,
  postTrip,
  tripPostulated2,
} from "../config/apiRoute.js";

export const getTripAPI = (id) => apiHttp("GET", `${postTrip}/${id}`);

export const delTripAPI = (id) => apiHttp("DELETE", `${postTrip}/${id}`);

export const getTripStateAPI = (state = 1, limit = 5, from = 0) =>
  apiHttp("GET", `${postTrip}?state=${state}&limit=${limit}&from=${from}`);

export const getTripsCreatedAPI = (limit = 5, from = 0, state) =>
  apiHttp(
    "GET",
    `${postTrip}/trips-created?limit=${limit}&from=${from}${
      state ? `&state=${state}` : ""
    }`
  );

export const postTripAPI = (body) => apiHttp("POST", `${postTrip}`, body);

export const putTrip = (idTrip, body) =>
  apiHttp("PUT", `${postTrip}/${idTrip}`, body);

export const putAcceptPostulation = (body) =>
  apiHttp("PUT", `${postTrip}/accept-postulation`, body);

export const delCancelPostulation = (body) =>
  apiHttp("DELETE", `${postTrip}/cancel-postulation`, body);

export const delTripChild = (id) => apiHttp("DELETE", `/trip-details/${id}`);

export const getTripsSearchFiltered = (body) =>
  apiHttp("POST", `${postTrip}/trips-filtered`, body);

export const addTrucksToTrip = (body) =>
  apiHttp("PUT", `${postTrip}/add-trucks`, body);

export const executeTrip = (body) =>
  apiHttp("PUT", `${postTrip}/execute-trip`, body);

export const getDetailsTrip = (id_trip) =>
  apiHttp("GET", `/trip-details/${id_trip}`);

export const getDetailsTripTc = (id_trip, id_tomador = false) =>
  apiHttp(
    "GET",
    `/trip/details-t/${id_trip}${id_tomador ? `?id_tomador=${id_tomador}` : ""}`
  );

export const getTripChilds = (
  id,
  state,
  limit = Number.MAX_SAFE_INTEGER,
  from = 0,
  idTc
) =>
  apiHttp(
    "GET",
    `/trip-details/by_id_trip?state=${state}&id_trip=${id}&limit=${limit}&from=${from}${
      idTc ? `&idTc=${idTc}` : ""
    }`
  );

export const getTrackingDetails = (id) => apiHttp("GET", `/tracking/${id}`);

export const getDetailTrip = (id) =>
  apiHttp("GET", `/trip-details/detail/${id}`);

export const assignmentToChildTrip = (id, body) => {
  return apiHttp("PUT", `/trip-details/assignment-web/${id}`, body);
};
/* export const addDriverToDetailTrip = (id, body) => {
 
  return apiHttp("PUT", `/trip-details/${id}/driver`, body);
}; */

export const addTruckToDetailTrip = (id, body) =>
  apiHttp("PUT", `/trip-details/${id}/truck`, body);

// New methods
export const getTripsCreatedByStateAPI = (
  limit = 5,
  from = 0,
  state = 1,
  since,
  to
) =>
  apiHttp(
    "GET",
    `${postTrip}/trips-created-state?limit=${limit}&from=${from}${
      state ? `&state=${state}` : ""
    }${since ? `&since=${since}` : ""}${to ? `&to=${to}` : ""}`
  );

export const getTripsTcCreatedByStateAPI = (
  limit = 5,
  from = 0,
  state = 1,
  since,
  to
) =>
  apiHttp(
    "GET",
    `${tripPostulated2}?limit=${limit}&from=${from}${
      state ? `&state=${state}` : ""
    }${since ? `&since=${since}` : ""}${to ? `&to=${to}` : ""}`
  );

export const getLocationsInAction = () =>
  apiHttp("GET", `${getLocationsByTrip}`);
