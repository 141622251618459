import React, { useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import { getToPostulateTripAPIAll } from "../../../../../api/modules";
import useGoogleMapsApi from "../../../../../services/useGoogleMapsApi";
import dbIcon from "../../../../../assets/img/br_icon.png";
import { AiOutlineConsoleSql } from "react-icons/ai";

const MapOffers = ({ trip_filtered }) => {
 
  const mapRef = useRef(null);
  const googleMapsApi = useGoogleMapsApi();
  const history = useHistory();

  const handleLoadGoogleMap = async () => {
    let map = mapRef.current;
    var bounds = new window.google.maps.LatLngBounds();
    const lat = "-38.4192641";
    const lng = "-63.5989206";
    const myLatlng = new googleMapsApi.LatLng(lat.toString(), lng.toString());
    const mapOptions = {
      zoom: 7,
      center: myLatlng,
      scrollwheel: true,
      zoomControl: true,
      mapTypeId: googleMapsApi.MapTypeId.ROADMAP,
      clickableIcons: false,
      disableDefaultUI: true,
    };
    map = new googleMapsApi.Map(map, mapOptions);
    map.addListener("dragend", () => {
      console.log("dragendListener");
    });

    if (!trip_filtered.length) return;
    const image = dbIcon;
    for (const trip of trip_filtered) {
      const origin = new googleMapsApi.LatLng(
        trip.origin_site.latitude_random,
        trip.origin_site.longitude_random
      );

      class MyMarker extends window.google.maps.OverlayView {
        constructor(params) {
          super();

          this.position = origin;

          const content = document.createElement("div");
          content.classList.add("marker");
          const title = document.createElement("h1");
          const textTitle = document.createTextNode("Detalles del viaje:");
          title.appendChild(textTitle);
          title.classList.add("marker-title");
          content.style.position = "absolute";
          content.style.fontSize = "14px";
          content.style.textAlign = "center";
          content.style.width = "160px";
          content.style.top = "-30px";
          content.style.color = "#70a33e";
          content.style.fontWeight = "500";
          content.style.transform = "translate(-50%, -100%)";
          content.style.transition = "all 0.2s ease";
          const img = document.createElement("img");
          img.src = params.image;
          img.classList.add("marker-icon");
          img.style.width = "22px";
          img.style.height = "32px";
          img.style.position = "absolute";
          img.style.right = "-10px";
          img.style.top = "-30px";
          const container = document.createElement("div");
          container.classList.add("container-icon");
          container.style.position = "absolute";
          container.style.cursor = "pointer";
          content.innerHTML =
            "Origen: " +
            params.origin_site.site_name +
            ". <br/> Destino: " +
            params.destiny_site.site_name;
          content.appendChild(title);
          container.appendChild(content);
          container.appendChild(img);
          container.addEventListener(
            "click",
            function () {
              history.push(
                `/app/my-travls-to-work/detail-travel/${params._id}`
              );
            },
            false
          );
          this.container = container;
        }
        onAdd() {
          this.getPanes().floatPane.appendChild(this.container);
        }
        onRemove() {
          this.container.remove();
        }
        draw() {
          const pos = this.getProjection().fromLatLngToDivPixel(this.position);
          this.container.style.left = pos.x + "2000px";
          this.container.style.top = pos.y + "1000px";
        }
      }
      const marker = new MyMarker({ ...trip, originLatLng: origin, image });

      const circle = new window.google.maps.Circle({
        center:
          origin /* new window.google.maps.LatLng(34.8984684, -88.5951751) */,
        radius: 5000,
        strokeColor: "#FF0000",
        strokeOpacity: 0.3,
        strokeWeight: 1,
        fillColor: "#FDEBD0",
        fillOpacity: 0.3,
      });
      marker.setMap(map);
      circle.setMap(map);
      bounds.extend(marker.position);
      bounds.extend(circle.center);
      map.setOptions({ maxZoom: 8 });
      map.fitBounds(bounds);

      window.google.maps.event.addListenerOnce(map, "idle", function () {
        console.log("paso por el listener");
        map.setOptions({ maxZoom: null });
      });
    }
  };

  useEffect(() => {
    if (googleMapsApi) {
      handleLoadGoogleMap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleMapsApi, trip_filtered]);

  return (
    <Row className="p-0 mb-3 mt-2">
      <Col md="12" className="p-0">
        <div className="map-container">
          <div id="map" ref={mapRef}></div>
        </div>
      </Col>
    </Row>
  );
};

export default MapOffers;
