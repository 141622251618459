import React, { useState } from "react";
import styled from "styled-components";
import { Card, Col, Row } from "react-bootstrap";
import { AiFillStar, AiOutlineArrowRight, AiOutlineStar } from "react-icons/ai";
import SliderLog from "../../../../components/atoms/SliderLog";
import moment from "moment";
import { GoTriangleDown, GoTriangleRight } from "react-icons/go";
import { BsStarFill, BsStars } from "react-icons/bs";
import LogButton from "../../../../components/atoms/LogButton";
import { COLOR_PRIMARY } from "../../../../constants/Colors";
import LogTextArea from "../../../../components/atoms/LogTextArea";
import { Formik } from "formik";
import * as Yup from "yup";
import ReactStars from "react-rating-stars-component";
import { postRating } from "../../../../api/modules";
import { errorToast, successToast } from "../../../../services/toastService";

const CardStyled = styled(Card)`
  border-radius: 15px;
  cursor: pointer;
  padding: 10px 20px;
  &:hover {
    opacity: 0.8;
  }
`;

const DivSegments = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  text-align: left;
  height: 100%;
`;

const Price = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  background-color: #9dc537;
  padding: 2px 10px 2px 10px;
  border-radius: 8px;
  width: 100%;
  color: white;
`;

const Reputation = ({
  score,
  comment,
  id_tripDetail,
  id_trip,
  setReputation,
  refetch,
  tomador,
}) => {
  const ReputationSchema = Yup.object().shape({
    comment: Yup.string().required("Comentario es obligatorio"),
    score: Yup.number().required("El puntaje es obligatorio"),
  });
  const handleSubmitAPI = async (body, resetForm) => {
    const response = await postRating({
      ...body,
      id_tripDetail,
      id_trip,
    });
    const { status, msg } = response;
    if (status !== undefined) {
      errorToast("Por favor intente nuevamente");
    } else {
      resetForm();
      setReputation((state) => !state);
      successToast(msg);
      refetch();
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ score, comment }}
      validationSchema={ReputationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmitAPI(values, resetForm);
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleReset,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Col className="d-flex flex-column align-items-start">
          <p className="text-primary mb-0 font-weight-bold ">Reputación</p>
          <ReactStars
            className="rating-contact-log"
            value={values.score || 0}
            onChange={(score) =>
              handleChange({
                target: {
                  name: "score",
                  value: score,
                },
              })
            }
            edit={!score}
            count={5}
            size={36}
            activeColor="#ffd700"
            color="#CDE0EB"
            /* char={<BsStarFill />} */
          />
          {errors["score"] && (
            <div className="mb-2 mt-0">
              <small className="text-danger">{errors["score"]}.</small>
            </div>
          )}

          <LogTextArea
            placeholder={`Ingresar comentario sobre el ${
              tomador ? "dador" : "tomador"
            }`}
            className="mb-2 number-font"
            name="comment"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            value={values.comment || ""}
            maxLength={160}
            readOnly={score}
          />

          {!score ? (
            <LogButton
              className="mt-0 pt-0 pb-0"
              size="xs"
              title={tomador ? "Calificar dador" : "Calificar tomador"}
              variant={"primary"}
              /* border="curve" */
              block="true"
              onClick={handleSubmit}
            />
          ) : null}
        </Col>
      )}
    </Formik>
  );
};

export default Reputation;
