import { Formik } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import LogButton from "../../../../components/atoms/LogButton";
import LogInput from "../../../../components/atoms/LogInput";
import * as Yup from "yup";

const RecoverPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Correo inválido").required("Correo obligatorio"),
});

const FirstStep = ({ handleSubmitRecoverPassword }) => {
  const history = useHistory();

  return (
    <>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={RecoverPasswordSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmitRecoverPassword(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Row>
            <Col md="5" className="mb-3">
              <a
                href={() => {}}
                className="text-secondary"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/sign-in")}
              >
                <small className="font-weight-bold">Volver</small>
              </a>
            </Col>
            <Col md="7" className="mt-2"></Col>
            <Col md="12">
              <h1 className="h2 mb-0 font-weight-bold">Recuperar contraseña</h1>
              <small className="text-info text-secondary font-weight-bold">
                Escriba su correo donde se iniciara el proceso de cambio de
                contraseña
              </small>
            </Col>
            <Col md="12" className="mt-4">
              <LogInput
                placeholder="Dirección de correo electrónico"
                className="mb-2"
                type="email"
                name="email"
                onChange={handleChange}
                onBlur={handleBlur}
                errors={errors}
                value={values.email}
              />
            </Col>
            <Col md="12">
              <LogButton
                type="submit"
                className="mt-3"
                title="Enviar"
                border="curve"
                block
                onClick={handleSubmit}
              />
            </Col>
          </Row>
        )}
      </Formik>
    </>
  );
};

export default FirstStep;
