import {
    Card,
} from "react-bootstrap";
import styled from 'styled-components';

export const CardLogBootstrapStyled = styled(Card)`
  padding: 10px;
  border-radius: 15px;
  border: none;
`;


