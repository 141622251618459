import axios from "axios";
import { store } from "../index";
import {
  SET_LOADING_SPINNER_OFF,
  SET_LOADING_SPINNER_ON,
  LOGOUT_AUTH,
} from "../redux/actionTypes";
import { errorToast } from "../services/toastService";
import history from "../utils/history";
export const API_URL_BACKEND = process.env.REACT_APP_BACKEND_API;

axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    // Do something before response is sent
    return response;
  },
  (error) => {
    console.error("ERROR: Response interceptor:", error.response);
    // Do something with error response
    if (error.response.status === 401 || error.response.status === 403) {
      // actions to do
      store.dispatch({
        type: LOGOUT_AUTH,
      });
      history.push("/");
      errorToast("Por favor vuelva a loguearse");
    }
    return Promise.reject(error);
  }
);

export const apiHttp = async (
  method,
  endpoint,
  data,
  options = {},
  loading = true
) => {
  const token = store.getState().authApp.token || localStorage?.token; // takes user's token
  const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `${token}`,
    device: "WEB",
  };
  store.dispatch({
    type: SET_LOADING_SPINNER_ON,
  });
  if (!options.hasOwnProperty("headers")) options.headers = defaultHeaders;
  let serviceResponse = {};
  method = method.toLowerCase();
  const servicePromise = axios({
    method,
    url: `${API_URL_BACKEND}${endpoint}`,
    data,
    ...options,
  });

  console.log("👉🏼 Endpoint: ", `${API_URL_BACKEND}${endpoint}`);
  try {
    const [materializedPromise] = await Promise.all([servicePromise]);
    serviceResponse = { ...materializedPromise.data };
  } catch (error) {
    serviceResponse = buildErrorMessage(error);
  }
  store.dispatch({
    type: SET_LOADING_SPINNER_OFF,
  });
  return serviceResponse;
};

function buildErrorMessage(error) {
  console.error("API ERROR", error.response);

  const errorResponse = {
    status: 0,
  };

  try {
    errorResponse.msg =
      error.response.data.msg || error.response.data.errors[0].msg;
    errorResponse.reason = error.response.data.error.name;
    errorResponse.errors = error.response.data.error.message;
    errorResponse.status = error.response.status;
  } catch (error) {
    if (!errorResponse.msg) errorResponse.msg = "Internal error";
  }

  return errorResponse;
}
