import React, { useState } from "react";
import styled from "styled-components";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Stack,
  Tooltip,
} from "react-bootstrap";

import LogButton from "../../../../components/atoms/LogButton";
import ReactStars from "react-rating-stars-component";
import CurrencyFormat from "react-currency-format";
import OpentripModal from "../../views/MyTravels/MyOffers/MyOfferDetail/OpenTripModal";
import { toNumber } from "lodash";
import ModalRating from "../../views/Modal-Rating/ModalRating";
import { useSelector } from "react-redux";
import { priceCalculation } from "../../../../utils/helpers";

const CardStyled = styled(Card)`
  width: 100%;
  border-radius: 15px;
  cursor: pointer;
  padding: 10px 20px;
  &:hover {
    opacity: 0.8;
  }
`;
const StarsContent = styled(Row)`
  display: flex;
  align-content: center;
  height: 30px;
  overflow: hidden;
`;

const CardPostulates = ({
  className,
  handleAcceptPostulation,
  handleCancelPostulation,
  payment_type_name,
  price,
  trucksNumber,
  postulation,
  price_type,
  price_truck_portulation,
  score = 0,
  id_trip,
  discount_trip,
  truck_charge,
  discount_dador = 0,
  ...rest
}) => {
  const [isPostulate, setIsPostulate] = useState(false);
  const [isRating, setIsRating] = useState(false);
  const [postId, setPostId] = useState({});
  const user = useSelector((state) => state.authApp.user);
  const offerPrice = postulation.price_ton_postulation
    ? typeof postulation.discount_trip === "number"
      ? postulation.price_ton_postulation /
        (1 - postulation.discount_trip / 100)
      : postulation.price_ton_postulation /
        (1 -
          ((user.discount_factor || postulation.discount_dador) +
            postulation.discount_tomador) /
            100)
    : postulation.price_per_ton;
  return (
    <CardStyled className={`${className} shadow-4dp m-2`} {...rest}>
      <Card.Body>
        <Row className="flex align-items-center h-sm-100">
          <Col
            md="3"
            className="d-flex cursor-pointer align-items-center justify-content-center justify-content-sm-start "
            onClick={() => {
              if (score) {
                setIsRating(true);
                setPostId({
                  id: postulation._id,
                  score: score ? toNumber(score).toFixed(2) : 0,
                });
              }
            }}
          >
            <Stack>
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-left`}>
                    {score ? "Puntaje " + score : "Sin puntaje"}
                  </Tooltip>
                }
              >
                <StarsContent>
                  <ReactStars
                    value={toNumber(score)}
                    isHalf={true}
                    count={5}
                    size={32}
                    activeColor="#ffd700"
                    color="#CDE0EB"
                    edit={false}
                  />
                </StarsContent>
              </OverlayTrigger>
              <Row className="justify-content-center mb-2 mb-sm-0 ">
                <span className="font-italic">Calificaciones</span>
              </Row>
            </Stack>
          </Col>
          <Col md="5">
            <Col>
              <Stack>
                {price_type === "1" ? (
                  <>
                    {" "}
                    <h5 className="p h5 font-weight-bold text-capitalize">
                      {priceCalculation(offerPrice)}

                      {"/" + (postulation.unit_truck_charge || "unidades")}
                    </h5>
                    <h5 className="p h5 font-weight-bold text-capitalize">
                      {payment_type_name +
                        " " +
                        postulation.payment_terms_days_postulation +
                        " días"}
                    </h5>
                  </>
                ) : (
                  <h5 className="p h5 font-weight-bold">
                    {payment_type_name}{" "}
                    <span className="text-primary font-weight-light">|</span>{" "}
                    <span className="number-font text-capitalize">
                      <CurrencyFormat
                        value={price}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(value) => value}
                      />{" "}
                    </span>
                  </h5>
                )}

                <h5 className="mb-0">
                  <strong>
                    {postulation.number_trucks}{" "}
                    {postulation.number_trucks <= 1 ? "Camión" : "Camiones"}{" "}
                  </strong>
                </h5>
              </Stack>
            </Col>
          </Col>
          <Col md="2" className="text-sm-left text-center my-2 my-sm-0">
            <LogButton
              className={"w-100"}
              title="Eliminar"
              color="infoLight"
              border="curve"
              block
              onClick={handleCancelPostulation}
            ></LogButton>
          </Col>
          <Col md="2" className="text-sm-right text-center">
            {price_type === "1" ? (
              <LogButton
                className={"w-100"}
                title="Detalles"
                color="primary"
                border="curve"
                onClick={() => setIsPostulate(true)}
              ></LogButton>
            ) : (
              <LogButton
                className={"w-100"}
                title="Confirmar"
                color="primary"
                border="curve"
                block
                onClick={handleAcceptPostulation}
              ></LogButton>
            )}
          </Col>
        </Row>
        {price_type === "1" && (
          <OpentripModal
            paymentType={payment_type_name}
            paymentDays={postulation.payment_terms_days_postulation}
            price_ton_postulation={priceCalculation(offerPrice)}
            number_trucks={postulation.number_trucks}
            price_truck_portulation={priceCalculation(
              offerPrice * truck_charge
            )}
            requirements_conditions={postulation.requirements_conditions}
            isPostulate={isPostulate}
            handleAcceptPostulation={handleAcceptPostulation}
            onClosePostulation={(payload) => {
              setIsPostulate(false);
            }}
          />
        )}
      </Card.Body>
      {postId.id && isRating ? (
        <ModalRating
          postId={postId.id}
          score={postId.score}
          isRating={isRating}
          onCloseRating={() => {
            setIsRating(false);
            setPostId({});
          }}
        />
      ) : null}
    </CardStyled>
  );
};

export default CardPostulates;
