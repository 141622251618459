import React from "react";
import styled from "styled-components";
import { Card, Col, Row } from "react-bootstrap";
import { AiOutlineArrowRight } from "react-icons/ai";
import SliderLog from "../../../../components/atoms/SliderLog";

const CardStyled = styled(Card)`
  border-radius: 15px;
  cursor: pointer;
  padding: 10px 20px;
  &:hover {
    opacity: 0.8;
  }
`;

const DivSegments = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: left;
`;

const CardItemTravelTruck = ({
  children,
  onClick,
  className,
  state,
  id,
  btnIcon,
  name,
  lastName,
  truckDomain,
  trailerDomain,
  showId = "n/d",
  ...rest
}) => {
  return (
    <CardStyled
      className={`${className} shadow-4dp m-2 `}
      onClick={onClick}
      {...rest}
    >
      <Card.Body>
        <Row className="flex align-items-center">
          <Col md="2" className="text-center">
            <h5 className="mb-0">
              <strong>
                {state === 3
                  ? "Camino a retirar"
                  : state === 4
                  ? "Carga retirada"
                  : state === 5
                  ? "Carga Entregada"
                  : "Estado 2"}
              </strong>
              {/* <SliderLog /> */}
            </h5>
          </Col>
          <Col md="3" className="text-center">
            <h5 className="mb-3 mb-sm-0">
              <strong className="font-italic">{"ID: "+showId}</strong>
            </h5>
          </Col>
          <Col md="5">
            <DivSegments>
              <div className="mr-4 w-50">
                <p className="mb-0 pt-0 mt-0 text-left">
                  <strong>Chofer</strong>
                </p>
                <p className="mb-0 pt-0 mt-0 text-left text-capitalize">{name}</p>
                <p className="mb-0 pt-0 mt-0 text-left text-capitalize">{lastName}</p>
              </div>
              {/* <AiOutlineArrowRight className="text-primary h1 ml-1 mr-1" /> */}
              <div className="ml-4 w-50">
                <p className="mb-0 pt-0 mt-0 text-left">
                  <strong>Equipo</strong>
                </p>
                <p className="mb-0 pt-0 mt-0 text-left">{truckDomain}</p>
                <p className="mb-0 pt-0 mt-0 text-left">{trailerDomain}</p>
              </div>
            </DivSegments>
          </Col>
          <Col md="2" className="text-center text-sm-right mt-3 mt-sm-0">
            {btnIcon || <p className="text-secondary mb-0">Ver detalle</p>}
          </Col>
        </Row>
      </Card.Body>
    </CardStyled>
  );
};

CardItemTravelTruck.propTypes = {};

export default CardItemTravelTruck;
