import Location from "../views/Locations/Form/Location";
import MyOfferDetail from "../views/MyTravels/MyOffers/MyOfferDetail";
import TravelDetail from "../views/MyTravelsToWork/TravelDetail";
import MyUnit from "../views/MyUnits/Form/MyUnit";
import TravellDetail from "../views/Travel/TravellDetail";
import TruckCharac from "../views/TruckCharac/Form/TruckCharac";
import TruckType from "../views/TruckTypes/Form/TruckType";
import DetailUnit from "../views/Units/Form/DetailUnit";
import UserDetails from "../views/Users/UserDetails/UserDetails";
import UserProfile from "../views/UserProfile";
import MyDriver from "../views/MyDrivers/Form/MyDriver";
import PendingsDetailsTC from "../views/MyTravelsTC/Pendings/PendingDetail";
import TravelsAll from "../views/Travels-All/TravelsAll";
import {
  ADMIN,
  DADOR_DE_CARGA,
  TOMADOR_DE_CARGA,
} from "../../../constants/UserType";

export const locations = ({ name }) => {
  return name === DADOR_DE_CARGA
    ? [
        // Dador de carga ///////////////////////////////////////////////////////////////////////////////////////////////////
        {
          path: "/locations/new-location-form",
          name: "Nueva localización",
          component: Location,
          layout: "/app",
          redirect: true,
        },
        {
          path: "/locations/edit-location-form/:id",
          name: "Editar localización",
          component: Location,
          layout: "/app",
          redirect: true,
        },
        //
        {
          path: "/list-travels/offer-travel/:id",
          name: "Resumen de tu viaje localización",
          component: MyOfferDetail,
          layout: "/app",
          redirect: true,
        },

        // Detalle de oferta de viaje
        {
          path: "/traves/view-travel/:id",
          name: "Resumen de tu viaje localización",
          component: TravellDetail,
          layout: "/app",
          redirect: true,
        },

        // Detalle de oferta de viaje
        {
          path: "/traves/view-travel/",
          name: "Resumen de tu viaje localización",
          component: TravellDetail,
          layout: "/app",
          redirect: true,
        },
        // Perfil de un usuario tercero
        {
          path: "/profile/user/:id",
          name: "Perfil del usuario",
          component: UserProfile,
          layout: "/app",
          redirect: true,
        },
      ]
    : name === TOMADOR_DE_CARGA
    ? [
        // Tomador de carga ////////////////////////////////////////////////////////////////////////
        {
          path: "/my-units/new-unit-form",
          name: "Resumen de tu viaje localización",
          component: MyUnit,
          layout: "/app",
          redirect: true,
        },
        {
          path: "/list-travels/pending-travel-tc/:id",
          name: "Resumen de tu viaje pendiente",
          component: PendingsDetailsTC,
          layout: "/app",
          redirect: true,
        },
        {
          path: "/my-units/edit-unit-form/:id",
          name: "Resumen de tu viaje localización",
          component: MyUnit,
          layout: "/app",
          redirect: true,
        },
        {
          path: "/my-travls-to-work/detail-travel/:id",
          name: "Resumen de tu viaje localización",
          component: TravelDetail,
          layout: "/app",
          redirect: true,
        },
        {
          path: "/my-drivers/detail-driver/:id",
          name: "Resumen de tu viaje localización",
          component: MyDriver,
          layout: "/app",
          redirect: true,
        },
      ]
    : name === ADMIN
    ? [
        // ADMIN //////////////////////////////////////////////////////////////////////////////////////////////////////
        {
          path: "/users/user-profile/:id",
          name: "Resumen de tu viaje localización",
          component: UserDetails,
          layout: "/app",
          redirect: true,
        },
        {
          path: "/units/unit-detail/:id",
          name: "Resumen de tu viaje localización",
          component: DetailUnit,
          layout: "/app",
          redirect: true,
        },
        {
          path: "/truck-types/truck-form",
          name: "Resumen de tu viaje localización",
          component: TruckType,
          layout: "/app",
          redirect: true,
        },
        {
          path: "/truck-charac/charac-form",
          name: "Resumen de tu viaje localización",
          component: TruckCharac,
          layout: "/app",
          redirect: true,
        },
        {
          path: "/travels-all/:id",
          name: "Viajes por ID",
          component: TravelsAll,
          layout: "/app",
          redirect: true,
        },
        {
          path: "/list-travels/offer-travel/:id",
          name: "Resumen de tu viaje localización",
          component: MyOfferDetail,
          layout: "/app",
          redirect: true,
        },
        {
          path: "/traves/view-travel/:id",
          name: "Resumen de tu viaje localización",
          component: TravellDetail,
          layout: "/app",
          redirect: true,
        },

        // Detalle de oferta de viaje
        {
          path: "/traves/view-travel/",
          name: "Resumen de tu viaje localización",
          component: TravellDetail,
          layout: "/app",
          redirect: true,
        },
      ]
    : [];
};
