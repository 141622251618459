import React from "react";
import { Modal } from "react-bootstrap";
import LogButton from "../../atoms/LogButton";
import styled from "styled-components";
import { FiAlertCircle } from "react-icons/fi";
import { COLOR_GRAY_TEXT } from "../../../constants/Colors";
import { BiStar } from "react-icons/bi";

const ModalLogStyled = styled(Modal)`
  opacity: 1;
  .modal-content {
    border-radius: 15px !important;
    padding: 20px;
  }
  p {
    color: ${COLOR_GRAY_TEXT};
  }
`;

const ContentModal = styled.div`
  text-align: center;
`;

const ModalRatings = ({ isShow, handleClose, title, subTitle }) => {
  return (
    <ModalLogStyled
      show={isShow}
      onClose={handleClose}
      onHide={handleClose}
      centered /* className="modal-exit" */
    >
      <Modal.Body>
        <ContentModal>
          <BiStar className="display-3 text-primary mb-3" />

          <h4 className="font-weight-bold text-primary mb-3">
            {title || "Tienes Viajes sin calificar"}
          </h4>
        </ContentModal>
      </Modal.Body>
      <Modal.Footer className="footer-modal">
        <LogButton
          className={"mx-auto mb-2 mb-sm-0"}
          color="secondary"
          onClick={() => handleClose(false)}
          title={"Continuar sin calificar"}
          border="curve"
        ></LogButton>
        <LogButton
          className={"mx-auto"}
          color="primary"
          onClick={() => handleClose(true)}
          title={"Calificar ahora"}
          border="curve"
        ></LogButton>
      </Modal.Footer>
    </ModalLogStyled>
  );
};

export default ModalRatings;
