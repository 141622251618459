import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import styled from "styled-components";
import PropTypes from "prop-types";

/* const LogGoogleLoginStyled = styled(GoogleLogin)`
  background: white;
  width: 100%;
  height: 42px;
  border: 2px solid #c6c3c3 !important;
  border-radius: 20px !important;
  margin-top: 5px !important;
  display: flex;
  justify-content: center;
  text-align: center;
  box-shadow: none !important;
  font-size: 16px !important;

  div {
    height: 30px;
    padding: 0px !important;
    padding-top: 2px !important;
  }
  span {
    font-weight: bold !important;
    color: #505050;
  }
`; */

const LogGoogleBtn = ({ handleCallBackResponse }) => {
  const responseGoogle = (response) => {
    const { credential } = response;
    handleCallBackResponse({ id_token: credential });
  };

  return (
    <GoogleLogin
      clientId="130088755262-lgf96v1iujc3jkdlupvqloeihie2bd6h.apps.googleusercontent.com"
      className="m-4 "
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      autoLoad={false}
      cookiePolicy={"single_host_origin"}
    />
  );
};

LogGoogleBtn.propTypes = {
  handleCallBackResponse: PropTypes.func.isRequired,
};

export default LogGoogleBtn;
