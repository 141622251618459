/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

// react-bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import LogButton from "../../../../components/atoms/LogButton";
import LocationItem from "../../components/molecules/LocationItem";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { getLocationsAPI } from "../../../../api/modules";
import ContentHeader from "../../components/molecules/ContentHeader";
import PaginationList from "../../../../components/organisms/pagination-list/PaginationList";

const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const ContainerList = styled.div`
  // height: 60vh;
  overflow-y: overlay;
  padding-right: 10px;
`;

function Locations() {
  const id = useSelector((state) => state.authApp.user._id);
  const history = useHistory();
  const INITIAL_ENTITY = {
    items: [],
    total: 0,
  };
  const [locations, setLocations] = useState(INITIAL_ENTITY);
  const [page, setPage] = useState(0);
  const numberList = 6;

  useEffect(() => {
    fetchData(numberList, 0);
  }, [id]);

  const fetchData = async (limit = numberList, from = 0) => {
    const response = await getLocationsAPI(6, from);
    const { locations, total } = response;
    if (locations) {
      setLocations({
        items: locations,
        total: total,
      });
    } else {
      setLocations(INITIAL_ENTITY);
    }
  };

  const handlePreviewPage = () => {
    fetchData(numberList, numberList * page - numberList);
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    fetchData(numberList, numberList * (page + 1));
  };

  return (
    <>
      <Container fluid>
        <ContentHeader title="Mis localizaciones" />
        <Row>
          <Col md="12">
            <ContainerList>
              {locations.items.map((location, index) => (
                <LocationItem
                  onClick={() =>
                    history.push(
                      `/app/locations/edit-location-form/${location._id}`
                    )
                  }
                  key={index}
                >
                  <strong>{location.site_name}</strong> {location.address}
                </LocationItem>
              ))}
              {locations.items.length === 0 && (
                <h2 className="font-weight-bold h3">
                  Aún no tenés ninguna localización registrada
                </h2>
              )}
            </ContainerList>
          </Col>
          <Col md="12" className="mt-4">
            <PaginationList
              items={locations.items}
              total={locations.total}
              currPage={page}
              handlePreviewPage={handlePreviewPage}
              handleNextPage={handleNextPage}
              numberList={numberList}
            />
          </Col>
          <Col md="12">
            <LogButton
              className="mt-3"
              title="Agregar nueva localización"
              border="curve"
              size="lg"
              block
              onClick={() => history.push("/app/locations/new-location-form")}
            />
            <div className="clearfix"></div>
          </Col>
        </Row>
      </Container>
      <RowStyled></RowStyled>
    </>
  );
}

export default Locations;
