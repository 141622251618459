import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import CardItemTravelBasic from "../../../../components/molecules/CardItemTravelBasic";
import { GoTriangleRight } from "react-icons/go";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import PaginationList from "../../../../../../components/organisms/pagination-list/PaginationList";
import { getAllTrips } from "../../../../../../api/modules/admin";


const ContainerList = styled.div`
  height: auto;
  overflow-y: overlay;
  padding-right: 10px;
`;
const DivEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
`;
const RowStyled = styled(Row)`
  position: relative;
  width: 100%;
  bottom: 0px;
  margin-top: 20px;
`;

const InActionsTravels = ({ id, searchQuery, ...rest }) => {
  const history = useHistory();
  const spinner = useSelector((state) => state.appReducer.isLoading);
  const [page, setPage] = useState(0);
  const numberList = 5;
  const INITIAL_STATE = {
    total: 0,
    items: [],
  };
  const [tripsAPI, setTripsAPI] = useState(INITIAL_STATE);
  const [query, setQuery] = useState({
    limit: numberList,
    from: page,
    state : "[3,4]",
    user_id: id,
    status: true,
  });

  const handlePreviewPage = () => {
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * page - numberList,
      };
    });
    setPage((prev) => prev - 1);
  };
  const handleNextPage = () => {
    setPage((prev) => prev + 1);
    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: numberList * (page + 1),
      };
    });
  };
  const fetchData = async () => {
    const tripsResponse = await getAllTrips({ ...query, ...searchQuery });

    const { trips, total } = tripsResponse;
    if (trips)
    setTripsAPI({
        ...INITIAL_STATE,
        items: trips,
        total,
      });
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {

    setQuery((prev) => {
      return {
        ...prev,
        limit: numberList,
        from: 0,
      };
    });
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);
  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <ContainerList>
              {tripsAPI.items.length ? (
                tripsAPI?.items.map((location, index) => (
                  <CardItemTravelBasic
                    {...rest}
                    state="Ejecución"
                    btnIcon={<GoTriangleRight />}
                    key={index}
                    originName={location.origin_site.site_name}
                    destinyName={location.destiny_site.site_name}
                    withdrawDate={location.withdraw_date}
                    deliveryDate={location.delivery_date}
                    onClick={() => {
                      history.push(
                        `?tripId=${location._id || "0"}`
                      );
                    }}
                    trucks_number_accepted={location.counterTripdetails}
                    trucksNumber={location.trucks_number}
                    showId={location.id_trip}
                  />
                ))
              ) : (
                <DivEmpty>
                  {!spinner ? (
                    <h2 className="font-weight-bold h3 text-center">
                      No hay viajes disponibles
                    </h2>
                  ) : null}
                </DivEmpty>
              )}
            </ContainerList>
          </Col>
        </Row>
      </Container>
      <RowStyled>
        <Col md="12">
          <PaginationList
            items={tripsAPI.items}
            total={tripsAPI.total}
            currPage={page}
            handlePreviewPage={handlePreviewPage}
            handleNextPage={handleNextPage}
            numberList={numberList}
          />
        </Col>
      </RowStyled>
    </>
  );
};

export default InActionsTravels;
