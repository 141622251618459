import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { BiEdit } from "react-icons/bi";
import { Checkbox, Table, Whisper, Popover } from "rsuite";
import { getBillingsAdmin, getUserPending } from "../../../../../api/modules";
import SearchToInvoice from "./Components/SearchToInvoice";
import ModalAuditedWeight from "./Components/ModalAuditedWeight";
import LogButton from "../../../../../components/atoms/LogButton";
import ModalInvoice from "./Components/ModalInvoice";
import ModalReceipt from "./Components/Modalreceipt";
import ModalLp from "./Components/ModalLp";
import { CardLogBootstrapStyled } from "../../../../../styledComponents/CardBootstrapLog";
const { Column, HeaderCell, Cell, ColumnGroup } = Table;
const TAXES_LABEL = process.env.REACT_APP_TAXES_LABEL;

function BillingAdmin() {
  const [showAudited, setShowAudited] = useState({ isShow: false, data: {} });
  const [invoiceModal, setInvoiceModal] = useState({ isShow: false, data: {} });
  const [receiptModal, setReceiptModal] = useState({ isShow: false, data: {} });
  const [lpModal, setLpModal] = useState({
    isShow: false,
    data: {},
    amount: 0,
  });
  const INITIAL_STATE = {
    total: 0,
    data: [],
    id_dador: null,
    id_tomador: null,
  };
  let checked = false;
  let indeterminate = false;
  const [dadorBalance, setDadorBalance] = useState("");
  const [dadorInvoice, setDadorInvoice] = useState("");
  const [tomadorBalance, setTomadorBalance] = useState("");
  const [tomadorLp, setTomadorLp] = useState("");

  const [refetch, setRefetch] = useState(false);
  const [billing, setBilling] = useState(INITIAL_STATE);
  const [checkeableRows, setCheckeableRows] = useState(INITIAL_STATE);
  const [query, setQuery] = useState({ id_tomador: "", id_dador: "" });
  const [pendingDador, setPendingDador] = useState([]);
  const [pendingTomador, setPendingTomador] = useState([]);
  const [selectedDador, setSelectedDador] = useState("");
  const [checkedKeys, setCheckedKeys] = React.useState([]);
  const closeAuditedModal = () => setShowAudited({ isShow: false, data: {} });
  const openAuditedModal = (rowData) =>
    setShowAudited({ isShow: true, data: rowData });
  const closeReceiptModal = () => setReceiptModal({ isShow: false, data: {} });
  const openReceiptModal = (rowData) =>
    setReceiptModal({ isShow: true, data: rowData });
  const closeLpModal = () => setLpModal({ isShow: false, data: {}, amount: 0 });
  const openLpModal = (rowData) => {
    const trips = rowData.invoice_id?.trip_details;
    const filtered = billing.data?.filter((ele) => trips?.includes(ele._id));
    const amount = filtered.reduce((accumulator, rowData) => {
      const discount = rowData.discount_trip
        ? rowData.price_per_ton *
          rowData.auditedWeight *
          (rowData.discount_trip / 100)
        : rowData.price_per_ton *
          rowData.auditedWeight *
          ((rowData.discount_dador + rowData.discount_tomador) / 100);

      return accumulator + parseFloat(discount.toFixed(2));
    }, 0);
    setLpModal({ isShow: true, data: rowData, amount: amount });
  };

  const CheckCell = ({ rowData, onChange, checkedKeys, dataKey, ...props }) => (
    <Cell {...props} style={{ padding: 0 }}>
      <div style={{ lineHeight: "20px" }}>
        <Checkbox
          style={{ marginLeft: "auto", marginRight: "auto" }}
          value={rowData[dataKey]}
          inline
          onChange={onChange}
          checked={checkedKeys.some((item) => item === rowData[dataKey])}
          disabled={
            !checkeableRows.data.some((item) => item._id === rowData._id)
          }
        />
      </div>
    </Cell>
  );

  const handleCheckAll = (value, checked) => {
    const keys = checked
      ? checkeableRows.data.map((item) => {
          if (typeof item.auditedWeight === "number") return item._id;
        })
      : [];
    setCheckedKeys(keys);
  };
  const handleCheck = (value, checked) => {
    const keys = checked
      ? [...checkedKeys, value]
      : checkedKeys.filter((item) => item !== value);
    setCheckedKeys(keys);
  };
  const NameCell = ({ rowData, dataKey, ...props }) => {
    const speaker = (
      <Popover>
        <Container>
          <h6 className="h6 text-primary font-weight-bold">Dador</h6>

          <p className="m-0">
            <b>Cuit:</b> {rowData.id_dador?.cuit}
          </p>
          <p className="m-0">
            <b>Mail:</b> {rowData.id_dador?.email}
          </p>
        </Container>

        <Container className="border-top mt-2 pt-2">
          <h6 className="h6 text-primary font-weight-bold">Tomador</h6>
          <p className="m-0">
            <b>Cuit:</b> {rowData.id_tomador?.cuit}
          </p>
          <p className="m-0">
            <b>Mail:</b> {rowData.id_tomador?.email}
          </p>
        </Container>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="top" speaker={speaker}>
          <a className="mb-0">
            {rowData.parent_id + rowData.order || "No posee"}{" "}
          </a>
        </Whisper>
      </Cell>
    );
  };

  const handleInvoice = () => {
    if (checkedKeys.length > 0) {
      const toInvoice = checkeableRows.data.filter((row) =>
        checkedKeys.includes(row._id)
      );
      setInvoiceModal((prev) => {
        return { data: toInvoice, isShow: true };
      });
    }
  };

  const calculateDadorBalance = () => {
    const uniqueInvoiceIds = new Set();
    let sum = 0;

    billing.data?.forEach((item) => {
      const invoiceId = item.invoice_id?._id;

      if (typeof invoiceId === "string" && !uniqueInvoiceIds.has(invoiceId)) {
        uniqueInvoiceIds.add(invoiceId);

        const amount = item.invoice_id?.amount || 0;
        const receiptAmounts =
          item.invoice_id?.receipts?.map((receipt) => receipt.receipt_amount) ||
          [];

        const totalReceiptAmount = receiptAmounts.reduce(
          (accumulator, currentItem) => accumulator + currentItem,
          0
        );

        sum += amount - totalReceiptAmount;
      }
    });

    setDadorBalance(sum);
  };
  const calculateTomadorBalance = () => {
    const uniqueLpIds = new Set();
    let sum = 0;

    billing.data?.forEach((item) => {
      const lpId = item.lp_id?._id;

      if (typeof lpId === "string" && !uniqueLpIds.has(lpId)) {
        uniqueLpIds.add(lpId);

        const amount = item.lp_id?.amount || 0;
        const paymentOrderAmounts =
          item.lp_id?.paymentOrder_id?.map(
            (paymentOrder) => paymentOrder.amount
          ) || [];

        const totalPaymentOrderAmount = paymentOrderAmounts.reduce(
          (accumulator, currentItem) => accumulator + currentItem,
          0
        );

        sum += amount - totalPaymentOrderAmount;
      }
    });

    setTomadorBalance(sum);
  };

  const calculateInvoiceDador = () => {
    const uniqueInvoiceIds = new Set();
    let sum = 0;

    billing.data?.forEach((item) => {
      const invoiceId = item.invoice_id?._id;

      if (typeof invoiceId === "string" && !uniqueInvoiceIds.has(invoiceId)) {
        uniqueInvoiceIds.add(invoiceId);

        const amount = item.invoice_id?.amount || 0;

        sum += amount;
      }
    });

    setDadorInvoice(sum);
  };
  const calculateLpTomador = () => {
    const uniqueLpIds = new Set();
    let sum = 0;

    billing.data?.forEach((item) => {
      const lpId = item.lp_id?._id;

      if (typeof lpId === "string" && !uniqueLpIds.has(lpId)) {
        uniqueLpIds.add(lpId);

        const amount = item.lp_id?.amount || 0;

        sum += amount;
      }
    });

    setTomadorLp(sum);
  };
  const getData = async () => {
    try {
      const { total, accounts, id_dador, id_tomador } = await getBillingsAdmin(
        query
      );
      setBilling({ total, data: accounts, id_dador, id_tomador });
      if (receiptModal.data) {
        let updatedData = accounts.find(
          (ele) => ele._id === receiptModal.data._id
        );
        setReceiptModal((prev) => {
          return { ...prev, data: updatedData };
        });
      }
      if (lpModal.data) {
        let updatedData = accounts.find((ele) => ele._id === lpModal.data._id);
        setLpModal((prev) => {
          return { ...prev, data: updatedData };
        });
      }
    } catch (error) {
      console.error("Billing Admin", error);
    }
  };
  const onRefetch = () => setRefetch((prev) => !prev);
  const getUsers = async () => {
    try {
      const { tomador, dador } = await getUserPending();
      setPendingDador(dador);
      setPendingTomador(tomador);
    } catch (error) {
      console.error("Get pending to audited", error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getData();
    setCheckedKeys([]);
  }, [refetch, query]);

  useEffect(() => {
    calculateDadorBalance();
    calculateInvoiceDador();
    calculateTomadorBalance();
    calculateLpTomador();
    const areDadorsEqual = billing.data?.every(
      (row) => row.id_dador._id === billing.data[0].id_dador._id
    );
    const areTomadorsEqual = billing.data?.every(
      (row) => row.id_tomador._id === billing.data[0].id_tomador._id
    );
    if (!areDadorsEqual || !areTomadorsEqual) {
      setCheckeableRows(INITIAL_STATE);
      return;
    }

    const filteredRows = billing.data.filter((row) => {
      return (
        typeof row.auditedWeight === "number" &&
        !row.invoice_id &&
        row.id_dador._id === billing.data[0].id_dador._id &&
        row.id_tomador._id === billing.data[0].id_tomador._id
      );
    });
    setCheckeableRows({
      data: filteredRows,
      total: filteredRows.length,
    });
  }, [billing]);

  if (checkedKeys.length === checkeableRows?.total) {
    checked = true;
  } else if (checkedKeys.length === 0) {
    checked = false;
  } else if (
    checkedKeys.length > 0 &&
    checkedKeys.length < checkeableRows?.total
  ) {
    indeterminate = true;
  }
  return (
    <Container fluid>
      <SearchToInvoice
        dador={pendingDador}
        tomador={pendingTomador}
        query={query}
        setQuery={setQuery}
        selectedDador={selectedDador}
        setSelectedDador={setSelectedDador}
      />

      <Row className="mt-4">
        <Col md="12">
          <h1 className=" h6 font-weight-bold font-italic">
            {TAXES_LABEL || "Montos netos sin impuestos"}
          </h1>
        </Col>
        <Col md="12">
          <Table
            rowHeight={30}
            hover={false}
            height={550}
            bordered
            cellBordered
            headerHeight={70}
            wordWrap={true}
            data={billing.data}
            rowClassName="p-0"
            onRowClick={(rowData, event) => {
              const clickedElementClass = event.target.className;
              if (
                typeof clickedElementClass !== "string" ||
                clickedElementClass.includes("rs-checkbox-wrapper") ||
                clickedElementClass instanceof SVGAnimatedString
              ) {
                return;
              }

              setSelectedDador(rowData?.id_dador?._id);
            }}
          >
            <ColumnGroup header="Viajes Finalizados" align="center">
              <Column flexGrow={1}>
                <HeaderCell>
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                    }}
                  >
                    <Checkbox
                      style={{ marginLeft: "auto", marginRight: "auto" }}
                      inline
                      checked={checked}
                      indeterminate={indeterminate}
                      onChange={handleCheckAll}
                      disabled={checkeableRows.total === 0}
                    />
                  </div>
                </HeaderCell>

                <CheckCell
                  dataKey="_id"
                  checkedKeys={checkedKeys}
                  onChange={handleCheck}
                />
              </Column>
              <Column flexGrow={2}>
                <HeaderCell>ID viaje</HeaderCell>
                <NameCell dataKey="ID_VIAJE" />
              </Column>
              <Column flexGrow={3}>
                <HeaderCell>Importe</HeaderCell>
                <Cell dataKey="billing_amount">
                  {(rowData) => {
                    return typeof rowData.auditedWeight === "number" ? (
                      <CurrencyFormat
                        value={rowData.price_per_ton * rowData.auditedWeight}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix={"$"}
                        renderText={(formattedValue) => (
                          <div>
                            <span>{formattedValue}</span>
                            {rowData.invoice_id ? null : (
                              <BiEdit
                                className="cursor-pointer ml-1"
                                onClick={() => openAuditedModal(rowData)}
                              />
                            )}
                          </div>
                        )}
                      />
                    ) : (
                      <div>
                        <span>Auditar Peso </span>
                        <BiEdit
                          className="cursor-pointer ml-1 mb-1"
                          onClick={() => openAuditedModal(rowData)}
                        />
                      </div>
                    );
                  }}
                </Cell>
              </Column>
            </ColumnGroup>
            <ColumnGroup header="Dador" align="center">
              <Column
                flexGrow={3}
                verticalAlign="top"
                rowSpan={(rowData) => rowData.row_span}
              >
                <HeaderCell>Nro. Factura</HeaderCell>
                <Cell dataKey="billing_number">
                  {(rowData) => {
                    return rowData.invoice_id?.invoice_number ? (
                      <>
                        <span>{rowData.invoice_id?.invoice_number + " "}</span>
                        <BiEdit
                          className="cursor-pointer ml-1 mb-1"
                          onClick={() => openReceiptModal(rowData)}
                        />
                      </>
                    ) : (
                      <p className="mb-0">No posee</p>
                    );
                  }}
                </Cell>
              </Column>
              <Column
                flexGrow={3}
                align="center"
                rowSpan={(rowData) => rowData.row_span}
              >
                <HeaderCell>Saldo Factura</HeaderCell>
                <Cell dataKey="invoice_balance">
                  {(rowData) => {
                    return rowData.invoice_id?.invoice_number ? (
                      <CurrencyFormat
                        value={
                          rowData.invoice_id?.amount -
                          rowData.invoice_id?.receipts?.reduce(
                            (accumulator, currentItem) => {
                              return accumulator + currentItem.receipt_amount;
                            },
                            0
                          )
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix={"$"}
                      />
                    ) : (
                      <p className="mb-0">No posee</p>
                    );
                  }}
                </Cell>
              </Column>
              <Column flexGrow={2}>
                <HeaderCell>Comision</HeaderCell>
                <Cell dataKey="comision_dador">
                  {(rowData) => {
                    return (
                      <>
                        <p className="mb-0">
                          {rowData.discount_dador
                            ? rowData.discount_dador + "%"
                            : "No posee"}{" "}
                        </p>
                      </>
                    );
                  }}
                </Cell>
              </Column>
            </ColumnGroup>

            <ColumnGroup header="Tomador" align="center">
              <Column flexGrow={3} rowSpan={(rowData) => rowData.row_span}>
                <HeaderCell>L. P.</HeaderCell>
                <Cell dataKey="lp_number">
                  {(rowData) => {
                    return rowData.invoice_id?.invoice_number ? (
                      rowData.lp_id ? (
                        <>
                          <span>{rowData.lp_id?.number + " "}</span>
                          <BiEdit
                            className="cursor-pointer ml-1 mb-1"
                            onClick={() => openLpModal(rowData)}
                          />
                        </>
                      ) : (
                        <>
                          <span>Cargar LP</span>
                          <BiEdit
                            className="cursor-pointer ml-1 mb-1"
                            onClick={() => openLpModal(rowData)}
                          />
                        </>
                      )
                    ) : (
                      <p className="mb-0">No posee</p>
                    );
                  }}
                </Cell>
              </Column>
              <Column flexGrow={3} rowSpan={(rowData) => rowData.row_span}>
                <HeaderCell>Saldo LP</HeaderCell>
                <Cell dataKey="tomador_balance">
                  {(rowData) => {
                    return rowData.lp_id?.number ? (
                      <CurrencyFormat
                        value={
                          rowData.lp_id?.amount -
                          rowData.lp_id?.paymentOrder_id?.reduce(
                            (accumulator, currentItem) => {
                              return accumulator + currentItem.amount;
                            },
                            0
                          )
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix={"$"}
                      />
                    ) : (
                      <p className="mb-0">No posee</p>
                    );
                  }}
                </Cell>
              </Column>
              <Column flexGrow={2}>
                <HeaderCell>Comision</HeaderCell>
                <Cell dataKey="comision_tomador">
                  {(rowData) => {
                    return (
                      <>
                        <p className="mb-0">
                          {rowData.discount_tomador
                            ? rowData.discount_tomador + "%"
                            : "No posee"}
                        </p>
                      </>
                    );
                  }}
                </Cell>
              </Column>
            </ColumnGroup>
            <ColumnGroup header="Comision" align="center">
              <Column flexGrow={2}>
                <HeaderCell>% Viaje</HeaderCell>
                <Cell dataKey="comision_trip">
                  {(rowData, rowIndex) => {
                    return (
                      <>
                        <p className="mb-0">
                          {typeof rowData.discount_trip === "number"
                            ? rowData.discount_trip
                            : "N/D"}{" "}
                        </p>
                      </>
                    );
                  }}
                </Cell>
              </Column>
              <Column flexGrow={3}>
                <HeaderCell>Importe</HeaderCell>
                <Cell dataKey="discount_amount">
                  {(rowData) => {
                    return (
                      <CurrencyFormat
                        value={
                          typeof rowData.auditedWeight === "number"
                            ? rowData.discount_trip
                              ? rowData.price_per_ton *
                                rowData.auditedWeight *
                                (rowData.discount_trip / 100)
                              : rowData.price_per_ton *
                                rowData.auditedWeight *
                                ((rowData.discount_dador +
                                  rowData.discount_tomador) /
                                  100)
                            : "No posee"
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        prefix={"$"}
                        renderText={(formattedValue) => (
                          <span>{formattedValue}</span>
                        )}
                      />
                    );
                  }}
                </Cell>
              </Column>
            </ColumnGroup>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center mt-2">
          <LogButton
            className="w-50"
            color="primary"
            onClick={handleInvoice}
            title={"Facturar Seleccionados"}
            border="curve"
            disabled={checkedKeys.length < 1}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <CardLogBootstrapStyled className="shadow-6dp p-4 ml-0 mr-0 mt-3 mb-4">
            <Row className="ml-0  mb-2">
              <h5 className="h5 text-primary font-weight-bold ">
                {billing.id_dador && billing.data[0]?.id_dador?.email
                  ? billing.data[0]?.id_dador?.email
                  : "Dadores"}
              </h5>
            </Row>
            <Row>
              <Col xs={6}>
                {" "}
                <label className="black-primary font-weight-bold mr-2">
                  Importe total viajes:
                </label>
              </Col>
              <Col xs={6}>
                <CurrencyFormat
                  value={billing.data
                    ?.filter((item) => typeof item.auditedWeight === "number")
                    ?.reduce((sum, item) => {
                      return sum + item.price_per_ton * item.auditedWeight;
                    }, 0)}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                  renderText={(formattedValue) => (
                    <div>
                      <span>{formattedValue}</span>
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                {" "}
                <label className="black-primary font-weight-bold mr-2">
                  Importe total facturado:
                </label>
              </Col>
              <Col xs={6}>
                <CurrencyFormat
                  value={dadorInvoice}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                  renderText={(formattedValue) => (
                    <div>
                      <span>{formattedValue}</span>
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                {" "}
                <label className="black-primary font-weight-bold mr-2">
                  Saldo de facturas:
                </label>
              </Col>
              <Col xs={6}>
                <CurrencyFormat
                  value={dadorBalance}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                  renderText={(formattedValue) => (
                    <div>
                      <span>{formattedValue}</span>
                    </div>
                  )}
                />
              </Col>
            </Row>
          </CardLogBootstrapStyled>
        </Col>
        <Col md={6}>
          <CardLogBootstrapStyled className="shadow-6dp p-4 ml-0 mr-0 mt-3 mb-4">
            <Row className="ml-0  mb-2">
              <h5 className="h5 text-primary font-weight-bold">
                {billing.id_tomador && billing.data[0]?.id_tomador?.email
                  ? billing.data[0]?.id_tomador?.email
                  : "Tomadores"}
              </h5>
            </Row>
            <Row>
              <Col xs={6}>
                {" "}
                <label className="black-primary font-weight-bold mr-2">
                  Importe total viajes:
                </label>
              </Col>
              <Col xs={6}>
                <CurrencyFormat
                  value={
                    billing.data
                      ?.filter((item) => typeof item.auditedWeight === "number")
                      ?.reduce((sum, item) => {
                        return sum + item.price_per_ton * item.auditedWeight;
                      }, 0) -
                    billing.data
                      ?.filter((item) => typeof item.auditedWeight === "number")
                      ?.reduce((sum, item) => {
                        return (
                          sum +
                          (item.discount_trip
                            ? item.price_per_ton *
                              item.auditedWeight *
                              (item.discount_trip / 100)
                            : item.price_per_ton *
                              item.auditedWeight *
                              ((item.discount_dador + item.discount_tomador) /
                                100))
                        );
                      }, 0)
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                  renderText={(formattedValue) => (
                    <div>
                      <span>{formattedValue}</span>
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                {" "}
                <label className="black-primary font-weight-bold mr-2">
                  Importe total LP:
                </label>
              </Col>
              <Col xs={6}>
                <CurrencyFormat
                  value={tomadorLp}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                  renderText={(formattedValue) => (
                    <div>
                      <span>{formattedValue}</span>
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                {" "}
                <label className="black-primary font-weight-bold mr-2">
                  Saldo de LP:
                </label>
              </Col>
              <Col xs={6}>
                <CurrencyFormat
                  value={tomadorBalance}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={"$"}
                  renderText={(formattedValue) => (
                    <div>
                      <span>{formattedValue}</span>
                    </div>
                  )}
                />
              </Col>
            </Row>
          </CardLogBootstrapStyled>
        </Col>
      </Row>

      {showAudited?.isShow ? (
        <ModalAuditedWeight
          isShow={showAudited.isShow}
          data={showAudited.data}
          handleClose={closeAuditedModal}
          refetch={onRefetch}
        />
      ) : null}
      {invoiceModal?.isShow ? (
        <ModalInvoice
          isShow={invoiceModal.isShow}
          data={invoiceModal.data}
          handleClose={() => {
            setInvoiceModal((prev) => {
              return { ...prev, isShow: false };
            });
          }}
          refetch={onRefetch}
        />
      ) : null}
      {receiptModal?.isShow ? (
        <ModalReceipt
          isShow={receiptModal.isShow}
          data={receiptModal.data}
          handleClose={closeReceiptModal}
          refetch={onRefetch}
        />
      ) : null}
      {lpModal?.isShow ? (
        <ModalLp
          isShow={lpModal.isShow}
          data={lpModal.data}
          amount={lpModal.amount}
          handleClose={closeLpModal}
          refetch={onRefetch}
        />
      ) : null}
    </Container>
  );
}

export default BillingAdmin;
